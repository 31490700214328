import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { TimelineMax, Power3 } from 'gsap';
import { Theme } from '@material-ui/core/styles';

import { primaryMain, secondaryMain } from 'oc-theme/lib';
import Particles from 'oc-animation/lib/Particles';
import { PARTICLES_HOW_SYSTEM_WORKS_STEP } from '@public/constants/particles';

const WINDOW_CLASS_NAME = 'step__window';
const WINDOW_CIRCLES_CLASS_NAME = 'window__circles';
const WINDOW_DASHES_CLASS_NAME = 'window__dashes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svg: {
      position: 'relative',
      width: theme.spacing(62),
    },
    window: {
      fill: 'none',
      strokeLinejoin: 'round',
      strokeMiterlimit: 3,
      strokeWidth: '2px',
    },
  }),
);

export type AnimationType = (
  tl: TimelineMax,
  componentRef?: React.RefObject<HTMLDivElement | null>,
) => void;

interface HowSystemWorksStepProps {
  className?: string;
  windowContentAnimation: AnimationType;
  manAnimation: AnimationType;
  onPause?: AnimationType;
  windowContentSvg: ReactElement;
  manSvg: ReactElement;
  isActive?: boolean;
}

const windowAnimation: AnimationType = (tl, componentRef) => {
  if (componentRef && componentRef.current) {
    const windowEl: NodeListOf<Element> = componentRef.current.querySelectorAll(
      `.${WINDOW_CLASS_NAME} *`,
    );

    tl.fromTo(
      windowEl,
      1,
      { drawSVG: '100%', ease: Power3.easeIn },
      {
        drawSVG: '0',
        ease: Power3.easeIn,
      },
      0,
    ).to(windowEl, 1, { drawSVG: '100%', ease: Power3.easeIn });

    const circlesAndDashes: NodeListOf<Element> = componentRef.current.querySelectorAll(
      `.${WINDOW_CIRCLES_CLASS_NAME} *, .${WINDOW_DASHES_CLASS_NAME} *`,
    );

    tl.staggerFromTo(
      circlesAndDashes,
      0.1,
      {
        drawSVG: '100%',
        ease: Power3.easeIn,
      },
      { drawSVG: '0', ease: Power3.easeIn },
      0.1,
      0,
    ).staggerTo(
      circlesAndDashes,
      0.1,
      {
        drawSVG: '100%',
        ease: Power3.easeIn,
      },
      0.1,
    );
  }
};

const initAnimation = (
  windowContentAnimation: AnimationType,
  manAnimation: AnimationType,
  timeline: TimelineMax,
  componentRef: React.RefObject<HTMLDivElement | null>,
): void => {
  timeline.restart();
  windowAnimation(timeline, componentRef);
  windowContentAnimation(timeline);
  manAnimation(timeline);
};

const HowSystemWorksStepImage: FC<HowSystemWorksStepProps> = ({
  windowContentAnimation,
  manAnimation,
  onPause,
  windowContentSvg,
  manSvg,
  isActive,
}) => {
  const classes = useStyles();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const timeline: TimelineMax = new TimelineMax();

  useEffect((): void => {
    if (isActive && !document.hidden) {
      initAnimation(windowContentAnimation, manAnimation, timeline, componentRef);
    } else {
      timeline.kill();

      if (onPause) {
        onPause(timeline);
      }
    }
  }, [isActive, manAnimation, onPause, componentRef, timeline, windowContentAnimation]);

  const particlesElement: ReactElement = (
    <Particles
      particles={PARTICLES_HOW_SYSTEM_WORKS_STEP}
      animationDurationMin={1}
      animationDurationMax={4}
    />
  );

  const windowSvgElement: ReactElement = (
    <g>
      <g>
        <g className={WINDOW_CIRCLES_CLASS_NAME}>
          <circle cx="80" cy="14" r="4" />
          <circle cx="92" cy="14" r="4" />
          <circle cx="104" cy="14" r="4" />
        </g>
        <g className={WINDOW_DASHES_CLASS_NAME}>
          <line x1="2" x2="12" y1="70" y2="70" />
          <line x1="16" x2="23" y1="70" y2="70" />
          <line x1="250" x2="262" y1="120" y2="120" />
          <line x1="266" x2="272" y1="120" y2="120" />
          <line x1="276" x2="281" y1="120" y2="120" />
        </g>
        <g className={WINDOW_CLASS_NAME}>
          <path d="m70 120h180v-107q1.5-8.25-8-8c-9.5 0.25-162-0.25-164 0s-8.312-1.312-8 8q0.313 9.313 0 107z" />
          <line x1="71" x2="250" y1="23" y2="23" />
        </g>
      </g>
    </g>
  );

  return (
    <div className={classes.svg} ref={componentRef}>
      {isActive && particlesElement}
      <svg viewBox="0 0 300 180" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            id="window-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="30%" style={{ stopColor: primaryMain, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: secondaryMain, stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        <g className={classes.window} stroke="url(#window-gradient)">
          {windowSvgElement}
          {windowContentSvg}
        </g>

        {manSvg}
      </svg>
    </div>
  );
};

export default HowSystemWorksStepImage;
