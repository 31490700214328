import { APP_ROOT } from '@constants/routes';

export const ROOT = APP_ROOT;

export const HOME = `${ROOT}home`;

export const ORDERS = `${ROOT}orders`;

export const ORDER = `${ORDERS}/:id`;

export const ORDER_CREATE = `${ORDERS}/create`;

export const PROFILE = `${ROOT}profile`;

export const SHUTDOWNS = `${ROOT}shutdowns`;

export const SHUTDOWN = `${SHUTDOWNS}/:id`;

export const SHUTDOWN_CREATE = `${SHUTDOWNS}/create`;

export const SHUTDOWN_UPDATE = `${SHUTDOWNS}/:id/update`;

export const METERING_DEVICES = `${ROOT}metering_devices`;

export const PAYMENT = `${ROOT}payment`;

export const ACQUIRING = `${ROOT}acquiring`;

export const ORGANIZATIONS = `${ROOT}organizations`;

export const ORGANIZATION = `${ORGANIZATIONS}/:id`;

export const CAMERAS = `${ROOT}cameras`;

export const CALCULATIONS_HISTORY = `${ROOT}calculations_history`;

export const PAYMENTS_HISTORY = `${ROOT}payments_history`;

export const COUNTERS = `${ROOT}counters`;

export const COUNTERS_HISTORY = `${ROOT}counters_history`;

export const REPORTS = `${ROOT}reports`;

export const ADDRESSES = `${ROOT}addresses`;

export const ADDRESS = `${ROOT}addresses/:id`;

export const PAYMENT_STATUS = `${ROOT}payment_status/:paymentId`;

export const FEEDBACK = `${ROOT}feedback`;

export const MESSAGE = `${ROOT}message`;

export const DOCUMENTS = `${ROOT}my_documents`;

export const DETAILS_AND_AGREEMENTS = `${ROOT}details_and_agreements`;

export const STATISTICS = `${ROOT}statistics`;

export const UN_SUBSCRIBE = `${ROOT}unsubscribe`;
