import Sort from '@constants/sort';
import { AddFilter, ClearFilter, RemoveFilter } from '@stores/interfaces/Filter';
import { Count, Load } from '@stores/interfaces/Entity';
import { OrganizationRoles } from './Organization';

export interface RatingInterface {
  id: number;
  organizationId?: number | null;
  organizationName?: string | null;
  newAll?: number | null;
  okAll?: number | null;
  newCurrent?: number | null;
  newEmergency?: number | null;
  doneCurrent?: number | null;
  doneEmergency?: number | null;
  delayedCurrent?: number | null;
  delayedEmergency?: number | null;
  deletedCurrent?: number | null;
  deletedEmergency?: number | null;
  inWorkCurrent?: number | null;
  inWorkEmergency?: number | null;
  overdueCurrent?: number | null;
  overdueEmergency?: number | null;
  efficiency?: number | null;
  efficiencyCurrent?: number | null;
  efficiencyEmergency?: number | null;
  quality?: number | null;
  qualityCurrent?: number | null;
  qualityEmergency?: number | null;
  dateStart?: string | null;
  dateEnd?: string | null;
}

export class Rating {
  public id: number;
  public organizationId?: number | null;
  public organizationName?: string | null;
  public newAll?: number | null;
  public okAll?: number | null;
  public newCurrent?: number | null;
  public newEmergency?: number | null;
  public doneCurrent?: number | null;
  public doneEmergency?: number | null;
  public delayedCurrent?: number | null;
  public delayedEmergency?: number | null;
  public deletedCurrent?: number | null;
  public deletedEmergency?: number | null;
  public inWorkCurrent?: number | null;
  public inWorkEmergency?: number | null;
  public overdueCurrent?: number | null;
  public overdueEmergency?: number | null;
  public efficiency?: number | null;
  public efficiencyCurrent?: number | null;
  public efficiencyEmergency?: number | null;
  public quality?: number | null;
  public qualityCurrent?: number | null;
  public qualityEmergency?: number | null;
  public dateStart?: string | null;
  public dateEnd?: string | null;

  public constructor(values: RatingInterface) {
    this.id = values.id;
    this.organizationId = values.organizationId;
    this.organizationName = values.organizationName;
    this.newAll = values.newAll;
    this.okAll = values.okAll;
    this.newCurrent = values.newCurrent;
    this.newEmergency = values.newEmergency;
    this.doneCurrent = values.doneCurrent;
    this.doneEmergency = values.doneEmergency;
    this.delayedCurrent = values.delayedCurrent;
    this.delayedEmergency = values.delayedEmergency;
    this.deletedCurrent = values.deletedCurrent;
    this.deletedEmergency = values.deletedEmergency;
    this.inWorkCurrent = values.inWorkCurrent;
    this.inWorkEmergency = values.inWorkEmergency;
    this.overdueCurrent = values.overdueCurrent;
    this.overdueEmergency = values.overdueEmergency;
    this.efficiency = values.efficiency;
    this.efficiencyCurrent = values.efficiencyCurrent;
    this.efficiencyEmergency = values.efficiencyEmergency;
    this.quality = values.quality;
    this.qualityCurrent = values.qualityCurrent;
    this.qualityEmergency = values.qualityEmergency;
    this.dateStart = values.dateStart;
    this.dateEnd = values.dateEnd;
  }
}

export enum RatingKeys {
  ROLE = 'role',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  ORGANIZATION_ID = 'organizationId',
}

export interface RatingFilterProps {
  [RatingKeys.ROLE]?: OrganizationRoles;
  [RatingKeys.START_DATE]?: number;
  [RatingKeys.END_DATE]?: number;
  [RatingKeys.ORGANIZATION_ID]?: number;
}

export type AddRatingFilter = AddFilter<RatingFilterProps>;

export type RemoveRatingFilter = RemoveFilter<RatingKeys>;

export type ClearRatingFilter = ClearFilter;

export enum RatingSortKeys {
  EFFICIENCY = 'efficiency',
}

export interface RatingSortProps {
  field: RatingSortKeys;
  desc: Sort;
}

export type CountRating = Count<{ filter?: RatingFilterProps }, number>;

export type LoadRating = Load<
  { filter?: RatingFilterProps; sort?: RatingSortProps[]; limit?: number; offset?: number },
  Rating[]
>;
