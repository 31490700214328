/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    getSbpPayment: paymentStore.getSbpPayment,
    sbpLoading: paymentStore.sbpLoading,
    sbpError: paymentStore.sbpError,
    sbpLink: paymentStore.sbpLink,
    sbpQrLink: paymentStore.sbpQrLink,
    cleanUpSbp: paymentStore.cleanUpSbp,
  }));
};
