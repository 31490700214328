/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useAuthProvidersStore = () => {
  const { authProviderStore } = useStores();

  return useObserver(() => ({
    loading: authProviderStore.loading,
    getUrl: authProviderStore.getUrl,
    login: authProviderStore.login,
  }));
};

export const useAuthenticationStore = () => {
  const { authenticationStore } = useStores();

  return useObserver(() => ({
    refreshByToken: authenticationStore.refreshByToken,
    singByTokenData: authenticationStore.singByTokenData,
  }));
};
