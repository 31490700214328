import { AddFilter, ClearFilter, RemoveFilter, FilterOperators } from '@stores/interfaces/Filter';
import { Load, Get, Count, Create, Update } from '@stores/interfaces/Entity';
import SortDirections from '@constants/sort';

export interface ShutdownInterface {
  id: number;
  reason?: string | null;
  dateStart?: number | null;
  dateEnd?: number | null;
  sendDate?: number | null;
  type?: string | null;
  body?: string | null;
  sender?: string | null;
  status?: ShutdownStatuses | null;
  cpt?: string | null;
  telephoneMessage?: string | null;
  title?: string | null;
  importanceLevel?: string | null;
  reasonId?: number | null;
  senderId?: number | null;
  typeId?: number | null;
  map?: {
    buildingNumber: string;
    flatId: number;
    houseId: number;
    houseNumber: string;
    lat: string;
    lng: string;
    streetName: string;
  }[];
  receivers?: {
    houseIds?: number[];
    flatIds?: number[];
  } | null;
}

export class Shutdown {
  public id: number;
  public reason?: string | null;
  public dateStart?: number | null;
  public dateEnd?: number | null;
  public sendDate?: number | null;
  public body?: string | null;
  public sender?: string | null;
  public type?: string | null;
  public status?: ShutdownStatuses | null;
  public title?: string | null;
  public cpt?: string | null;
  public telephoneMessage?: string | null;
  public importanceLevel?: string | null;
  public reasonId?: number | null;
  public senderId?: number | null;
  public typeId?: number | null;
  public map?: {
    buildingNumber: string;
    flatId: number;
    houseId: number;
    houseNumber: string;
    lat: string;
    lng: string;
    streetName: string;
  }[];
  public receivers?: {
    houseIds?: number[];
    flatIds?: number[];
  } | null;

  public constructor(value: ShutdownInterface) {
    this.id = value.id;
    this.reason = value.reason;
    this.dateStart = value.dateStart;
    this.dateEnd = value.dateEnd;
    this.sendDate = value.sendDate;
    this.body = value.body;
    this.sender = value.sender;
    this.type = value.type;
    this.status = value.status;
    this.title = value.title;
    this.reason = value.reason;
    this.cpt = value.cpt;
    this.telephoneMessage = value.telephoneMessage;
    this.importanceLevel = value.importanceLevel;
    this.reasonId = value.reasonId;
    this.senderId = value.senderId;
    this.typeId = value.typeId;
    this.map = value.map;
    this.receivers = value.receivers;
  }
}

export interface ShutdownCreationProps {
  body?: string;
  dateStart: number;
  dateEnd: number;
  reason: number;
  type: number;
  sender: number;
  address: {
    streetIds: number[];
    houseIds: number[];
    flatIds: number[];
  };
}

export enum ShutdownKeys {
  ID = 'id',
  HOUSE_ID = 'houseId',
  FLAT_ID = 'flatId',
  STREET_ID = 'streetId',
  STATUS = 'status',
  REASON = 'reason',
  TYPE = 'type',
  DATE_START = 'dateStart',
  DATE_END = 'dateEnd',
  SENDER = 'sender',
  BODY = 'body',
}

export interface ShutdownFilterProps {
  [ShutdownKeys.ID]?: number;
  [ShutdownKeys.FLAT_ID]?: FilterOperators<number>;
  [ShutdownKeys.STREET_ID]?: FilterOperators<number>;
  [ShutdownKeys.HOUSE_ID]?: FilterOperators<number>;
  [ShutdownKeys.STATUS]?:
    | ShutdownStatuses.COMPLETED
    | ShutdownStatuses.ACTUAL
    | ShutdownStatuses.PLANNED;
  [ShutdownKeys.REASON]?: FilterOperators<string>;
  [ShutdownKeys.TYPE]?: FilterOperators<string>;
  [ShutdownKeys.DATE_START]?: FilterOperators<number>;
  [ShutdownKeys.DATE_END]?: FilterOperators<number>;
  [ShutdownKeys.SENDER]?: FilterOperators<string>;
  [ShutdownKeys.BODY]?: FilterOperators<string>;
}

export enum ShutdownSortKeys {
  DATE_START = 'dateStart',
  ID = 'id',
}

export type AddShutdownFilters = AddFilter<ShutdownFilterProps>;

export type RemoveShutdownFilters = RemoveFilter<ShutdownKeys>;

export type ClearShutdownFilters = ClearFilter;

export interface ShutdownSortProps {
  field: ShutdownSortKeys;
  desc: SortDirections;
}

export type AddShutdownSort = (sort: ShutdownSortProps[]) => void;

export enum ShutdownStatuses {
  COMPLETED = 'completed',
  ACTUAL = 'actual',
  PLANNED = 'planned',
}

export const ShutdownStatusLabels: { [key in ShutdownStatuses]: string } = {
  [ShutdownStatuses.ACTUAL]: 'Текущее',
  [ShutdownStatuses.PLANNED]: 'Планируемое',
  [ShutdownStatuses.COMPLETED]: 'Прошедшее',
};

export type CountShutdown = Count<{ filter?: ShutdownFilterProps }, number>;

export type LoadShutdown = Load<
  { filter?: ShutdownFilterProps; sort?: ShutdownSortProps[]; limit?: number; offset?: number },
  Shutdown[]
>;

export type GetShutdown = Get<Shutdown, ShutdownFilterProps>;

export type CreateShutdown = Create<ShutdownCreationProps, Promise<Shutdown | null>>;

export type UpdateShutdown = Update<
  {
    payload?: Partial<ShutdownCreationProps>;
    entity?: Shutdown | null;
  },
  Promise<Shutdown | null>
>;
