import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { InputProps } from '@material-ui/core/Input';

import { DESCRIPTION_MAX_LENGTH } from '@core/validation/index';
import { FormHelperTextProps, OutlinedInputProps } from '@material-ui/core';

const useStyles = makeStyles(
  createStyles({
    textFieldMultilineFormHelperText: {
      textAlign: 'right',
    },
    textFieldMultilineFormHelperTextError: {
      textAlign: 'left',
    },
  }),
);

interface DescriptionFieldProps {
  className?: string;
  id?: string;
  autoFocus?: boolean;
  label?: string;
  touched?: boolean;
  error?: string;
  value?: string;
  required?: boolean;
  helperText?: string;
  name?: string;
  dataTestAttribute?: string;
  rowsMax?: number;
  onChange: InputProps['onChange'];
  onBlur: InputProps['onBlur'];
}

const DescrriptionField: FC<DescriptionFieldProps> = ({
  className,
  autoFocus,
  touched,
  error,
  label = 'Описание проблемы',
  value,
  name = 'text',
  required,
  dataTestAttribute,
  helperText,
  rowsMax = 100,
  onChange,
  onBlur,
}) => {
  const classes = useStyles();

  const formhelperTextProps: FormHelperTextProps = {
    variant: 'standard',
    className: classNames(classes.textFieldMultilineFormHelperText, {
      [classes.textFieldMultilineFormHelperTextError]: Boolean(error),
    }),
  };

  const helperTextElement: ReactNode = (touched && error) || (
    <Grid component="span" container={true} justify="space-between">
      <Grid component="span" item={true}>
        {error || helperText || 'Подробнее опишите суть вашей проблемы'}
      </Grid>
      <Grid component="span" item={true}>
        {`${value?.length}/${DESCRIPTION_MAX_LENGTH}`}
      </Grid>
    </Grid>
  );

  const inputProps: OutlinedInputProps = {
    inputProps: {
      'data-test': dataTestAttribute,
    },
    notched: false,
  };

  return (
    <TextField
      className={className}
      autoFocus={autoFocus}
      name={name}
      FormHelperTextProps={formhelperTextProps}
      InputProps={inputProps}
      error={Boolean(touched && error)}
      helperText={helperTextElement}
      label={label}
      multiline={true}
      required={required}
      fullWidth={true}
      rowsMax={rowsMax}
      value={value}
      InputLabelProps={{ shrink: true }}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default DescrriptionField;
