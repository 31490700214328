import { observable, action, computed } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse, JrpcResponseError } from '@httpClient/jrpc';
import Services from '@services/index';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import Store from './Store';

export type GetUrlResponse = JrpcResponse<string>;
export type LoginResponse = JrpcResponse<
  {
    refresh_token: string;
    token: string;
  },
  {},
  JrpcResponseError<{}>
>;

class AuthProviderStore extends Store implements Loading {
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);
    this._loading = false;
  }

  @action public getUrl = async (name: string): Promise<string> => {
    let url = '';
    this._loading = true;

    await this._services.opencity.requests
      .authProviderGetUrl({ params: { name } })
      .then(({ data: { result } }: AxiosResponse<GetUrlResponse>) => {
        if (result) {
          url = result;
        }
      })
      .finally(this._endLoading);

    return url;
  };

  @action public login = async (
    name: string,
    activationCode: string,
  ): Promise<{ token: string; refresh_token: string }> => {
    let tokenData = { token: '', refresh_token: '' };
    this._loading = true;

    await this._services.opencity.requests
      .authProviderLogin({ params: { name, activationCode } })
      .then(({ data: { result } }: AxiosResponse<LoginResponse>) => {
        if (result) {
          tokenData = result;
        }
      })
      .finally(this._endLoading);

    return tokenData;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default AuthProviderStore;
