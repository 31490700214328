import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@core/stores/interfaces/Entity';

export interface PaymentInterface {
  id: string;
  amount: number;
  date: number;
  merchantGroupId?: number | null;
  accountNumber?: string | null;
  place?: string | null;
  acceptorName?: string | null;
}

export class Payment {
  public id: string;
  public amount: number;
  public date: number;
  public merchantGroupId?: number | null;
  public accountNumber?: string | null;
  public place?: string | null;
  public acceptorName?: string | null;

  public constructor(value: PaymentInterface) {
    this.id = value.id;
    this.amount = value.amount;
    this.date = value.date;
    this.merchantGroupId = value.merchantGroupId;
    this.accountNumber = value.accountNumber;
    this.place = value.place;
    this.acceptorName = value.acceptorName;
  }
}

export enum PaymentKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export interface PaymentFilterProps {
  [PaymentKeys.PROVIDER_ID]?: FilterOperators<number>;
  [PaymentKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [PaymentKeys.START_DATE]?: number;
  [PaymentKeys.END_DATE]?: number;
}

export type LoadPayment = Load<
  { filter?: PaymentFilterProps; limit?: number; offset?: number },
  Payment[]
>;

export enum BankNamesEnum {
  qiwi = 'киви',
  tinkoff = 'тинькофф',
  premium = 'премиум',
}
