export interface TransitionInterface {
  name: string;
  title: string;
  desription?: string;
  state_from: string;
  state_to: string;
}

export class Transition {
  public name: string;
  public title: string;
  public description?: string;
  public stateFrom: string;
  public stateTo: string;

  public constructor(value: TransitionInterface) {
    this.name = value.name;
    this.title = value.title;
    this.description = value.desription;
    this.stateFrom = value.state_from;
    this.stateTo = value.state_to;
  }
}
