import React, { FC, ReactElement, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import cn from 'classnames';

import config from '@core/constants/config';
import { useStatisticsStore } from './hooks';

const toNumberDigit = (value: string): string =>
  value
    .split('')
    .reduce(
      (acc: string[], char: string, i: number): string[] => [
        ...acc,
        char,
        (value.length - i - 1) % 3 === 0 ? ' ' : '',
      ],
      [],
    )
    .join('');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(50),
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(40),
      },
    },
    divider: {
      backgroundColor: '#f1f6ff',
      height: 3,
      margin: theme.spacing(2, 0),
    },
    img: {
      marginLeft: -theme.spacing(8),
      position: 'absolute',
      top: theme.spacing(-36),
      width: `calc(100% + ${theme.spacing(16)}px)`,
      zIndex: -1,

      [theme.breakpoints.down('xs')]: {
        marginLeft: -theme.spacing(4),
        width: `calc(100% + ${theme.spacing(8)}px)`,
      },
    },
    paper: {
      borderRadius: 20,
      boxShadow: 'none !important',
      boxSizing: 'border-box',
      flexGrow: 1,
      height: 190,
      margin: '0 8px',
      padding: theme.spacing(4),
      width: 354,

      [theme.breakpoints.down('sm')]: {
        background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        borderRadius: '20px 0 0 20px',
        height: 'inherit',
        padding: theme.spacing(4, 6),
        width: '100%',
      },
    },
    paperContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 24px)',
      },
    },
    statistics: {
      marginTop: 50,

      [theme.breakpoints.down('sm')]: {
        alignContent: 'flex-end',
        flexDirection: 'column',
        left: theme.spacing(6),
        position: 'relative',
      },

      [theme.breakpoints.down('xs')]: {
        left: theme.spacing(2),
      },
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        color: '#FFFFFF',
        textAlign: 'left',
      },
    },
    title: {
      fontWeight: 700,

      [theme.breakpoints.down('md')]: {
        fontSize: '1.85rem !important',
        textAlign: 'center',
      },
    },

    wave: {
      maxHeight: 2000,
    },
  }),
);

interface ProjectStatisticsProps {
  id: string;
}

const ProjectStatistics: FC<ProjectStatisticsProps> = ({ id }) => {
  const classes = useStyles();

  const { load, statistics } = useStatisticsStore();

  useEffect((): void => {
    load();
  }, []);

  const renderStatisticBlock = (
    value: number,
    description: string,
    blockId: string,
  ): ReactElement => (
    <Grid className={classes.paperContainer} item={true}>
      <Paper className={classes.paper}>
        <Typography
          className={classes.text}
          id={blockId}
          color="textPrimary"
          variant="h3"
          align="center"
          style={{ fontWeight: 600, fontSize: '30px' }}
        >
          {toNumberDigit(value.toString())}
        </Typography>
        <Hidden smDown={true}>
          <Divider className={classes.divider} />
        </Hidden>
        <Typography className={classes.text} variant="body1" color="textPrimary" align="center">
          {description}
        </Typography>
      </Paper>
    </Grid>
  );

  const titleElement: ReactElement = (
    <Grid container={true} item={true} direction="column" alignItems="center" spacing={4}>
      <Grid item={true}>
        <Typography className={classes.title} color="textPrimary" variant="h2">
          О проекте в цифрах
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography color="textPrimary" variant="body1">
          Год запуска проекта {config.projectLaunchDate} г.
        </Typography>
      </Grid>
    </Grid>
  );

  const statisticBlocksElement: ReactElement = (
    <Grid
      className={classes.statistics}
      container={true}
      justify="center"
      spacing={4}
      alignContent="space-between"
    >
      {renderStatisticBlock(
        statistics.organizationCount,
        'Количество обслуживающих организаций работающих в системе',
        `${id}__organization-count`,
      )}
      {renderStatisticBlock(
        statistics.userCount,
        'Число зарегистрированных жителей',
        `${id}__user-count`,
      )}
      {renderStatisticBlock(statistics.orderCount, 'Общее число заявок', `${id}__order-count`)}
      {renderStatisticBlock(
        statistics.completedOrderCount,
        'Число выполненных заявок',
        `${id}__completed-order-count`,
      )}
    </Grid>
  );

  return (
    <Grid
      className={classes.container}
      id={id}
      container={true}
      direction="column"
      component="section"
      data-testid="project-statistics"
    >
      {titleElement}
      {statisticBlocksElement}
      <Hidden mdUp={true}>
        <img
          className={cn(classes.img, classes.wave)}
          alt=""
          src="/images/wave_mobile_image_1.png"
        />
      </Hidden>
      <Hidden mdUp={true}>
        <img className={classes.img} alt="" src="/images/wave_mobile_image_2.png" />
      </Hidden>
    </Grid>
  );
};

export default ProjectStatistics;
