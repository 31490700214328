export interface JrpcConfig {
  jsonrpc: string;
}

export interface JrpcResponseError<Data> {
  code?: ErrorCodes;
  data?: Data;
  message?: string;
  name?: string;
}

export interface JrpcResponse<ResultData = {}, ErrorData = {}, RequestParams = {}> {
  actionName: string;
  id: number | string;
  result?: ResultData;
  error?: JrpcResponseError<ErrorData>;
  request?: { params: RequestParams };
  name?: string;
}

export interface JrpcRequest {
  jsonrpc: string;
  method: string;
  id: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  cancelTokenHandlerObject?: any;
}

export enum ErrorCodes {
  BAD_CREDENTIALS = 403,
  ERROR = 120000,
  WAITING = 121000,
  ADDRESS_ALREADY_ADDED_1 = 111004,
  ADDRESS_ALREADY_ADDED_2 = 160000,
  PERSONAL_ACCOUNT_NOT_FOUND_1 = 120000,
  PERSONAL_ACCOUNT_NOT_FOUND_2 = 900000,
  PERSONAL_ACCOUNT_HAS_ALREADY_BEEN_ADDED = 900200,
  ANY_OTHER_ERROR = 170000,
  PARTNER_NOT_ACTIVE = 102000,
}
