import { FilterOperators } from '@stores/interfaces/Filter';
import { Load, Create } from '@stores/interfaces/Entity';
import SortDirections from '@constants/sort';

export interface MeteringDeviceValueInterface {
  id: number;
  meteringDeviceId: number;
  dailyValue: number;
  nightValue: number;
  readOutAt: number;
  dailyZeroCrossing: number;
  nightZeroCrossing: number;
  acceptedAt: number;
}

export class MeteringDeviceValue {
  public id: number;
  public meteringDeviceId: number;
  public dailyValue: number;
  public nightValue: number;
  public readOutAt: number;
  public dailyZeroCrossing: number;
  public nightZeroCrossing: number;
  public acceptedAt: number;

  public constructor(value: MeteringDeviceValueInterface) {
    this.id = value.id;
    this.meteringDeviceId = value.meteringDeviceId;
    this.dailyValue = value.dailyValue;
    this.nightValue = value.nightValue;
    this.readOutAt = value.readOutAt;
    this.dailyZeroCrossing = value.dailyZeroCrossing;
    this.nightZeroCrossing = value.nightZeroCrossing;
    this.acceptedAt = value.acceptedAt;
  }
}

export enum MeteringDeviceValueFilterKeys {
  ID = 'id',
  METERING_DEVICE_ID = 'meteringDeviceId',
  PERSONAL_ACCOUNT_ID = 'personalAccountId',
  READ_OUT_AT = 'readOutAt',
}

export interface MeteringDeviceValueFilterProps {
  [MeteringDeviceValueFilterKeys.ID]?: FilterOperators<number>;
  [MeteringDeviceValueFilterKeys.METERING_DEVICE_ID]?: FilterOperators<number>;
  [MeteringDeviceValueFilterKeys.PERSONAL_ACCOUNT_ID]?: FilterOperators<number>;
}

export interface MeteringDeviceValueCreationProps {
  meteringDeviceId: number;
  dailyValue: number;
  nightValue?: number;
}

export interface MeteringDeviceValueSortProps {
  field: MeteringDeviceValueFilterKeys;
  desc: SortDirections;
}

export type CreateMeteringDeviceValue = Create<MeteringDeviceValueCreationProps, Promise<boolean>>;

export type LoadMeteringDeviceValue = Load<
  {
    filter?: MeteringDeviceValueFilterProps;
    limit?: number;
    offset?: number;
    sort?: MeteringDeviceValueSortProps[];
  },
  MeteringDeviceValue[]
>;
