import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';
import { CounterKeys } from './Counter';

export interface CounterHistoryInterface {
  acceptanceDate: number;
  checkDate: number | null;
  entryDate: number;
  id: number;
  indicationPeriod: number;
  isEnabled: boolean;
  modelName: string;
  nextCheckDate: boolean;
  previousValue: number;
  resourceId: number;
  resourceName: string;
  serialNumber: string;
  typeDescription: string;
  typeId: number;
  unitName: string;
}

export class CounterHistory {
  public acceptanceDate: number;
  public checkDate: number | null;
  public entryDate: number;
  public id: number;
  public indicationPeriod: number;
  public isEnabled: boolean;
  public modelName: string;
  public nextCheckDate: boolean;
  public previousValue: number;
  public resourceId: number;
  public resourceName: string;
  public serialNumber: string;
  public typeDescription: string;
  public typeId: number;
  public unitName: string;

  public constructor(value: CounterHistoryInterface) {
    this.acceptanceDate = value.acceptanceDate;
    this.checkDate = value.checkDate;
    this.entryDate = value.entryDate;
    this.id = value.id;
    this.indicationPeriod = value.indicationPeriod;
    this.isEnabled = value.isEnabled;
    this.modelName = value.modelName;
    this.nextCheckDate = value.nextCheckDate;
    this.previousValue = value.previousValue;
    this.resourceId = value.resourceId;
    this.resourceName = value.resourceName;
    this.serialNumber = value.serialNumber;
    this.typeDescription = value.typeDescription;
    this.typeId = value.typeId;
    this.unitName = value.unitName;
  }
}

export interface CounterHistoryFilterProps {
  [CounterKeys.PROVIDER_ID]?: FilterOperators<number>;
  [CounterKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [CounterKeys.COUNTER_ID]?: FilterOperators<number>;
  [CounterKeys.START_DATE]?: number;
  [CounterKeys.END_DATE]?: number;
}

export type LoadCounterHistory = Load<
  { filter?: CounterHistoryFilterProps; limit?: number; offset?: number },
  CounterHistory[]
>;
