import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import SliderComponent from '@core/components/Slider';
import { SlideComponentProps, SlideButton } from '@core/components/Slider/Slider';
import agryzContent from './slides/agryz.json';
import almetContent from './slides/almet.json';
import elabugaContent from './slides/elabuga.json';
import grozContent from './slides/groz.json';
import innopolisContent from './slides/innopolis.json';
import nizhnekamskContent from './slides/nizhnekamsk.json';
import nnovContent from './slides/nnov.json';
import usinskContent from './slides/usinsk.json';
import zainskContent from './slides/zainsk.json';
import kazanContent from './slides/kazan.json';
import lesosibirskContent from './slides/lesosibirsk.json';
import project from '@constants/project';
import config from '@constants/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(68),
      },
    },
    regButtonContainer: {
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: theme.spacing(4),
      },
    },
    orderButton: {
      background: '#FFF',
    },
    container: {
      alignSelf: 'flex-start',
      borderRadius: 25,
      boxSizing: 'border-box',
      marginTop: theme.spacing(40),
      maxWidth: 640,
      minHeight: 160,
      padding: theme.spacing(4),
      width: '100%',
      zIndex: 2,

      [theme.breakpoints.down(1240)]: {
        marginTop: theme.spacing(0),
      },

      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
        background: '#FFF',
      },

      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none !important',
        padding: 0,
      },
    },
    footer: {
      whiteSpace: 'pre',

      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'normal',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      display: 'inline-block',
    },
    title: {
      fontWeight: 700,
      paddingBottom: theme.spacing(6),

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.85rem !important',
        textAlign: 'center',
      },
    },
    buttonsBlock: {
      paddingTop: '40px !important',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-around',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },

    customButton: {
      paddingTop: '0 !important',
    },

    sliderButtons: {
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    slide: {
      marginBottom: '50px',
      minHeight: '250px',
    },
    text: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 'inherit',
      color: '#44546B',
      // textAlign: 'justify',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    slidesContainer: {
      justifyContent: 'flex-start',
    },
  }),
);

const Slide: FC<{ content: SlideComponentProps }> = ({ content }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { title, text, buttons } = content;

  const titleElement: ReactElement = (
    <Typography variant="h2" className={classes.title} color={'textPrimary'}>
      {title}
    </Typography>
  );

  const phoneLink = config.supportPhoneNumber
    .split(',')
    .map(
      item =>
        `
    <a
      href="tel:${item.replace(/[^0-9+]/g, '')}"
      class="${classes.link}"
    >
      ${item}
    </a>`,
    )
    .join(',');

  const textElement: ReactElement = (
    <div
      className={classes.text}
      dangerouslySetInnerHTML={{
        __html: text?.replace('SUPPORT_PHONE', phoneLink) || '',
      }}
    />
  );

  const renderSecondaryButton = (button: SlideButton): ReactElement =>
    button.route.startsWith('http') ? (
      <a
        className={classes.link}
        href={button.route}
        tabIndex={-1}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className={classNames(classes.button, classes.orderButton)}
          color="primary"
          variant="outlined"
          fullWidth={true}
        >
          {button.text}
        </Button>
      </a>
    ) : (
      <Link className={classes.link} to={button.route} tabIndex={-1}>
        <Button
          className={classNames(classes.button, classes.orderButton)}
          color="primary"
          variant="outlined"
          fullWidth={true}
        >
          {button.text}
        </Button>
      </Link>
    );

  const renderPrimaryButton = (button: SlideButton): ReactElement =>
    button.route.startsWith('http') ? (
      <a
        className={classNames(classes.link, classes.regButtonContainer)}
        href={button.route}
        tabIndex={-1}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className={classes.button} color="primary" variant="contained" fullWidth={true}>
          {button.text}
        </Button>
      </a>
    ) : (
      <Link
        className={classNames(classes.link, classes.regButtonContainer)}
        to={button.route}
        tabIndex={-1}
      >
        <Button className={classes.button} color="primary" variant="contained" fullWidth={true}>
          {button.text}
        </Button>
      </Link>
    );

  const renderButton = (button: SlideButton, idx: number): ReactElement =>
    idx ? renderSecondaryButton(button) : renderPrimaryButton(button);

  return (
    <Grid
      container={true}
      item={true}
      direction="column"
      spacing={isXs ? 4 : 2}
      className={classes.slide}
    >
      <Grid item={true}>{titleElement}</Grid>
      <Grid item={true}> {textElement}</Grid>
      <Grid
        item={true}
        container={true}
        className={classNames(classes.buttonsBlock, !text && classes.customButton)}
      >
        {buttons?.map(renderButton)}
      </Grid>
    </Grid>
  );
};

const contentLoader = (project: string): {}[] => {
  switch (project) {
    case 'agryz':
      return agryzContent;
    case 'almet':
      return almetContent;
    case 'elabuga':
      return elabugaContent;
    case 'groz':
      return grozContent;
    case 'innopolis':
      return innopolisContent;
    case 'kazan':
      return kazanContent;
    case 'nizhnekamsk':
      return nizhnekamskContent;
    case 'nnov':
      return nnovContent;
    case 'usinsk':
      return usinskContent;
    case 'zainsk':
      return zainskContent;
    case 'lesosibirsk':
      return lesosibirskContent;
    default:
      return [];
  }
};

const SendOrder: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="send-order">
      <SliderComponent
        content={contentLoader(project)}
        SlideComponent={Slide}
        classes={{
          buttonsContainer: classes.sliderButtons,
          slidesContainer: classes.slidesContainer,
        }}
      />
    </div>
  );
};

export default SendOrder;
