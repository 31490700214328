import { Load } from '@stores/interfaces/Entity';

export interface ShutdownReasonInterface {
  id: number;
  name?: string | null;
  isPublic?: boolean | null;
}

export class ShutdownReason {
  public id: number;
  public name?: string | null;
  public isPublic?: boolean | null;

  public constructor(value: ShutdownReasonInterface) {
    this.id = value.id;
    this.name = value.name;
    this.isPublic = value.isPublic;
  }
}

export type LoadShutdownReason = Load<{ limit?: number }, ShutdownReason[]>;
