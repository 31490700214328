enum BillingMethods {
  USER_ACCOUNT_INDEX = 'billing.user.account.index',
  USER_ACCOUNT_CREATE = 'billing.user.account.create',
  USER_ACCOUNT_UPDATE = 'billing.user.account.update',
  USER_ACCOUNT_DELETE = 'billing.user.account.delete',
  ACCOUNT_INDEX = 'billing.account.index',
  ACCOUNT_EXISTS = 'billing.account.exists',
  COUNTER_VALUE_CREATE = 'billing.counter.value.create',
  COUNTER_VALUE_INDEX = 'billing.counter.value.index',
  COUNTER_INDEX = 'billing.counter.index',
  MERCHANT_INDEX = 'billing.merchant.index',
  MERCHANT_GROUP_INDEX = 'billing.merchant.group.index',
  SERVICE_INDEX = 'billing.service.index',
  PAYMENT_RECEIPT_INDEX = 'billing.payment.receipt.index',
  PAYMENT_INDEX = 'billing.payment.index',
  PAYMENT_GET_LINK = 'billing.payment.get_link',
  ACCRUAL_INDEX = 'billing.accrual.index',
  ORGANIZATION_INDEX = 'billing.organization.index',
  QR_CODE_CREATE = 'billing.qr.code.create',
  QR_CODE_SEND = 'billing.qr.code.email',
  PAYMENT_CARD_INDEX = 'billing.payment.card.index',
  PAYMENT_CARD_PAY = 'billing.payment.card.pay',
  PAYMENT_STATUS = 'billing.payment.status',
  PAYMENT_CARD_DETACH = 'billing.payment.card.detach',
  PAYMENT_CARD_ATTACH = 'billing.payment.card.create',
  MERCHANT_EMAIL = 'billing.merchant.email',
  USER_CREATE = 'billing.user.create',
  USER_NOTIFICATION_CREATE = 'billing.user.notification.create',
  USER_NOTIFICATION_TYPE_CREATE = 'billing.user.notification.type.create',
  USER_NOTIFICATION_TYPE_DELETE = 'billing.user.notification.type.delete',
  USER_NOTIFICATION_DELETE = 'billing.user.notification.delete',
  ORGANIZATION_BANNER_INDEX = 'billing.organization.banner.index',
  ACCOUNT_CONTRACT_INDEX = 'billing.account.contract.index',
  ANNOUNCEMENTS_INDEX = 'billing.organization.announcements.index',
  ACCOUNT_ACT_INDEX = 'billing.account.act.index',
  PAYMENT_SBP_GET = 'billing.payment.get_sbp',
  DADATA_SUGGS_GET = 'billing.dadata.get_suggestions',
  DADATA_IS_SHOW_CAPTCHA = 'billing.dadata.is_show_captcha',
  CLERK_ACCOUNT_INDEX = 'billing.account.clerk.index',
}

export default BillingMethods;
