import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  Classifier,
  ClassifierInterface,
  GetClassifier,
  ClassifierKeys,
} from '@core/entities/Opencity/Classifier';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';

type ClassifierSearchResponse = JrpcResponse<ClassifierInterface[]>;

class ClassifierStore extends Store implements Loading, Entity<Classifier, number> {
  @observable private _loading: boolean;
  @observable private _classifiers: Classifier[];

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(serivces: Services) {
    super(serivces);

    this._classifiers = [];
    this._loading = false;
  }

  @action public search = async (classifier: string): Promise<Classifier[]> => {
    this._loading = true;

    let classifiers: Classifier[] = [];

    await this._services.opencity.requests
      .classifierSearch({ params: { classifier } })
      .then(({ data: { result } }: AxiosResponse<ClassifierSearchResponse>) => {
        if (Array.isArray(result)) {
          classifiers = result.map(
            (value: ClassifierInterface, key: number): Classifier =>
              new Classifier({ key, ...value }),
          );

          this._classifiers = classifiers;
        }
      })
      .finally(this._endLoading);

    return classifiers;
  };

  @action public get: GetClassifier = async filter =>
    this._classifiers.find((value: Classifier): boolean => value.id === filter[ClassifierKeys.ID]);

  @action public cleanUp = (): void => {
    this._classifiers = [];
    this._loading = false;
  };

  @computed public get list(): Classifier[] {
    return toJS(this._classifiers);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default ClassifierStore;
