import { Load } from '@stores/interfaces/Entity';
import { FilterOperators } from '@stores/interfaces/Filter';

export enum BannerName {
  PREPAYMENT = 'prepayment',
  RECEIPT = 'receipt',
  CARD_ABONENT = 'cardAbonent',
  ZERO_COMMISSION = 'zeroCommission',
  STOP_PAYMENT = 'stopPayment',
  STOCK = 'stock',
  QIWI_CARD = 'qiwiCard',
}

export interface BannerInterface {
  providerI: string | null;
  bannerId: string;
  bannerName: BannerName;
  bannerDescription: string;
  common: boolean;
}

export class Banner {
  public providerI: string | null;
  public bannerId: string;
  public bannerName: BannerName;
  public bannerDescription: string;
  public common: boolean;

  public constructor(value: BannerInterface) {
    this.providerI = value.providerI;
    this.bannerId = value.bannerId;
    this.bannerName = value.bannerName;
    this.bannerDescription = value.bannerDescription;
    this.common = value.common;
  }
}

export enum BannerKeys {
  PROVIDER_ID = 'providerId',
}

export interface BannerFilterProps {
  [BannerKeys.PROVIDER_ID]?: FilterOperators<number>;
}

export type LoadBanner = Load<
  {
    filter?: BannerFilterProps;
    limit?: number;
    offset?: number;
  },
  Banner[]
>;
