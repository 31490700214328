import { observable, action, computed, reaction, IObservableValue } from 'mobx';

import config from '@core/constants/config';

export type SnackbarTypes = 'error' | 'success' | 'warning';

class SnackbarStore {
  @observable private _message: IObservableValue<string>;
  @observable private _type: SnackbarTypes;

  public constructor() {
    this._message = observable.box('');
    this._type = 'success';

    reaction(
      (): string => this._message.get(),
      (): void => {
        this._message.set('');
      },
      { delay: config.messageCloseTime },
    );
  }

  @action public setMessage = (message: string, type?: SnackbarTypes): void => {
    this._message.set(message);

    if (type) {
      this._type = type;
    }
  };

  @action public cleanUp = (): void => {
    this._message.set('');
    this._type = 'success';
  };

  @computed public get message(): string {
    return this._message.get();
  }

  @computed public get type(): SnackbarTypes {
    return this._type;
  }
}

export default SnackbarStore;
