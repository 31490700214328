import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Theme,
  makeStyles,
  Grid,
  IconButton,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import theme from '@core/themes/kazan';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },

  link: {
    width: '100%',
    fontSize: 15,
    textDecoration: 'none',
    color: `${theme.palette.primary.main} !important`,

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },

  button: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
    },
  },

  paper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 400,
    },
  },
}));

interface TransitionModalProps {
  isOpen: boolean;
  link: string;
  handleLink: () => void;
  onClose: () => void;
}

export const TransitionModal: FC<TransitionModalProps> = ({
  isOpen,
  link,
  handleLink,
  onClose,
}) => {
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={isXs}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
          <Grid item className={classes.title}>
            Переход к оплате
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        Вы будете направлены на страницу эквайринга для оплаты услуг. Страница оплаты будет открыта
        в новой вкладке
      </DialogContent>
      <DialogActions>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          className={classes.link}
          onClick={handleLink}
        >
          <Button className={classes.button} color="primary" variant="contained" fullWidth={true}>
            Перейти к оплате
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
};
