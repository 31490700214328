import * as yup from 'yup';

import { nameApplicant, email, description } from '@core/validation';

const title = yup.string().required('Необходимо указать заголовок');
const address = yup.string().notRequired();

export const FormSchema = yup.object({
  nameApplicant: nameApplicant.required('Необходимо указать ваше имя'),
  email: email.required('Необходимо указать ваш адрес электронной почты'),
  address,
  title,
  text: description.required('Необходимо указать причину'),
});

export type FormProps = yup.InferType<typeof FormSchema>;
