import React, { FC, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import YandexMap from '@components/YandexMap';
import config from '@core/constants/config';
import { useMapStore } from './hooks';
import { mapStore } from '@core/stores/RootStore';

const CONTAINER_MIN_HEIGHT = 676;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: CONTAINER_MIN_HEIGHT,
      marginTop: theme.spacing(50),
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
        marginTop: 0,
      },
    },
    img: {
      bottom: 0,
      position: 'absolute',
      width: 'calc(100vw - 18px)',
      zIndex: -1,
    },
    map: {
      bottom: 0,
      height: '100%',
      position: 'absolute',
      width: 'calc(100vw - 18px)',
    },
  }),
);

interface MapBlockProps {
  id: string;
}

const MapBlock: FC<MapBlockProps> = ({ id }) => {
  const classes = useStyles();

  const { get, mapOrders } = useMapStore();

  useEffect(get, []);

  return (
    <Grid
      id={id}
      container={true}
      alignItems="center"
      justify="center"
      className={classes.container}
      component="section"
      data-testid="map"
    >
      <img className={classes.img} alt="" src="/images/wave_image_2.png" />
      <YandexMap
        mapStore={mapStore}
        mapConfig={config.map}
        className={classes.map}
        orders={mapOrders}
      />
    </Grid>
  );
};

export default MapBlock;
