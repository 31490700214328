import Services from '@services/index';

class Store<Relations = {}> {
  protected readonly _services: Services;
  protected readonly _relations: Relations;

  public constructor(services: Services, relations?: Relations) {
    this._services = services;
    this._relations = relations || ({} as Relations);
  }
}

export default Store;
