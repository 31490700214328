import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface PreferableOrganizationInterface {
  houseId: number;
  providerId: number;
  trackerId?: number;
}

export class PreferableOrganization {
  public houseId: number;
  public providerId: number;
  public trackerId?: number;

  public constructor(value: PreferableOrganizationInterface) {
    this.houseId = value.houseId;
    this.providerId = value.providerId;
    this.trackerId = value.trackerId;
  }
}

export enum PreferableOrganizationKeys {
  HOUSE_ID = 'houseId',
  TRACKER_ID = 'trackerId',
}

export interface PreferableOrganizationFilterProps {
  [PreferableOrganizationKeys.HOUSE_ID]: FilterOperators<number>;
  [PreferableOrganizationKeys.TRACKER_ID]?: FilterOperators<number>;
}

export type LoadPreferableOrganization = Load<
  { filter?: PreferableOrganizationFilterProps; limit?: number },
  PreferableOrganization[]
>;
