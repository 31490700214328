import { observable, action, computed } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import AuthenticationStore from './AuthenticationStore';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity, Load } from './interfaces/Entity';

type AuthOperationAllowedResponse = JrpcResponse<
  string[] | { authorized?: boolean; constraints?: any }
>;

interface OperationStoreRelations {
  authenticationStore: AuthenticationStore;
}

class OperationStore extends Store<OperationStoreRelations> implements Loading, Entity<string, {}> {
  @observable private _operations: string[];
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services, relations: OperationStoreRelations) {
    super(services, relations);

    this._operations = [];
    this._loading = false;
  }

  @action public load: Load<
    {},
    string[] | { authorized?: boolean; constraints?: any }
  > = async () => {
    this._loading = true;

    let userId = '';
    let operations: string[] = [];

    if (this._relations.authenticationStore.tokenPayload) {
      userId = this._relations.authenticationStore.tokenPayload.sub;
    }

    await this._services.opencity.requests
      .authOperationAllowed({ params: { user_id: userId } })
      .then(
        ({
          data: {
            result,
            // , error
          },
        }: AxiosResponse<AuthOperationAllowedResponse>) => {
          if (!Array.isArray(result)) {
            if (result?.authorized === false) {
              this._relations.authenticationStore.signOut();
            }
          }

          if (Array.isArray(result)) {
            this._operations = result;

            operations = result;
          }

          // if (this._relations.authenticationStore.authenticated && error && error.code === 504) {
          //   this._relations.authenticationStore.signOut();
          // }
        },
      )
      .finally(this._endLoading);

    return operations;
  };

  @action public cleanUp = (): void => {
    this._operations = [];
    this._loading = false;
  };

  @action public isAllowed = (operation: string): boolean => this._operations.includes(operation);

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get list(): string[] {
    return this._operations;
  }
}

export default OperationStore;
