import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { TransitionInterface, Transition } from '@entities/Workflow/Transition';
import { Order } from '@core/entities/Opencity/Order';
import city from '@core/constants/project';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';

type TransitionByStateFromIndexResponse = JrpcResponse<TransitionInterface[]>;

class WorkflowStore extends Store implements Loading {
  @observable private _loading: boolean;
  @observable private _transitions: Transition[];

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._transitions = [];
    this._loading = false;
  }

  @action public load = async (stateName?: string | null, order?: Order): Promise<Transition[]> => {
    this._loading = true;

    let transitions: Transition[] = [];

    await this._services.workflow.requests
      .transitionByStateFromIndex({
        params: {
          entity: order,
          schema_name: `${city}_${order?.trackerId}`,
          state_name: stateName,
        },
      })
      .then(({ data: { result } }: AxiosResponse<TransitionByStateFromIndexResponse>) => {
        if (Array.isArray(result)) {
          transitions = result.map(value => new Transition(value));

          this._transitions = transitions;
        }
      })
      .finally(this._endLoading);

    return transitions;
  };

  @action public cleanUp = (): void => {
    this._transitions = [];
    this._loading = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get list(): Transition[] {
    return toJS(this._transitions);
  }
}

export default WorkflowStore;
