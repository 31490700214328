import { Load, Count } from '@stores/interfaces/Entity';
import { FilterOperators, AddFilter, ClearFilter } from '@stores/interfaces/Filter';

export interface DistrictInterface {
  id: number;
  name?: string | null;
  fiasId?: string | null;
  cityId?: number | null;
  typeId?: number | null;
}

export class District {
  public id: number;
  public name?: string | null;
  public fiasId?: string | null;
  public cityId?: number | null;
  public typeId?: number | null;

  public constructor(value: DistrictInterface) {
    this.id = value.id;
    this.name = value.name;
    this.fiasId = value.fiasId;
    this.cityId = value.cityId;
    this.typeId = value.typeId;
  }
}

export enum DistrictKeys {
  ID = 'id',
  NAME = 'name',
  CITY = 'city',
  CITY_ID = 'cityId',
  CITY_FIAS_ID = 'cityFiasId',
  FIAS_ID = 'fiasId',
  CONTROL_ID = 'controlId',
  PROVIDER_ID = 'providerId',
  ORGANIZATION_ID = 'organizationId',
  PERSONAL_ACCOUNT_ID = 'personalAccountId',
}

export interface DistrictFilterProps {
  [DistrictKeys.ID]?: FilterOperators<number>;
  [DistrictKeys.NAME]?: FilterOperators<string>;
  [DistrictKeys.CITY]?: FilterOperators<string>;
  [DistrictKeys.CITY_ID]?: FilterOperators<number>;
  [DistrictKeys.CITY_FIAS_ID]?: FilterOperators<string>;
  [DistrictKeys.FIAS_ID]?: FilterOperators<string>;
  [DistrictKeys.CONTROL_ID]?: FilterOperators<number>;
  [DistrictKeys.PROVIDER_ID]?: FilterOperators<number>;
  [DistrictKeys.ORGANIZATION_ID]?: FilterOperators<number>;
  [DistrictKeys.PERSONAL_ACCOUNT_ID]?: FilterOperators<number>;
}

export type AddDistrictFilter = AddFilter<DistrictFilterProps>;

export type LoadDistrict = Load<
  { limit?: number; offset?: number; filter?: DistrictFilterProps },
  District[]
>;

export type CountDistrict = Count<{ filter?: DistrictFilterProps }, number>;

export type ClearDistrictFilter = ClearFilter;
