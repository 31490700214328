import projects from './projects';

export default process.env.REACT_APP_PROJECT as projects;

export const isBilling = projects.BILLING === process.env.REACT_APP_PROJECT;

export const isSpecialBilling = projects.BILLING_SPECIAL === process.env.REACT_APP_PROJECT;

export const isKazan = projects.KAZAN === process.env.REACT_APP_PROJECT;

export const isNizhnekamsk = projects.NIZHNEKAMSK === process.env.REACT_APP_PROJECT;

export const isAgryz = projects.AGRYZ === process.env.REACT_APP_PROJECT;

export const isUsinsk = projects.USINSK === process.env.REACT_APP_PROJECT;
