import { Load } from '@stores/interfaces/Entity';

export interface ShutdownSenderInterface {
  id: number;
  name?: string | null;
  organizationId?: number | null;
}

export class ShutdownSender {
  public id: number;
  public name?: string | null;
  public organizationId?: number | null;

  public constructor(value: ShutdownSenderInterface) {
    this.id = value.id;
    this.name = value.name;
    this.organizationId = value.organizationId;
  }
}

export type LoadShutdownSender = Load<{ limit?: number }, ShutdownSender[]>;
