import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import SignControls from '@public/components/SignControls';

interface SignControlsProps {
  pageYOffset: number;
  pathname: string;
  id: string;
}

const HeaderRight: FC<SignControlsProps> = ({ id, pathname, pageYOffset }) => (
  <Grid container={true} spacing={4} justify="flex-end" alignItems="center" wrap="nowrap">
    <SignControls id={id} pathname={pathname} pageYOffset={pageYOffset} />
  </Grid>
);

export default HeaderRight;
