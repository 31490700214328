import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFeedbackStore = () => {
  const { feedbackStore } = useStores();

  return useObserver(() => ({
    create: feedbackStore.create,
  }));
};

export const useSignStore = () => {
  const { signStore } = useStores();

  return useObserver(() => ({
    captchaCheck: signStore.captchaCheck,
  }));
};

export const useOwnProfileStore = () => {
  const { ownProfileStore } = useStores();

  return useObserver(() => ({
    billingUsers: ownProfileStore.billingUsers,
  }));
};
