import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  OrganizationInterface,
  Organization,
  OrganizationFilterProps,
  OrganizationKeys,
  LoadOrganization,
  GetOrganization,
} from '@entities/Billing/Organization';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';

type OrganizationIndexResponse = JrpcResponse<{ items: OrganizationInterface[]; total: number }>;

class OrganizationStore extends Store
  implements
    Loading,
    Pagination,
    Entity<Organization, { filter?: OrganizationFilterProps; limit?: number; offset?: number }>,
    Filter<OrganizationFilterProps, OrganizationKeys> {
  @observable private _organizations: Organization[];
  @observable private _filter: OrganizationFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._organizations = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public load: LoadOrganization = async params => {
    let organizations: Organization[] = [];

    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .organizationIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<OrganizationIndexResponse>) => {
        if (result?.items && Array.isArray(result?.items)) {
          organizations = result.items.map(value => new Organization(value));

          this._organizations = organizations;
        }

        if (typeof result?.total === 'number') {
          this._total = result?.total;
        }
      })
      .finally(this._endLoading);

    return organizations;
  };

  @action public get: GetOrganization = async filter =>
    this._organizations.find(
      (value: Organization): boolean => value.id === filter[OrganizationKeys.ID],
    );

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._organizations = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Organization[] {
    return toJS(this._organizations);
  }

  @computed public get filter(): OrganizationFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default OrganizationStore;
