import { action, observable, computed, toJS } from 'mobx';

import { PageSettingsInterface, settings, PageSettingsValue } from '@core/entities/PageSettings';
import { OrderFilterProps, OrderKeys, OrderState, OrderTabs } from '@entities/Opencity/Order';
import { Address } from '@entities/Opencity/Address';
import { OptionType } from '@components/Autocomplete';
import storageHelper from '@utils/storageHelper';

export interface ConfigurationStoreInterface {
  filter: OrderFilterProps;
  tabState: OrderState;
  selectedColumns: Array<OrderKeys>;
  limit: number;
  offset: number;
  addressSearchData: Address | null;
  controlSearchData: OptionType[];
  providerSearchData: OptionType[];
  pageTab: number;
}

class ConfigurationStore {
  private _pageName: string;
  @observable private _filter: OrderFilterProps;
  @observable private _tabState: OrderTabs;
  @observable private _selectedColumns: Array<OrderKeys>;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _addressSearchData: Address | null;
  @observable private _controlSearchData: OptionType[];
  @observable private _providerSearchData: OptionType[];
  @observable private _pageTab: number;

  constructor() {
    this._filter = settings.filter;
    this._tabState = settings.tabState;
    this._selectedColumns = settings.selectedColumns;
    this._limit = settings.limit;
    this._offset = settings.offset;
    this._addressSearchData = settings.addressSearchData;
    this._controlSearchData = settings.controlSearchData;
    this._providerSearchData = settings.providerSearchData;
    this._pageTab = settings.pageTab;
    this._pageName = 'ORDERS';
  }

  private saveToStorage = (): void => {
    storageHelper.session.setValue(this._pageName, JSON.stringify(this.settings));
  };

  @action private redeclareSettings(settings: PageSettingsInterface): void {
    this._filter = settings.filter;
    this._tabState = settings.tabState;
    this._selectedColumns = settings.selectedColumns;
    this._limit = settings.limit;
    this._offset = settings.offset;
    this._addressSearchData = settings.addressSearchData;
    this._controlSearchData = settings.controlSearchData;
    this._providerSearchData = settings.providerSearchData;
    this._pageTab = settings.pageTab;
    this.saveToStorage();
  }

  @action changeSettings = (key: keyof PageSettingsInterface, value: PageSettingsValue): void => {
    switch (key) {
      case 'filter':
        this._filter = value as OrderFilterProps;
        break;
      case 'tabState':
        this._tabState = value as OrderTabs;
        break;
      case 'selectedColumns':
        this._selectedColumns = value as Array<OrderKeys>;
        break;
      case 'limit':
        this._limit = value as number;
        break;
      case 'offset':
        this._offset = value as number;
        break;
      case 'addressSearchData':
        this._addressSearchData = value as Address | null;
        break;
      case 'controlSearchData':
        this._controlSearchData = value as OptionType[];
        break;
      case 'providerSearchData':
        this._providerSearchData = value as OptionType[];
        break;
      case 'pageTab':
        this._pageTab = value as number;
        break;
    }
    this.saveToStorage();
  };

  @action getPageSettings = (pageName: string): void => {
    this._pageName = pageName;
    const settings = storageHelper.session.getValue(pageName)
      ? JSON.parse(storageHelper.session.getValue(pageName) as string)
      : null;

    if (settings) {
      this.redeclareSettings(settings);
    }
  };

  @action cleanUp = (): void => {
    this._filter = settings.filter;
    this._tabState = settings.tabState;
    this._selectedColumns = settings.selectedColumns;
    this._limit = settings.limit;
    this._offset = settings.offset;
    this._addressSearchData = settings.addressSearchData;
    this._controlSearchData = settings.controlSearchData;
    this._providerSearchData = settings.providerSearchData;
    this._pageTab = settings.pageTab;
    this._pageName = 'ORDERS';
  };

  @computed public get settings(): PageSettingsInterface {
    return {
      filter: toJS(this._filter),
      tabState: toJS(this._tabState),
      selectedColumns: toJS(this._selectedColumns),
      limit: toJS(this._limit),
      offset: toJS(this._offset),
      addressSearchData: toJS(this._addressSearchData),
      controlSearchData: toJS(this._controlSearchData),
      providerSearchData: toJS(this._providerSearchData),
      pageTab: toJS(this._pageTab),
    };
  }
}
export default ConfigurationStore;
