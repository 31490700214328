import { observable, computed, action } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import Store from './Store';
import { Loading, endLoading } from '@core/stores/interfaces/Loading';

import { CreateNotificationType, DeleteNotificationType } from '@entities/Billing/NotificationType';
import SnackbarStore from '@stores/SnackbarStore';
import { JrpcResponse } from '@core/httpClient/jrpc';

interface CounterStoreRelations {
  snackbarStore: SnackbarStore;
}

// type UnsubscribeResponse = {result: any} | {result: any}

type UnsubscribeErrorResponse = AxiosResponse<{ error: any }>;
type UnsubscribeResponse = AxiosResponse<{ result: any }>;

class NotificationStore extends Store<CounterStoreRelations> implements Loading {
  @observable private _loading: boolean;
  @observable private _error: boolean;
  @observable private _unsubscribed: boolean;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services, relations: CounterStoreRelations) {
    super(services, relations);

    this._loading = false;
    this._error = false;
    this._unsubscribed = false;
  }

  @action public createNotificationType: CreateNotificationType = async params => {
    return await this._services.billing.requests
      .userNotificationTypeCreate({ params })
      .then(({ data: { result } }: AxiosResponse<{ result: boolean }>) => {
        return result;
      });
  };

  @action public deleteNotificationType: DeleteNotificationType = async params => {
    return await this._services.billing.requests
      .userNotificationTypeDelete({ params })
      .then(({ data: { result } }: AxiosResponse<{ result: boolean }>) => {
        return result;
      });
  };

  @action public deleteNotification = async (): Promise<number> => {
    return await this._services.billing.requests
      .userNotificationDelete()
      .then(({ data: { result } }: AxiosResponse<{ result: number }>) => {
        return result;
      });
  };

  @action public deleteOpenCityNotification = async (params: any): Promise<any> => {
    this._loading = true;

    return await this._services.opencity.requests
      .userNotificationDelete({ params: { ...params } })
      .then(({ data: { result, error } }: AxiosResponse<JrpcResponse<any>>) => {
        if (result) {
          this._unsubscribed = true;

          return result;
        }

        this._error = true;
      })
      .catch(() => {
        this._error = true;
      })
      .finally(() => {
        this._loading = false;
      });
  };

  @action public unsubscribeNotification = async (params: any): Promise<any> => {
    this._loading = true;

    return await this._services.opencity.requests
      .userNotificationUnsubscribe({ params: { ...params } })
      .then(({ data: { result, error } }: AxiosResponse<JrpcResponse<any>>) => {
        if (result) {
          this._unsubscribed = true;

          return result;
        }

        this._error = true;
      })
      .catch(() => {
        this._error = true;
      })
      .finally(() => {
        this._loading = false;
      });
  };

  @action public cleanUp = (): void => {
    this._loading = false;
    this._error = false;
    this._unsubscribed = false;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get error(): boolean {
    return this._error;
  }

  @computed public get unsubscribed(): boolean {
    return this._unsubscribed;
  }
}

export default NotificationStore;
