import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import Store from './Store';
import { OptionType } from '@components/Autocomplete';

class FastPaymentStore extends Store {
  @observable private _orgId: OptionType | null;
  @observable private _accountNumber: string;

  public constructor(services: Services) {
    super(services);

    this._accountNumber = '';
    this._orgId = null;
  }

  @action public setOrgName = (orgId: OptionType | null): void => {
    this._orgId = orgId;
  };

  @action public setAccountNumber = (accountNumber: string): void => {
    this._accountNumber = accountNumber;
  };

  @action public cleanUp = (): void => {
    this._accountNumber = '';
    this._orgId = null;
  };

  @computed public get orgName(): OptionType | null {
    return toJS(this._orgId);
  }

  @computed public get accountNumber(): string {
    return toJS(this._accountNumber);
  }
}
export default FastPaymentStore;
