import { AxiosResponse } from 'axios';
import { mergeDeepRight } from 'ramda';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  FlatAccount,
  FlatAccountInterface,
  FlatAccountFilterProps,
  FlatAccountKeys,
  FlatAccountCount,
  FlatAccountLoad,
  FlatAccountGet,
  AddFlatAccountFilter,
  ClearFlatAccountFilter,
} from '@core/entities/Opencity/FlatAccount';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Filter } from './interfaces/Filter';
import { Pagination } from './interfaces/Pagination';
import { Entity } from './interfaces/Entity';

export type FlatAccountIndexResponse = JrpcResponse<
  { items: FlatAccountInterface[]; total: number },
  {},
  { filter: FlatAccountFilterProps }
>;
export type FlatAccountCountResponse = JrpcResponse<number>;

class FlatAccountStore extends Store
  implements
    Loading,
    Pagination,
    Filter<FlatAccountFilterProps, FlatAccountKeys>,
    Entity<FlatAccount, { filter?: FlatAccountFilterProps; limit?: number; offset?: number }> {
  @observable private _flatAccounts: FlatAccount[];
  @observable private _filter: FlatAccountFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._flatAccounts = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public count: FlatAccountCount = async params => {
    let count = 0;

    const filter = (params && params.filter) || toJS(this._filter);

    this._filter = filter;

    await this._services.opencity.requests
      .flatAccountCount({
        params: { filter },
      })
      .then(({ data: { result } }: AxiosResponse<FlatAccountCountResponse>) => {
        if (typeof result === 'number') {
          count = result;

          this._total = count;
        }
      });

    return count;
  };

  @action public load: FlatAccountLoad = async params => {
    this._loading = true;

    let flatAccounts: FlatAccount[] = [];

    const { _filter, _limit, _offset } = this;

    const filter = (params && params.filter) || toJS<FlatAccountFilterProps>(_filter);
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .flatAccountIndex({
        params: { filter, limit, offset },
      })
      .then(({ data: { result } }: AxiosResponse<FlatAccountIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            flatAccounts = items.map(value => new FlatAccount(value));

            this._flatAccounts = flatAccounts;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return flatAccounts;
  };

  @action public get: FlatAccountGet = async filter => {
    let flatAccount: FlatAccount | undefined = await this._flatAccounts.find(
      value => value.id === filter[FlatAccountKeys.ID]?.$eq,
    );

    if (!flatAccount) {
      this._loading = true;

      await this._services.opencity.requests
        .flatAccountIndex({ params: { filter } })
        .then(({ data: { result } }: AxiosResponse<FlatAccountIndexResponse>): void => {
          if (result && Array.isArray(result.items)) {
            flatAccount = result.items[0];
          }
        })
        .finally(this._endLoading);
    }

    return flatAccount;
  };

  @action public addFilter: AddFlatAccountFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public clearFilter: ClearFlatAccountFilter = () => {
    this._filter = {};
  };

  @action public cleanUp = (): void => {
    this._flatAccounts = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): FlatAccount[] {
    return toJS<FlatAccount[]>(this._flatAccounts);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get filter(): FlatAccountFilterProps {
    return toJS<FlatAccountFilterProps>(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default FlatAccountStore;
