import { Load } from '@stores/interfaces/Entity';

export interface ClassifierTagInterface {
  id: number;
  name?: string | null;
  iconLink?: string | null;
}

export class ClassifierTag {
  public id: number;
  public name?: string | null;
  public iconLink?: string | null;

  public constructor(value: ClassifierTagInterface) {
    this.id = value.id;
    this.name = value.name;
    this.iconLink = value.iconLink;
  }
}

export type ClassifierTagLoad = Load<{ limit?: number; offset?: number }>;
