import React, { FC } from 'react';
import { Typography, Link, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import cn from 'classnames';

import { CounterStatus } from '@core/entities/Billing/Counter';
import { VERIFICATION } from '@core/constants/helpCenter';
import { MESSAGE } from '@private/constants/routes';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles(
  createStyles({
    root: {
      marginBottom: 24,
    },

    counterConsumptionWrapper: {
      marginTop: 8,
      height: 40,
    },

    counterConsumption: {
      color: '#07003D',
    },

    expiredText: {
      marginTop: 4,
      fontSize: '13px !important',
      color: '#F30E45',
    },

    link: {
      fontSize: '13px !important',
    },

    infoTitle: {
      fontSize: '15px',
      lineHeight: '24px',
      color: 'rgba(7, 0, 61, 0.5)',
    },

    fromPublic: {
      marginTop: 0,
      color: '#FFFFFF',
    },
  }),
);

interface Props {
  counterStatus: CounterStatus;
  isExpired: boolean;
  nextCheckDate?: number | null;
  measure?: string;
  unitName?: string | null;
  fromPublic?: boolean;
}

export const AdditionalInfoBlock: FC<Props> = ({
  counterStatus,
  isExpired,
  nextCheckDate,
  measure,
  unitName,
  fromPublic = false,
}) => {
  const classes = useStyles();

  if (
    counterStatus === CounterStatus.NEED_VERIFICATION ||
    counterStatus === CounterStatus.NEED_AUTO_VERIFICATION ||
    isExpired
  ) {
    return (
      <Typography
        variant="body2"
        className={cn(classes.expiredText, fromPublic && classes.fromPublic)}
      >
        {`Срок поверки счетчика истек${
          nextCheckDate && isExpired ? ` ${moment(nextCheckDate, 'X').format('DD.MM.YYYY')}` : ''
        }. Требуется поверка счетчика. `}
        <span>
          {!isSpecialBilling && (
            <Link target="_blank" href={VERIFICATION} className={classes.link}>
              Как провести поверку счетчиков?
            </Link>
          )}
        </span>
      </Typography>
    );
  }

  if (counterStatus === CounterStatus.REPAIR && !fromPublic) {
    return (
      <Typography variant="body2" className={classes.expiredText}>
        {`Счетчик на ремонте. `}
        <span>
          <Link target="_blank" href={MESSAGE} className={classes.link}>
            Обратитесь в обслуживающую организацию
          </Link>
        </span>
      </Typography>
    );
  }

  if (counterStatus === CounterStatus.REPAIR && fromPublic) {
    return (
      <Typography
        variant="body2"
        className={cn(classes.expiredText, fromPublic && classes.fromPublic)}
      >
        Счетчик на ремонте. Обратитесь в обслуживающую организацию
      </Typography>
    );
  }

  return (
    <>
      <Typography className={classes.infoTitle}>Потребление</Typography>

      <Grid container alignItems="center" className={classes.counterConsumptionWrapper}>
        <Typography variant="body2" className={classes.counterConsumption}>
          {`${measure || 0} ${unitName || ''}`}
        </Typography>
      </Grid>
    </>
  );
};
