import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  ReactElement,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Checkbox,
  Collapse,
  Divider,
  FormLabel,
  FormControlLabel,
  Chip,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FormikHelpers, useFormik } from 'formik';
import moment from 'moment';
import cn from 'classnames';
import classNames from 'classnames';

import { TransitionModal } from '@private/pages/Payment/PaymentBlock/TransitionModal';
import { PaymentMethods } from '@core/components/PaymentMethods';
import { Tooltip } from '@core/components/Tooltip';
import { Skeleton } from './components';
import { floatValueFormatter } from '@core/utils/formatter';
import { Indication } from '@core/stores/PaymentStore';
import { EXTERNAL_SUPPLIER } from '@core/constants/openCityProviderId';

import { Counter, CounterKeys, CounterStatus } from '@entities/Billing/Counter';
import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { Account } from '@entities/Billing/Account';
import { CounterValue } from '@entities/Billing/CounterValue';
import EmailField from '@core/components/Fields/EmailField';

import { yM } from '@utils/yandexMetrika/yandexMetrika';
import QrPaymentWindow from '@private/pages/Payment/QrPaymentWindow/QrPaymentWindow';

import {
  usePaymentStore,
  useCounterStore,
  useSnackbarStore,
  useAuthenticationStore,
  useAccountStore,
} from './hooks';
import { FormSchema, FormProps } from './FormSchema';
import { Merchant } from '@core/entities/Billing/Merchant';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BalanceInfoBanner } from '@core/components';
import { openTransitionModal } from '@core/utils/clientData';
import { AdditionalInfoBlock } from '@private/pages/Counters/components/AdditionalInfoBlock';
import { isSpecialBilling, isBilling } from '@core/constants/project';
import useBankCommissionList from '@core/utils/useBankCommissionList';
import PaymentBannerModal from '@private/components/PaymentBannerModal';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  title: {
    padding: '16px 16px 16px 32px',
  },

  dialogTitleWrapper: {
    width: 'calc(100% - 60px)',
  },

  dialogTitle: {
    color: textColor,
  },

  сontent: {
    padding: '0 32px',
    flexGrow: 0,
  },

  actions: {
    padding: 32,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  link: {
    cursor: 'pointer',
  },

  button: {
    fontSize: 15,
    marginLeft: 24,
    minWidth: 220,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },

  label: {
    color: 'rgba(7, 0, 61, 0.5)',
  },

  helperText: {
    color: textColor,
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '8px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
  },

  input: {
    color: textColor,
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },

  disabledInput: {
    opacity: '1 !important',
  },

  textFieldRoot: {
    marginTop: 0,
  },

  inputRoot: {
    marginTop: 0,
  },

  counterInput: {
    textAlign: 'right',
    padding: '14px 10px',
  },

  dialogContainer: {
    marginTop: 0,
    marginBottom: 0,
  },

  paymentInfo: {
    paddingTop: 2,
  },

  paymentInfoText: {
    color: textColor,
  },

  wantToTransfer: {
    width: 'calc(100% - 42px)',
    fontSize: 15,
    color: textColor,
  },

  saveCardBlock: {
    marginLeft: -12,
    width: 'calc(100% + 12px)',

    '&:hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },

  checkboxWrapper: {
    marginLeft: -9,
    paddingRight: 9,
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
    },

    '@media (max-width: 372px)': {
      marginTop: 12,
    },
  },

  checkbox: {
    color: '#2196F3',
  },

  countersBlock: {
    marginTop: theme.spacing(4),
  },

  tableTitleWrapper: {
    marginBottom: theme.spacing(1.5),
  },

  secondaryTableText: {
    fontSize: '13px !important',
    color: textColor,
    opacity: 0.5,
  },

  counterColumn: {
    flex: 0.55,
    paddingRight: theme.spacing(2),
  },

  previousColumn: {
    flex: 0.25,
    paddingRight: theme.spacing(4),
  },

  previousTitle: {
    width: 'calc(100% + 15px)',
  },

  currentColumn: {
    flex: 0.2,
  },

  tableRow: {
    marginTop: theme.spacing(4),
  },

  tableRowSmall: {
    marginTop: theme.spacing(6),
  },

  counterItem: {},

  previousItem: {},

  previousMeasureValue: {
    color: 'rgba(7, 0, 61, 0.5)',
  },

  resourceName: {
    fontWeight: 500,
    color: textColor,
    display: 'flex',
    alignItems: 'center',
  },

  chipWrapper: {
    fontSize: 13,
    marginLeft: 8,
  },

  chip: {
    backgroundColor: '#F44336',
    height: 24,
    color: '#FFFFFF',

    '& > span': {
      whiteSpace: 'normal',
    },
  },

  tooltipText: {
    fontSize: '13px !important',
    color: '#FFFFFF',
  },

  previousValue: {
    color: textColor,
  },

  tooltipWrapper: {
    width: 'fit-content',
  },

  smallText: {
    fontSize: '10px !important',
    color: textColor,
    opacity: 0.5,
  },

  previousValueWrapperSmall: {
    marginTop: theme.spacing(1),
  },

  paymentMethodsWrapper: {
    marginTop: 10,
    marginBottom: 5,
  },

  divider: {
    marginTop: '20px',
    marginBottom: '20px',
    '@media (max-width: 599px)': {
      padding: theme.spacing(0, 8),
      marginRight: theme.spacing(-8),
      marginLeft: theme.spacing(-8),
    },
  },
  infoIcon: {
    width: '18px',
    height: '18px',
    paddingTop: '2px',
    marginLeft: theme.spacing(1),
  },
  tooltip: {
    color: 'rgba(7, 0, 61, 0.5)',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      color: '#18CDA6',
    },
  },
  tooltipLink: {
    color: isSpecialBilling ? theme.palette.primary.main : '#18CDA6',
    textDecoration: 'none',
  },

  infoBannerWrapper: {
    marginTop: 12,
    marginBottom: 6,
  },

  disabledCounter: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },

  paymentSelectWrapper: {
    paddingTop: 24,
    [theme.breakpoints.up('sm')]: {},
  },

  leftBlockPaymentSelect: {
    flex: 1.3,
  },

  imgContainer: {
    flexBasis: 0,
  },

  mir: {
    marginRight: theme.spacing(2),
  },

  sbpImg: {
    marginLeft: '-6px',
    marginRight: '13px',
    paddingTop: '8px',
    width: 30,
  },

  creditCard: {
    color: theme.palette.primary.main,
    width: '27px',
    height: '27px',
    paddingRight: '27px',
  },
}));

const EMPTY_CARD = 'card';
const SBP = 'sbp';

interface CustomCounterValue extends CounterValue {
  typeName?: string | null;
}

const countersDataFormatter = (
  counters: Counter[],
  providerId: MerchantGroup['providerId'],
  accountNumber: Account['number'],
): CustomCounterValue[] => {
  return counters.map(counter => {
    const { id, typeId, typeName } = counter;
    return {
      accountNumber,
      counterId: id,
      value: null,
      date: Number(moment().format('X')),
      typeId,
      providerId: providerId,
      typeName,
    };
  });
};

const countersDataIndicationFormat = (counters: CustomCounterValue[]): Indication[] => {
  return counters.map(counter => {
    const { counterId, typeName, value } = counter;
    return {
      serviceName: String(typeName),
      counterSerialNumber: String(counterId),
      value: typeof value === 'number' ? value : null,
    };
  });
};

const previousValueFormatted = (previousValue?: number | null): [string, string] | [null] => {
  if (typeof previousValue !== 'number') {
    return [null];
  }

  const [intPart, decimalPart] = String(previousValue).split('.');
  return [intPart, decimalPart];
};

const previousCounterDate = (previousValueDate?: number | null): string | null => {
  return typeof previousValueDate === 'number'
    ? moment(Number(previousValueDate) * 1000 || undefined).format('D MMMM YYYY')
    : null;
};

const FORM_ID = 'payment_counters-form';

interface Props {
  accounts?: Account[];
  accountBenefit?: Account['benefit'];
  isVisibile: boolean;
  onClose: () => void;
  merchantGroup: MerchantGroup;
  merchants: Merchant[];
  accountNumber: Account['number'];
  paymentSource?: number;
}

interface CounterErrors {
  index: number;
  error: boolean;
}

export const PaymentAndCountersFormDialog: FC<Props> = ({
  accounts,
  accountBenefit,
  isVisibile,
  onClose,
  merchantGroup,
  merchants,
  accountNumber,
  paymentSource,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const { getCommissionPaymentForm, getSbpEnabled } = useBankCommissionList(merchantGroup);

  const { paymentGetLink } = usePaymentStore();
  const { loadValue, loadCounters, list: counterList } = useCounterStore();
  const { setMessage } = useSnackbarStore();
  const { authenticated } = useAuthenticationStore();
  const { accountList } = useAccountStore();

  const accountIsClosed = accounts && !accounts[0]?.active;
  const firstCounterDate = accounts?.[0]?.firstCounterDate;
  const lastCounterDate = accounts?.[0]?.lastCounterDate;
  const transferringPossibility = accounts?.[0]?.transferringPossibility;

  const _providerId = useMemo(() => merchantGroup.providerId, [merchantGroup.providerId]);
  const emailFieldLabel = 'Укажите email';
  const helperText = merchantGroup.fiscal
    ? 'На указанный email также будет отправлена информация о платеже'
    : undefined;

  const singleMethod = useMemo(() => EXTERNAL_SUPPLIER.includes(Number(_providerId)), [
    _providerId,
  ]);

  const [qrPaymentModalOpen, setQrPaymentModalOpen] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [isSbpEnabled, setIsSbpEnabled] = useState(false);
  const [counterErrors, setCounterErrors] = useState<CounterErrors[]>([]);
  const [link, setLink] = useState('');
  const [isTransitionModalOpen, setTransitionModalState] = useState(false);
  const [wantToTransfer, setWantToTransfer] = useState(true);
  const [fiscalCheckbox, toggleFiscalCheckbox] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string | number>(EMPTY_CARD);
  const [_counters, setCounters] = useState(counterList);
  const [countersData, setCountersData] = useState<CustomCounterValue[]>(
    countersDataFormatter(counterList, _providerId, accountNumber),
  );
  const [countersLoading, toggleCountersLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isPaymentModalOpen, setPaymentModalOpen] = useState<boolean>(true);

  const currentBalanceDate = useMemo(
    () =>
      merchantGroup.balanceDate
        ? moment(merchantGroup.balanceDate, 'X')
            .subtract(1, 'months')
            .unix()
        : moment()
            .month(moment().month() - 1)
            .date(2)
            .unix(),
    [merchantGroup.balanceDate],
  );

  const buttonName = useMemo(() => {
    if (loading) {
      return 'Подождите, идет загрузка';
    }
    if (wantToTransfer) {
      return isSpecialBilling || accountIsClosed ? 'Оплатить' : 'Оплатить и передать';
    }
    return 'Оплатить';
  }, [loading, wantToTransfer]);

  const handleTransitionModalClose = useCallback(() => {
    setTransitionModalState(false);
  }, []);

  const qrPaymentWindowCloseHandler = (): void => {
    setQrPaymentModalOpen(false);
  };

  const handleCheckbox = useCallback(() => {
    setWantToTransfer(wantToTransfer => !wantToTransfer);
  }, []);

  const submitCounter = useCallback(
    async (values: CustomCounterValue): Promise<boolean | void> => {
      const { accountNumber, value, date, typeId, providerId } = values;

      return await loadValue({
        accountNumber,
        value,
        date,
        typeId,
        providerId,
      }).then(res => {
        if (res) {
          yM.goals.sendCounters();
        }
      });
    },
    [loadValue],
  );

  const loadData = useCallback(async () => {
    toggleCountersLoading(true);

    if (_providerId && accountNumber) {
      const countersResult = await loadCounters({
        filter: {
          [CounterKeys.PROVIDER_ID]: { $eq: _providerId },
          [CounterKeys.ACCOUNT_NUMBER]: { $eq: accountNumber },
        },
      });

      setCounters(countersResult);
      setCountersData(countersDataFormatter(countersResult, _providerId, accountNumber));
    }
    toggleCountersLoading(false);
  }, [_providerId, accountNumber, loadCounters]);

  const onSubmit = (
    { amount, wantToTransfer, counters, emailForFiscalReceipt }: FormProps,
    { setFieldError, setFieldValue }: FormikHelpers<FormProps>,
  ): void => {
    setPaymentError('');

    if (merchantGroup.paymentMin && Number(amount) < merchantGroup.paymentMin) {
      setFieldError(
        'amount',
        `Минимально возможный платеж ${Number(merchantGroup.paymentMin)} руб.`,
      );

      return;
    }

    authenticated;

    if (authenticated && merchantGroup?.paymentMax && Number(amount) > merchantGroup.paymentMax) {
      setFieldError(
        'amount',
        `Максимально возможный платеж ${Number(merchantGroup.paymentMax)} руб.`,
      );

      return;
    } else if (Number(amount) > 15000) {
      setFieldError('amount', `Максимально возможный платеж 15000 руб.`);

      return;
    }

    const filledCounters = Boolean(
      counters.find(counter => {
        if (Boolean(counter) && Number(counter) >= 0) {
          return counter;
        }
      }),
    );

    if (wantToTransfer && !filledCounters && !isSpecialBilling && !accountIsClosed) {
      if (counters.length) {
        setMessage('Заполните корректно показания счётчиков', 'error');
        return;
      }
    }

    setLoading(true);

    if (
      wantToTransfer &&
      filledCounters &&
      !singleMethod &&
      !isSpecialBilling &&
      !accountIsClosed
    ) {
      Promise.all(
        countersData.filter(counter => typeof counter.value === 'number' && submitCounter(counter)),
      ).then(result => {
        if (result.length) {
          setFieldValue(
            'counters',
            _counters.map(() => ''),
          );
          loadData();
        }
      });
    }

    if (merchantGroup.providerId && accountNumber && paymentMethod !== SBP) {
      paymentGetLink({
        providerId: merchantGroup.providerId,
        merchantGroupId: merchantGroup.id,
        accountNumber,
        amount: Number(amount),
        distribution: singleMethod
          ? merchants.reduce<Array<{ merchantId: string; amount: number }>>((acc, merchant) => {
              if (merchant.balance) {
                acc.push({
                  merchantId: merchant.id,
                  amount: merchant.balance,
                });
              }

              return acc;
            }, [])
          : undefined,
        indications:
          wantToTransfer && singleMethod && filledCounters
            ? countersDataIndicationFormat(countersData).reduce<Indication[]>((acc, _counter) => {
                if (typeof _counter.value === 'number') {
                  acc.push(_counter);
                }

                return acc;
              }, [] as Indication[])
            : undefined,
        date: Number(currentBalanceDate),
        paymentSource,
        emailForFiscalReceipt,
      })
        .then(({ value, error }) => {
          if (value) {
            yM.goals.sendFormPaymentFast();

            if (openTransitionModal) {
              setLink(value);
              setTransitionModalState(true);
            } else {
              window.open(value);
            }
          }

          if (wantToTransfer && singleMethod && filledCounters) {
            setFieldValue(
              'counters',
              _counters.map(() => ''),
            );
            loadData();
          }

          if (error) {
            setPaymentError(error);
          }
        })
        .finally(() => setLoading(false));
    } else if (paymentMethod === SBP) {
      setQrPaymentModalOpen(true);
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<FormProps>({
    initialValues: {
      amount: merchantGroup.balance && merchantGroup.balance > 0 ? merchantGroup.balance : 0,
      wantToTransfer: true,
      counters: _counters.map(() => ''),
      emailForFiscalReceipt: '',
    },
    validationSchema: FormSchema,
    onSubmit,
  });

  const renderPaymentCommission = useCallback(() => {
    return getCommissionPaymentForm(values.amount, accountBenefit);
  }, [values.amount, accountBenefit]);

  const handleClose = useCallback((): void => {
    onClose();
    setPaymentError('');
    resetForm();
  }, [onClose, resetForm]);

  const handleLink = useCallback(() => {
    handleClose();
    handleTransitionModalClose();
  }, [handleClose, handleTransitionModalClose]);

  const handleSumChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      event.target.value = floatValueFormatter(event.target.value, 2, 5);
      handleChange(event);
    },
    [handleChange],
  );

  const changeCounterData = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
      integer?: null | number,
      decimal?: null | number,
    ): void => {
      e.preventDefault();

      const result = floatValueFormatter(e.target.value, decimal, integer);

      const currentCountersValue = values.counters.map((item, _index) => {
        if (index === _index) {
          return result;
        } else {
          return item;
        }
      });

      setFieldValue('counters', currentCountersValue);

      const _result = result ? Number(result) : null;

      setCountersData(countersData => {
        countersData[index] = { ...countersData[index], value: _result };
        return countersData;
      });
    },
    [setFieldValue, values.counters],
  );

  const changePaymentMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const evt = parseInt(event.target.value);

    if (isNaN(evt)) {
      setPaymentMethod(event.target.value as string);
    } else {
      setPaymentMethod(evt);
    }
  };

  const refreshPaymentMethod = useCallback((): void => {
    if (!isSbpEnabled) {
      setPaymentMethod(EMPTY_CARD);
    }
  }, [isSbpEnabled]);

  useEffect(() => {
    setFieldValue('wantToTransfer', wantToTransfer);
  }, [setFieldValue, wantToTransfer]);

  useEffect(() => {
    setIsSbpEnabled(getSbpEnabled(values.amount));
  }, [values.amount]);

  useEffect(() => {
    refreshPaymentMethod();
  }, [isSbpEnabled]);

  const renderLesserValueError = (num: string | null | undefined) => (
    <Grid container justify="flex-start" direction="column">
      <Grid item>
        <Typography style={{ fontSize: 12, textAlign: 'end' }} variant="caption">
          Показание меньше предыдущего
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip
          enterTouchDelay={0}
          disableFocusListener
          title={
            <Grid container justify="flex-start" direction="column" spacing={2}>
              <Grid item>
                <Typography variant="caption">
                  Некорректные показания для счетчика № {num}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Новые показания счетчика не могут быть ниже предыдущих. В прошлом месяце вы
                  передали большие показания. Пожалуйста, проверьте, корректность внесенных данных.
                  Для корректировки показаний и начислений в меньшую сторону обратитесь в вашу
                  обслуживающую организацию
                </Typography>
              </Grid>
            </Grid>
          }
        >
          <Typography style={{ fontSize: 12, textAlign: 'end' }} variant="caption" color="primary">
            Подробнее
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const FiscalTooltip = (
    <Tooltip
      interactive
      title={
        <Typography className={classes.tooltipText}>
          {merchantGroup.fiscal ? (
            <span>
              Информация о платеже будет отправлена на указанный вами email. Фискальный документ
              (кассовый чек) направляется Оператором фискальных данных (процесс отправки может
              занимать несколько дней). В случае, если плательщик не получил фискальный документ,
              ему необходимо обратиться с запросом к получателю платежа.
              <br />
              <a
                className={classes.tooltipLink}
                href="https://payment.help.opencity.pro/ka_receipt "
                target={'__blank'}
              >
                Подробнее
              </a>
            </span>
          ) : (
            <span>
              Информация о платеже будет отправлена на указанный вами email. Для получения
              фискального документа (кассового чека) необходимо обращаться к получателю платежа.
              <br />
              <a
                className={classes.tooltipLink}
                href="https://payment.help.opencity.pro/ka_receipt "
                target={'__blank'}
              >
                Подробнее
              </a>
            </span>
          )}
        </Typography>
      }
      placement={'bottom-start'}
    >
      <InfoOutlinedIcon className={classes.infoIcon} />
    </Tooltip>
  );

  const chipTooltip = (status: CounterStatus): ReactNode => (
    <Tooltip
      title={<AdditionalInfoBlock counterStatus={status} isExpired={false} fromPublic />}
      placement="top"
      interactive
    >
      <Chip className={classes.chip} label="Недоступен" />
    </Tooltip>
  );

  const renderPaymentMethodDefault = (
    <Hidden xsDown>
      <Grid item container direction="column" spacing={6}>
        <Grid item>
          <PaymentMethods />
        </Grid>
      </Grid>
    </Hidden>
  );

  const renderPaymentMethodSelect = (
    <Grid item className={classes.paymentSelectWrapper}>
      <Typography variant="h4" style={{ marginBottom: '9px', fontSize: '16px' }}>
        {isSbpEnabled ? 'Выберите способ оплаты:' : 'Cпособ оплаты: '}
      </Typography>

      <Grid container wrap="nowrap" spacing={3} direction={'column'}>
        <>
          <Grid item container className={classes.leftBlockPaymentSelect}>
            {
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={paymentMethod}
                onChange={changePaymentMethod}
                style={{ width: '100%', padding: 0 }}
              >
                {isSbpEnabled && (
                  <FormControlLabel
                    value={SBP}
                    control={<Radio color="primary" />}
                    label={
                      <div
                        style={{
                          marginLeft: '30px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          fontSize: '15px',
                        }}
                      >
                        <div className={classNames(classes.imgContainer, classes.mir)}>
                          <img className={classes.sbpImg} alt={'SBP'} src="/images/sbp_logo.png" />
                        </div>
                        {isMd ? 'Оплата через СБП' : 'Оплата по QR-коду'}
                      </div>
                    }
                    labelPlacement="start"
                    style={{
                      border: '1px solid rgba(7, 0, 61, 0.15)',
                      borderRadius: '8px',
                      width: '100%',
                      padding: 0,
                      margin: 0,
                      marginBottom: '16px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  />
                )}

                <FormControlLabel
                  value={EMPTY_CARD}
                  control={<Radio color="primary" />}
                  label={
                    <div
                      style={{
                        marginLeft: '24px',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '15px',
                      }}
                    >
                      <CreditCardIcon className={classes.creditCard} />
                      Картой
                    </div>
                  }
                  labelPlacement="start"
                  style={{
                    border: '1px solid rgba(7, 0, 61, 0.15)',
                    borderRadius: '8px',
                    width: '100%',
                    padding: 0,

                    margin: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                />
              </RadioGroup>
            }
          </Grid>
        </>
      </Grid>
    </Grid>
  );

  return (
    <form id={FORM_ID} onSubmit={handleSubmit} noValidate>
      <Dialog open={isVisibile} onClose={handleClose} fullScreen={isXs} fullWidth={true}>
        <DialogTitle classes={{ root: classes.title }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.dialogTitleWrapper}>
              <Typography variant="h4" className={classes.dialogTitle}>
                {isSpecialBilling || accountIsClosed || isBilling
                  ? 'Оплата ЖКУ'
                  : 'Оплата и передача показаний'}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <PaymentBannerModal
          isOpen={isPaymentModalOpen}
          onClose={() => setPaymentModalOpen(false)}
        />

        <DialogContent className={classes.сontent}>
          <Grid className={classes.dialogContainer} direction="column" container>
            <Grid item={true}>
              <TextField
                fullWidth={true}
                label="Сумма к оплате"
                name="amount"
                type="number"
                value={values.amount}
                error={touched.amount && Boolean(errors.amount)}
                helperText={
                  touched.amount && Boolean(errors.amount)
                    ? errors.amount
                    : renderPaymentCommission()
                }
                onChange={handleSumChange}
                onBlur={handleBlur}
                disabled={singleMethod}
                InputLabelProps={{
                  className: classes.label,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                InputProps={{
                  classes: {
                    input: cn(classes.input, singleMethod && classes.disabledInput),
                  },
                  endAdornment: !isXs && <PaymentMethods adornment />,
                }}
              />
            </Grid>
            <Grid className={classes.paymentInfo}>
              <Typography variant="caption" component="p" className={classes.paymentInfoText}>
                Принимаются карты только российских банков
              </Typography>

              <Hidden smUp>
                <Typography variant="caption" component="p" className={classes.paymentInfoText}>
                  {isSpecialBilling || accountIsClosed || !wantToTransfer
                    ? `Нажимая \u00ABОплатить\u00BB, Вы соглашаетесь `
                    : `Нажимая \u00ABОплатить и передать\u00BB, Вы соглашаетесь `}
                  <Link
                    className={classes.link}
                    target="_blank"
                    href={merchantGroup.offerLink || ''}
                  >
                    {`с\u00A0условиями\u00A0оферты`}
                  </Link>
                </Typography>
              </Hidden>
            </Grid>

            <Grid item className={classes.infoBannerWrapper}>
              <BalanceInfoBanner providerId={Number(merchantGroup.providerId)} />
            </Grid>
            {!isSpecialBilling && !accountIsClosed && (
              <>
                <Grid
                  container
                  alignItems="center"
                  onClick={handleCheckbox}
                  className={classes.checkboxWrapper}
                >
                  <Checkbox checked={wantToTransfer} className={classes.checkbox} />
                  <span className={classes.wantToTransfer}>Хочу передать показания счётчиков</span>
                </Grid>

                <Collapse
                  in={wantToTransfer}
                  timeout={1000}
                  className={cn(wantToTransfer && !isXs && classes.countersBlock)}
                >
                  {countersLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {_counters.length ? (
                        <Grid container direction="column">
                          <Hidden xsDown>
                            <Grid container item className={classes.tableTitleWrapper}>
                              <Grid item className={classes.counterColumn}>
                                <Typography variant="body2" className={classes.secondaryTableText}>
                                  Счётчик
                                </Typography>
                              </Grid>
                              <Grid item className={classes.previousColumn}>
                                <Typography
                                  variant="body2"
                                  className={cn(classes.secondaryTableText, classes.previousTitle)}
                                >
                                  Прошлое значение
                                </Typography>
                              </Grid>
                              <Grid item className={classes.currentColumn}>
                                <Typography
                                  variant="body2"
                                  align="right"
                                  className={classes.secondaryTableText}
                                >
                                  Текущее
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Hidden>

                          <Grid container item>
                            {_counters.map((counter, index) => {
                              const {
                                nextCheckDate,
                                number,
                                resourceName,
                                decimal,
                                integer,
                                unitName,
                                status,
                              } = counter;
                              const previousValue = previousValueFormatted(counter.previousValue);
                              const previousValueDate = previousCounterDate(
                                counter.previousValueDate,
                              );

                              const statusNotWorking = status !== CounterStatus.WORKING;
                              const isExpired = !nextCheckDate
                                ? false
                                : nextCheckDate <=
                                  moment()
                                    .startOf('days')
                                    .unix()
                                ? true
                                : false;
                              const isUnavailableToday =
                                Boolean(previousValueDate) &&
                                moment(Number(previousValueDate), 'X').format('DD/MM/YYYY') ===
                                  moment().format('DD/MM/YYYY');
                              const isBlockedByPeriod =
                                firstCounterDate && lastCounterDate
                                  ? moment().format('DD') <
                                      moment(firstCounterDate * 1000).format('DD') ||
                                    moment().format('DD') >
                                      moment(lastCounterDate * 1000).format('DD')
                                  : transferringPossibility &&
                                    lastCounterDate !== null &&
                                    moment()
                                      .startOf('days')
                                      .unix() > Number(lastCounterDate);
                              const disabledCounter =
                                statusNotWorking ||
                                isExpired ||
                                isUnavailableToday ||
                                isBlockedByPeriod;
                              const dateFieldHelperText = () => {
                                if (isBlockedByPeriod && !statusNotWorking) {
                                  if (firstCounterDate) {
                                    return (
                                      <div
                                        style={{
                                          color: '#07003D',
                                          opacity: '0.9',
                                          fontSize: '13px',
                                        }}
                                      >
                                        Приём показаний доступен c{' '}
                                        {moment(Number(firstCounterDate), 'X').format('DD')}.
                                        {moment(Number(firstCounterDate), 'X').format('MM')} до{' '}
                                        {moment(Number(lastCounterDate), 'X').format('DD')}.
                                        {moment(Number(lastCounterDate), 'X').format('MM')}
                                      </div>
                                    );
                                  }
                                  return (
                                    <div
                                      style={{ color: '#07003D', opacity: '0.9', fontSize: '13px' }}
                                    >
                                      Приём показаний доступен до{' '}
                                      {moment(Number(lastCounterDate), 'X').format('DD')}.
                                      {moment(Number(lastCounterDate), 'X').format('MM')}
                                    </div>
                                  );
                                }

                                return '';
                              };
                              return (
                                <>
                                  {isXs ? (
                                    <Grid
                                      container
                                      item
                                      key={index}
                                      direction="column"
                                      className={classes.tableRowSmall}
                                    >
                                      <Grid container item>
                                        <Typography
                                          variant="body2"
                                          className={classes.resourceName}
                                        >
                                          {`${resourceName} №${number}`}
                                          <span className={classes.chipWrapper}>
                                            {statusNotWorking && chipTooltip(status)}
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid container item>
                                        <Typography variant="body2" className={classes.smallText}>
                                          {nextCheckDate
                                            ? `Поверка счётчика до ${moment(
                                                nextCheckDate,
                                                'X',
                                              ).format('D MMMM YYYY')}`
                                            : 'Нет данных о поверке счётчика'}
                                        </Typography>
                                      </Grid>
                                      <Grid container item>
                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          type="number"
                                          disabled={disabledCounter}
                                          value={values.counters[index]}
                                          error={counterErrors.some(
                                            e => e.index === index && e.error === true,
                                          )}
                                          helperText={
                                            <>
                                              {counterErrors.some(
                                                e => e.index === index && e.error === true,
                                              ) && renderLesserValueError(number)}
                                              {dateFieldHelperText()}
                                            </>
                                          }
                                          className={cn(disabledCounter && classes.disabledCounter)}
                                          onChange={(value): void => {
                                            if (counter.previousValue) {
                                              if (
                                                Number(value.target.value) < counter.previousValue
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: true,
                                                    index: index,
                                                  },
                                                ]);
                                              }

                                              if (
                                                !value.target.value ||
                                                value.target.value.length === 0
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: false,
                                                    index: index,
                                                  },
                                                ]);
                                              }

                                              if (
                                                Number(value.target.value) >= counter.previousValue
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: false,
                                                    index: index,
                                                  },
                                                ]);
                                              }
                                            }

                                            changeCounterData(value, index, integer, decimal);
                                          }}
                                          InputLabelProps={{
                                            className: classes.label,
                                          }}
                                          InputProps={{
                                            classes: {
                                              input: classes.input,
                                            },
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        className={classes.previousValueWrapperSmall}
                                      >
                                        <Typography variant="body2" className={classes.smallText}>
                                          {`Последнее показание${
                                            previousValueDate ? ` (${previousValueDate})` : ''
                                          }: ${previousValue[0]}${
                                            previousValue[1] ? `,${previousValue[1]}` : ''
                                          }${unitName ? ` ${unitName}` : ''}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid container item key={index} className={classes.tableRow}>
                                      <Grid
                                        container
                                        item
                                        direction="column"
                                        justify="space-between"
                                        className={cn(classes.counterColumn, classes.counterItem)}
                                      >
                                        <Typography
                                          variant="body2"
                                          className={classes.resourceName}
                                        >
                                          {resourceName}
                                          <span className={classes.chipWrapper}>
                                            {statusNotWorking && chipTooltip(status)}
                                          </span>
                                        </Typography>
                                        <Tooltip
                                          interactive
                                          placement="top"
                                          className={classes.tooltipWrapper}
                                          title={
                                            <Grid>
                                              <Typography
                                                variant="body2"
                                                className={classes.tooltipText}
                                              >
                                                {nextCheckDate
                                                  ? `Поверка до ${moment(nextCheckDate, 'X').format(
                                                      'D MMMM YYYY',
                                                    )}`
                                                  : 'Нет данных о поверке счётчика'}
                                              </Typography>
                                            </Grid>
                                          }
                                        >
                                          <Typography className={classes.secondaryTableText}>
                                            Прибор учёта №{number}
                                          </Typography>
                                        </Tooltip>
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        direction="column"
                                        justify="space-between"
                                        alignItems="flex-end"
                                        className={cn(classes.previousColumn, classes.previousItem)}
                                      >
                                        <Typography
                                          variant="body2"
                                          className={classes.previousValue}
                                        >
                                          {previousValue[0]}
                                          {previousValue[1] && (
                                            <span className={classes.previousMeasureValue}>
                                              ,{previousValue[1]}
                                            </span>
                                          )}
                                        </Typography>
                                        {previousValueDate && (
                                          <Typography
                                            variant="body2"
                                            className={classes.secondaryTableText}
                                          >
                                            от {previousValueDate}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid container item className={classes.currentColumn}>
                                        <TextField
                                          className={cn(
                                            classes.textFieldRoot,
                                            disabledCounter && classes.disabledCounter,
                                          )}
                                          variant="outlined"
                                          type="number"
                                          disabled={disabledCounter}
                                          error={counterErrors.some(
                                            e => e.index === index && e.error === true,
                                          )}
                                          helperText={
                                            <>
                                              {counterErrors.some(
                                                e => e.index === index && e.error === true,
                                              ) && renderLesserValueError(number)}
                                              {dateFieldHelperText()}
                                            </>
                                          }
                                          value={values.counters[index]}
                                          onChange={(value): void => {
                                            if (counter.previousValue) {
                                              if (
                                                Number(value.target.value) < counter.previousValue
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: true,
                                                    index: index,
                                                  },
                                                ]);
                                              }

                                              if (
                                                !value.target.value ||
                                                value.target.value.length === 0
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: false,
                                                    index: index,
                                                  },
                                                ]);
                                              }

                                              if (
                                                Number(value.target.value) >= counter.previousValue
                                              ) {
                                                setCounterErrors(prevValue => [
                                                  ...prevValue.filter(e => e.index !== index),
                                                  {
                                                    error: false,
                                                    index: index,
                                                  },
                                                ]);
                                              }
                                            }

                                            changeCounterData(value, index, integer, decimal);
                                          }}
                                          InputLabelProps={{
                                            className: classes.label,
                                          }}
                                          InputProps={{
                                            classes: {
                                              root: classes.inputRoot,
                                              input: cn(classes.input, classes.counterInput),
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container justify="center">
                          <Typography variant="body2" className={classes.secondaryTableText}>
                            По вашему ЛС не удалось найти доступные счетчики
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Collapse>
              </>
            )}
            {!singleMethod && !isSpecialBilling && (
              <>
                {wantToTransfer && <Divider className={classes.divider} />}
                <Grid
                  container
                  alignItems="center"
                  justify={'flex-start'}
                  className={classes.checkboxWrapper}
                >
                  <Grid
                    item
                    onClick={(): void => {
                      toggleFiscalCheckbox(!fiscalCheckbox);
                    }}
                  >
                    <Checkbox checked={fiscalCheckbox} className={classes.checkbox} />
                  </Grid>

                  <Grid
                    item
                    onClick={(): void => {
                      toggleFiscalCheckbox(!fiscalCheckbox);
                    }}
                  >
                    <span className={classes.wantToTransfer}>
                      {merchantGroup.fiscal
                        ? 'Получить фискальный чек на email'
                        : 'Получить информацию о платеже на email'}
                    </span>
                  </Grid>

                  <Grid item className={classes.tooltip}>
                    {FiscalTooltip}
                  </Grid>
                </Grid>

                <Collapse
                  in={fiscalCheckbox}
                  timeout={1000}
                  className={cn(fiscalCheckbox && !isXs && classes.countersBlock)}
                >
                  <FormLabel>
                    <Typography variant="caption">{emailFieldLabel}</Typography>
                  </FormLabel>
                  <EmailField
                    withoutLabel={true}
                    value={values.emailForFiscalReceipt}
                    onChange={handleChange}
                    helperText={errors.emailForFiscalReceipt || ''}
                    error={errors.emailForFiscalReceipt}
                    name={'emailForFiscalReceipt'}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Collapse>
              </>
            )}

            {!merchantGroup.tokenizationEnabled
              ? renderPaymentMethodDefault
              : renderPaymentMethodSelect}

            {paymentError && (
              <Grid item={true}>
                <Alert severity="error">{paymentError}</Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions classes={{ root: classes.actions }}>
          <Hidden xsDown>
            <Typography variant="caption" component="p" color="textSecondary">
              {isSpecialBilling || accountIsClosed || !wantToTransfer
                ? `Нажимая \u00ABОплатить\u00BB, Вы соглашаетесь `
                : `Нажимая \u00ABОплатить и передать\u00BB, Вы соглашаетесь `}
              <Link className={classes.link} target="_blank" href={merchantGroup.offerLink || ''}>
                {`с\u00A0условиями\u00A0оферты`}
              </Link>
            </Typography>
          </Hidden>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={FORM_ID}
            disabled={loading || counterErrors.some(e => e.error === true)}
            className={classes.button}
          >
            {buttonName}
          </Button>
        </DialogActions>
      </Dialog>

      <TransitionModal
        isOpen={isTransitionModalOpen}
        link={link}
        onClose={handleTransitionModalClose}
        handleLink={handleLink}
      />

      <QrPaymentWindow
        account={accountNumber ? accountNumber : ''}
        provider={_providerId ? _providerId : 0}
        emailForFiscalReceipt={fiscalCheckbox ? values.emailForFiscalReceipt : ''}
        paymentSource={paymentSource ? paymentSource : 0}
        merchantGroup={merchantGroup.id}
        open={qrPaymentModalOpen}
        close={qrPaymentWindowCloseHandler}
        amount={Number(values.amount)}
      />
    </form>
  );
};
