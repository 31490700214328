import { AxiosResponse } from 'axios';
import { mergeDeepRight } from 'ramda';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import OpencityActionNames from '@services/opencity/actionNames';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  Flat,
  FlatInterface,
  FlatKeys,
  FlatFilterProps,
  LoadFlat,
  CountFlat,
  AddFlatFilter,
  ClearFlatFilter,
} from '@core/entities/Opencity/Flat';
import Store from './Store';
import { Loading, endLoading, SetLoading } from './interfaces/Loading';
import { Pagination } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';

export type GisFlatIndexResponse = JrpcResponse<{ items: FlatInterface[]; total: number }>;
export type GisFlatCountResponse = JrpcResponse<number>;

class FlatStore extends Store
  implements
    Loading,
    Pagination,
    Filter<FlatFilterProps, FlatKeys>,
    Entity<Flat, { filter?: FlatFilterProps; limit?: number; offset?: number }> {
  @observable private _flats: Flat[];
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _filter: FlatFilterProps;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._flats = [];
    this._loading = false;
    this._limit = 10;
    this._offset = 0;
    this._total = 0;
    this._filter = {};
  }

  @action public count: CountFlat = async params => {
    let count: number = this._total;

    const filter = (params && params.filter) || toJS(this._filter);

    this._filter = filter;

    await this._services.opencity.requests
      .gisFlatCount({
        params: {
          filter,
        },
      })
      .then(({ data: { result } }: AxiosResponse<GisFlatCountResponse>): void => {
        if (result) {
          count = result;

          this._total = count;
        }
      });

    return count;
  };

  @action public load: LoadFlat = async params => {
    this._loading = true;

    let flats: Flat[] = [];

    const { _filter, _offset, _limit } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests[
      params?.ownFlats ? OpencityActionNames.USER_FLAT_INDEX : OpencityActionNames.GIS_FLAT_INDEX
    ]({
      params: {
        filter,
        limit,
        offset,
      },
    })
      .then(({ data: { result } }: AxiosResponse<GisFlatIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            flats = items.map(value => new Flat(value));

            this._flats = flats;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return flats;
  };

  @action public getFlatsByHouseId = (houseId: number): Flat[] => {
    return this.list.filter((value: Flat): boolean => value.houseId === houseId);
  };

  @action public addFilter: AddFlatFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public clearFilter: ClearFlatFilter = () => {
    this._filter = {};
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  @action public cleanUp = (): void => {
    this._flats = [];
    this._filter = {};
    this._loading = false;
    this._limit = 10;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Flat[] {
    return toJS(this._flats);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get filter(): FlatFilterProps {
    return toJS(this._filter);
  }
}

export default FlatStore;
