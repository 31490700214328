import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  Theme,
  createStyles,
  useMediaQuery,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Icon from '@core/components/Icon';
import { TELEGRAM_LINK } from '@constants/telegram';
import SocialShareHeader from '../SocialShareHeader';
import { yM } from '@core/utils/yandexMetrika/yandexMetrika';
import theme from '@core/themes/billing';

interface SocialIconsProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '101px',

      [theme.breakpoints.down('xs')]: {
        width: '200px',
        margin: '0 0 4px 0',
      },
    },
    icon: {
      cursor: 'pointer',

      '&:hover': {
        '& > path': {
          transition: 'all 0.2s',
          fillOpacity: '1',
        },
      },
    },
    link: {
      textDecoration: 'none',
    },
    dialogContent: {
      padding: '0 55px',

      [theme.breakpoints.down('xs')]: {
        padding: '0 10px',
      },
    },
    socialIconsBlock: {
      marginTop: '50px',
      width: '290px',

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        width: '90%',
      },
    },
    socialText: { marginTop: '35px' },
    catBird: {
      marginTop: '25px',

      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        bottom: 0,
        right: 'calc(100vw/2 - 90px)',
      },
    },
    tooltip: {
      fontSize: 13,
      background: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 500,
    },
    yaTooltip: {
      lineHeight: '15px',
      fontSize: '15px',
      background: '#FFFFFF',
      fontWeight: 300,
      border: '1px solid #000000 ',
      borderRadius: 0,
      color: '#000000',
      boxShadow: '-3px -3px 20px 0px rgba(34, 60, 80, 0.24)',
    },
    iconSocialDiv: {
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'all 0.2s',

      '&:hover': {
        background: '#E6E6E6',
      },

      [theme.breakpoints.down(281)]: {
        margin: '2px 10px 0 0',
      },
    },
    iconSocial: {
      width: '48px',
      height: '48px',
    },
    miniSnack: {
      width: '162px',
      padding: '5px 13px',
      zIndex: 1001,
      position: 'absolute',
      bottom: '25%',
      left: '33%',
      background: '#E6E6E6',
      borderRadius: '25px',

      [theme.breakpoints.down('xs')]: {
        left: 'calc(100vw/2 - 90px)',
        bottom: '40%',
      },
    },
  }),
);

const SocialIcons: FC<SocialIconsProps> = ({}) => {
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [linkTooltipText, setLinkTooltipText] = useState<string>('');

  const handleClose = (): void => {
    setShowDialog(!showDialog);
  };

  const handleOpen = (): void => {
    yM.reachGoal('to_share');
    setShowDialog(!showDialog);
  };

  const generateServiceLink = (): void => {
    yM.reachGoal('share_our_link');

    navigator['clipboard']
      .writeText(
        'https://cabinet.rc-online.ru\n\nПопробуйте Кабинет Абонента - оплата ЖКУ, показания счетчиков, электронная квитанция и многое другое',
      )
      .then(() => {
        setLinkTooltipText('Ссылка скопирована');
        setLinkCopied(true);

        setTimeout(() => setLinkCopied(false), 1000);
      })
      .catch(err => {
        setLinkTooltipText('Поробуйте позже!');
        setLinkCopied(true);

        setTimeout(() => setLinkCopied(false), 1000);
      });
  };

  return (
    <div className={classes.wrapper} onClick={() => yM.reachGoal('to_our_telegram')}>
      <Tooltip classes={{ tooltip: classes.tooltip }} title={'Мы в Telegram'}>
        <a className={classes.link} target="_blank" rel="noopener noreferrer" href={TELEGRAM_LINK}>
          <Icon icon={'socialTelegram'} className={classes.icon} />
        </a>
      </Tooltip>

      <Tooltip classes={{ tooltip: classes.tooltip }} title={'Пройти опрос'}>
        <a
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/forms/d/1aL7RImtF_WBrmIfUDLoO7bJ_c9UdYNuc0fsQ_7LznGA/viewform?edit_requested=true"
        >
          <Icon icon={'socialFace'} className={classes.icon} />
        </a>
      </Tooltip>

      <Tooltip classes={{ tooltip: classes.tooltip }} title={'Рассказать соседям'}>
        <div onClick={handleOpen}>
          <Icon icon={'socialSendTo'} className={classes.icon} />
        </div>
      </Tooltip>

      <Dialog open={showDialog} maxWidth="sm" onClose={handleClose} fullScreen={isXs}>
        <DialogTitle style={{ padding: '16px 18px !important' }}>
          <Grid container={true} justify={'space-between'} alignItems={'center'}>
            <Grid item={true}>
              <Typography variant="h6" style={isXs ? { fontSize: '18px' } : {}}>
                Рассказать соседям
              </Typography>
            </Grid>

            <Grid item={true}>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container={true} spacing={2} alignItems="center" justify="center">
            {isXs ? (
              <>
                <Grid item={true} className={classes.socialText}>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    Нажмите на иконку мессенджера и поделитесь ссылкой на сервис с вашими соседями.
                    Здесь просто и удобно — им тоже понравится
                  </Typography>
                </Grid>

                <Grid
                  item={true}
                  container={true}
                  justify="space-between"
                  className={classes.socialIconsBlock}
                >
                  <SocialShareHeader />

                  <Tooltip classes={{ tooltip: classes.yaTooltip }} title={'Копировать ссылку'}>
                    <div onClick={generateServiceLink} className={classes.iconSocialDiv}>
                      <Icon icon={'socialModalChain'} className={classes.iconSocial} />
                    </div>
                  </Tooltip>

                  {linkCopied && <div className={classes.miniSnack}>{linkTooltipText}</div>}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item={true}
                  container={true}
                  justify="space-between"
                  className={classes.socialIconsBlock}
                >
                  <SocialShareHeader />

                  <Tooltip classes={{ tooltip: classes.yaTooltip }} title={'Копировать ссылку'}>
                    <div onClick={generateServiceLink} className={classes.iconSocialDiv}>
                      <Icon icon={'socialModalChain'} className={classes.iconSocial} />
                    </div>
                  </Tooltip>

                  {linkCopied && <div className={classes.miniSnack}>{linkTooltipText}</div>}
                </Grid>

                <Grid item={true} className={classes.socialText}>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    Нажмите на иконку мессенджера и поделитесь ссылкой на сервис с вашими соседями.
                    Здесь просто и удобно — им тоже понравится
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid item={true} container={true} justify="flex-end" className={classes.catBird}>
            <img
              width={isXs ? '220px' : '160px'}
              height={isXs ? '209px' : '149px'}
              src={linkCopied ? '/images/catWithBirdCopied.svg' : '/images/catWithBird.svg'}
              alt="catWithBird"
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SocialIcons;
