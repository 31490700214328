// Компонент желательно переписать на функциональный в рамках следующего тех. спринта
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { libraryIsLoaded } from '@utils/libraryIsLoaded';
import { MapOrder } from '@entities/Opencity/MapOrder';
import { Camera } from '@entities/Opencity/Camera';
import { OrderType } from '@entities/Opencity/Order';
import MapStore from '@core/stores/MapStore';

const styles = createStyles({
  hiddenMap: {
    height: 0,
    margin: 0,
    padding: 0,
    width: 0,
  },
  map: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

interface YandexMapProps extends WithStyles<typeof styles> {
  orders?: MapOrder[];
  cameras?: Camera[];
  className?: string;
  openCamera?: (camera: Camera) => void;
  mapConfig: {
    center: [number, number];
    zoom: number;
  };
  mapStore: MapStore;
}

@observer
class YandexMap extends Component<YandexMapProps, {}> {
  private mapContainer: any;
  private map: any;

  public constructor(props: YandexMapProps) {
    super(props);

    this.mapContainer = null;

    this.map = null;

    this.props.mapStore.setMapState.bind(this);
  }

  // Код ниже оставлен для референса, чтобы понимать как это оптимизировать
  // при переписывании компонента

  // public shouldComponentUpdate(nextProps: Readonly<YandexMapProps>): boolean {
  //   if (this.props) {
  //     if (this.props.cameras && nextProps.cameras) {
  //       return this.props.cameras.length !== nextProps.cameras.length;
  //     }
  //     if (this.props.orders && nextProps.orders) {
  //       return this.props.orders.length !== nextProps.orders.length;
  //     }
  //   }

  //   return (
  //     this.props.mapConfig.center[0] !== nextProps.mapConfig.center[0] ||
  //     this.props.mapConfig.center[1] !== nextProps.mapConfig.center[1]
  //   );
  // }

  public componentDidUpdate(): void {
    this.renderMap();
  }

  public componentDidMount(): void {
    this.renderMap();
  }

  public render(): JSX.Element {
    const { classes, className } = this.props;
    const isLoaded: boolean = libraryIsLoaded('ymaps');

    return (
      <div className={classNames(classes.root, className)}>
        <div
          className={classNames(classes.map, {
            [classes.hiddenMap]: !isLoaded,
          })}
          ref={(map): void => {
            this.mapContainer = map;
          }}
        >
          {!isLoaded && <span>Произошла ошибка при загрузке карты</span>}
        </div>
      </div>
    );
  }

  private renderOrders = (map: any): void => {
    const { ymaps } = window as any;
    const { orders } = this.props;

    ymaps.modules.require(['PieChartClusterer'], (PieChartClusterer: any) => {
      const clusterer = new PieChartClusterer({
        disableClickZoom: true,
        gridSize: 64,
        opacity: 0.5,
        preset: 'islands#greenClusterIcons',
        showInAlphabeticalOrder: true,
      });

      const markers: any[] = [];

      const isDefined = (param: string | undefined | null): boolean =>
        !(param === 'undef' || param === '-' || param === '' || param === undefined);

      const constructContent = (data: MapOrder): string => {
        const street = `ул. ${data.streetName},`;
        let house = '';
        let building = '';

        if (isDefined(data.buildingNumber)) {
          house = `д. ${data.houseNumber},`;
          building = `стр. ${data.buildingNumber}`;
        } else {
          house = `д. ${data.houseNumber}`;
        }

        return street + house + building;
      };

      function addMarker(data: MapOrder): any {
        const content = constructContent(data);
        let preset = 'islands#greenDotIcon';

        if (data.type === OrderType.EMERGENCY) {
          preset = 'islands#redDotIcon';
        }

        return new ymaps.Placemark(
          [Number(data.lat), Number(data.lng)],
          {
            balloonContentBody: content,
            balloonContentHeader: data.category,
            hintContent: data.category,
            zIndexActive: 999999,
          },
          {
            preset,
          },
        );
      }

      if (orders) {
        orders.forEach(order => {
          const marker = addMarker(order);
          markers.push(marker);
        });
      }

      clusterer.add(markers);

      map.geoObjects.add(clusterer);
    });
  };

  renderCameras = (map: any): void => {
    const { ymaps } = window as any;
    const { cameras } = this.props;
    const fullscreenControl = new ymaps.control.FullscreenControl();
    map.controls.add(fullscreenControl);

    ymaps.modules.require(['PieChartClusterer'], (PieChartClusterer: any) => {
      const clusterer = new PieChartClusterer({
        disableClickZoom: false,
        gridSize: 64,
        opacity: 0.5,
        preset: 'islands#greenClusterIcons',
        showInAlphabeticalOrder: true,
        maxZoom: 18,
      });

      const markers: any[] = [];

      cameras?.forEach(camera => {
        const cameraPlaceMark = new ymaps.Placemark(
          [camera.latitude, camera.longitude],
          {
            hintContent: camera.name,
            zIndexActive: 999999,
          },
          {
            preset: 'islands#greenDotIcon',
            iconColor: '#18cda6',
          },
        );

        cameraPlaceMark.events.add('click', (e: any): void => {
          e.preventDefault();
          if (this.props.openCamera && camera) {
            this.props.openCamera(camera);
            fullscreenControl.exitFullscreen();
          }
        });

        markers.push(cameraPlaceMark);
      });
      clusterer.add(markers);
      map.geoObjects.add(clusterer);
    });
  };

  private renderMap = (): void => {
    const { ymaps } = window as any;
    const { mapConfig } = this.props;

    if (ymaps) {
      ymaps.ready((): void => {
        if (this.map) {
          this.map.destroy();
        }

        const map = new ymaps.Map(this.mapContainer, {
          behaviors: ['dblClickZoom', 'drag'],
          controls: ['zoomControl'],
          ...mapConfig,
        });
        this.map = map;

        map.events
          .add(['mouseleave'], (event: any) => {
            if (event.originalEvent.map.behaviors.isEnabled('scrollZoom')) {
              event.originalEvent.map.behaviors.disable(['scrollZoom']);
            }
          })
          .add(['click'], (event: any) => {
            if (event.originalEvent.map.behaviors.isEnabled('scrollZoom')) {
              event.originalEvent.map.behaviors.disable(['scrollZoom']);
            } else {
              event.originalEvent.map.behaviors.enable(['scrollZoom']);
            }
          });

        map.behaviors.disable('scrollZoom');

        this.renderOrders(map);
        this.renderCameras(map);
      });
    }
  };
}

export default withStyles(styles)(YandexMap);
