import { MerchantGroup, BankCommissionList } from '@core/entities/Billing/MerchantGroup';

class BankCommission {
  private comsList?: BankCommissionList[];

  constructor(private mGroup?: MerchantGroup) {
    if (mGroup) {
      if (mGroup.bankCommissionList && mGroup.bankCommissionList.length > 0) {
        this.comsList = mGroup.bankCommissionList;
      }
    }
  }

  public getCommission = (amount: number) =>
    this.comsList
      ? this.getCommissionForPaymentBlock(amount)
      : this.getCommissionForPaymentBlockOld(amount);

  public getPaymentButtonName = (amount: number) => {
    return this.getCommission(amount).toLowerCase() === 'без комиссии' && amount > 0
      ? 'Оплатить без комиссии'
      : 'Оплатить';
  };

  public getCommissionPaymentForm = (amount: number, accountBenefit?: number) =>
    this.comsList
      ? this.getCommissionForPaymentForm(amount, accountBenefit)
      : this.getCommissionForPaymentFormOld(amount, accountBenefit);

  private getCommissionForPaymentForm = (amount: number, accountBenefit?: number) => {
    const list = this.getBankComsList(amount);

    if ((amount === 0 || !amount) && list?.bankCommissionIsUpper && accountBenefit === 1) {
      return `Сервисный сбор будет рассчитан после ввода суммы платежа`;
    }

    if ((amount === 0 || !amount) && !list?.bankCommissionIsUpper && accountBenefit === 1) {
      return `Комиссия будет рассчитана после ввода суммы платежа`;
    }

    if (amount === 0 || !amount) {
      return `Комиссия будет рассчитана после ввода суммы платежа`;
    }

    if (amount > 0 && amount < Number(list?.paymentMin)) {
      return `Минимально возможный платеж ${Number(this.mGroup?.paymentMin)} руб.`;
    }

    if (list?.bankCommissionIsUpper) {
      if (amount >= Number(list?.paymentMin) && amount <= Number(list?.paymentMax)) {
        if (Number(list?.bankCommissionPercent) !== 0 && Number(list?.bankCommissionMin) === 0) {
          return `${accountBenefit === 1 ? 'Сервисный сбор:' : 'Комиссия:'} ${
            list?.bankCommissionPercent
          }%`;
        }

        if (Number(list?.bankCommissionPercent) === 0 && Number(list?.bankCommissionMin) !== 0) {
          return `${accountBenefit === 1 ? 'Сервисный сбор:' : 'Комиссия:'} ${Number(
            list?.bankCommissionMin,
          )} руб.`;
        }

        if (Number(list?.bankCommissionPercent) !== 0 && Number(list?.bankCommissionMin) !== 0) {
          return `${accountBenefit === 1 ? 'Сервисный сбор:' : 'Комиссия:'} ${
            list?.bankCommissionPercent
          }% (не менее ${Number(list?.bankCommissionMin)} руб.)`;
        }
      }
    }

    return 'Без комиссии';
  };

  public getSbpEnabled = (amount: number) => {
    const list = this.getBankComsList(amount);

    if (amount >= Number(list?.paymentMin) && amount <= Number(list?.paymentMax)) {
      if (list?.sbpEnabled) {
        return true;
      }
    }

    return false;
  };

  private getCommissionForPaymentFormOld = (amount: number, accountBenefit?: number) => {
    if (this.mGroup) {
      const { bankCommissionIsUpper, bankCommission, paymentMax, paymentMin } = this.mGroup;

      if ((amount === 0 || !amount) && bankCommissionIsUpper && accountBenefit === 1) {
        return `Сервисный сбор будет рассчитан после ввода суммы платежа`;
      }

      if ((amount === 0 || !amount) && !bankCommissionIsUpper && accountBenefit === 1) {
        return `Комиссия будет рассчитана после ввода суммы платежа`;
      }

      if (amount === 0 || !amount) {
        return `Комиссия будет рассчитана после ввода суммы платежа`;
      }

      if (amount > 0 && amount < Number(paymentMin)) {
        return `Минимально возможный платеж ${Number(paymentMin)} руб.`;
      }

      if (bankCommissionIsUpper) {
        if (amount >= Number(paymentMin) && amount <= Number(paymentMax)) {
          if (bankCommission) {
            return `${accountBenefit === 1 ? 'Сервисный сбор:' : 'Комиссия:'} ${bankCommission}%`;
          }
        }
      }
    }

    return 'Без комиссии';
  };

  private getBankComsList = (amount: number): BankCommissionList | null => {
    if (this.comsList) {
      const minPayments: number[] = [];

      for (const list of this.comsList) {
        minPayments.push(Number(list.paymentMin));

        if (amount >= Number(list.paymentMin) && amount <= Number(list.paymentMax)) {
          return list;
        }
      }

      const lesserPayment = Math.min(...minPayments);
      for (const list of this.comsList) {
        if (Number(list.paymentMin) === lesserPayment) {
          return list;
        }
      }
    }

    return null;
  };

  private getCommissionForPaymentBlock = (amount: number) => {
    const list = this.getBankComsList(amount);

    if (list?.bankCommissionIsUpper) {
      if (Number(list.bankCommissionMin) == 0 && Number(list.bankCommissionPercent) > 0) {
        return `${list.bankCommissionPercent}%`;
      }

      if (Number(list.bankCommissionPercent) == 0 && Number(list.bankCommissionMin) > 0) {
        return `${Number(list.bankCommissionMin)} руб.`;
      }

      if (Number(list.bankCommissionPercent) != 0 && Number(list.bankCommissionMin) != 0) {
        return `${list.bankCommissionPercent}% (не менее ${Number(list.bankCommissionMin)} руб.)`;
      }
    }
    return `без комиссии`;
  };

  private getCommissionForPaymentBlockOld = (amount: number) => {
    if (this.mGroup) {
      if (this.mGroup.bankCommissionIsUpper) {
        if (amount > Number(this.mGroup.paymentMin) && amount < Number(this.mGroup.paymentMax)) {
          if (this.mGroup.bankCommission != 0) {
            return `${this.mGroup.bankCommission}%`;
          }
        }
      }
    }
    return 'без комиссии';
  };
}

const useBankCommissionList = (mGroup?: MerchantGroup) => new BankCommission(mGroup);

export default useBankCommissionList;
