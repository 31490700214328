import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { SIGN_IN, SIGN_UP, ROOT } from '@public/constants/routes';
import { PAGE_Y_OFFSET, CITY_IMAGE_ANIMATION_DURATION } from '@public/constants/ui';
import { isUsinsk } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderColor: '#ffffff',
      borderRadius: '4px !important',
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500,
      height: theme.spacing(9),
      padding: 0,
      textAlign: 'center',
      width: theme.spacing(28),
    },
    buttonAfterScroll: {
      backgroundClip: 'content-box, border-box',
      backgroundImage: `linear-gradient(white, white), radial-gradient(ellipse at top center, ${theme.palette.secondary.main} 50%, ${theme.palette.primary.main} 100%)`,
      backgroundOrigin: 'border-box',
      border: 'solid 1px transparent',
      color: theme.palette.primary.main,
    },
    link: {
      textDecoration: 'none',
    },
    registrationButton: {
      width: theme.spacing(40),
    },
  }),
);

interface SignControlsProps {
  pageYOffset: number;
  pathname: string;
  id: string;
}

const SignControls: FC<SignControlsProps> = ({ id, pathname, pageYOffset }) => {
  const classes = useStyles();

  const [buttonClasses, setButtonClasses] = useState<string>(
    classNames(classes.button, classes.buttonAfterScroll),
  );

  // useEffect(() => {
  //   setButtonClasses(classNames(classes.button, classes.buttonAfterScroll));

  //   const timeout = setTimeout((): void => {
  //     setButtonClasses(classes.button);
  //   }, (CITY_IMAGE_ANIMATION_DURATION - 1) * 1000);

  //   return (): void => {
  //     clearTimeout(timeout);
  //   };
  // }, [pathname, classes.button, classes.buttonAfterScroll]);

  return (
    <>
      <Grid item={true}>
        <Link className={classes.link} to={SIGN_IN} tabIndex={-1}>
          <Button
            className={classNames(buttonClasses, {
              // [classes.buttonAfterScroll]: pathname !== ROOT || pageYOffset >= PAGE_Y_OFFSET,
            })}
            id={`${id}__sign-in`}
            variant="outlined"
          >
            Вход
          </Button>
        </Link>
      </Grid>

      {!isUsinsk && (
        <Grid item={true}>
          <Link className={classes.link} to={SIGN_UP} tabIndex={-1}>
            <Button
              className={classNames(buttonClasses, classes.registrationButton, {
                [classes.buttonAfterScroll]: pathname !== ROOT || pageYOffset >= PAGE_Y_OFFSET,
              })}
              id={`${id}__sign-up`}
              variant="outlined"
            >
              Регистрация
            </Button>
          </Link>
        </Grid>
      )}
    </>
  );
};

export default SignControls;
