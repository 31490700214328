import React, { ChangeEvent, FC, ReactElement, useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import OrganizationSearch from '@private/components/OrganizationSearch';
import NameApplicantField from '@components/Fields/NameApplicantField';
import EmailField from '@components/Fields/EmailField';
import DescriptionField from '@components/Fields/DescriptionField';
import { useFeedbackStore, useOwnProfileStore, useSignStore } from './hooks';
import { FormSchema } from './FormSchema.billing';
import { Organization } from '@entities/Billing/Organization';
import config from '@core/constants/config';
import { isEmpty } from 'ramda';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  fromPublic?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: 'auto',
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: 10,
          marginRight: 4,
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          background: '#eceff1',
          borderRadius: 10,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#c0cbd7',
          borderRadius: 10,
          width: '0.2em',
          height: 10,
        },
      },
    },

    actions: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      width: theme.spacing(32),
    },
    emailField: {
      marginBottom: '15px',
    },
    organization: {
      width: '100%',
      marginBottom: '15px',
    },
    recaptchaText: {
      color: 'rgba(68, 84, 107, 0.5)',
      fontSize: 13,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),

      textAlign: 'center',
      '& > a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },

    content: {
      marginRight: 12,
      paddingRight: 12,
    },
  }),
);

const FORM_ID = 'feedback_dialog__form';

const FeedbackDialog: FC<FeedbackDialogProps> = ({ open, onClose, fromPublic = false }) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const location = useLocation<any>();

  const classes = useStyles();
  const { create } = useFeedbackStore();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { captchaCheck } = useSignStore();
  const [isRobot, setIsRobot] = useState(false);

  const { billingUsers } = useOwnProfileStore();

  const noAccount = isEmpty(billingUsers);

  const organizationFieldLabel = useMemo(
    () =>
      fromPublic
        ? 'Исполнитель коммунальных услуг'
        : noAccount
        ? 'Исполнитель коммунальных услуг'
        : 'Исполнитель коммунальных услуг *',
    [fromPublic, noAccount],
  );

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      nameApplicant: '',
      email: '',
      title: '',
      text: '',
      reason: -1,
      personalAccount: '',
      organizationName: '',
    },
    validationSchema: FormSchema,
    onSubmit: async values => {
      if (recaptchaRef.current) {
        const response = await recaptchaRef.current.executeAsync();
        const isHuman = await captchaCheck({ response: String(response) }, true);

        if (!isHuman) {
          setIsRobot(true);
          return;
        }

        await recaptchaRef.current.reset();
      }

      // if (!fromPublic && !values.organizationName && !noAccount) {
      //   setFieldError('organizationName', 'Необходимо указать исполнителя');
      //   return;
      // }

      create(
        {
          data: {
            title: values.title,
            body: `Отзыв с Кабинета Абонента: ${values.text}`,
            fio: values.nameApplicant,
            email: values.email,
            reason: values.reason,
            personalAccount: Number(values.personalAccount) || undefined,
            organizationName: values.organizationName,
          },
        },
        { notification: true },
      );

      resetForm();

      onClose();
    },
  });

  // const selectOrganization = (value: Organization | null): void => {
  //   setFieldValue('organizationName', value?.name || '');
  // };

  const handleClose = (): void => {
    onClose();

    resetForm();
  };

  useEffect(() => {
    const _text = location.state?.support_description;

    if (_text) {
      setFieldValue('text', _text);
    }
  }, [location, setFieldValue]);

  const titleElement: ReactElement = <DialogTitle>Обратная связь</DialogTitle>;

  const RecaptchaInfo: ReactElement = (
    <div className={classes.recaptchaText}>
      Этот сайт защищен reCAPTCHA и применяются&nbsp;
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
        Политика конфиденциальности
      </a>
      &nbsp;и&nbsp;
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
        Условия использования
      </a>
      &nbsp;Google.
    </div>
  );

  const content: ReactElement = (
    <DialogContent className={classes.content}>
      <form id={FORM_ID} noValidate={true} onSubmit={handleSubmit}>
        <Grid container={true} direction="column">
          <Grid item={true} container={true}>
            <NameApplicantField
              id={`${FORM_ID}__name-applicant`}
              label="Ваше имя"
              required={true}
              value={values.nameApplicant}
              placeholder=" "
              helperText=" "
              error={errors.nameApplicant}
              touched={touched.nameApplicant}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__account`}
              fullWidth={true}
              label="Лицевой счёт"
              name="personalAccount"
              value={values.personalAccount}
              error={Boolean(errors.personalAccount && touched.personalAccount)}
              helperText={
                Boolean(errors.personalAccount && touched.personalAccount)
                  ? errors.personalAccount
                  : ' '
              }
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setFieldValue('personalAccount', e.target.value.replace(/[^0-9]/g, ''));
              }}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
            />
          </Grid>
          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__organization`}
              fullWidth={true}
              label="Исполнитель коммунальных услуг"
              required={false}
              name="organizationName"
              value={values.organizationName}
              error={Boolean(errors.organizationName && touched.organizationName)}
              helperText={
                Boolean(errors.organizationName && touched.organizationName)
                  ? errors.organizationName
                  : ' '
              }
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'Исполнитель коммунальных услуг или его ИНН'}
            />
            {/* <OrganizationSearch
              className={classes.organization}
              selectOrganization={selectOrganization}
              textFieldProps={{
                placeholder: 'Исполнитель коммунальных услуг или его ИНН',
                label: organizationFieldLabel,
                error: Boolean(errors.organizationName),
                helperText: errors.organizationName,
              }}
            /> */}
          </Grid>
          <Grid item={true} container={true} style={{ marginBottom: '15px' }}>
            <InputLabel shrink={true} variant="standard" error={Boolean(errors.reason)}>
              Причина
            </InputLabel>
            <Select
              fullWidth={true}
              variant={'outlined'}
              value={values.reason}
              onChange={handleChange}
              name={'reason'}
              error={Boolean(errors.reason)}
            >
              <MenuItem value={-1}>Не заполнено</MenuItem>
              <MenuItem value={10700}>Регистрация и восстановление пароля</MenuItem>
              <MenuItem value={10701}>Оплата ЖКУ</MenuItem>
              <MenuItem value={10702}>Приборы учета</MenuItem>
              <MenuItem value={10703}>Другое</MenuItem>
            </Select>
            <FormHelperText error={Boolean(errors.reason)}>{errors.reason}</FormHelperText>
          </Grid>

          <Grid item={true} container={true}>
            <TextField
              id={`${FORM_ID}__title`}
              fullWidth={true}
              label="Заголовок"
              required={true}
              name="title"
              value={values.title}
              error={Boolean(errors.title && touched.title)}
              helperText={Boolean(errors.title && touched.title) ? errors.title : ' '}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item={true} container={true}>
            <DescriptionField
              id={`${FORM_ID}__description`}
              label="Описание"
              helperText=" "
              required={true}
              value={values.text}
              error={errors.text}
              touched={touched.text}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item={true} container={true}>
            <EmailField
              id={`${FORM_ID}__email`}
              className={classes.emailField}
              required={true}
              helperText="На этот адрес будет отправлен ответ"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );

  const actions: ReactElement = (
    <DialogActions>
      <Button
        className={classes.button}
        id={`${FORM_ID}__cancel`}
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleClose}
      >
        Отмена
      </Button>
      <Button
        className={classes.button}
        id={`${FORM_ID}__close`}
        type="submit"
        form={FORM_ID}
        color="primary"
        variant="contained"
        size="small"
        disabled={isRobot}
      >
        Отправить
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      fullScreen={matches}
      onClose={handleClose}
      classes={{ paper: classes.root }}
    >
      {titleElement}
      {content}
      {actions}
      {RecaptchaInfo}
      {config.recaptchaKey && (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.recaptchaKey} />
      )}
    </Dialog>
  );
};

export default FeedbackDialog;
