import { observable, computed, action, toJS } from 'mobx';
import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import {
  Accrual,
  AccrualFilterProps,
  AccrualInterface,
  LoadAccrual,
} from '@entities/Billing/Accrual';
import { AxiosResponse } from 'axios';
import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@core/services';
import Store from '@stores/Store';

type AccrualIndexResponse = JrpcResponse<{ items: AccrualInterface[]; total: number }>;

class AccrualStore extends Store implements Loading {
  @observable private _loading: boolean;
  @observable private _total: number;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _filter: AccrualFilterProps;
  @observable private _accruals: Accrual[];

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._loading = false;
    this._accruals = [];
    this._total = 0;
    this._limit = 0;
    this._offset = 0;
    this._filter = {};
  }

  @action public cleanUp = (): void => {
    this._loading = false;
    this._accruals = [];
    this._total = 0;
    this._limit = 0;
    this._offset = 0;
    this._filter = {};
  };

  @action public load: LoadAccrual = async params => {
    let accruals: Accrual[] = [];
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .accrualIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<AccrualIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          accruals = result.items.map(value => new Accrual(value));

          this._accruals = accruals;
        }

        if (typeof result?.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return accruals;
  };

  @action public getMerchantGroup = (date: number): MerchantGroup[] => {
    let merchantGroups: MerchantGroup[] = [];

    if (this._accruals.length) {
      const accrual = this._accruals.find(item => item.balanceDate === date);
      if (accrual && accrual.merchantGroups) {
        merchantGroups = accrual.merchantGroups;
      }
    }

    return merchantGroups;
  };

  @action public getMeanBalanceByMerchant = (id: string, monthCount: number): number[] => {
    const _accruals = this._accruals.slice(0, monthCount);

    return _accruals.map(accrual => {
      if (accrual.merchantGroups) {
        return accrual.merchantGroups.find(item => item.id === id)?.balance || 0;
      }

      return 0;
    });
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get list(): Accrual[] {
    return toJS(this._accruals);
  }
}

export default AccrualStore;
