import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    isAllowed: operationStore.isAllowed,
  }));
};
