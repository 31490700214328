import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Theme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {},
  button: {
    display: 'none',
    margin: '0 auto',
    marginTop: '13px',
    padding: '6px 50px',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      '& + &': {
        marginBottom: theme.spacing(3),
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& + &': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  contentWrapper: {
    padding: '37px',

    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
    },
  },
  elReceiptSuccessModalMainPict: {
    '& img': {
      display: 'block',
      margin: '0 auto',
      marginBottom: '35px',
    },
  },
  payButton: {
    minWidth: '152px',
    margin: '0 auto',
    marginBottom: '20px',
    '@media (max-width: 750px)': {
      flexGrow: '0.6',
    },
  },
}));

export interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PaymentBannerModal = ({ isOpen, onClose }: SuccessModalProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={false}
      fullScreen={isXs}
      className={classes.dialog}
      transitionDuration={500}
    >
      <DialogContent classes={{ root: classes.contentWrapper }}>
        <Typography variant="body1">
          <p>
            С 1 июля 2024 года вступает в силу Федеральный закон от 19.12.2023 № 602-ФЗ «О внесении
            изменений в статью 155 Жилищного кодекса Российской Федерации и отдельные
            законодательные акты Российской Федерации».
          </p>
          <p>
            Согласно закону с лиц, нуждающихся в социальной поддержке, не будет взиматься банковская
            комиссия при оплате коммунальных услуг.
          </p>
          <p>
            Вы можете оплатить коммунальные услуги без комиссии при предъявлении соответствующего
            документа, подтверждающего вашу принадлежность к той или иной льготной категории, в
            местах приема платежей (отделение банка и др.)
          </p>
          В утвержденный список льготных категорий граждан входят:
          <br />
          1) лица старше 18 лет, входящие в состав многодетной семьи;
          <br />
          2) пенсионеры;
          <br />
          3) инвалиды;
          <br />
          4) ветераны боевых действий;
          <br />
          5) члены семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны
          и ветеранов боевых действий.
        </Typography>
      </DialogContent>
      <Grid container alignItems="center">
        <Button className={classes.payButton} color="primary" variant="contained" onClick={onClose}>
          Понятно, далее
        </Button>
      </Grid>
    </Dialog>
  );
};
