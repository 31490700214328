import { observable, action, computed, toJS, reaction } from 'mobx';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

import { JrpcResponse, JrpcResponseError } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  AuthenticationToken,
  AuthenticationTokenPayload,
} from '@core/entities/Authentication/AuthenticationToken';
import { GUEST_TOKEN } from '@constants/authentication';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import Store from './Store';
import { SIGN_IN, SIGN_UP } from '@public/constants/routes';

export type TokenCreateResponse = JrpcResponse<
  {
    refresh_token: string;
    token: string;
  },
  {},
  JrpcResponseError<{}>
>;

const TOKEN_EXP_DIFF = 120;

type TokenRefreshResponse = JrpcResponse<string>;

class AuthenticationStore extends Store implements Loading {
  @observable private _token?: AuthenticationToken;
  @observable private _refreshToken?: AuthenticationToken;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(50).bind(this);

  private _timeout: NodeJS.Timeout | null;

  public constructor(services: Services) {
    super(services);

    this._loading = false;
    this._timeout = null;

    const token: string | null = localStorage.getItem('token');
    const refreshToken: string | null = localStorage.getItem('refresh_token');

    if (refreshToken) {
      this._refreshToken = this._toTokenValues(refreshToken);
    }

    if (token) {
      this._token = this._toTokenValues(token);

      this._services.opencity.setHeaders({ Authorization: `Bearer ${token}` });
      this._services.cctv.setHeaders({ Authorization: `Bearer ${token}` });
      this._services.billing.setHeaders({ Authorization: `Bearer ${token}` });
      this._services.workflow.setHeaders({ Authorization: `Bearer ${token}` });
    }

    if (
      !refreshToken ||
      !token ||
      this._toTokenValues(refreshToken).payload.exp < this._getNow() + TOKEN_EXP_DIFF
    ) {
      this._signInAsGuest();
    }

    if (
      refreshToken &&
      this._toTokenValues(refreshToken).payload.exp > this._getNow() + TOKEN_EXP_DIFF
    ) {
      this._setTimeout();
    }

    reaction(
      () => this._token,
      value => {
        if (value) {
          this._services.opencity.setHeaders({ Authorization: `Bearer ${value?.token}` });
          this._services.cctv.setHeaders({ Authorization: `Bearer ${value?.token}` });
          this._services.billing.setHeaders({ Authorization: `Bearer ${value?.token}` });
          this._services.workflow.setHeaders({ Authorization: `Bearer ${value?.token}` });

          this._setTimeout();
        } else {
          this._services.opencity.removeHeader('Authorization');
          this._services.cctv.removeHeader('Authorization');
          this._services.billing.removeHeader('Authorization');
          this._services.workflow.removeHeader('Authorization');
        }
      },
    );

    this._services.opencity.client.interceptors.response.use(async response => {
      if (response.data.error?.code === 425 || response.data.error?.code === 504) {
        this.signOut();

        // if (
        //   this._refreshToken?.payload?.exp &&
        //   this._refreshToken.payload.exp > this._getNow() + TOKEN_EXP_DIFF
        // ) {
        //   this.signOut();
        // }
      }

      return response;
    });
  }

  @action public signIn = async (
    login: string,
    password: string,
  ): Promise<JrpcResponseError<{}>> => {
    let signInError: JrpcResponseError<{}> = {};

    this._loading = true;

    await this._services.authentication.requests
      .tokenCreate({
        params: { login, password },
      })
      .then(({ data: { result, error } }: AxiosResponse<TokenCreateResponse>) => {
        if (result) {
          this._services.opencity.setHeaders({ Authorization: `Bearer ${result.token}` });
          this._services.cctv.setHeaders({ Authorization: `Bearer ${result.token}` });
          this._services.workflow.setHeaders({ Authorization: `Bearer ${result.token}` });
          this._services.billing.setHeaders({ Authorization: `Bearer ${result.token}` });

          this._token = this._toTokenValues(result.token);
          this._refreshToken = this._toTokenValues(result.refresh_token);

          localStorage.setItem('token', result.token);
          localStorage.setItem('refresh_token', result.refresh_token);

          this._setTimeout();
        }

        if (error) {
          signInError = error;
        }
      })
      .finally(this._endLoading);

    return signInError;
  };

  @action public singByTokenData = (data: { token: string; refresh_token: string }): void => {
    this._services.opencity.setHeaders({ Authorization: `Bearer ${data.token}` });
    this._services.cctv.setHeaders({ Authorization: `Bearer ${data.token}` });
    this._services.workflow.setHeaders({ Authorization: `Bearer ${data.token}` });
    this._services.billing.setHeaders({ Authorization: `Bearer ${data.token}` });

    this._token = this._toTokenValues(data.token);
    this._refreshToken = this._toTokenValues(data.refresh_token);

    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh_token', data.refresh_token);

    this._setTimeout();
  };

  @action public refresh = async (): Promise<string> => {
    this._loading = true;

    let token = '';

    await this._services.authentication.requests
      .tokenRefresh({
        params: { refresh_token: this.refreshToken },
      })
      .then(({ data: { result } }: AxiosResponse<TokenRefreshResponse>) => {
        if (result) {
          this._token = this._toTokenValues(result);

          token = result;

          localStorage.setItem('token', result);
        }
      })
      .finally(this._endLoading);

    return token;
  };

  @action public refreshByToken = async (token: string): Promise<boolean> => {
    localStorage.setItem('refresh_token', token);
    this._refreshToken = this._toTokenValues(token);
    let tokenChanged = false;

    await this.refresh().then(() => (tokenChanged = true));

    return tokenChanged;
  };

  @action public signOut = (): void => {
    this._signInAsGuest();
    sessionStorage.clear();
    window.location.pathname = SIGN_IN;
  };

  @action public signOutToSignUp = (): void => {
    this._signInAsGuest();
    window.location.replace(`${SIGN_UP}?path=afterDeleteUser`);
  };

  private _signInAsGuest = (): void => {
    this._token = this._toTokenValues(GUEST_TOKEN);
    this._refreshToken = undefined;

    this._services.opencity.setHeaders({ Authorization: `Bearer ${GUEST_TOKEN}` });
    this._services.billing.setHeaders({ Authorization: `Bearer ${GUEST_TOKEN}` });
    this._services.cctv.setHeaders({ Authorization: `Bearer ${GUEST_TOKEN}` });
    this._services.workflow.setHeaders({ Authorization: `Bearer ${GUEST_TOKEN}` });

    localStorage.setItem('token', GUEST_TOKEN);
    localStorage.removeItem('refresh_token');
  };

  private _getNow = (): number => {
    return Math.floor(Date.now() / 1000);
  };

  private _toTokenValues = (token: string): AuthenticationToken => {
    return { token, payload: jwtDecode(token) };
  };

  private _setTimeout = async (): Promise<void> => {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    if (this._token && this._refreshToken) {
      const tokenLifeTime = this._token.payload.exp - this._getNow() - TOKEN_EXP_DIFF;

      const refreshTokenLifeTime = this._refreshToken.payload.exp - this._getNow() - TOKEN_EXP_DIFF;

      if (refreshTokenLifeTime > 0) {
        if (tokenLifeTime > 0) {
          this._timeout = setTimeout(async () => {
            const token = await this.refresh();

            if (token) {
              this._token = this._toTokenValues(token);
            } else {
              this._signInAsGuest();
            }
          }, tokenLifeTime * 1000);
        } else {
          const token = await this.refresh();

          if (token) {
            this._token = this._toTokenValues(token);
          } else {
            this._signInAsGuest();
          }
        }
      } else {
        this._signInAsGuest();
      }
    }
  };

  @computed public get authenticated(): boolean {
    const { _refreshToken, _getNow } = this;

    return Boolean(
      _refreshToken &&
        _refreshToken.payload.sub !== jwtDecode<AuthenticationTokenPayload>(GUEST_TOKEN).sub &&
        _refreshToken.payload.exp >= _getNow() + TOKEN_EXP_DIFF,
    );
  }

  @computed public get authenticatedAsGuest(): boolean {
    return this.token === GUEST_TOKEN;
  }

  @computed public get token(): string {
    return this._token ? this._token.token : '';
  }

  @computed public get tokenPayload(): AuthenticationTokenPayload | null {
    return this._token ? toJS(this._token.payload) : null;
  }

  @computed public get refreshToken(): string {
    return this._refreshToken ? this._refreshToken.token : '';
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default AuthenticationStore;
