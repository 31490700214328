import React, { FC, ReactChild, useState, useEffect } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.down('xs')]: {
        '-webkit-transition': 'all .3s ease',
        transition: 'all .3s ease',
      },

      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
);

interface HeaderProps {
  children: ReactChild[] | ReactChild;
  className?: string;
  disableDisappearance?: boolean;
  threshold?: number;
}

const Header: FC<HeaderProps> = ({
  children,
  className,
  disableDisappearance = false,
  threshold = 100,
}) => {
  const classes = useStyles();

  const [appBarClasses, setAppBarClasses] = useState(classNames(classes.appBar, className));

  useEffect((): void => {
    setAppBarClasses(classNames(classes.appBar, className));
  }, [className]);

  const trigger = useScrollTrigger({ threshold });

  return (
    <Slide appear={false} direction="down" in={disableDisappearance || !trigger}>
      <AppBar className={appBarClasses}>{children}</AppBar>
    </Slide>
  );
};

export default Header;
