import { Update } from '@core/stores/interfaces/Entity';
import { OrderKeys } from '@core/entities/Opencity/Order';

export interface OrdersPagePreference {
  selectedColumns?: Array<OrderKeys>;
  trackerId?: string | string[];
}

export interface OpencityPagesPreference {
  ADDRESS: OrdersPagePreference;
  ORDERS: OrdersPagePreference;
  TRACKER: OrdersPagePreference;
}

export interface UserPreference {
  selectedUserId?: string;
  selectedFlatId?: number;
  pages: OpencityPagesPreference;
}

export const DEFAULT_USER_PREFERENCE: UserPreference = {
  selectedUserId: undefined,
  selectedFlatId: undefined,
  pages: {
    ADDRESS: {
      selectedColumns: undefined,
    },
    ORDERS: {
      selectedColumns: undefined,
    },
    TRACKER: {
      trackerId: undefined,
    },
  },
};

export type UpdateUserPreference = Update<{ data: UserPreference }, Promise<UserPreference>>;
