/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSignStore = () => {
  const { signStore } = useStores();

  return useObserver(() => ({
    userCodeConfirm: signStore.userCodeConfirm,
  }));
};
