import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  ClassifierCategory,
  ClassifierCategoryInterface,
  ClassifierCategoryKeys,
  ClassifierCategoryFilterProps,
  ClassifierCategoryLoad,
} from '@core/entities/Opencity/ClassifierCategory';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';

type ReferenceClassifierCategoryIndex = JrpcResponse<{
  items: ClassifierCategoryInterface[];
  total: number;
}>;

class ClassifierCategoryStore extends Store
  implements
    Loading,
    Pagination,
    Entity<
      ClassifierCategory,
      { filter?: ClassifierCategoryFilterProps; limit?: number; offset?: number }
    >,
    Filter<ClassifierCategoryFilterProps, ClassifierCategoryKeys> {
  @observable private _loading: boolean;
  @observable private _classifierCategories: ClassifierCategory[];
  @observable private _filter: ClassifierCategoryFilterProps;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._classifierCategories = [];
    this._filter = {};
    this._loading = false;
    this._limit = 0;
    this._offset = 0;
    this._total = 0;
  }

  @action load: ClassifierCategoryLoad = async params => {
    this._loading = true;

    let classifierCategories: ClassifierCategory[] = [];

    const filter = params?.filter || this._filter;
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._limit = limit;
    this._offset = offset;
    this._filter = filter;

    await this._services.opencity.requests
      .referenceClassifierCategoryIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<ReferenceClassifierCategoryIndex>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            this._classifierCategories = items.map<ClassifierCategory>(
              value => new ClassifierCategory(value),
            );
            classifierCategories = toJS(this._classifierCategories);
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .then(this._endLoading);

    return classifierCategories;
  };

  @action setLimit: SetLimit = value => {
    this._limit = value;
  };

  @action setOffset: SetOffset = value => {
    this._offset = value;
  };

  @computed public get list(): ClassifierCategory[] {
    return toJS(this._classifierCategories);
  }

  @computed public get filter(): ClassifierCategoryFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default ClassifierCategoryStore;
