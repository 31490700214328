import { FilterOperators } from '@core/stores/interfaces/Filter';

// export enum DadataFilterKeys {
//   SOMETHING = 'something',
// }

// export interface DadataFilterProps {
//   [DadataFilterKeys.SOMETHING]?: FilterOperators<string>;
// }

export interface DadataParams {
  // filter?: DadataFilterProps;
  address: string;
  limit?: number;
  captchaResponse?: string | null;
}

export interface DadataSuggestionsInterface {
  address?: string;
  fullAddress?: string;
  houseFiasId?: string;
}

export interface DadataSuggestionsListInterface {
  suggestions?: DadataSuggestionsInterface[];
}

export class DadataSuggestionsList implements DadataSuggestionsListInterface {
  public constructor(value: DadataSuggestionsListInterface) {
    this.suggestions = value.suggestions;
  }
  suggestions?: DadataSuggestionsInterface[];
}
