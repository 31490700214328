import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import OpencityActionNames from '@services/opencity/actionNames';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  Street,
  StreetInterface,
  LoadStreet,
  CountStreet,
  StreetFilterProps,
  StreetKeys,
  ClearStreetFilter,
} from '@core/entities/Opencity/Street';
import Store from './Store';
import { Loading, endLoading, SetLoading } from './interfaces/Loading';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Entity } from './interfaces/Entity';
import { Filter } from './interfaces/Filter';

type GisStreetIndexResponse = JrpcResponse<{ items: StreetInterface[]; total: number }>;
type GisStreetCountResponse = JrpcResponse<number>;

class StreetStore extends Store
  implements
    Loading,
    Pagination,
    Entity<Street, { limit?: number; filter?: StreetFilterProps }>,
    Filter<StreetFilterProps, StreetKeys> {
  @observable private _streets: Street[];
  @observable private _filter: StreetFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._streets = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public count: CountStreet = async params => {
    let total: number = this._total;

    const filter = (params && params.filter) || toJS(this._filter);

    this._services.opencity.requests
      .gisStreetCount({ params: { filter } })
      .then(({ data: { result } }: AxiosResponse<GisStreetCountResponse>) => {
        if (typeof result === 'number') {
          total = result;

          this._total = total;
        }
      });

    return total;
  };

  @action public load: LoadStreet = async params => {
    this._loading = true;

    let streets: Street[] = [];

    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const filter = (params && params.filter) || toJS(this._filter);
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    await this._services.opencity.requests[
      params?.ownStreets
        ? OpencityActionNames.USER_STREET_INDEX
        : OpencityActionNames.GIS_STREET_INDEX
    ]({
      params: { limit, offset, filter },
    })
      .then(({ data: { result } }: AxiosResponse<GisStreetIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            streets = items.map(value => new Street(value));

            this._streets = streets;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return streets;
  };

  @action clearFilter: ClearStreetFilter = () => {
    this._filter = {};
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  @action public cleanUp = (): void => {
    this._streets = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  };

  @computed public get list(): Street[] {
    return toJS(this._streets);
  }

  @computed public get filter(): StreetFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default StreetStore;
