import { Load } from '@core/stores/interfaces/Entity';

export interface CardInterface {
  authId: string;
  tokenId: number;
  createdAt: number;
  expiredAt: number;
  mask: string;
  cardExpiredAt: string;
  issuingBank: string;
  paymentSystem: string;
}

export class Card {
  public authId: string;
  public tokenId: number;
  public createdAt: number;
  public expiredAt: number;
  public mask: string;
  public cardExpiredAt: string;
  public issuingBank: string;
  public paymentSystem: string;

  public constructor(value: CardInterface) {
    this.authId = value.authId;
    this.tokenId = value.tokenId;
    this.createdAt = value.createdAt;
    this.expiredAt = value.expiredAt;
    this.mask = value.mask;
    this.cardExpiredAt = value.cardExpiredAt;
    this.issuingBank = value.issuingBank;
    this.paymentSystem = value.paymentSystem;
  }
}

export enum CardKeys {
  AUTH_ID = 'authId',
  MERCHANT_GROUP_ID = 'merchantGroupId',
}

export interface CardKeysFilterProps {
  [CardKeys.AUTH_ID]?: string;
  [CardKeys.MERCHANT_GROUP_ID]?: string;
}

export type LoadCards = Load<
  { filter?: CardKeysFilterProps; limit?: number; offset?: number },
  Card[]
>;
