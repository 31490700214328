import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse, JrpcResponseError } from '@httpClient/jrpc';
import {
  MeteringDevice,
  MeteringDeviceFilterKeys,
  MeteringDeviceFilterProps,
  LoadMeteringDevice,
} from '@core/entities/Opencity/MeteringDevice';
import MeteringDeviceValueStore from './MeteringDeviceValueStore';
import SnackbarStore from './SnackbarStore';

import Store from './Store';
import { endLoading, Loading } from './interfaces/Loading';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';

type MeteringDeviceIndexResponse = JrpcResponse<{ items: MeteringDevice[] }>;
// type MeteringDeviceRefreshResponse = JrpcResponse<boolean, JrpcResponseError<{}>>;

interface Relations {
  meteringDeviceValueStore: MeteringDeviceValueStore;
  snackbarStore: SnackbarStore;
}

class MeteringDeviceStore extends Store<Relations>
  implements
    Loading,
    Filter<MeteringDeviceFilterProps, MeteringDeviceFilterKeys>,
    Entity<MeteringDevice, {}> {
  @observable private _meteringDevices: MeteringDevice[];
  @observable private _filter: MeteringDeviceFilterProps;
  @observable private _loading: boolean;
  @observable private _offset: number;
  @observable private _limit: number;

  @action private _endLoading = endLoading(500).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._meteringDevices = [];
    this._filter = {};
    this._loading = false;
    this._offset = 0;
    this._limit = 20;
  }

  @action public load: LoadMeteringDevice = async params => {
    this._loading = true;

    let meteringDevices: MeteringDevice[] = [];

    const { _limit, _offset, _filter } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .deviceMeteringIndex({ params: { filter, limit, offset } })
      .then(({ data: { result, error } }: AxiosResponse<MeteringDeviceIndexResponse>) => {
        if (result && result.items) {
          meteringDevices = result.items;
        }
        if (error && error.data) {
          this._relations.snackbarStore.setMessage(`${error.data}`, 'error');
        }
      })
      .finally(this._endLoading);

    this._meteringDevices = meteringDevices;

    return meteringDevices;
  };

  @action public refreshDevices = (id: number): Promise<JrpcResponseError<{}>> => {
    this._loading = true;

    return this._services.opencity.requests
      .deviceMeteringRefresh({
        params: { personalAccountId: id },
      })
      .finally(this._endLoading);
  };

  @computed public get list(): MeteringDevice[] {
    return toJS(this._meteringDevices);
  }

  @computed public get filter(): MeteringDeviceFilterProps {
    return toJS<MeteringDeviceFilterProps>(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default MeteringDeviceStore;
