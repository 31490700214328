import React, { FC } from 'react';
import { createStyles, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton as SkeletonDefault } from '@material-ui/lab';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 200,
    },

    firstColumn: {
      flex: 0.55,
    },
    secondColumn: {
      flex: 0.25,
      marginLeft: 8,
    },
    thirdColumn: {
      flex: 0.2,
      marginLeft: 8,
    },

    mobileSize: {
      height: 170,
    },
  }),
);

export const Skeleton: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  if (isXs) {
    return (
      <Grid container direction="column" justify="space-between" className={classes.mobileSize}>
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="stretch"
        className={classes.firstColumn}
      >
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={20} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
      </Grid>

      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="stretch"
        className={classes.secondColumn}
      >
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={20} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
      </Grid>

      <Grid
        container
        item
        direction="column"
        justify="space-between"
        alignItems="stretch"
        className={classes.thirdColumn}
      >
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={20} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
        <SkeletonDefault animation="wave" variant="rect" width="100%" height={50} />
      </Grid>
    </Grid>
  );
};
