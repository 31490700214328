import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
  useMediaQuery,
  Typography,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { APP_STORE, GOOGLE_PLAY_FROM_PUBLIC, APP_GALLERY } from '@core/constants/mobile';
import storageHelper from '@utils/storageHelper';

import { useStyles } from './styles';

export const MOBILE_PROMPT = 'mobilePrompt';

export const MobileAppPromotion: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const isLeaved = storageHelper.session.getValue(MOBILE_PROMPT);

    if (isLeaved !== 'true') {
      storageHelper.session.setValue(MOBILE_PROMPT, 'true');
      setTimeout(() => setState(true), 10000);
    }
  }, []);

  const handleClose = useCallback(() => {
    setState(false);
  }, []);

  return (
    <Dialog
      open={isVisible && isXs}
      onClose={handleClose}
      fullWidth
      fullScreen
      transitionDuration={500}
    >
      <DialogTitle className={classes.title}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container item>
          <Typography variant="h3" className={cn(classes.text, classes.boldText)}>
            Еще удобнее в приложении
          </Typography>
        </Grid>
        <Grid container item className={classes.contentTextWrapper}>
          <Typography variant="body2" className={classes.text}>
            Все возможности сервиса всегда под рукой!
          </Typography>
        </Grid>
        <Grid container item className={classes.buttonsContainer} spacing={2}>
          <Grid item className={classes.linkWrapper}>
            <Link className={classes.link} href={APP_STORE} target="_blank" tabIndex={-1}>
              <img src="/images/mobile/app_store_mobile_promotion.svg" alt="" height={62} />
            </Link>
          </Grid>

          <Grid item className={classes.linkWrapper}>
            <Link
              className={classes.link}
              href={GOOGLE_PLAY_FROM_PUBLIC}
              target="_blank"
              tabIndex={-1}
            >
              <img src="/images/mobile/google_play_mobile_promotion.svg" alt="" height={62} />
            </Link>
          </Grid>

          <Grid item className={classes.linkWrapper}>
            <Link className={classes.link} href={APP_GALLERY} target="_blank" tabIndex={-1}>
              <img src="/images/mobile/app_gallery_mobile_promotion.svg" alt="" height={62} />
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justify="center"
          className={classes.secondaryTextContainer}
          onClick={handleClose}
        >
          <Typography
            variant="body2"
            align="center"
            className={cn(classes.text, classes.secondaryText)}
          >
            Хочу остаться на сайте
          </Typography>
        </Grid>
        <Grid container item justify="center" className={classes.imageContainer}>
          <img src="/images/slider/mobile.png" className={classes.image} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
