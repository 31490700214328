import React, { FC, Suspense, ReactElement, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';

import { useYandexMetrika } from '@core/hooks/yandexMetrika';
import { useAuthenticationStore, useSnackbarStore } from './hooks';
import Preloader from 'oc-preloader/lib';
import { APP_ROOT, AUTH_SOCIAL_PAGE, EMAIL_CONFIRM_PAGE } from '@constants/routes';
import config from '@constants/config';
import AuthSocial from '@common/pages/AuthSocial';
import EmailConfirm from '@common/pages/EmailConfirm';
import Public from './public';
import { isBilling, isSpecialBilling, isKazan } from '@core/constants/project';
import { GOOGLE_PLAY_SMART_BANNER } from '@core/constants/mobile';

const useStyles = makeStyles(
  createStyles({
    preloader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const smartBannerMetaTags = [
  <meta name="smartbanner:title" content="Кабинет Абонента" key={1} />,
  <meta name="smartbanner:author" content=" " key={2} />,
  <meta name="smartbanner:price" content="Бесплатно" key={3} />,
  <meta name="smartbanner:price-suffix-google" content=" - в Google Play" key={4} />,
  <meta name="smartbanner:icon-google" content="/images/logo_without_text_image.png" key={6} />,
  <meta name="smartbanner:button" content="Открыть" key={7} />,
  <meta name="smartbanner:button-url-google" content={GOOGLE_PLAY_SMART_BANNER} key={8} />,
  <meta name="smartbanner:enabled-platforms" content="android" key={9} />,
  <meta
    name="smartbanner:exclude-user-agent-regex"
    content="/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/"
  />,
  <meta name="smartbanner:close-label" content="Закрыть" key={10} />,
  <meta name="smartbanner:custom-design-modifier" content="custom-design" key={11} />,
];

const Private = lazy(() => import('./private'));
// const Unsubscribe = lazy(() => import('./common/pages/Unsubscribe'));

const getFavicon = (): HTMLLinkElement | null =>
  document.getElementById('favicon') as HTMLLinkElement;
const getManifest = (): HTMLLinkElement | null =>
  document.getElementById('manifest') as HTMLLinkElement;

const App: FC = () => {
  const [mountApprove, setMountApprove] = useState(false);
  const { authenticated, refreshByToken } = useAuthenticationStore();
  const { setMessage } = useSnackbarStore();
  const { gAKey } = config;

  useEffect(() => {
    const favicon = getFavicon();
    const manifest = getManifest();

    if (favicon && isSpecialBilling) favicon.href = '/images/special_favicon.svg';
    if (manifest && isSpecialBilling) manifest.href = '/manifest.cab.json';
  }, []);

  useYandexMetrika();

  // useEffect(() => {
  //   if (!isBilling && !isSpecialBilling && !isKazan) {
  //     setMessage('Если у вас включен VPN, пожалуйста, выключите его', 'warning');
  //   }
  // }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const refresh = url.searchParams.get('refresh');
    const code = url.searchParams.get('code');

    if (code && window.location.href.includes('auth/social')) {
      setMountApprove(false);
    } else if (window.location.href.includes('emailConfirm')) {
      setMountApprove(false);
      // } else if (window.location.href.includes('unsubscribe')) {
      //   setMountApprove(false);
    } else if (refresh) {
      refreshByToken(refresh).then(() => setMountApprove(true));
    } else setMountApprove(true);
  }, []);

  const classes = useStyles();

  const preloaderElement: ReactElement = (
    <Preloader
      color={isSpecialBilling ? '#3167F3' : ''}
      className={classes.preloader}
      height="100%"
    />
  );

  const renderPrivate = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <Private />
    </Suspense>
  );

  const renderPublic = (): ReactElement => <Public />;

  const renderSocial = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <AuthSocial setMountApprove={setMountApprove} />
    </Suspense>
  );

  const renderEmailConfirm = (): ReactElement => (
    <Suspense fallback={preloaderElement}>
      <EmailConfirm setMountApprove={setMountApprove} />
    </Suspense>
  );

  return (
    <>
      <Helmet>
        <meta property="og:title" content={config.projectName} />
        <meta property="og:description" content={config.projectDescription} />
        <meta property="og:image" content={`${window.location.origin}/images/share.jpg?v=1`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.origin} />
        <meta name="description" content={config.projectDescription} />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
        <script src={`js/clusterer_pie_chart.js`} type="text/javascript"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-176447924-1"></script>
        {gAKey && (
          <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${gAKey}');`}</script>
        )}
        {isBilling && smartBannerMetaTags}
      </Helmet>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <Switch>
            {!mountApprove && <Route path={AUTH_SOCIAL_PAGE} render={renderSocial} />}
            {!mountApprove && <Route path={EMAIL_CONFIRM_PAGE} render={renderEmailConfirm} />}
            {authenticated && mountApprove && <Route path={APP_ROOT} render={renderPrivate} />}
            {!authenticated && mountApprove && <Route path={APP_ROOT} render={renderPublic} />}
            {/* {!mountApprove && <Route path={UN_SUBSCRIBE} render={renderUnsubscribe} />} */}
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default App;
