import React, {
  useState,
  useEffect,
  FC,
  useCallback,
  useRef,
  ReactElement,
  ReactNode,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Slide, SlideProps } from '@material-ui/core';
import cn from 'classnames';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // overflowY: 'hidden',
      zIndex: 3,
      // overflowX: 'hidden',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    upper: {
      display: 'flex',
      width: '100%',
    },

    button: {
      height: theme.spacing(4),
      minWidth: theme.spacing(2),
      maxWidth: theme.spacing(8 * 2),
      padding: 0,
      opacity: 0.2,

      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(8),
      },

      '& + &': {
        marginLeft: theme.spacing(2),
      },
    },
    visualBtn: {
      height: theme.spacing(1),
      width: '100%',
      backgroundColor: isSpecialBilling ? '#3167f3' : '#12A7D8',
    },
    activeBtn: {
      opacity: 0.5,
    },
    container: {
      width: '100%',
    },

    leftArrowWrapper: {
      alignSelf: 'center',
      padding: '5px 5px 5px 10px',
    },

    rightArrowWrapper: {
      alignSelf: 'center',
      padding: '5px 10px 5px 5px',
    },

    arrow: {
      border: 'solid white',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 3,
    },

    rightArrow: {
      transform: 'rotate(-45deg)',
      '-webkit-transform': 'rotate(-45deg)',
    },

    leftArrow: {
      transform: 'rotate(135deg)',
      '-webkit-transform': 'rotate(135deg)',
    },
  }),
);

interface SliderProps {
  interval?: number;
  classes?: {
    root?: string;
    buttonsContainer?: string;
    slidesContainer?: string;
  };
  lowerButton?: boolean;
  lateralButton?: boolean;
  content: {}[];
  SlideComponent: FC<{ content: SlideComponentProps }>;
}

export interface SlideButton {
  route: string;
  link?: string;
  text: string;
  handler?: string;
}

export interface SlideComponentProps {
  image?: string;
  title?: string;
  text?: string;
  textArray?: string[];
  buttons?: SlideButton[];
  content?: ReactNode;
  href?: string;
  hrefText?: string;
  slideHandler?: string;
}

const Slider: FC<SliderProps> = ({
  interval = 5000,
  classes,
  content,
  lowerButton = true,
  lateralButton = false,
  SlideComponent,
}) => {
  const styles = useStyles();

  const autoPlayRef = useRef<any>();
  const [index, setIndex] = useState(0);
  const [slideTimeout, setSlideTimeout] = useState(800);
  const currentContent = content[index] || false;
  const numSlides = content.length;

  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<SlideProps['direction']>('down');

  const handleButton = useCallback(
    position => {
      if (position === index) return;
      const nextSlideTimeout = 450;
      setSlideTimeout(400);
      let curDirection: SlideProps['direction'] = 'right';
      let nextDirection: SlideProps['direction'] = 'left';

      if (position < index) {
        curDirection = 'left';
        nextDirection = 'right';
      }

      setSlideDirection(curDirection);
      setSlideIn(false);

      setTimeout(() => {
        setIndex(position);
        setSlideDirection(nextDirection);
        setSlideIn(true);
      }, nextSlideTimeout);
    },
    [index],
  );

  const onSlideChangeClick = (
    direction: SlideProps['direction'],
    keyBoard = false,
    tapArrow = false,
  ): void => {
    let nextSlideTimeout = 800;
    setSlideTimeout(800);

    if (keyBoard) {
      nextSlideTimeout = 200;
      setSlideTimeout(200);
    }

    if (tapArrow) {
      nextSlideTimeout = 250;
      setSlideTimeout(200);
    }

    const increment = direction === 'left' ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;

    const oppDirection = direction === 'left' ? 'right' : 'left';
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setIndex(newIndex);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, nextSlideTimeout);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'ArrowRight') {
        onSlideChangeClick('right', true);
      }
      if (e.key === 'ArrowLeft') {
        onSlideChangeClick('left', true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    clearTimeout(autoPlayRef.current);
    autoPlayRef.current = setTimeout(() => onSlideChangeClick('right'), interval);
  });

  const buttonStyle = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        width: `calc((100% - ${(numSlides - 1) * theme.spacing(2)}px) / ${numSlides})`,
      },
    }),
  );

  useEffect(() => {
    setIndex(0);
  }, [content]);

  const buttonClasses = buttonStyle();

  const renderSliderButton = (): ReactElement => {
    return (
      <Grid container justify="center" className={classes?.buttonsContainer}>
        {content.map((item: {}, position: number) => {
          return (
            <Button
              key={position}
              className={cn(styles.button, buttonClasses.button, {
                [styles.activeBtn]: position === index,
              })}
              onClick={(): void => handleButton(position)}
            >
              <div className={styles.visualBtn} />
            </Button>
          );
        })}
      </Grid>
    );
  };

  if (!content.length) return <></>;

  if (content.length === 1)
    return (
      <div className={cn(styles.root, classes?.root)}>
        <div className={styles.container}>
          <div className={styles.container}>
            <SlideComponent content={currentContent} />
          </div>
        </div>
      </div>
    );

  return (
    <div className={cn(styles.root, classes?.root)}>
      <div className={styles.upper}>
        {lateralButton && (
          <div
            className={styles.leftArrowWrapper}
            onClick={(): void => onSlideChangeClick('left', false, true)}
          >
            <i className={cn(styles.arrow, styles.leftArrow)}></i>
          </div>
        )}
        <div className={styles.container}>
          <Slide in={slideIn} direction={slideDirection} timeout={slideTimeout}>
            <div className={styles.container}>
              <SlideComponent content={currentContent} />
            </div>
          </Slide>
        </div>
        {lateralButton && (
          <div
            className={styles.rightArrowWrapper}
            onClick={(): void => onSlideChangeClick('right', false, true)}
          >
            <i className={cn(styles.arrow, styles.rightArrow)}></i>
          </div>
        )}
      </div>
      {lowerButton && <div className={styles.container}>{renderSliderButton()}</div>}
    </div>
  );
};

export default Slider;
