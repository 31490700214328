import * as yup from 'yup';

import { nameApplicant, email, description } from '@core/validation';

const title = yup.string().required('Необходимо указать заголовок');

export const FormSchema = yup.object({
  nameApplicant: nameApplicant.required('Необходимо указать ваше имя'),
  email: email.required('Необходимо указать ваш адрес электронной почты'),
  title,
  text: description.required('Необходимо заполнить описание'),
  reason: yup.number().moreThan(0, 'Укажите причину'),
  personalAccount: yup.string().required('Необходимо указать лицевой счёт'),
  organizationName: yup
    .string()
    .notRequired()
    .max(200, 'Максимальное количество символов 200'),
});

export type FormProps = yup.InferType<typeof FormSchema>;
