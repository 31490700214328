import { FilterOperators } from '@core/stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface ClassifierTrackerInterface {
  id: number;
  name?: string | null;
  types?: string[];
}

export class ClassifierTracker {
  public id: number;
  public name?: string | null;
  public types?: string[];

  public constructor(value: ClassifierTrackerInterface) {
    this.id = value.id;
    this.name = value.name;
    this.types = value.types;
  }
}

export enum ClassifierTrackerKeys {
  TRACKER_FROM_ID = 'trackerFromId',
}

export interface ClassifierTrackerFilterProps {
  [ClassifierTrackerKeys.TRACKER_FROM_ID]?: FilterOperators<number>;
}

export type ClassifierTrackerLoad = Load<
  { limit?: number; offset?: number; filter?: ClassifierTrackerFilterProps },
  ClassifierTracker[]
>;
