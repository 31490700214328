import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useMapStore = () => {
  const { mapStore } = useStores();

  return useObserver(() => ({
    get: mapStore.get,
    mapOrders: mapStore.orders,
  }));
};
