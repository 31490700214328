import React, { FC, ReactChild, ReactElement } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(
  createStyles({
    container: {
      width: '100%',
    },
  }),
);

interface FooterProps {
  children: ReactChild[] | ReactChild;
  className?: string;
}

const Footer: FC<FooterProps> = ({ className, children }) => {
  const classes = useStyles();

  const renderChildren = (childrenValue: ReactChild, i: number): ReactElement => {
    return (
      <Grid key={i} item={true} md={12}>
        {childrenValue}
      </Grid>
    );
  };

  const renderChildrens = (childrens: ReactChild[]): ReactElement[] => {
    return childrens.map(renderChildren);
  };

  return (
    <Grid
      className={classNames(classes.container, className)}
      container={true}
      item={true}
      direction="column"
      component="footer"
      wrap="nowrap"
    >
      {Array.isArray(children)
        ? renderChildrens(children as ReactChild[])
        : renderChildren(children, 0)}
    </Grid>
  );
};

export default Footer;
