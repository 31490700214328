import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Theme,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { STOCK } from '@core/constants/helpCenter';
import { TextSymbol } from '@core/constants/symbols';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 755,
  },

  dialogContent: {
    paddingTop: 0,
  },

  image: {
    minWidth: '40%',
    objectFit: 'cover',
  },

  actionsWrapper: {
    padding: theme.spacing(6, 6, 8, 6),
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },

  link: {
    textDecoration: 'none',
    width: '100%',
  },

  subtitle: {
    fontWeight: 500,
    fontSize: 17,
  },

  title: {
    marginTop: 12,
  },
}));

interface ReceiptDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  onPayment: () => void;
}

export const StockDetails: FC<ReceiptDetailsProps> = ({ isOpen, onClose, onPayment }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isXs}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
          <Typography
            variant="body1"
            color="textPrimary"
            className={cn(classes.subtitle, classes.title)}
          >
            Оплачивайте ЖКУ в личном кабинете — выигрывайте денежные призы на лицевой счет!
          </Typography>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container spacing={6}>
          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="body1" color="textPrimary" className={classes.subtitle}>
                Как стать участником акции?
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`Совершите три ежемесячных платежа за ЖКУ в личном кабинете в период с 1 января по 31
                марта 2022${TextSymbol.NO_BREAK_SPACE}г. на общую сумму не менее 5${TextSymbol.NO_BREAK_SPACE}000 рублей`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="body1" color="textPrimary" className={classes.subtitle}>
                Призовой фонд
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`В акции будет 11 победителей, которые получат: главный приз в размере 4${TextSymbol.NO_BREAK_SPACE}000
                рублей, 3 приза по 3${TextSymbol.NO_BREAK_SPACE}000 рублей и 7 призов по 1${TextSymbol.NO_BREAK_SPACE}000 рублей. Суммы будут перечислены
                на лицевой счет ЖКУ победителей`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="body1" color="textPrimary" className={classes.subtitle}>
                Как будут определяться победители?
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`По окончании периода для совершения платежей (31 марта 2022${TextSymbol.NO_BREAK_SPACE}г.), каждому участнику
                будет присвоен порядковый номер. Победители будут определены с помощью генератора
                случайных чисел с использованием присвоенных им номеров. Запись определения
                победителей и их список будут опубликованы на нашем сайте 8 апреля 2022${TextSymbol.NO_BREAK_SPACE}г.`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justify="center" alignItems="center">
            <img src="/images/house.svg" className={classes.image} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>
        <Grid container className={classes.actions} spacing={isXs ? 2 : 8}>
          <Grid item container>
            <a target="_blank" rel="noopener noreferrer" href={STOCK} className={classes.link}>
              <Button color="primary" variant="outlined" fullWidth>
                Положение об Акции
              </Button>
            </a>
          </Grid>
          <Grid item container>
            <Button color="primary" variant="contained" onClick={onPayment} fullWidth>
              Оплатить
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
