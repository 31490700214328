import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  ClassifierTracker,
  ClassifierTrackerInterface,
  ClassifierTrackerLoad,
} from '@core/entities/Opencity/ClassifierTracker';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';

type ReferenceClassifierTrackerIndex = JrpcResponse<{
  items: ClassifierTrackerInterface[];
  total: number;
}>;

class ClassifierTrackerStore extends Store
  implements Loading, Pagination, Entity<ClassifierTracker, { limit?: number; offset?: number }> {
  @observable private _loading: boolean;
  @observable private _classifierTrackers: ClassifierTracker[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._classifierTrackers = [];
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action load: ClassifierTrackerLoad = async params => {
    this._loading = true;

    let classifierTrackers: ClassifierTracker[] = [];

    const filter = params?.filter;
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .referenceClassifierTrackerIndex({ params: { limit, offset, filter } })
      .then(({ data: { result } }: AxiosResponse<ReferenceClassifierTrackerIndex>) => {
        if (result) {
          const { items, total } = result;
          if (Array.isArray(items)) {
            this._classifierTrackers = items.map<ClassifierTracker>(
              value => new ClassifierTracker(value),
            );
            classifierTrackers = toJS(this._classifierTrackers);
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return classifierTrackers;
  };

  @action setLimit: SetLimit = value => {
    this._limit = value;
  };

  @action setOffset: SetOffset = value => {
    this._offset = value;
  };

  @computed public get list(): ClassifierTracker[] {
    return toJS(this._classifierTrackers);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default ClassifierTrackerStore;
