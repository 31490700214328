import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '../httpClient/jrpc';
import Services from '../services';
import {
  OrderDocumentInterface,
  OrderDocument,
  CreateOrderDocument,
  LoadOrderDocument,
} from '@core/entities/Opencity/OrderDocument';
import Store from './Store';
import { Loading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';

type IssueDocumentCreateResponse = JrpcResponse<OrderDocumentInterface>;
type IssueDocumentIndexResponse = JrpcResponse<{ items: OrderDocumentInterface[] }>;

class DocumentOrderStore extends Store
  implements Loading, Entity<OrderDocument, {}, number, { orderId: number; fileId: number }> {
  @observable private _orderDocuments: OrderDocument[];
  @observable private _loading: boolean;

  public constructor(services: Services) {
    super(services);

    this._orderDocuments = [];
    this._loading = false;
  }

  @action public load: LoadOrderDocument = async params => {
    let orderDocuments: OrderDocument[] = [];

    await this._services.opencity.requests
      .issueDocumentIndex({ params: { filter: params?.filter } })
      .then(({ data: { result } }: AxiosResponse<IssueDocumentIndexResponse>) => {
        if (result && Array.isArray(result.items)) {
          orderDocuments = result?.items;
        }
      });

    return orderDocuments;
  };

  @action public create: CreateOrderDocument = async ({ orderId, fileId }) => {
    const {
      data: { result },
    }: AxiosResponse<IssueDocumentCreateResponse> = await this._services.opencity.requests.issueDocumentCreate(
      {
        params: {
          data: { orderId, fileId },
        },
      },
    );

    if (result) {
      return new OrderDocument(result);
    }

    return null;
  };

  @action public cleanUp = (): void => {
    this._orderDocuments = [];
    this._loading = false;
  };

  @computed public get list(): OrderDocument[] {
    return toJS(this._orderDocuments);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default DocumentOrderStore;
