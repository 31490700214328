export const HEADER_HEIGHT = 110;

export const MOBILE_HEADER_HEIGHT = 80;

export const MOBILE_DRAWER_WIDTH = 260;

export const PAGE_Y_OFFSET = 30;

export const DEFAULT_TRANSITION_DELAY = '100ms';

export const CITY_IMAGE_ANIMATION_DURATION = 5;
