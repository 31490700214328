import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, Button, Theme, useMediaQuery, useTheme } from '@material-ui/core';

import { FOOTER_HEIGHT } from '@private/constants/ui';
import { APP_GALLERY, APP_STORE, GOOGLE_PLAY_FROM_PUBLIC } from '@core/constants/mobile';
import { footerActualDate } from '@core/utils/formatter';
import config from '@core/constants/config';
import SocialIcons from '@private/components/Layout/HeaderRight/SocialIcons';
import { isBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: FOOTER_HEIGHT,
      zIndex: 1200,
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: 0,
      borderTop: '1px solid rgba(0, 0, 0, 0.15)',

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        justifyContent: 'space-evenly',
        padding: '16px',
      },
    },

    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },

    linkWrapper: {
      [theme.breakpoints.up('sm')]: {
        '& + &': {
          marginLeft: 8,
        },
      },
    },

    downloadContainer: {
      display: 'flex',
      width: 'auto',

      [theme.breakpoints.down('xs')]: {
        marginTop: 6,
        justifyContent: 'center',
      },

      [theme.breakpoints.up(1070)]: {
        position: 'absolute',
        right: 16,
      },
    },

    buttonWrapper: {
      padding: 0,
    },

    buttonImage: {
      width: '107px',
      height: '32.3px',
    },

    text: {
      marginTop: '15px',
      fontSize: 11,
      color: '#07003D',
      opacity: 0.5,

      '@media (min-width: 600px) and (max-width: 750px)': {
        maxWidth: 250,
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
  }),
);

export const BillingFooter: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      {isBilling && isXs ? <SocialIcons /> : null}

      <Grid
        container={isXs ? true : false}
        className={classes.downloadContainer}
        spacing={isXs ? 2 : 0}
      >
        <Grid item className={classes.linkWrapper}>
          <Link className={classes.link} href={APP_STORE} target="_blank" tabIndex={-1}>
            <Button className={classes.buttonWrapper}>
              <img className={classes.buttonImage} src="/images/svgStores/appStore.svg" alt="" />
            </Button>
          </Link>
        </Grid>

        <Grid item className={classes.linkWrapper}>
          <Link
            className={classes.link}
            href={GOOGLE_PLAY_FROM_PUBLIC}
            target="_blank"
            tabIndex={-1}
          >
            <Button className={classes.buttonWrapper}>
              <img className={classes.buttonImage} src="/images/svgStores/googlePlay.svg" alt="" />
            </Button>
          </Link>
        </Grid>

        <Grid item className={classes.linkWrapper}>
          <Link className={classes.link} href={APP_GALLERY} target="_blank" tabIndex={-1}>
            <Button className={classes.buttonWrapper}>
              <img className={classes.buttonImage} src="/images/svgStores/appGallery.svg" alt="" />
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.text}>
        {footerActualDate(config.footerCaption)}
      </Typography>
    </Grid>
  );
};
