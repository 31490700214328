import config from '../../constants/config';
import { YMActionNames, YMActionNameType } from './actionNames';

class YM {
  private id: number;
  public goals: {
    [key in YMActionNameType]: () => void;
  };

  constructor(id: number) {
    this.id = id;
    this.goals = Object.keys(YMActionNames).reduce(
      (acc, key) => {
        acc[key as YMActionNameType] = (): void =>
          this.reachGoal(YMActionNames[key as YMActionNameType]);

        return acc;
      },
      {} as {
        [key in YMActionNameType]: () => void;
      },
    );
  }

  public reachGoal = (identifier: string): void => {
    window.ym?.(this.id, 'reachGoal', identifier);
  };
}

export const yM = new YM(Number(config.yandexMetrikaKey));
