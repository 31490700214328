import Store from './Store';
import { action, computed, observable } from 'mobx';

import Services from '@core/services';
import {
  ClaimsDynamics,
  ClaimsDynamicsInterface,
  ClaimsStatistics,
  ClaimsStatisticsInterface,
  EventMapClaimsList,
  EventMapClaimsListInterface,
  TopFiveControlList,
  TopFiveHouseList,
} from '@core/entities/Opencity/ClaimsStatistics';

class ClaimsStatisticsStore extends Store {
  @observable private _loading: boolean;
  @observable private _loadingDynamics: boolean;
  @observable private _loadingEventMapList: boolean;
  @observable private _loadingTopFiveClaimsStatistics: boolean;
  @observable private _loadingEventMapListError: boolean;
  @observable private _claimsStatistics?: ClaimsStatistics;
  @observable private _topFiveClaimsStatistics?: ClaimsStatistics;
  @observable private _topFiveClaimsStatisticsBefore?: ClaimsStatistics;
  @observable private _claimsDynamics?: ClaimsDynamics;
  @observable private _eventMapClaimsList?: EventMapClaimsList;
  @observable private _eventMapClaimsListTotal: number;

  @observable private _loadingTopByHouse: boolean;
  @observable private _loadingTopByHouseError: boolean;
  @observable private _topByHouseList?: TopFiveHouseList;

  @observable private _loadingTopByActiveHouses: boolean;
  @observable private _loadingTopByActiveHousesError: boolean;
  @observable private _topByActiveHousesList?: TopFiveHouseList;

  @observable private _loadingTopFiveForHouse: boolean;
  @observable private _topFiveForHouse?: ClaimsStatistics;

  @observable private _loadingTopFiveForControl: boolean;
  @observable private _topFiveForControl?: ClaimsStatistics;

  @observable private _loadingTopByControl: boolean;
  @observable private _loadingTopByControlError: boolean;
  @observable private _topByControlList?: TopFiveControlList;

  public constructor(services: Services) {
    super(services);

    this._loading = false;
    this._loadingDynamics = false;
    this._loadingEventMapList = false;
    this._loadingTopFiveClaimsStatistics = false;
    this._loadingEventMapListError = false;
    this._loadingTopByHouseError = false;
    this._loadingTopByHouse = false;
    this._loadingTopByActiveHousesError = false;
    this._loadingTopByActiveHouses = false;
    this._loadingTopFiveForHouse = false;
    this._loadingTopFiveForControl = false;
    this._loadingTopByControl = false;
    this._loadingTopByControlError = false;
    this._eventMapClaimsListTotal = 0;
  }

  @action public loadTopFiveForControl = async (params: any): Promise<void> => {
    this._loadingTopFiveForControl = true;

    let topFiveForControl: ClaimsStatisticsInterface = {};

    await this._services.opencity.requests
      .statisticsIndex({
        params: {
          filter: {
            trackerId: params.trackerId,
            type: params.type,
            timestamp: params.timestamp,
            controlId: params.controlId,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          topFiveForControl = new ClaimsStatistics(result);

          this._topFiveForControl = topFiveForControl;
        } else {
          this._topFiveForControl = new ClaimsStatistics({});
        }
      })
      .finally(() => (this._loadingTopFiveForControl = false));
  };

  @action public loadTopByControl = async (params: any): Promise<TopFiveControlList> => {
    this._loadingTopByControlError = false;
    this._loadingTopByControl = true;
    this._topByControlList = undefined;

    let topByControlList: TopFiveControlList = {};

    const { trackerId, state, timestamp, categoryId, controlId } = params;

    await this._services.opencity.requests
      .statisticsTopByControl({
        params: {
          filter: {
            trackerId,
            state,
            timestamp,
            controlId,
            categoryId,
          },
          limit: 0,
        },
      })
      .then(({ data: { result } }: any) => {
        if (!result) {
          this._loadingTopByControlError = true;
        } else if (Array.isArray(result?.top)) {
          topByControlList = new TopFiveControlList({ top: result?.top });
          this._topByControlList = topByControlList;
        }
      })
      .finally(() => (this._loadingTopByControl = false));

    return topByControlList;
  };

  @action public loadTopByActiveHouses = async (params: any): Promise<TopFiveHouseList> => {
    this._loadingTopByActiveHousesError = false;
    this._loadingTopByActiveHouses = true;
    this._topByActiveHousesList = undefined;

    let topByActiveHousesList: TopFiveHouseList = {};

    const { trackerId, state, timestamp, categoryId, controlId } = params;

    await this._services.opencity.requests
      .statisticsTopByHouse({
        params: {
          filter: {
            trackerId,
            state,
            timestamp,
            controlId,
            categoryId,
          },
          limit: 5,
        },
      })
      .then(({ data: { result } }: any) => {
        if (!result) {
          this._loadingTopByActiveHousesError = true;
        } else if (Array.isArray(result?.top)) {
          topByActiveHousesList = new TopFiveHouseList({ top: result?.top });
          this._topByActiveHousesList = topByActiveHousesList;
        }
      })
      .finally(() => (this._loadingTopByActiveHouses = false));

    return topByActiveHousesList;
  };

  @action public loadTopByHouse = async (params: any): Promise<TopFiveHouseList> => {
    this._loadingTopByHouseError = false;
    this._loadingTopByHouse = true;
    this._topByHouseList = undefined;

    let topByHouseList: TopFiveHouseList = {};

    const { trackerId, state, timestamp, categoryId, controlId } = params;

    await this._services.opencity.requests
      .statisticsTopByHouse({
        params: {
          filter: {
            trackerId,
            state,
            timestamp,
            categoryId,
            controlId,
          },
          limit: 5,
        },
      })
      .then(({ data: { result } }: any) => {
        if (!result) {
          this._loadingTopByHouseError = true;
        } else if (Array.isArray(result?.top)) {
          topByHouseList = new TopFiveHouseList({ top: result?.top });
          this._topByHouseList = topByHouseList;
        }
      })
      .finally(() => (this._loadingTopByHouse = false));

    return topByHouseList;
  };

  @action public loadClaimsStatistics = async (params: any): Promise<ClaimsStatistics> => {
    this._loading = true;

    let claimsStatistics: ClaimsStatisticsInterface = {};

    if (!params.trackerId) {
      this._loading = false;
      return claimsStatistics;
    }

    await this._services.opencity.requests
      .statisticsIndex({
        params: {
          filter: {
            trackerId: params.trackerId,
            type: params.type,
            controlId: params.controlId,
            timestamp: params.timestamp,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          claimsStatistics = new ClaimsStatistics(result);

          this._claimsStatistics = claimsStatistics;
        } else {
          this._claimsStatistics = new ClaimsStatistics({});
        }
      })
      .finally(() => (this._loading = false));

    return claimsStatistics;
  };

  @action public loadTopFiveForHouse = async (params: any): Promise<void> => {
    this._loadingTopFiveForHouse = true;

    let topFiveForHouse: ClaimsStatisticsInterface = {};

    await this._services.opencity.requests
      .statisticsIndex({
        params: {
          filter: {
            trackerId: params.trackerId,
            type: params.type,
            timestamp: params.timestamp,
            houseId: params.houseId,
            controlId: params.controlId,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          topFiveForHouse = new ClaimsStatistics(result);

          this._topFiveForHouse = topFiveForHouse;
        } else {
          this._topFiveForHouse = new ClaimsStatistics({});
        }
      })
      .finally(() => (this._loadingTopFiveForHouse = false));
  };

  @action public loadTopFiveClaimsStatistics = async (params: any): Promise<void> => {
    this._loadingTopFiveClaimsStatistics = true;

    let topFiveClaimsStatistics: ClaimsStatisticsInterface = {};

    if (!params.trackerId) {
      this._loadingTopFiveClaimsStatistics = false;
    }

    await this._services.opencity.requests
      .statisticsIndex({
        params: {
          filter: {
            trackerId: params.trackerId,
            type: params.type,
            controlId: params.controlId,
            timestamp: params.timestamp,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          topFiveClaimsStatistics = new ClaimsStatistics(result);

          this._topFiveClaimsStatistics = topFiveClaimsStatistics;
        } else {
          this._topFiveClaimsStatistics = new ClaimsStatistics({});
        }
      });

    await this._services.opencity.requests
      .statisticsIndex({
        params: {
          filter: {
            trackerId: params.trackerId,
            type: params.type,
            controlId: params.controlId,
            timestamp: params.timestampBefore,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          topFiveClaimsStatistics = new ClaimsStatistics(result);

          this._topFiveClaimsStatisticsBefore = topFiveClaimsStatistics;
        } else {
          this._topFiveClaimsStatisticsBefore = new ClaimsStatistics({});
        }
      })
      .finally(() => (this._loadingTopFiveClaimsStatistics = false));
  };

  @action public loadClaimsDynamics = async (params: any): Promise<ClaimsDynamics> => {
    this._loadingDynamics = true;

    let claimsDynamics: ClaimsDynamicsInterface = {
      data: null,
    };

    if (!params.trackerId) {
      this._loadingDynamics = false;
      return claimsDynamics;
    }

    const { countOfGraphics, ...restParams } = params;

    await this._services.opencity.requests
      .statisticsDynamicsIndex({ params: { filter: restParams, countOfGraphics } })
      .then(({ data: { result } }: any) => {
        if (result) {
          claimsDynamics = new ClaimsDynamics({ data: result });
        }

        this._claimsDynamics = claimsDynamics;
      })
      .finally(() => (this._loadingDynamics = false));

    return claimsDynamics;
  };

  @action public loadEventMapClaims = async (params: any): Promise<EventMapClaimsList> => {
    this._loadingEventMapListError = false;
    this._loadingEventMapList = true;
    this._eventMapClaimsList = undefined;
    this._eventMapClaimsListTotal = 0;

    let eventMapClaimsList: EventMapClaimsListInterface = {
      data: null,
    };

    if (!params.trackerId) {
      this._loadingEventMapList = false;
      return eventMapClaimsList;
    }

    const { trackerId, state, timestamp } = params;

    await this._services.opencity.requests
      .issueIndex({
        params: {
          filter: {
            trackerId,
            state,
            timestamp,
          },
          select: [
            'id',
            'latitude',
            'longitude',
            'type',
            'streetName',
            'houseNumber',
            'buildingNumber',
            'flatNumber',
            'serviceName',
          ],
          sort: [
            {
              field: 'streetName',
              desc: 'ASC',
            },
            {
              field: 'houseNumber',
              desc: 'ASC',
            },
            {
              field: 'flatNumber',
              desc: 'ASC',
            },
          ],
          limit: 0,
        },
      })
      .then(({ data: { result } }: any) => {
        if (!result) {
          this._loadingEventMapListError = true;
        } else if (result?.total !== 0) {
          eventMapClaimsList = new EventMapClaimsList({ data: result?.items });
          this._eventMapClaimsList = eventMapClaimsList;
          this._eventMapClaimsListTotal = result?.total;
        } else {
          this._eventMapClaimsList = undefined;
        }
      })
      .finally(() => (this._loadingEventMapList = false));

    return eventMapClaimsList;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get loadingDynamics(): boolean {
    return this._loadingDynamics;
  }

  @computed public get loadingEventMapList(): boolean {
    return this._loadingEventMapList;
  }

  @computed public get loadingEventMapListError(): boolean {
    return this._loadingEventMapListError;
  }

  @computed public get loadingTopFiveClaimsStatistics(): boolean {
    return this._loadingTopFiveClaimsStatistics;
  }

  @computed public get claimsStatistics(): ClaimsStatistics | undefined {
    return this._claimsStatistics;
  }

  @computed public get topFiveClaimsStatistics(): ClaimsStatistics | undefined {
    return this._topFiveClaimsStatistics;
  }

  @computed public get topFiveClaimsStatisticsBefore(): ClaimsStatistics | undefined {
    return this._topFiveClaimsStatisticsBefore;
  }

  @computed public get claimsDynamics(): ClaimsDynamics | undefined {
    return this._claimsDynamics;
  }

  @computed public get eventMapClaimsList(): EventMapClaimsList | undefined {
    return this._eventMapClaimsList;
  }

  @computed public get eventMapClaimsListTotal(): number {
    return this._eventMapClaimsListTotal;
  }

  @computed public get topByHouseList(): TopFiveHouseList | undefined {
    return this._topByHouseList;
  }

  @computed public get loadingTopByHouse(): boolean {
    return this._loadingTopByHouse;
  }

  @computed public get loadingTopByHouseError(): boolean {
    return this._loadingTopByHouseError;
  }

  @computed public get topByActiveHousesList(): TopFiveHouseList | undefined {
    return this._topByActiveHousesList;
  }

  @computed public get loadingTopByActiveHouses(): boolean {
    return this._loadingTopByActiveHouses;
  }

  @computed public get loadingTopByActiveHousesError(): boolean {
    return this._loadingTopByActiveHousesError;
  }

  @computed public get loadingTopFiveForHouse(): boolean {
    return this._loadingTopFiveForHouse;
  }

  @computed public get topFiveForHouse(): ClaimsStatistics | undefined {
    return this._topFiveForHouse;
  }

  @computed public get loadingTopByControl(): boolean {
    return this._loadingTopByControl;
  }

  @computed public get loadingTopByControlError(): boolean {
    return this._loadingTopByControlError;
  }

  @computed public get topByControlList(): TopFiveControlList | undefined {
    return this._topByControlList;
  }

  @computed public get loadingTopFiveForControl(): boolean {
    return this._loadingTopFiveForControl;
  }

  @computed public get topFiveForControl(): ClaimsStatistics | undefined {
    return this._topFiveForControl;
  }
}

export default ClaimsStatisticsStore;
