enum BillingActionNames {
  ACCOUNT_INDEX = 'accountIndex',
  ACCOUNT_EXISTS = 'accountExists',
  COUNTER_VALUE_CREATE = 'counterValueCreate',
  COUNTER_VALUE_INDEX = 'counterValueIndex',
  COUNTER_INDEX = 'counterIndex',
  MERCHANT_INDEX = 'merchantIndex',
  MERCHANT_GROUP_INDEX = 'merchantGroupIndex',
  SERVICE_INDEX = 'serviceIndex',
  PAYMENT_RECEIPT_INDEX = 'paymentReceiptIndex',
  PAYMENT_INDEX = 'paymentIndex',
  PAYMENT_GET_LINK = 'paymentGetLink',
  USER_ACCOUNT_INDEX = 'userAccountIndex',
  USER_ACCOUNT_CREATE = 'userAccountCreate',
  USER_ACCOUNT_UPDATE = 'userAccountUpdate',
  USER_ACCOUNT_DELETE = 'userAccountDelete',
  ACCRUAL_INDEX = 'accrualIndex',
  ORGANIZATION_INDEX = 'organizationIndex',
  QR_CODE_CREATE = 'qrCodeCreate',
  QR_CODE_SEND = 'qrCodeSend',
  PAYMENT_CARD_INDEX = 'paymentCardIndex',
  PAYMENT_CARD_PAY = 'paymentCardPay',
  PAYMENT_STATUS = 'paymentStatus',
  PAYMENT_CARD_DETACH = 'paymentCardDetach',
  PAYMENT_CARD_ATTACH = 'paymentCardAttach',
  MERCHANT_EMAIL = 'merchantEmail',
  USER_CREATE = 'userCreate',
  USER_NOTIFICATION_CREATE = 'userNotificationCreate',
  USER_NOTIFICATION_TYPE_CREATE = 'userNotificationTypeCreate',
  USER_NOTIFICATION_TYPE_DELETE = 'userNotificationTypeDelete',
  USER_NOTIFICATION_DELETE = 'userNotificationDelete',
  ORGANIZATION_BANNER_INDEX = 'organizationBannerIndex',
  ACCOUNT_CONTRACT_INDEX = 'accountContractIndex',
  ANNOUNCEMENTS_INDEX = 'announcementsIndex',
  ACCOUNT_ACT_INDEX = 'accounActIndex',
  PAYMENT_SBP_GET = 'paymentSbpGet',
  DADATA_SUGGS_GET = 'dadataSuggsGet',
  DADATA_IS_SHOW_CAPTCHA = 'dadataIsShowCaptcha',
  CLERK_ACCOUNT_INDEX = 'clerkAccountIndex',
}

export default BillingActionNames;
