import React, { FC, ReactElement, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { usePaymentStore } from './hooks';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useOwnProfileStore } from '../hooks';
import { moneyFormat } from '@core/utils/formatter';
import moment from 'moment';
import { MINIO_END_POINT } from '@core/constants/minio';
import project from '@core/constants/project';

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(8),
    width: '100%',
    textAlign: 'center',
  },

  imgRow: {
    width: '100%',
    textAlign: 'center',
  },

  loaderContainer: {
    width: '50px',
    height: '50px',
    background: theme.palette.primary.main,
    borderRadius: '50px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorContainer: {
    width: '50px',
    height: '50px',
    background: 'red',
    borderRadius: '50px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    color: '#fff',
    width: '30px !important',
    height: '30px !important',
  },

  dialogContent: {
    paddingBottom: '35px',
  },

  imgContainer: {
    flexBasis: 0,
  },

  img: {
    width: 133,
    height: 'auto',
  },

  qrFrameImgContainer: {
    position: 'relative',
    margin: '0 auto',
    width: '240px',
    height: '240px',
    backgroundImage: 'url("./images/sbp_frame.svg")',
    backgroundSize: '240px 240px !important',
    backgroundRepeat: 'no-repeat',
  },

  qrCode: {
    width: '202px',
    height: '200px',
    top: '20px',
    right: '20px',
    position: 'absolute',
    backgroundSize: 'contain !important',
  },
}));

export type QrPaymentWindowProps = {
  account?: string;
  provider?: number;
  emailForFiscalReceipt: string;
  paymentSource?: number;
  merchantGroup: string;
  open: boolean;
  amount: number;
  close: () => void;
};

const QrPaymentWindow: FC<QrPaymentWindowProps> = ({
  account,
  provider,
  amount,
  open,
  close,
  merchantGroup,
  emailForFiscalReceipt,
  paymentSource,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { selectedBillingUser } = useOwnProfileStore();
  const { getSbpPayment, sbpLoading, sbpError, sbpLink, sbpQrLink, cleanUpSbp } = usePaymentStore();

  const accountNumber = selectedBillingUser ? selectedBillingUser.number : account!;
  const providerId = selectedBillingUser ? Number(selectedBillingUser.providerId) : provider!;
  const merchantGroupId = merchantGroup;
  const date = Number(moment(moment.now()).format('X'));

  const closeModal = () => {
    cleanUpSbp();
    close();
  };

  useEffect(() => {
    if (open) {
      getSbpPayment({
        accountNumber,
        providerId,
        merchantGroupId,
        amount,
        date,
        emailForFiscalReceipt,
        paymentSource,
      });
    }
  }, [open]);

  useEffect(() => {
    if (isXs && sbpLink !== '' && !sbpError) {
      window.location.replace(sbpLink);

      cleanUpSbp();
      close();
    }
  }, [sbpLink]);

  const Loader: ReactElement = (
    <div className={classes.loaderContainer}>
      <CircularProgress className={classes.icon} />
    </div>
  );

  const Error: ReactElement = (
    <div className={classes.errorContainer}>
      <CloseIcon className={classes.icon} />
    </div>
  );

  const QrCodeImage: ReactElement = (
    <div
      className={classes.qrCode}
      style={{ background: `url("${MINIO_END_POINT}/${project}/${sbpQrLink}"), #F0F2F5` }}
    >
      {sbpLoading && Loader}
      {sbpError && Error}
    </div>
  );

  return (
    <Dialog open={open} onClose={close} maxWidth={'md'} fullScreen={isXs} fullWidth={true}>
      <DialogTitle>
        <Grid container={true} alignItems={'center'} justify={'space-between'}>
          <Grid item={true}>QR-код для оплаты</Grid>
          <Grid item={true}>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Grid container={true} direction={'column'}>
          <Grid item={true} className={classes.row}>
            {<div className={classes.qrFrameImgContainer}>{QrCodeImage}</div>}
          </Grid>

          {!sbpError && (
            <Grid item={true}>
              <Typography variant="h4" className={classes.row}>
                К оплате: {moneyFormat(amount)}
              </Typography>
            </Grid>
          )}

          <Grid item={true}>
            <Typography variant="body1" className={classes.row}>
              {sbpError
                ? 'Извините в данный момент сервис СБП недоступен'
                : 'Для оплаты зайдите в мобильное приложение банка и отсканируйте QR-код'}
            </Typography>
          </Grid>

          <Grid item={true} className={classes.imgRow}>
            <div className={classes.imgContainer}>
              <img className={classes.img} alt={'SBP'} src="/images/sbp.png" />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default QrPaymentWindow;
