import { AxiosRequestConfig } from 'axios';

import { HttpClient } from '@httpClient/index';
import Methods from './methods';
import ActionNames from './actionNames';
import { createCancelTokenHandler } from '@httpClient/cancelTokenHandler';

const cancelTokenHandlerObject = createCancelTokenHandler(Methods.ISSUE_INDEX);

class OpencityService extends HttpClient<ActionNames> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(request: any, options?: AxiosRequestConfig) {
    super(request, options);

    this.init();
  }

  private init = (): void => {
    this.requests[ActionNames.AUTH_PASSWORD_RESET] = this.request({
      method: Methods.AUTH_PASSWORD_RESET,
    });

    this.requests[ActionNames.AUTH_PASSWORD_RECOVERY] = this.request({
      method: Methods.AUTH_PASSWORD_RECOVERY,
    });

    this.requests[ActionNames.AUTH_PASSWORD_UPDATE] = this.request({
      method: Methods.AUTH_PASSWORD_UPDATE,
    });

    this.requests[ActionNames.ADDRESS_SEARCH] = this.request({ method: Methods.ADDRESS_SEARCH });

    this.requests[ActionNames.CLASSIFIER_SEARCH] = this.request({
      method: Methods.CLASSIFIER_SEARCH,
    });

    this.requests[ActionNames.MAP_ISSUE_INDEX] = this.request({ method: Methods.MAP_ISSUE_INDEX });

    this.requests[ActionNames.FLAT_ACCOUNT_INDEX] = this.request({
      method: Methods.FLAT_ACCOUNT_INDEX,
    });

    this.requests[ActionNames.FLAT_ACCOUNT_COUNT] = this.request({
      method: Methods.FLAT_ACCOUNT_COUNT,
    });

    this.requests[ActionNames.FLAT_ACCOUNT_OWNER_CREATE] = this.request({
      method: Methods.FLAT_ACCOUNT_OWNER_CREATE,
    });

    this.requests[ActionNames.FLAT_ACCOUNT_OWNER_UPDATE] = this.request({
      method: Methods.FLAT_ACCOUNT_OWNER_UPDATE,
    });

    this.requests[ActionNames.ORGANIZATION_INDEX] = this.request({
      method: Methods.ORGANIZATION_INDEX,
    });

    this.requests[ActionNames.ORGANIZATION_DISTRICT_INDEX] = this.request({
      method: Methods.ORGANIZATION_DISTRICT_INDEX,
    });

    this.requests[ActionNames.ISSUE_CREATE] = this.request({ method: Methods.ISSUE_CREATE });

    this.requests[ActionNames.ISSUE_COUNT] = this.request({ method: Methods.ISSUE_COUNT });

    this.requests[ActionNames.ISSUE_INDEX] = this.request({
      method: Methods.ISSUE_INDEX,
      cancelTokenHandlerObject: cancelTokenHandlerObject,
    });

    this.requests[ActionNames.ISSUE_UPDATE] = this.request({ method: Methods.ISSUE_UPDATE });

    this.requests[ActionNames.ISSUE_EXPORT] = this.request({ method: Methods.ISSUE_EXPORT });

    this.requests[ActionNames.ISSUE_DOCUMENT_CREATE] = this.request({
      method: Methods.ISSUE_DOCUMENT_CREATE,
    });

    this.requests[ActionNames.ISSUE_DOCUMENT_INDEX] = this.request({
      method: Methods.ISSUE_DOCUMENT_INDEX,
    });

    // issue update aliases

    this.requests[ActionNames.ISSUE_DONE_CLOSED_BY_COMMUNAL_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_CLOSED_BY_COMMUNAL_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_PROVIDER_CLOSED_BY_COMMUNAL_UPDATE] = this.request({
      method: Methods.ISSUE_TO_PROVIDER_CLOSED_BY_COMMUNAL_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_CLOSED_BY_COMMUNAL_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_CLOSED_BY_COMMUNAL_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_DELAYED_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_DELAYED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_TO_EXECUTE_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_TO_EXECUTE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_TO_PROVIDER_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_TO_PROVIDER_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DELAYED_RESTORED_UPDATE] = this.request({
      method: Methods.ISSUE_DELAYED_RESTORED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_RESTORED_DELAYED_UPDATE] = this.request({
      method: Methods.ISSUE_RESTORED_DELAYED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_RESTORED_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_RESTORED_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_EXECUTE_DELAYED_UPDATE] = this.request({
      method: Methods.ISSUE_TO_EXECUTE_DELAYED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_RESTORED_TO_PROVIDER_UPDATE] = this.request({
      method: Methods.ISSUE_RESTORED_TO_PROVIDER_UPDATE,
    });

    this.requests[ActionNames.ISSUE_RESTORED_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_RESTORED_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_EXECUTE_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_TO_EXECUTE_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_EXECUTE_TO_PROVIDER_UPDATE] = this.request({
      method: Methods.ISSUE_TO_EXECUTE_TO_PROVIDER_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_EXECUTE_DELAYED_UPDATE] = this.request({
      method: Methods.ISSUE_TO_EXECUTE_DELAYED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_PROVIDER_IN_WORK_UPDATE] = this.request({
      method: Methods.ISSUE_TO_PROVIDER_IN_WORK_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_REJECTED_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_REJECTED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_IN_WORK_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_IN_WORK_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_DONE_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_DONE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_REJECTED_NEW_UPDATE] = this.request({
      method: Methods.ISSUE_REJECTED_NEW_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_PROVIDER_DONE_UPDATE] = this.request({
      method: Methods.ISSUE_TO_PROVIDER_DONE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_DONE_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_DONE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_REJECTED_CLOSED_UPDATE] = this.request({
      method: Methods.ISSUE_REJECTED_CLOSED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_IN_WORK_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_IN_WORK_UPDATE,
    });

    this.requests[ActionNames.ISSUE_IN_WORK_DONE_UPDATE] = this.request({
      method: Methods.ISSUE_IN_WORK_DONE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DONE_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DONE_TO_PROVIDER_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_TO_PROVIDER_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DONE_OK_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_OK_UPDATE,
    });

    this.requests[ActionNames.ISSUE_RESTORED_DONE_UPDATE] = this.request({
      method: Methods.ISSUE_RESTORED_DONE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DONE_CLOSED_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_CLOSED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_OK_UNCONFIRMED_UPDATE] = this.request({
      method: Methods.ISSUE_OK_UNCONFIRMED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_OK_CLOSED_BY_CITIZEN_UPDATE] = this.request({
      method: Methods.ISSUE_OK_CLOSED_BY_CITIZEN_UPDATE,
    });

    this.requests[ActionNames.ISSUE_OK_CLOSED_UPDATE] = this.request({
      method: Methods.ISSUE_OK_CLOSED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_OK_CLOSED_BY_STD_UPDATE] = this.request({
      method: Methods.ISSUE_OK_CLOSED_BY_STD_UPDATE,
    });

    this.requests[ActionNames.ISSUE_OK_CLOSED_AUTO_UPDATE] = this.request({
      method: Methods.ISSUE_OK_CLOSED_AUTO_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_TO_EXECUTE_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_TO_EXECUTE_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE,
    });

    this.requests[ActionNames.ISSUE_UNCONFIRMED_CLOSED_BY_ANALYST_UPDATE] = this.request({
      method: Methods.ISSUE_UNCONFIRMED_CLOSED_BY_ANALYST_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DONE_UNCONFIRMED_UPDATE] = this.request({
      method: Methods.ISSUE_DONE_UNCONFIRMED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_PROVIDER_DELETED_UPDATE] = this.request({
      method: Methods.ISSUE_TO_PROVIDER_DELETED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_DELETED_RESTORED_UPDATE] = this.request({
      method: Methods.ISSUE_DELETED_RESTORED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_NEW_REJECTED_UPDATE] = this.request({
      method: Methods.ISSUE_NEW_REJECTED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_TO_PROVIDER_DELAYED_UPDATE] = this.request({
      method: Methods.ISSUE_TO_PROVIDER_DELAYED_UPDATE,
    });

    this.requests[ActionNames.ISSUE_COMMENT_INDEX] = this.request({
      method: Methods.ISSUE_COMMENT_INDEX,
    });

    this.requests[ActionNames.ISSUE_STATISTICS_DYNAMICS] = this.request({
      method: Methods.ISSUE_STATISTICS_DYNAMICS,
    });

    this.requests[ActionNames.USER_COUNT] = this.request({ method: Methods.USER_COUNT });

    this.requests[ActionNames.USER_CREATE] = this.request({ method: Methods.USER_CREATE });

    this.requests[ActionNames.USER_INDEX] = this.request({ method: Methods.USER_INDEX });

    // this.requests[ActionNames.USER_ACCOUNT_INDEX] = this.request({
    //   method: Methods.USER_ACCOUNT_INDEX,
    // });

    this.requests[ActionNames.USER_DELETE] = this.request({ method: Methods.USER_DELETE });

    this.requests[ActionNames.USER_CATEGORY_INDEX] = this.request({
      method: Methods.USER_CATEGORY_INDEX,
    });

    this.requests[ActionNames.USER_RULE_UPDATE] = this.request({
      method: Methods.USER_RULE_UPDATE,
    });

    this.requests[ActionNames.ORGANIZATION_COUNT] = this.request({
      method: Methods.ORGANIZATION_COUNT,
    });

    this.requests[ActionNames.ORGANIZATION_RATING_INDEX] = this.request({
      method: Methods.ORGANIZATION_RATING_INDEX,
    });

    this.requests[ActionNames.ORGANIZATION_PREFERABLE_INDEX] = this.request({
      method: Methods.ORGANIZATION_PREFERABLE_INDEX,
    });

    this.requests[ActionNames.INTERRUPT_INDEX] = this.request({ method: Methods.INTERRUPT_INDEX });

    this.requests[ActionNames.INTERRUPT_COUNT] = this.request({ method: Methods.INTERRUPT_COUNT });

    this.requests[ActionNames.INTERRUPT_CREATE] = this.request({
      method: Methods.INTERRUPT_CREATE,
    });

    this.requests[ActionNames.INTERRUPT_UPDATE] = this.request({
      method: Methods.INTERRUPT_UPDATE,
    });

    this.requests[ActionNames.PROFILE_INDEX] = this.request({ method: Methods.PROFILE_INDEX });

    this.requests[ActionNames.PROFILE_CREATE] = this.request({ method: Methods.PROFILE_CREATE });

    this.requests[ActionNames.PROFILE_UPDATE] = this.request({ method: Methods.PROFILE_UPDATE });

    this.requests[ActionNames.PROFILE_DELETE] = this.request({ method: Methods.PROFILE_DELETE });

    this.requests[ActionNames.AVATAR_CREATE] = this.request({ method: Methods.AVATAR_CREATE });

    this.requests[ActionNames.AVATAR_DELETE] = this.request({ method: Methods.AVATAR_DELETE });

    this.requests[ActionNames.AVATAR_INDEX] = this.request({ method: Methods.AVATAR_INDEX });

    this.requests[ActionNames.AVATAR_UPDATE] = this.request({ method: Methods.AVATAR_UPDATE });

    this.requests[ActionNames.PROFILE_ACCOUNT_CREATE] = this.request({
      method: Methods.PROFILE_ACCOUNT_CREATE,
    });

    this.requests[ActionNames.PROFILE_ACCOUNT_DELETE] = this.request({
      method: Methods.PROFILE_ACCOUNT_DELETE,
    });

    this.requests[ActionNames.GIS_STREET_INDEX] = this.request({
      method: Methods.GIS_STREET_INDEX,
    });

    this.requests[ActionNames.GIS_STREET_COUNT] = this.request({
      method: Methods.GIS_STREET_COUNT,
    });

    this.requests[ActionNames.GIS_DISTRICT_INDEX] = this.request({
      method: Methods.GIS_DISTRICT_INDEX,
    });

    this.requests[ActionNames.GIS_DISTRICT_COUNT] = this.request({
      method: Methods.GIS_DISTRICT_COUNT,
    });

    this.requests[ActionNames.GIS_HOUSE_INDEX] = this.request({ method: Methods.GIS_HOUSE_INDEX });

    this.requests[ActionNames.GIS_HOUSE_COUNT] = this.request({ method: Methods.GIS_HOUSE_COUNT });

    this.requests[ActionNames.GIS_FLAT_INDEX] = this.request({ method: Methods.GIS_FLAT_INDEX });

    this.requests[ActionNames.GIS_FLAT_COUNT] = this.request({ method: Methods.GIS_FLAT_COUNT });

    this.requests[ActionNames.USER_STREET_INDEX] = this.request({
      method: Methods.USER_STREET_INDEX,
    });

    this.requests[ActionNames.USER_HOUSE_INDEX] = this.request({
      method: Methods.USER_HOUSE_INDEX,
    });

    this.requests[ActionNames.USER_FLAT_INDEX] = this.request({
      method: Methods.USER_FLAT_INDEX,
    });

    this.requests[ActionNames.DEVICE_METERING_REFRESH] = this.request({
      method: Methods.DEVICE_METERING_REFRESH,
    });

    this.requests[ActionNames.DEVICE_METERING_INDEX] = this.request({
      method: Methods.DEVICE_METERING_INDEX,
    });

    this.requests[ActionNames.DEVICE_METERING_VALUE_INDEX] = this.request({
      method: Methods.DEVICE_METERING_VALUE_INDEX,
    });

    this.requests[ActionNames.DEVICE_METERING_VALUE_CREATE] = this.request({
      method: Methods.DEVICE_METERING_VALUE_CREATE,
    });

    this.requests[ActionNames.FILE_CREATE] = this.request({ method: Methods.FILE_CREATE });

    this.requests[ActionNames.FILE_INDEX] = this.request({ method: Methods.FILE_INDEX });

    this.requests[ActionNames.REFERENCE_INTERRUPT_REASON_INDEX] = this.request({
      method: Methods.REFERENCE_INTERRUPT_REASON_INDEX,
    });

    this.requests[ActionNames.REFERENCE_INTERRUPT_SENDER_INDEX] = this.request({
      method: Methods.REFERENCE_INTERRUPT_SENDER_INDEX,
    });

    this.requests[ActionNames.REFERENCE_INTERRUPT_TYPE_INDEX] = this.request({
      method: Methods.REFERENCE_INTERRUPT_TYPE_INDEX,
    });

    this.requests[ActionNames.REFERENCE_CLASSIFIER_CATEGORY_INDEX] = this.request({
      method: Methods.REFERENCE_CLASSIFIER_CATEGORY_INDEX,
    });

    this.requests[ActionNames.REFERENCE_CLASSIFIER_TAG_INDEX] = this.request({
      method: Methods.REFERENCE_CLASSIFIER_TAG_INDEX,
    });

    this.requests[ActionNames.REFERENCE_CLASSIFIER_TRACKER_INDEX] = this.request({
      method: Methods.REFERENCE_CLASSIFIER_TRACKER_INDEX,
    });

    this.requests[ActionNames.REFERENCE_CLASSIFIER_TYPE_INDEX] = this.request({
      method: Methods.REFERENCE_CLASSIFIER_TYPE_INDEX,
    });

    this.requests[ActionNames.FEEDBACK_CREATE] = this.request({ method: Methods.FEEDBACK_CREATE });

    // this.requests[ActionNames.ACCOUNT_INDEX] = this.request({ method: Methods.ACCOUNT_INDEX });
    //
    // this.requests[ActionNames.ACCOUNT_EXISTS] = this.request({ method: Methods.ACCOUNT_EXISTS });
    //
    // this.requests[ActionNames.COUNTER_VALUE_CREATE] = this.request({
    //   method: Methods.COUNTER_VALUE_CREATE,
    // });
    //
    // this.requests[ActionNames.COUNTER_INDEX] = this.request({ method: Methods.COUNTER_INDEX });
    //
    // this.requests[ActionNames.MERCHANT_INDEX] = this.request({ method: Methods.MERCHANT_INDEX });
    //
    // this.requests[ActionNames.MERCHANT_GROUP_INDEX] = this.request({
    //   method: Methods.MERCHANT_GROUP_INDEX,
    // });
    //
    // this.requests[ActionNames.SERVICE_INDEX] = this.request({ method: Methods.SERVICE_INDEX });
    //
    // this.requests[ActionNames.PAYMENT_RECEIPT_INDEX] = this.request({
    //   method: Methods.PAYMENT_RECEIPT_INDEX,
    // });
    //
    // this.requests[ActionNames.PAYMENT_INDEX] = this.request({ method: Methods.PAYMENT_INDEX });
    //
    // this.requests[ActionNames.PAYMENT_GET_LINK] = this.request({
    //   method: Methods.PAYMENT_GET_LINK,
    // });
    //
    // this.requests[ActionNames.TRANSITION_BY_STATE_FROM_INDEX] = this.request({
    //   method: Methods.TRANSITION_BY_STATE_FROM_INDEX,
    // });
    //
    this.requests[ActionNames.AUTH_OPERATION_ALLOWED] = this.request({
      method: Methods.AUTH_OPERATION_ALLOWED,
    });
    //
    // this.requests[ActionNames.CAMERA_INDEX] = this.request({
    //   method: Methods.CAMERA_INDEX,
    // });
    //
    // this.requests[ActionNames.STREAM_INDEX] = this.request({
    //   method: Methods.STREAM_INDEX,
    // });
    //
    // this.requests[ActionNames.ACCRUAL_INDEX] = this.request({
    //   method: Methods.ACCRUAL_INDEX,
    // });
    //
    // this.requests[ActionNames.PAYMENT_INDEX] = this.request({
    //   method: Methods.PAYMENT_INDEX,
    // });
    this.requests[ActionNames.REPORT_INTERRUPT_CREATE] = this.request({
      method: Methods.REPORT_INTERRUPT_CREATE,
    });

    this.requests[ActionNames.REPORT_ACCOUNT_CREATE] = this.request({
      method: Methods.REPORT_ACCOUNT_CREATE,
    });

    this.requests[ActionNames.REPORT_INTERRUPT_EXTENDED_CREATE] = this.request({
      method: Methods.REPORT_INTERRUPT_EXTENDED_CREATE,
    });

    this.requests[ActionNames.REPORT_INTERRUPT_SENDER_CREATE] = this.request({
      method: Methods.REPORT_INTERRUPT_SENDER_CREATE,
    });

    this.requests[ActionNames.REPORT_INTERRUPT_CONTROL_CREATE] = this.request({
      method: Methods.REPORT_INTERRUPT_CONTROL_CREATE,
    });

    this.requests[ActionNames.REPORT_ISSUE_OPERATOR_CREATE] = this.request({
      method: Methods.REPORT_ISSUE_OPERATOR_CREATE,
    });

    this.requests[ActionNames.REPORT_COUNTER_CREATE] = this.request({
      method: Methods.REPORT_COUNTER_CREATE,
    });

    this.requests[ActionNames.REPORT_ISSUE_CHECKERBOARD] = this.request({
      method: Methods.REPORT_ISSUE_CHECKERBOARD,
    });

    this.requests[ActionNames.REPORT_ISSUE_FOOTCLOTH] = this.request({
      method: Methods.REPORT_ISSUE_FOOTCLOTH,
    });

    this.requests[ActionNames.REPORT_ISSUE_CREATE] = this.request({
      method: Methods.REPORT_ISSUE_CREATE,
    });
    this.requests[ActionNames.REPORT_INDEX] = this.request({
      method: Methods.REPORT_INDEX,
    });

    this.requests[ActionNames.AUTH_PROVIDER_GET_URL] = this.request({
      method: Methods.AUTH_PROVIDER_GET_URL,
    });

    this.requests[ActionNames.AUTH_PROVIDER_LOGIN] = this.request({
      method: Methods.AUTH_PROVIDER_LOGIN,
    });

    this.requests[ActionNames.ISSUE_CHANGE_PROVIDER] = this.request({
      method: Methods.ISSUE_CHANGE_PROVIDER,
    });

    this.requests[ActionNames.ISSUE_CHANGE_CLASSIFIER] = this.request({
      method: Methods.ISSUE_CHANGE_CLASSIFIER,
    });

    this.requests[ActionNames.CACHE_ISSUE_COUNT] = this.request({
      method: Methods.CACHE_ISSUE_COUNT,
    });

    this.requests[ActionNames.USER_CODE_GENERATE] = this.request({
      method: Methods.USER_CODE_GENERATE,
    });

    this.requests[ActionNames.USER_CODE_GET_NEXT_TIME] = this.request({
      method: Methods.USER_CODE_GET_NEXT_TIME,
    });

    this.requests[ActionNames.USER_CODE_CONFIRM] = this.request({
      method: Methods.USER_CODE_CONFIRM,
    });

    this.requests[ActionNames.USER_CODE_VALIDATE] = this.request({
      method: Methods.USER_CODE_VALIDATE,
    });

    this.requests[ActionNames.AUTH_LOGIN_EXIST] = this.request({
      method: Methods.AUTH_LOGIN_EXIST,
    });

    this.requests[ActionNames.USER_NOTIFICATION_DELETE] = this.request({
      method: Methods.USER_NOTIFICATION_DELETE,
    });

    this.requests[ActionNames.ISSUE_COMMENT_CREATE] = this.request({
      method: Methods.ISSUE_COMMENT_CREATE,
    });
    this.requests[ActionNames.ISSUE_VIEW] = this.request({
      method: Methods.ISSUE_VIEW,
    });

    this.requests[ActionNames.ISSUE_NOTICE] = this.request({
      method: Methods.ISSUE_NOTICE,
    });

    this.requests[ActionNames.USER_PREFERENCE_UPDATE] = this.request({
      method: Methods.USER_PREFERENCE_UPDATE,
    });

    this.requests[ActionNames.USER_PREFERENCE_INDEX] = this.request({
      method: Methods.USER_PREFERENCE_INDEX,
    });

    this.requests[ActionNames.CAPTCHA_CHECK] = this.request({
      method: Methods.CAPTCHA_CHECK,
    });

    this.requests[ActionNames.ISSUE_STATISTICS_INDEX] = this.request({
      method: Methods.ISSUE_STATISTICS_INDEX,
    });

    this.requests[ActionNames.ISSUE_STATISTICS_TOP_BY_HOUSE] = this.request({
      method: Methods.ISSUE_STATISTICS_TOP_BY_HOUSE,
    });

    this.requests[ActionNames.ISSUE_STATISTICS_TOP_BY_CONTROL] = this.request({
      method: Methods.ISSUE_STATISTICS_TOP_BY_CONTROL,
    });

    this.requests[ActionNames.USER_NOTIFICATION_UNSUBSCRIBE] = this.request({
      method: Methods.USER_NOTIFICATION_UNSUBSCRIBE,
    });

    this.requests[ActionNames.ISSUE_RECORD_GET_URL] = this.request({
      method: Methods.ISSUE_RECORD_GET_URL,
    });

    this.requests[ActionNames.OVERHAUL_INDEX] = this.request({
      method: Methods.OVERHAUL_INDEX,
    });

    this.requests[ActionNames.FLAT_ACCOUNT_GET_LAST_APPLICANT] = this.request({
      method: Methods.FLAT_ACCOUNT_GET_LAST_APPLICANT,
    });
  };
}

export default OpencityService;
