import { observable, computed, action, toJS } from 'mobx';

import Store from '@stores/Store';
import Services from '@core/services';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import {
  LoadReport,
  Report,
  ReportFilterProps,
  ReportInterface,
  CreateOperatorReport,
  CreateIssueReport,
  ReportSortProps,
  ReportKeys,
  CreateInterruptReport,
  CreateInterruptReportSender,
  CreateCounterReport,
  CreateAccountReport,
} from '@entities/Opencity/Report';
import { AxiosResponse } from 'axios';
import { JrpcResponse } from '@httpClient/jrpc';
import SnackbarStore from '@stores/SnackbarStore';
import SortDirections from '@constants/sort';

type ReportIssueIndex = JrpcResponse<{ items: ReportInterface[]; total: number }>;
type ReportIssueOperatorCreate = JrpcResponse<ReportInterface>;
type ReportIssueCreate = JrpcResponse<ReportInterface>;

interface ReportStoreRelations {
  snackbarStore: SnackbarStore;
}

const DEFAULT_SORT: ReportSortProps[] = [
  { field: ReportKeys.CREATED_AT, desc: SortDirections.DESC },
];

class ReportStore extends Store<ReportStoreRelations> implements Loading {
  @observable private _loading: boolean;
  @observable private _offset: number;
  @observable private _limit: number;
  @observable private _total: number;
  @observable private _reports: Report[];
  @observable private _filter: ReportFilterProps;
  @observable private _sort: ReportSortProps[];
  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services, relations: ReportStoreRelations) {
    super(services, relations);
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._reports = [];
    this._filter = {};
    this._sort = DEFAULT_SORT;
  }

  @action load: LoadReport = async params => {
    this._loading = true;

    let reports: Report[] = [];

    const { _limit, _offset, _filter, _sort } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;
    const sort = (params && params.sort) || _sort;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;
    this._sort = sort;

    await this._services.opencity.requests
      .reportIndex({ params: { filter, limit, offset, sort } })
      .then(({ data: { result } }: AxiosResponse<ReportIssueIndex>) => {
        if (result) {
          const { items, total } = result;
          if (Array.isArray(items)) {
            reports = items.map(value => new Report(value));

            this._reports = reports;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return reports;
  };

  @action createAccountReport: CreateAccountReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, filter } = params;
    await this._services.opencity.requests
      .reportAccountCreate({
        params: {
          title,
          filter,
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage('Отчёт поставлен в очередь', 'success');
        }

        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createCounterReport: CreateCounterReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, config } = params;
    await this._services.opencity.requests
      .reportCounterCreate({ params: { title, config } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createOperatorReport: CreateOperatorReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, config } = params;
    await this._services.opencity.requests
      .reportIssueOperatorCreate({ params: { title, config } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createInterruptReport: CreateInterruptReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, filter } = params;

    await this._services.opencity.requests
      .reportInterruptCreate({ params: { title, filter } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createExtendedInterruptReport: CreateInterruptReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, filter } = params;

    await this._services.opencity.requests
      .reportInterruptExtendedCreate({ params: { title, filter } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createInterruptReportSender: CreateInterruptReportSender = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, config, filter } = params;

    await this._services.opencity.requests
      .reportInterruptSenderCreate({ params: { title, config, filter } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createInterruptReportControl: CreateInterruptReportSender = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, config, filter } = params;

    await this._services.opencity.requests
      .reportInterruptControlCreate({ params: { title, config, filter } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action createIssueReport: CreateIssueReport = async params => {
    let report: Report | null = null;
    this._loading = true;

    const { title, config, filter } = params;
    await this._services.opencity.requests
      .reportIssueCreate({ params: { title, config, filter } })
      .then(({ data: { result, error } }: AxiosResponse<ReportIssueOperatorCreate>) => {
        if (result) {
          report = result;

          this._relations.snackbarStore.setMessage(`Отчёт поставлен в очередь`, 'success');
        }
        if (error) {
          this._relations.snackbarStore.setMessage(
            typeof error.data === 'string' ? error.data : `Ошибка формирования отчёта`,
            'error',
          );
        }
      })
      .finally(this._endLoading);

    return report;
  };

  @action public cleanUp = (): void => {
    this._total = 0;
    this._offset = 0;
    this._reports = [];
    this._filter = {};
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get reports(): Report[] {
    return this._reports;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get limit(): number {
    return this._limit;
  }
}

export default ReportStore;
