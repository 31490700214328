import { useEffect } from 'react';

import config from '../constants/config';

export const useYandexMetrika = (): void => {
  useEffect(() => {
    const { ym } = window;

    if (process.env.NODE_ENV === 'production' && ym && config.yandexMetrikaKey) {
      ym(Number(config.yandexMetrikaKey), 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    }
  }, []);
};
