/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSnackbarStore = () => {
  const { snackbarStore } = useStores();

  return useObserver(() => ({
    cleanUpSnackbarStore: snackbarStore.cleanUp,
    setMessage: snackbarStore.setMessage,
  }));
};

export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    isAllowed: operationStore.isAllowed,
    list: operationStore.list,
  }));
};
