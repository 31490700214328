import config from '@constants/config';

export const GOOGLE_PLAY = config.googlePlayLink;

export const GOOGLE_PLAY_FROM_PUBLIC = `${GOOGLE_PLAY}&referrer=utm_source%3Dpublicpage%26utm_medium%3Dlandingpage`;

export const GOOGLE_PLAY_SMART_BANNER = `${GOOGLE_PLAY}&referrer=utm_source%3Dsmartappbanner%26utm_medium%3Dsmartappbannerandroid`;

export const APP_STORE =
  'https://apps.apple.com/us/app/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D0%B0%D0%B1%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%B0/id1580720823';

export const APP_GALLERY = 'https://appgallery.huawei.com/app/C108556123';
