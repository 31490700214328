import React, { FC, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Theme,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { useHistory } from 'react-router-dom';
import { ROOT, SIGN_IN } from '@public/constants/routes';

const textColor = '#07003D';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 755,
    color: textColor,
  },

  dialogTitle: {
    paddingRight: 10,
  },

  dialogContent: {
    flexGrow: 0,
    paddingTop: 0,
  },

  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },

  text: {
    color: textColor,
    opacity: 0.5,
    '& + &': {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px !important',
    },
  },

  button: {
    padding: `6px ${theme.spacing(8)}px`,
    '@media (max-width: 640px)': {
      padding: `6px ${theme.spacing(6)}px`,
    },
  },

  closeButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },

  actionsWrapper: {
    padding: theme.spacing(6, 6, 8, 6),
  },

  actions: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CodeConfirmed: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSignIn = useCallback(() => {
    history.push(SIGN_IN);
    onClose();
  }, [history, onClose]);

  const handleLanding = useCallback(() => {
    history.push(ROOT);
    onClose();
  }, [history, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isXs}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
          <Grid item className={classes.title}>
            Вы успешно подтвердили email!
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Typography variant="body2" className={classes.text}>
          Спасибо, что подтвердили свой email! Чтобы воспользоваться функционалом личного кабинета,
          необходимо авторизоваться.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>
        <Grid container className={classes.actions}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleLanding}
            className={cn(classes.button, classes.closeButton)}
          >
            На главную
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSignIn}
            className={classes.button}
          >
            Вход
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
