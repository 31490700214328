import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { User, DeleteUser } from '@core/entities/Opencity/User';
import { CreateBillingUser } from '@core/entities/Billing/User';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';

type UserDeleteResponse = JrpcResponse<number>;
type CreateUserResponse = JrpcResponse<boolean>;
class UserStore extends Store implements Loading, Entity<User, {}> {
  @observable private _users: User[];
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._loading = false;
    this._users = [];
  }

  @action public userCreate: CreateBillingUser = async ({
    accountNumber,
    email,
    organizationId,
    organizationName,
    providerId,
    sourceId,
  }) => {
    this._loading = true;
    let creationResult = false;
    let resultError = '';

    await this._services.billing.requests
      .userCreate({
        params: {
          data: {
            accountNumber,
            email,
            organizationId,
            organizationName,
            providerId,
            sourceId,
          },
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<CreateUserResponse>): void => {
        if (result) {
          creationResult = result;
        }
        if (error) {
          creationResult = false;
          if (error.code && error.code === 900200) {
            resultError = 'ALREADY_EXIST';
          } else {
            resultError = error.message || '';
          }
        }
      })
      .finally(this._endLoading);

    return [creationResult, resultError];
  };

  @action public delete: DeleteUser = async ({ filter }) => {
    this._loading = true;

    let isDeleted = 0;

    await this._services.opencity.requests
      .userDelete({ params: { filter } })
      .then(({ data: { result } }: AxiosResponse<UserDeleteResponse>): void => {
        if (typeof result === 'number') {
          isDeleted = result;
        }
      })
      .finally(this._endLoading);

    return isDeleted;
  };

  @computed public get list(): User[] {
    return toJS<User[]>(this._users);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default UserStore;
