import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  ClassifierTag,
  ClassifierTagInterface,
  ClassifierTagLoad,
} from '@core/entities/Opencity/ClassifierTag';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';

type ReferenceClassifierTrackerIndex = JrpcResponse<{
  items: ClassifierTagInterface[];
  total: number;
}>;

class ClassifierTrackerStore extends Store
  implements Loading, Pagination, Entity<ClassifierTag, { limit?: number; offset?: number }> {
  @observable private _loading: boolean;
  @observable private _classifierTags: ClassifierTag[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._classifierTags = [];
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action load: ClassifierTagLoad = async params => {
    this._loading = true;

    const classifierTags: ClassifierTag[] = [];

    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .referenceClassifierTagIndex({ params: { limit, offset } })
      .then(({ data: { result } }: AxiosResponse<ReferenceClassifierTrackerIndex>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            this._classifierTags = items.map<ClassifierTag>(value => new ClassifierTag(value));
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return classifierTags;
  };

  @action setLimit: SetLimit = value => {
    this._limit = value;
  };

  @action setOffset: SetOffset = value => {
    this._offset = value;
  };

  @computed public get list(): ClassifierTag[] {
    return toJS(this._classifierTags);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default ClassifierTrackerStore;
