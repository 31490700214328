import Store from './Store';
import { action, computed, observable, toJS } from 'mobx';

import Services from '@core/services';
import {
  InformationMessages,
  InformationMessagesInterface,
} from '@core/entities/Billing/InformationMessages';

class InformationMessagesStore extends Store {
  @observable private _loading: boolean;
  @observable private _informationMessages: InformationMessages;

  public constructor(services: Services) {
    super(services);

    this._loading = false;

    this._informationMessages = {
      items: [
        {
          providerId: 0,
          announcements: [],
        },
      ],
      count: 0,
    };
  }

  @action public loadInformationMessages = async (params: any): Promise<InformationMessages> => {
    this._loading = true;

    let informationMessages: InformationMessagesInterface = {
      items: [
        {
          providerId: 0,
          announcements: [],
        },
      ],
      count: 0,
    };

    await this._services.billing.requests
      .announcementsIndex({
        params: {
          filter: {
            providerId: params.providerId,
            accountNumber: params.accountNumber,
          },
        },
      })
      .then(({ data: { result } }: any) => {
        if (result) {
          informationMessages = new InformationMessages(result);

          this._informationMessages = informationMessages;
        } else {
          this._informationMessages = new InformationMessages(informationMessages);
        }
      })
      .finally(() => (this._loading = false));

    return informationMessages;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get informationMessages(): InformationMessages {
    return this._informationMessages;
  }
}

export default InformationMessagesStore;
