import React, { FC } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import CloseIcon from '@material-ui/icons/Close';

import { SnackbarTypes } from '@stores/SnackbarStore';
import config from '@core/constants/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: '#FFFFFF',
    },
    snackbarError: {
      backgroundColor: `${red[300]} !important`,
    },
    snackbarMessage: {
      fontWeight: 400,
    },
    snackbarWarning: {
      backgroundColor: '#f0ad4e !important',
    },
    snackbarRoot: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexWrap: 'nowrap',
    },
  }),
);

interface SnackbarProps {
  open: boolean;
  message?: string | null;
  type?: SnackbarTypes;
  onClose?: () => void;
}

const Snackbar: FC<SnackbarProps> = ({ open, message, type, onClose }) => {
  const classes = useStyles();

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={config.messageCloseTime}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={onClose}
    >
      <SnackbarContent
        classes={{
          root: classNames(classes.snackbarRoot, {
            [classes.snackbarError]: type === 'error',
            [classes.snackbarWarning]: type === 'warning',
          }),
        }}
        message={
          <Typography className={classes.snackbarMessage} variant="body1">
            {message}
          </Typography>
        }
        action={
          <>
            <IconButton onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </>
        }
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
