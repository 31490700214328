import { Load } from '@stores/interfaces/Entity';
import { FilterOperators } from '@stores/interfaces/Filter';
import SortDirections from '@constants/sort';

export interface ClassifierTypeInterface {
  id: number;
  name?: string | null;
  type?: string | null;
  categoryId?: number | null;
  categoryName?: string | null;
  trackerId?: number | null;
  trackerName?: string | null;
  shortName?: string | null;
}

export class ClassifierType {
  public id: number;
  public name?: string | null;
  public type?: string | null;
  public categoryId?: number | null;
  public categoryName?: string | null;
  public trackerId?: number | null;
  public trackerName?: string | null;
  public shortName?: string | null;

  public constructor(value: ClassifierTypeInterface) {
    this.id = value.id;
    this.name = value.name;
    this.type = value.type;
    this.categoryId = value.categoryId;
    this.categoryName = value.categoryName;
    this.trackerId = value.trackerId;
    this.trackerName = value.trackerName;
    this.shortName = value.shortName;
  }
}

export enum ClassifierTypeKeys {
  TRACKER_ID = 'trackerId',
  PARENT_ID = 'parentId',
  TAG_ID = 'tagId',
  TYPE = 'type',
}

export interface ClassifierTypeFilterProps {
  [ClassifierTypeKeys.TRACKER_ID]?: FilterOperators<number>;
  [ClassifierTypeKeys.PARENT_ID]?: FilterOperators<number>;
  [ClassifierTypeKeys.TAG_ID]?: FilterOperators<number>;
}

export interface ClassifierTypeSortProps {
  field: ClassifierTypeKeys;
  desc: SortDirections;
}

export type ClassifierTypeLoad = Load<
  {
    filter?: ClassifierTypeFilterProps;
    limit?: number;
    offset?: number;
    sort?: ClassifierTypeSortProps[];
  },
  ClassifierType[]
>;
