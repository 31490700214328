import React, { FC, useCallback, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Tooltip as BaseTooltip,
  TooltipProps,
} from '@material-ui/core';

import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    children: {
      display: 'flex',
      cursor: 'pointer',
    },
    title: {
      fontSize: 13,
    },
    tooltip: {
      padding: theme.spacing(4),
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    arrow: {
      color: 'rgba(0, 0, 0, 0.8)',
      fontSize: 20,
    },
    hidden: {
      visibility: 'hidden',
    },

    smallTooltip: {
      padding: 8,
    },
  }),
);

interface Props extends TooltipProps {
  smallSize?: boolean;
}

export const Tooltip: FC<Props> = ({
  smallSize = false,
  hidden = false,
  title,
  children,
  ...props
}) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, []);

  return (
    <div onMouseLeave={handleClose}>
      <BaseTooltip
        open={isOpen}
        title={<div className={classes.title}>{title}</div>}
        arrow
        {...props}
        classes={{
          tooltip: cn(
            classes.tooltip,
            { [classes.hidden]: hidden },
            smallSize && classes.smallTooltip,
          ),
          arrow: classes.arrow,
        }}
      >
        <div onMouseEnter={handleOpen} onClick={handleOpen} className={classes.children}>
          {children}
        </div>
      </BaseTooltip>
    </div>
  );
};
