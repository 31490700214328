import { Delete } from '@stores/interfaces/Entity';
import { FilterOperators } from '@stores/interfaces/Filter';

export interface UserInterfacee {
  id: number;
  login: string;
  profileId: number;
  createdAt: number;
  organizationId: number;
  authId: string;
  isEmailConfirmed?: number;
  socialProvider?: string;
}

export class User {
  public id: number;
  public login: string;
  public profileId: number;
  public createdAt: number;
  public organizationId: number;
  public authId: string;
  public isEmailConfirmed?: number;
  public socialProvider?: string;

  public constructor(value: UserInterfacee) {
    this.id = value.id;
    this.login = value.login;
    this.profileId = value.profileId;
    this.createdAt = value.createdAt;
    this.organizationId = value.organizationId;
    this.authId = value.authId;
    this.isEmailConfirmed = value.isEmailConfirmed;
    this.socialProvider = value.socialProvider;
  }
}

export enum UserKeys {
  ID = 'id',
}

export interface UserFilterProps {
  [UserKeys.ID]?: FilterOperators<number>;
}

export type DeleteUser = Delete<{ filter: UserFilterProps }, Promise<number>>;
