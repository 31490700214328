import { FilterOperators } from '@core/stores/interfaces/Filter';
import { OrderInterface, OrderType } from '@core/entities/Opencity/Order';

export enum ClaimsStatisticsKeys {
  TRACKER_ID = 'trackerId',
  TYPE = 'type',
  CONTROL_ID = 'controlId',
  TIMESTAMP = 'timestamp',
  COUNT_OF_GRAPHICS = 'countOfGraphics',
}

export const EventMapOrderStateAll = [
  { name: 'Новые', key: 'new' },
  { name: 'Назначенные', key: 'to provider' },
  { name: 'В работе', key: 'in work' },
  { name: 'Выполненные', key: 'done' },
  { name: 'Подтвержденные', key: 'ok' },
  { name: 'Закрытые', key: 'closed' },
  { name: 'Отложенные', key: 'delayed' },
  { name: 'Удаленные', key: 'deleted' },
  { name: 'Неподтвержденные', key: 'unconfirmed' },
  { name: 'Восстановленные', key: 'restored' },
  { name: 'К выполнению', key: 'to execute' },
  { name: 'Закрыты жителем', key: 'closed by citizen' },
  { name: 'Переданы коммунальным службам', key: 'closed by communal' },
  { name: 'Закрыты аналитиком', key: 'closed by analyst' },
  { name: 'Закрытые автоматически', key: 'closed auto' },
  { name: 'Закрыты по нормативу', key: 'closed by std' },
  { name: 'Отказано', key: 'not accepted' },
  { name: 'Возвращенные', key: 'returned' },
  { name: 'Отклоненные', key: 'rejected' },
];

// toGeneralProvider: { singular: 'Назначена', plural: 'Назначена', key: 'to general provider' },

export interface ClaimsStatisticsFilterProps {
  [ClaimsStatisticsKeys.TRACKER_ID]?: FilterOperators<number>;
  [ClaimsStatisticsKeys.TYPE]?: FilterOperators<string>;
  [ClaimsStatisticsKeys.CONTROL_ID]?: FilterOperators<number>;
  [ClaimsStatisticsKeys.TIMESTAMP]?: FilterOperators<number>;
  [ClaimsStatisticsKeys.COUNT_OF_GRAPHICS]?: FilterOperators<number>;
}

export interface TopCategories {
  categoryId: number[];
  title: string;
  count: number;
  percent: number;
}

export interface IncomingData {
  id: number | null | undefined;
  type: OrderType | null | undefined;
  streetName: string | null | undefined;
  houseNumber: string | null | undefined;
  flatNumber: string | null | undefined;
  serviceName: string | null | undefined;
  buildingNumber: string | null | undefined;
}
export interface DataGroupedByLatLng {
  latitude: string;
  longitude: string;
  claims: IncomingData[];
}

export interface DataGroupedByServiceName {
  serviceName: string;
  claims: IncomingData[];
}

export interface DataGroupedFinally {
  latitude: string;
  longitude: string;
  claims: DataGroupedByServiceName[];
}

export interface Outage {
  id: number;
  reason?: string | null;
  dateStart?: number | null;
  dateEnd?: number | null;
  sendDate?: number | null;
  body?: string | null;
  sender?: string | null;
  type?: string | null;
  status?: string | null;
  reasonId?: number | null;
  senderId?: number | null;
  typeId?: number | null;
}
export interface OutageDataGroupedFinally {
  lat: string;
  lng: string;
  outagesHouses: {
    houseId: number;
    flatId: number;
    streetName: string;
    houseNumber: string;
    buildingNumber: string;
    outages: Outage[];
  }[];
}

export interface ClaimsStatisticsInterface {
  allCount?: number | null;
  newAllPercent?: number | null;
  successCount?: number | null;
  successPercent?: number | null;
  delayCount?: number | null;
  problemCount?: number | null;
  newProblemPercent?: number | null;
  currentPercent?: number | null;
  emergencyPercent?: number | null;
  topCategories?: TopCategories[] | null;
  id?: string | null;
}

export interface TopFiveHouseInterface {
  fullName?: string;
  houseId?: number;
  allCount?: number;
  successCount?: number;
  successPercent?: number;
  controlId?: number;
  controlName?: string;
}

export interface TopFiveControlInterface {
  controlName?: string;
  controlId?: number;
  allCount?: number;
  newAllPercent?: number;
  successCount?: number;
  successPercent?: number;
}

export class ClaimsStatistics implements ClaimsStatisticsInterface {
  allCount?: number | null;
  newAllPercent?: number | null;
  successCount?: number | null;
  successPercent?: number | null;
  delayCount?: number | null;
  problemCount?: number | null;
  newProblemPercent?: number | null;
  currentPercent?: number | null;
  emergencyPercent?: number | null;
  topCategories?: TopCategories[] | null;
  id?: string | null;

  public constructor(value: ClaimsStatisticsInterface) {
    this.allCount = value.allCount;
    this.newAllPercent = value.newAllPercent;
    this.successCount = value.successCount;
    this.successPercent = value.successPercent;
    this.delayCount = value.delayCount;
    this.problemCount = value.problemCount;
    this.newProblemPercent = value.newProblemPercent;
    this.currentPercent = value.currentPercent;
    this.emergencyPercent = value.emergencyPercent;
    this.topCategories = value.topCategories;
    this.id = value.id;
  }
}

export interface TopFiveHouseListInterface {
  top?: TopFiveHouseInterface[];
}

export interface TopFiveControlListInterface {
  top?: TopFiveControlInterface[];
}
export interface ClaimsDynamicsInterface {
  data: { count: number; date: string }[][] | null;
}

export interface EventMapClaimsListInterface {
  data: Partial<OrderInterface>[] | null;
}

export class ClaimsDynamics implements ClaimsDynamicsInterface {
  public constructor(value: ClaimsDynamicsInterface) {
    this.data = value.data;
  }
  data: { count: number; date: string }[][] | null;
}

export class EventMapClaimsList implements EventMapClaimsListInterface {
  public constructor(value: EventMapClaimsListInterface) {
    this.data = value.data;
  }
  data: Partial<OrderInterface>[] | null;
}

export class TopFiveHouseList implements TopFiveHouseListInterface {
  public constructor(value: TopFiveHouseListInterface) {
    this.top = value.top;
  }
  top?: TopFiveHouseInterface[];
}

export class TopFiveControlList implements TopFiveControlListInterface {
  public constructor(value: TopFiveControlListInterface) {
    this.top = value.top;
  }
  top?: TopFiveControlInterface[];
}
