const DEFAULT_EMAIL_REGEXP = '^([A-Za-z0-9_-.])+@([A-Za-z0-9_-.])+.([A-Za-z]{2,4})$';

const REACT_APP_EMAIL_VALIDATION_PATTERN =
  process.env.REACT_APP_EMAIL_VALIDATION_PATTERN || DEFAULT_EMAIL_REGEXP;

export const EMAIL_REGEXP = new RegExp(REACT_APP_EMAIL_VALIDATION_PATTERN);

export const PASSWORD_REGEXP = new RegExp(
  /((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()?~=_\/\|+-]))/,
);
