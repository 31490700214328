import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import { OrganizationRoles } from '@core/entities/Opencity/Organization';
import {
  Rating,
  RatingInterface,
  RatingFilterProps,
  RatingSortProps,
  RatingKeys,
  RatingSortKeys,
  CountRating,
  LoadRating,
} from '@core/entities/Opencity/Rating';
import SortDirections from '@constants/sort';
import Store from './Store';
import { Pagination } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { Sort } from './interfaces/Sort';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';

type OrganizationRatingIndexResponse = JrpcResponse<{ items: RatingInterface[]; total: number }>;
type OrganizationCountResponse = JrpcResponse<number>;

const DEFAULT_FILTER: RatingFilterProps = {
  endDate: new Date().setHours(0, 0, 0, 0) / 1000,
  role: OrganizationRoles.control,
  startDate: new Date().setHours(0, 0, 0, 0) / 1000 - 30 * 3600 * 24,
};

const DEFAULT_SORT: RatingSortProps[] = [
  { field: RatingSortKeys.EFFICIENCY, desc: SortDirections.DESC },
];

class RatingsStore extends Store
  implements
    Pagination,
    Filter<RatingFilterProps, RatingKeys>,
    Sort<RatingSortProps>,
    Loading,
    Entity<
      Rating,
      { filter?: RatingFilterProps; sort?: RatingSortProps[]; limit?: number; offset?: number }
    > {
  @observable private _ratings: Rating[];
  @observable private _offset: number;
  @observable private _limit: number;
  @observable private _filter: RatingFilterProps;
  @observable private _sort: RatingSortProps[];
  @observable private _total: number;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._ratings = [];
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._loading = false;
    this._filter = DEFAULT_FILTER;
    this._sort = DEFAULT_SORT;
  }

  @action public load: LoadRating = async params => {
    let ratings: Rating[] = [];

    this._loading = true;

    const { _filter, _offset, _limit, _sort } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const sort = (params && params.sort) || _sort;
    const limit = params && typeof params.limit === 'number' ? params.limit : _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;

    this._filter = filter;
    this._sort = sort;
    this._limit = limit;
    this._offset = offset;

    await this._services.opencity.requests
      .organizationRatingIndex({
        params: {
          filter,
          limit,
          offset,
          sort,
        },
      })
      .then(({ data: { result } }: AxiosResponse<OrganizationRatingIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            ratings = items.map(value => new Rating(value));

            this._ratings = ratings;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return ratings;
  };

  @action public count: CountRating = async params => {
    this._loading = true;

    let total = 0;

    const filter = (params && params.filter) || toJS(this._filter);

    this._filter = filter;

    await this._services.opencity.requests
      .organizationCount({
        params: { filter: { role: filter[RatingKeys.ROLE] } },
      })
      .then(({ data: { result } }: AxiosResponse<OrganizationCountResponse>) => {
        if (result) {
          total = result;

          this._total = total;
        }
      })
      .finally(this._endLoading);

    return total;
  };

  @action public cleanUp = (): void => {
    this._ratings = [];
    this._offset = 0;
    this._limit = 20;
    this._total = 0;
    this._loading = false;
    this._filter = DEFAULT_FILTER;
    this._sort = DEFAULT_SORT;
  };

  @computed public get list(): Rating[] {
    return toJS(this._ratings);
  }

  @computed public get filter(): RatingFilterProps {
    return toJS(this._filter);
  }

  @computed public get sort(): RatingSortProps[] {
    return this._sort;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default RatingsStore;
