import React, { FC, SVGProps } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ClassValue } from 'classnames/types';

import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as Shutdowns } from './shutdowns.svg';
import { ReactComponent as MeterIcon } from './meter.svg';
import { ReactComponent as OrderIcon } from './order.svg';
import { ReactComponent as OrganizationIcon } from './organization.svg';
import { ReactComponent as SecurityCameraIcon } from './security-camera.svg';
import { ReactComponent as QuestionIcon } from './question.svg';
import { ReactComponent as PaymentIcon } from './pay.svg';
import { ReactComponent as CalculationsIcon } from './calculations.svg';
import { ReactComponent as PaymentsIcon } from './payments.svg';
import { ReactComponent as ReportIcon } from './report.svg';
import { ReactComponent as AddressIcon } from './address.svg';
import { ReactComponent as PinIcon } from './pin.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as OwnerEmailIcon } from './owner_email.svg';
import { ReactComponent as CounterIcon } from './counter.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import { ReactComponent as TelegramIcon } from './telegram.svg';
import { ReactComponent as QuestionnaireIcon } from './questionnaire.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as ReceiptIcon } from './receipt.svg';
import { ReactComponent as DetailingIcon } from './detailing.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Cards } from './guy_with_cards.svg';
import { ReactComponent as UnavailablePayment } from './unavailable_payment.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Exit } from './exit.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as PrepaymentCalendar } from './prepayment_calendar.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Document } from './document.svg';
import { ReactComponent as Details } from './details.svg';
import { ReactComponent as Statistics } from './statistics.svg';
import { ReactComponent as SmallInfo } from './smallinfo.svg';
import { ReactComponent as LegendBlue } from './legendblue.svg';
import { ReactComponent as LegendOrange } from './legendorange.svg';
import { ReactComponent as MarkerBlue } from './blueMarker.svg';
import { ReactComponent as MarkerOrange } from './orangeMarker.svg';
import { ReactComponent as MarkerRed } from './redMarker.svg';
import { ReactComponent as MarkerGreen } from './greenMarker.svg';
import { ReactComponent as MarkerYellow } from './yellowMarker.svg';
import { ReactComponent as MarkerGray } from './grayMarker.svg';
import { ReactComponent as SmallCalendar } from './smallcalendar.svg';
import { ReactComponent as Applicant } from './applicantIcon.svg';
import { ReactComponent as ClaimType } from './claimTypeIcon.svg';
import { ReactComponent as CreateDate } from './createDateIcon.svg';
import { ReactComponent as MapIcon } from './mapIcon.svg';
import { ReactComponent as NormDate } from './normDateIcon.svg';
import { ReactComponent as PersonalAccount } from './personalAccountIcon.svg';
import { ReactComponent as PhoneIcon } from './phoneIcon.svg';
import { ReactComponent as UkIcon } from './ukIcon.svg';
import { ReactComponent as Payer } from './payer.svg';
import { ReactComponent as AudioPlayerPlay } from './audioPlayerPlay.svg';
import { ReactComponent as AudioPlayerPause } from './audioPlayerPause.svg';
import { ReactComponent as GreenArrow } from './arrowgreen.svg';
import { ReactComponent as RedArrow } from './arrowred.svg';
import { ReactComponent as GreyArrow } from './arrowgrey.svg';
import { ReactComponent as SocialFace } from './socialFace.svg';
import { ReactComponent as SocialSendTo } from './socialSendTo.svg';
import { ReactComponent as SocialTelegram } from './socialTelegram.svg';
import { ReactComponent as SocialModalVK } from './socialModalVK.svg';
import { ReactComponent as SocialModalWhatsApp } from './socialModalWhatsApp.svg';
import { ReactComponent as SocialModalTelegram } from './socialModalTelegram.svg';
import { ReactComponent as SocialModalChain } from './socialModalChain.svg';
import { ReactComponent as GoToCamerasPage } from './goToCamerasPage.svg';
import { ReactComponent as GoToClaimsMap } from './goToClaimsMap.svg';
import { ReactComponent as SmellyCat } from './SmellyCat.svg';
import { ReactComponent as BoxCat } from './BoxCat.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      '& > path, g, g > path': {
        fill: `${theme.palette.primary.main} !important`,
      },

      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  }),
);

export type IconTypes =
  | 'add'
  | 'shutdowns'
  | 'meter'
  | 'order'
  | 'organization'
  | 'payment'
  | 'calculations'
  | 'payments'
  | 'securityCamera'
  | 'question'
  | 'report'
  | 'address'
  | 'pin'
  | 'email'
  | 'owner_email'
  | 'counter'
  | 'calendar'
  | 'telegram'
  | 'questionnaire'
  | 'search'
  | 'receipt'
  | 'detailing'
  | 'home'
  | 'house'
  | 'checked'
  | 'guy_with_cards'
  | 'unavailable_payment'
  | 'info'
  | 'exit'
  | 'success'
  | 'support'
  | 'prepaymentCalendar'
  | 'message'
  | 'download'
  | 'document'
  | 'details'
  | 'message'
  | 'statistics'
  | 'smallinfo'
  | 'arrowgreen'
  | 'arrowred'
  | 'arrowgrey'
  | 'legendblue'
  | 'legendorange'
  | 'markerblue'
  | 'markerorange'
  | 'markerred'
  | 'markergreen'
  | 'markeryellow'
  | 'markergray'
  | 'smallcalendar'
  | 'applicant'
  | 'claimType'
  | 'createDate'
  | 'mapIcon'
  | 'normDate'
  | 'personalAccount'
  | 'phoneIcon'
  | 'ukIcon'
  | 'payer'
  | 'audioPlayerPlay'
  | 'audioPlayerPause'
  | 'socialFace'
  | 'socialSendTo'
  | 'socialTelegram'
  | 'socialModalVK'
  | 'socialModalWhatsApp'
  | 'socialModalTelegram'
  | 'socialModalChain'
  | 'goToCamerasPage'
  | 'goToClaimsMap'
  | 'smellyCat'
  | 'boxCat';

export interface IconProps extends Omit<SVGProps<SVGSVGElement>, 'className'> {
  className?: ClassValue;
  icon: IconTypes;
}

const Icon: FC<IconProps> = ({ className, icon }) => {
  const classes = useStyles();

  switch (icon) {
    case 'add': {
      return <AddIcon className={classNames(classes.icon, className)} />;
    }

    case 'shutdowns': {
      return <Shutdowns className={classNames(classes.icon, className)} />;
    }

    case 'meter': {
      return <MeterIcon className={classNames(classes.icon, className)} />;
    }

    case 'order': {
      return <OrderIcon className={classNames(classes.icon, className)} />;
    }

    case 'organization': {
      return <OrganizationIcon className={classNames(classes.icon, className)} />;
    }

    case 'securityCamera': {
      return <SecurityCameraIcon className={classNames(classes.icon, className)} />;
    }

    case 'payment': {
      return <PaymentIcon className={classNames(classes.icon, className)} />;
    }

    case 'calculations': {
      return <CalculationsIcon className={classNames(classes.icon, className)} />;
    }

    case 'payments': {
      return <PaymentsIcon className={classNames(classes.icon, className)} />;
    }

    case 'question': {
      return <QuestionIcon className={classNames(classes.icon, className)} />;
    }

    case 'report': {
      return <ReportIcon className={classNames(classes.icon, className)} />;
    }

    case 'address': {
      return <AddressIcon className={classNames(classes.icon, className)} />;
    }

    case 'pin': {
      return <PinIcon className={classNames(classes.icon, className)} />;
    }

    case 'email': {
      return <EmailIcon className={classNames(classes.icon, className)} />;
    }

    case 'owner_email': {
      return <OwnerEmailIcon className={classNames(classes.icon, className)} />;
    }

    case 'counter': {
      return <CounterIcon className={classNames(classes.icon, className)} />;
    }

    case 'calendar': {
      return <CalendarIcon className={classNames(classes.icon, className)} />;
    }

    case 'telegram': {
      return <TelegramIcon className={classNames(classes.icon, className)} />;
    }

    case 'questionnaire': {
      return <QuestionnaireIcon className={classNames(classes.icon, className)} />;
    }

    case 'receipt': {
      return <ReceiptIcon className={classNames(className)} />;
    }

    case 'detailing': {
      return <DetailingIcon className={classNames(className)} />;
    }

    case 'home': {
      return <Home className={classNames(classes.icon, className)} />;
    }

    case 'house': {
      return <House className={classNames(classes.icon, className)} />;
    }

    case 'search': {
      return <Search className={classNames(classes.icon, className)} />;
    }

    case 'checked': {
      return <Checked className={classNames(classes.icon, className)} />;
    }

    case 'guy_with_cards': {
      return <Cards className={classNames(className)} />;
    }

    case 'unavailable_payment': {
      return <UnavailablePayment className={classNames(className)} />;
    }

    case 'info': {
      return <Info className={classNames(classes.icon, className)} />;
    }

    case 'exit': {
      return <Exit className={classNames(classes.icon, className)} />;
    }

    case 'success': {
      return <Success className={classNames(classes.icon, className)} />;
    }

    case 'support': {
      return <Support className={classNames(classes.icon, className)} />;
    }

    case 'prepaymentCalendar': {
      return <PrepaymentCalendar className={classNames(classes.icon, className)} />;
    }

    case 'message': {
      return <Message className={classNames(classes.icon, className)} />;
    }

    case 'download': {
      return <Download className={classNames(classes.icon, className)} />;
    }

    case 'document': {
      return <Document className={classNames(classes.icon, className)} />;
    }

    case 'details': {
      return <Details className={classNames(classes.icon, className)} />;
    }

    case 'message': {
      return <Document className={classNames(classes.icon, className)} />;
    }

    case 'statistics': {
      return <Statistics className={classNames(classes.icon, className)} />;
    }

    case 'smallinfo': {
      return <SmallInfo className={classNames(className)} />;
    }

    case 'arrowgreen': {
      return <GreenArrow className={classNames(className)} />;
    }

    case 'arrowred': {
      return <RedArrow className={classNames(className)} />;
    }

    case 'arrowgrey': {
      return <GreyArrow className={classNames(className)} />;
    }

    case 'legendblue': {
      return <LegendBlue className={classNames(className)} />;
    }

    case 'legendorange': {
      return <LegendOrange className={classNames(className)} />;
    }

    case 'markerblue': {
      return <MarkerBlue className={classNames(className)} />;
    }

    case 'markerorange': {
      return <MarkerOrange className={classNames(className)} />;
    }

    case 'markerred': {
      return <MarkerRed className={classNames(className)} />;
    }

    case 'markergreen': {
      return <MarkerGreen className={classNames(className)} />;
    }

    case 'markeryellow': {
      return <MarkerYellow className={classNames(className)} />;
    }

    case 'markergray': {
      return <MarkerGray className={classNames(className)} />;
    }

    case 'smallcalendar': {
      return <SmallCalendar className={classNames(className)} />;
    }

    case 'applicant': {
      return <Applicant className={classNames(className)} />;
    }

    case 'claimType': {
      return <ClaimType className={classNames(className)} />;
    }

    case 'createDate': {
      return <CreateDate className={classNames(className)} />;
    }

    case 'mapIcon': {
      return <MapIcon className={classNames(className)} />;
    }

    case 'normDate': {
      return <NormDate className={classNames(className)} />;
    }

    case 'personalAccount': {
      return <PersonalAccount className={classNames(className)} />;
    }

    case 'phoneIcon': {
      return <PhoneIcon className={classNames(className)} />;
    }

    case 'ukIcon': {
      return <UkIcon className={classNames(className)} />;
    }

    case 'payer': {
      return <Payer className={classNames(className)} />;
    }

    case 'audioPlayerPlay': {
      return <AudioPlayerPlay className={classNames(className)} />;
    }

    case 'audioPlayerPause': {
      return <AudioPlayerPause className={classNames(className)} />;
    }

    case 'socialFace': {
      return <SocialFace className={classNames(className)} />;
    }

    case 'socialSendTo': {
      return <SocialSendTo className={classNames(className)} />;
    }

    case 'socialTelegram': {
      return <SocialTelegram className={classNames(className)} />;
    }

    case 'socialModalVK': {
      return <SocialModalVK className={classNames(className)} />;
    }

    case 'socialModalWhatsApp': {
      return <SocialModalWhatsApp className={classNames(className)} />;
    }

    case 'socialModalTelegram': {
      return <SocialModalTelegram className={classNames(className)} />;
    }

    case 'socialModalChain': {
      return <SocialModalChain className={classNames(className)} />;
    }

    case 'goToCamerasPage': {
      return <GoToCamerasPage className={classNames(className)} />;
    }

    case 'goToClaimsMap': {
      return <GoToClaimsMap className={classNames(className)} />;
    }

    case 'smellyCat': {
      return <SmellyCat className={classNames(className)} />;
    }

    case 'boxCat': {
      return <BoxCat className={classNames(className)} />;
    }

    default: {
      return null;
    }
  }
};

export default Icon;
