/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import { AxiosPromise, CancelToken } from 'axios';

import HttpClient from '../httpClient';
import { JrpcConfig, JrpcRequest } from '../jrpc';
import { Headers } from '../headers';

const request = (entryPoint: string, jrpcConfig: JrpcConfig): any =>
  function(this: HttpClient<any>, config: JrpcRequest) {
    return (params?: any, headers?: Headers): AxiosPromise<any> => {
      const { client } = this;

      let requestConfig: JrpcRequest = { ...jrpcConfig, ...config, id: uuidv4() };
      const cancelConfig: { cancelToken?: CancelToken } = {};

      if (params) {
        requestConfig = { ...requestConfig, ...params };
      }

      if (config.cancelTokenHandlerObject) {
        cancelConfig.cancelToken = config.cancelTokenHandlerObject[
          requestConfig.method
        ].handleRequestCancellation().token;
      }

      if (headers) {
        this.setHeaders(headers);
      }

      return client.post(entryPoint, requestConfig, {
        ...(cancelConfig.cancelToken ? { ...cancelConfig } : {}),
      });
    };
  };

export default request;
