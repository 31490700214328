import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  District,
  DistrictInterface,
  LoadDistrict,
  CountDistrict,
  DistrictFilterProps,
  DistrictKeys,
  ClearDistrictFilter,
} from '@core/entities/Opencity/District';
import Store from './Store';
import { Loading, endLoading, SetLoading } from './interfaces/Loading';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Entity } from './interfaces/Entity';
import { Filter } from './interfaces/Filter';

type GisStreetIndexResponse = JrpcResponse<{ items: DistrictInterface[]; total: number }>;
type GisStreetCountResponse = JrpcResponse<number>;

class DistrictStore extends Store
  implements
    Loading,
    Pagination,
    Entity<District, { limit?: number; filter?: DistrictFilterProps }>,
    Filter<DistrictFilterProps, DistrictKeys> {
  @observable private _districts: District[];
  @observable private _filter: DistrictFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._districts = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public count: CountDistrict = async params => {
    let total: number = this._total;

    const filter = (params && params.filter) || toJS(this._filter);

    this._services.opencity.requests
      .gisDistrictCount({ params: { filter } })
      .then(({ data: { result } }: AxiosResponse<GisStreetCountResponse>) => {
        if (typeof result === 'number') {
          total = result;

          this._total = total;
        }
      });

    return total;
  };

  @action public load: LoadDistrict = async params => {
    this._loading = true;

    let streets: District[] = [];

    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const filter = (params && params.filter) || toJS(this._filter);
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    await this._services.opencity.requests
      .gisDistrictIndex({
        params: { limit, offset, filter },
      })
      .then(({ data: { result } }: AxiosResponse<GisStreetIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            streets = items.map(value => new District(value));

            this._districts = streets;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return streets;
  };

  @action clearFilter: ClearDistrictFilter = () => {
    this._filter = {};
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  @action public cleanUp = (): void => {
    this._districts = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  };

  @computed public get list(): District[] {
    return toJS(this._districts);
  }

  @computed public get filter(): DistrictFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default DistrictStore;
