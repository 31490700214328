import { AxiosRequestConfig } from 'axios';

import { HttpClient } from '@httpClient/index';
import Methods from './methods';
import ActionNames from './actionNames';

class BillingService extends HttpClient<ActionNames> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(request: any, options?: AxiosRequestConfig) {
    super(request, options);

    this.init();
  }

  private init = (): void => {
    this.requests[ActionNames.USER_ACCOUNT_INDEX] = this.request({
      method: Methods.USER_ACCOUNT_INDEX,
    });
    this.requests[ActionNames.USER_ACCOUNT_CREATE] = this.request({
      method: Methods.USER_ACCOUNT_CREATE,
    });
    this.requests[ActionNames.USER_ACCOUNT_UPDATE] = this.request({
      method: Methods.USER_ACCOUNT_UPDATE,
    });
    this.requests[ActionNames.USER_ACCOUNT_DELETE] = this.request({
      method: Methods.USER_ACCOUNT_DELETE,
    });
    this.requests[ActionNames.ACCOUNT_INDEX] = this.request({ method: Methods.ACCOUNT_INDEX });

    this.requests[ActionNames.ACCOUNT_EXISTS] = this.request({ method: Methods.ACCOUNT_EXISTS });

    this.requests[ActionNames.COUNTER_VALUE_CREATE] = this.request({
      method: Methods.COUNTER_VALUE_CREATE,
    });

    this.requests[ActionNames.COUNTER_VALUE_INDEX] = this.request({
      method: Methods.COUNTER_VALUE_INDEX,
    });

    this.requests[ActionNames.COUNTER_INDEX] = this.request({ method: Methods.COUNTER_INDEX });

    this.requests[ActionNames.MERCHANT_INDEX] = this.request({ method: Methods.MERCHANT_INDEX });

    this.requests[ActionNames.MERCHANT_GROUP_INDEX] = this.request({
      method: Methods.MERCHANT_GROUP_INDEX,
    });

    this.requests[ActionNames.SERVICE_INDEX] = this.request({ method: Methods.SERVICE_INDEX });

    this.requests[ActionNames.PAYMENT_RECEIPT_INDEX] = this.request({
      method: Methods.PAYMENT_RECEIPT_INDEX,
    });

    this.requests[ActionNames.PAYMENT_INDEX] = this.request({ method: Methods.PAYMENT_INDEX });

    this.requests[ActionNames.PAYMENT_GET_LINK] = this.request({
      method: Methods.PAYMENT_GET_LINK,
    });

    this.requests[ActionNames.ACCRUAL_INDEX] = this.request({
      method: Methods.ACCRUAL_INDEX,
    });

    this.requests[ActionNames.PAYMENT_INDEX] = this.request({
      method: Methods.PAYMENT_INDEX,
    });
    this.requests[ActionNames.ORGANIZATION_INDEX] = this.request({
      method: Methods.ORGANIZATION_INDEX,
    });

    this.requests[ActionNames.QR_CODE_CREATE] = this.request({
      method: Methods.QR_CODE_CREATE,
    });

    this.requests[ActionNames.USER_NOTIFICATION_CREATE] = this.request({
      method: Methods.USER_NOTIFICATION_CREATE,
    });

    this.requests[ActionNames.USER_NOTIFICATION_TYPE_CREATE] = this.request({
      method: Methods.USER_NOTIFICATION_TYPE_CREATE,
    });

    this.requests[ActionNames.USER_NOTIFICATION_TYPE_DELETE] = this.request({
      method: Methods.USER_NOTIFICATION_TYPE_DELETE,
    });

    this.requests[ActionNames.USER_NOTIFICATION_DELETE] = this.request({
      method: Methods.USER_NOTIFICATION_DELETE,
    });

    this.requests[ActionNames.QR_CODE_SEND] = this.request({
      method: Methods.QR_CODE_SEND,
    });

    this.requests[ActionNames.PAYMENT_CARD_INDEX] = this.request({
      method: Methods.PAYMENT_CARD_INDEX,
    });

    this.requests[ActionNames.PAYMENT_CARD_PAY] = this.request({
      method: Methods.PAYMENT_CARD_PAY,
    });

    this.requests[ActionNames.PAYMENT_STATUS] = this.request({
      method: Methods.PAYMENT_STATUS,
    });

    this.requests[ActionNames.PAYMENT_CARD_DETACH] = this.request({
      method: Methods.PAYMENT_CARD_DETACH,
    });

    this.requests[ActionNames.PAYMENT_CARD_ATTACH] = this.request({
      method: Methods.PAYMENT_CARD_ATTACH,
    });

    this.requests[ActionNames.MERCHANT_EMAIL] = this.request({
      method: Methods.MERCHANT_EMAIL,
    });

    this.requests[ActionNames.USER_CREATE] = this.request({
      method: Methods.USER_CREATE,
    });

    this.requests[ActionNames.ORGANIZATION_BANNER_INDEX] = this.request({
      method: Methods.ORGANIZATION_BANNER_INDEX,
    });

    this.requests[ActionNames.ACCOUNT_CONTRACT_INDEX] = this.request({
      method: Methods.ACCOUNT_CONTRACT_INDEX,
    });

    this.requests[ActionNames.ANNOUNCEMENTS_INDEX] = this.request({
      method: Methods.ANNOUNCEMENTS_INDEX,
    });

    this.requests[ActionNames.ACCOUNT_ACT_INDEX] = this.request({
      method: Methods.ACCOUNT_ACT_INDEX,
    });

    this.requests[ActionNames.PAYMENT_SBP_GET] = this.request({
      method: Methods.PAYMENT_SBP_GET,
    });

    this.requests[ActionNames.DADATA_SUGGS_GET] = this.request({
      method: Methods.DADATA_SUGGS_GET,
    });

    this.requests[ActionNames.DADATA_IS_SHOW_CAPTCHA] = this.request({
      method: Methods.DADATA_IS_SHOW_CAPTCHA,
    });

    this.requests[ActionNames.CLERK_ACCOUNT_INDEX] = this.request({
      method: Methods.CLERK_ACCOUNT_INDEX,
    });
  };
}

export default BillingService;
