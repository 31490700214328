import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Logo from '../Logo';
import HeaderMenu from '../HeaderMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
      },
    },
  }),
);

interface HeaderLeftProps {
  id: string;
  pathname: string;
}

const HeaderLeft: FC<HeaderLeftProps> = ({ pathname, id }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container={true} wrap="nowrap">
      <Grid item={true}>
        <Logo id={id} />
      </Grid>
      <Grid container={true} item={true} alignItems="center" justify="flex-end">
        <HeaderMenu id={`${id}__menu`} pathname={pathname} />
      </Grid>
    </Grid>
  );
};

export default HeaderLeft;
