interface StorageInterface {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
}

class Storage {
  private _storage: StorageInterface;

  constructor(storage: StorageInterface) {
    this._storage = storage;
  }

  public getValue = (key: string): string | null => {
    return this._storage.getItem(key);
  };

  public setValue = (key: string, value: string): void => {
    this._storage.setItem(key, value);
  };

  public removeValue = (key: string): void => {
    this._storage.removeItem(key);
  };
}

const storageHelper = {
  session: new Storage(sessionStorage),
  local: new Storage(localStorage),
};

export default storageHelper;
