import { AxiosResponse } from 'axios';
import { observable, action, computed, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  Resource,
  ResourceInterface,
  ResourcePrefix,
  UploadResource,
  LoadResource,
  RemoveResource,
} from '@core/entities/Opencity/Resource';
import { MINIO_END_POINT } from '@constants/minio';
import city, { isSpecialBilling } from '@core/constants/project';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';

type FileCreateResponse = JrpcResponse<ResourceInterface[]>;
type FileIndexResponse = JrpcResponse<{ items: ResourceInterface[]; total: number }>;

class ResourceStore extends Store implements Loading, Pagination {
  @observable private _resources: Resource[];
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(-1).bind(this);

  public constructor(services: Services) {
    super(services);

    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._resources = [];
    this._loading = false;
  }

  @action public load: LoadResource = async (
    sourceType,
    sourceId,
    limit = this._limit,
  ): Promise<Resource[]> => {
    let resources: Resource[] = [];

    await this._services.opencity.requests
      .fileIndex({
        params: {
          filter: {
            sourceId: { $in: sourceId },
            sourceType,
          },
          limit,
        },
      })
      .then(({ data: { result } }: AxiosResponse<FileIndexResponse>) => {
        if (result && Array.isArray(result.items)) {
          resources = result.items.map<Resource>(value => new Resource(value));
        }
      });

    this._resources = resources;

    return resources;
  };

  @action public upload: UploadResource = async (
    values,
    resources,
    isSecure,
  ): Promise<Resource[]> => {
    this._loading = true;

    let resultResources = toJS<Resource[]>(resources || this._resources);

    const uploadPromises = values.map(
      resource =>
        new Promise<Resource[]>(resolve => {
          const reader: FileReader = new FileReader();

          let resultResources: Resource[] = [];

          reader.readAsArrayBuffer(resource);

          reader.onload = async (): Promise<void> => {
            const fileName = uuidv4();
            const size = resource.size;
            const prefix = isSecure ? ResourcePrefix.SECURED : ResourcePrefix.NOT_SECURED;

            const response = await fetch(
              `${MINIO_END_POINT}/${isSpecialBilling ? 'billing' : city}/${prefix}/${fileName}`,
              {
                method: 'PUT',
                body: new Buffer(reader.result as ArrayBuffer),
                headers: { 'Content-Type': resource.type, 'Content-Length': String(size) },
              },
            );

            if (response.status === 200) {
              await this._services.opencity.requests
                .fileCreate({
                  id: uuidv4(),
                  params: {
                    data: [
                      {
                        fileName,
                        isSecure,
                        mimeType: resource.type,
                        originalFileName: resource.name,
                        size,
                        url: `/${isSpecialBilling ? 'billing' : city}/${prefix}/${fileName}`,
                      },
                    ],
                  },
                })
                .then(({ data: { result } }: AxiosResponse<FileCreateResponse>) => {
                  if (Array.isArray(result)) {
                    resolve(
                      (resultResources = [
                        ...result.map(value => new Resource(value)),
                        ...resultResources,
                      ]),
                    );

                    this._resources = resultResources;
                  }
                })
                .finally(this._endLoading);
            }
          };
        }),
    );

    for await (const resources of uploadPromises) {
      resultResources = [...resultResources, ...resources];
    }

    if (!resources) {
      this._resources = resultResources;
    }

    return resultResources;
  };

  @action public remove: RemoveResource = (id, resources) => {
    const resultResources = (resources || this._resources).reduce<Resource[]>((acc, value) => {
      if (value.id === id) {
        return acc;
      }

      return [...acc, value];
    }, []);

    if (!resources) {
      this._resources = resultResources;
    }

    return resultResources;
  };

  @action public setLimit: SetLimit = value => {
    this._limit = value;
  };

  @action public setOffset: SetOffset = value => {
    this._offset = value;
  };

  @action public cleanUp = (): void => {
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._resources = [];
    this._loading = false;
  };

  @computed public get list(): Resource[] {
    return toJS(this._resources);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default ResourceStore;
