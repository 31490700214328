import { observable } from 'mobx';

import { AddFilter, RemoveFilter, ClearFilter, FilterOperators } from '@stores/interfaces/Filter';
import { Load, Count, Get } from '@stores/interfaces/Entity';

export interface FlatAccountInterface {
  id: number;
  number?: number | null;
  flatId?: number | null;
  citizenId?: number | null;
  streetName?: string;
  flatNumber?: string;
  buildingNumber?: string;
  houseNumber?: string;
  payerName?: string;
  payerLastName?: string;
  payerPatronymic?: string;
}

export class FlatAccount {
  @observable public id: number;
  @observable public accountNumber?: number | null;
  @observable public number?: number | null;
  @observable public flatId?: number | null;
  @observable public citizenId?: number | null;
  @observable public streetName?: string;
  @observable public buildingNumber?: string;
  @observable public houseNumber?: string;
  @observable public flatNumber?: string;
  @observable public payerName?: string;
  @observable public payerLastName?: string;
  @observable public payerPatronymic?: string;

  public constructor(value: FlatAccountInterface) {
    this.id = value.id;
    this.accountNumber = value.number;
    this.flatId = value.flatId;
    this.citizenId = value.citizenId;
    this.streetName = value.streetName;
    this.buildingNumber = value.buildingNumber;
    this.houseNumber = value.houseNumber;
    this.payerName = value.payerName;
    this.payerLastName = value.payerLastName;
    this.payerPatronymic = value.payerPatronymic;
    this.number = value.number;
    this.flatNumber = value.flatNumber;
  }
}

export enum FlatAccountKeys {
  ID = 'id',
  FLAT_ID = 'flatId',
  CITIZEN_ID = 'citizenId',
  NUMBER = 'number',
  STREET_NAME = 'streetName',
  FLAT_NUMBER = 'flatNumber',
  BUILDING_NUMBER = 'buildingNumber',
  HOUSE_NUMBER = 'houseNumber',
  PAYER_NAME = 'payerName',
  PAYER_LAST_NAME = 'payerLastName',
  PAYER_PATRONYMIC = 'payerPatronymic',
  PAYER_FULL_NAME = 'payerFullName',
}

export interface FlatAccountFilterProps {
  [FlatAccountKeys.ID]?: FilterOperators<number>;
  [FlatAccountKeys.CITIZEN_ID]?: FilterOperators<number>;
  [FlatAccountKeys.FLAT_ID]?: FilterOperators<number | null>;
  [FlatAccountKeys.NUMBER]?: FilterOperators<string>;
  [FlatAccountKeys.STREET_NAME]?: FilterOperators<string>;
  [FlatAccountKeys.FLAT_NUMBER]?: FilterOperators<string>;
  [FlatAccountKeys.BUILDING_NUMBER]?: FilterOperators<string>;
  [FlatAccountKeys.HOUSE_NUMBER]?: FilterOperators<string>;
  [FlatAccountKeys.PAYER_NAME]?: FilterOperators<string>;
  [FlatAccountKeys.PAYER_LAST_NAME]?: FilterOperators<string>;
  [FlatAccountKeys.PAYER_PATRONYMIC]?: FilterOperators<string>;
  [FlatAccountKeys.PAYER_FULL_NAME]?: FilterOperators<string>;
}

export type AddFlatAccountFilter = AddFilter<FlatAccountFilterProps>;

export type RemoveFlatAccountFilter = RemoveFilter<FlatAccountKeys>;

export type ClearFlatAccountFilter = ClearFilter;

export type FlatAccountLoad = Load<
  {
    filter?: FlatAccountFilterProps;
    limit?: number;
    offset?: number;
  },
  FlatAccount[]
>;

export type FlatAccountCount = Count<{ filter?: FlatAccountFilterProps }, number>;

export type FlatAccountGet = Get<FlatAccount, FlatAccountFilterProps>;
