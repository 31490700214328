export type SetLoading = (loading: boolean) => void;

export interface Loading {
  loading: boolean;
  setLoading?: SetLoading;
}

export const endLoading = (ms = 500) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function(this: any): void {
    if (ms >= 0) {
      const timeout: NodeJS.Timeout = setTimeout((): void => {
        this._loading = false;

        clearTimeout(timeout);
      }, ms);
    } else {
      this._loading = false;
    }
  };
