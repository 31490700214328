/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useFastPaymentStore = () => {
  const { fastPaymentStore } = useStores();

  return useObserver(() => ({
    cleanUp: fastPaymentStore.cleanUp,
  }));
};
