import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export enum CounterStatus {
  WORKING = 1,
  NEED_VERIFICATION = 2,
  NEED_AUTO_VERIFICATION = 3,
  REPAIR = 4,
}

export interface CounterInterface {
  id: number;
  previousValue?: number | null;
  previousValueDate?: number | null;
  number?: string | null;
  integer?: number | null;
  decimal?: number | null;
  description?: string | null;
  typeId: number;
  typeName?: string | null;
  isEnabled?: boolean | null;
  averageRate: number | null;
  serviceId?: number | null;
  status: CounterStatus;
  nextCheckDate?: number | null;
  unitName?: string | null;
  unitPrice?: number;
  resourceId: number;
  resourceName: string;
}

export class Counter {
  public id: number;
  public previousValue?: number | null;
  public previousValueDate?: number | null;
  public number?: string | null;
  public integer?: number | null;
  public decimal?: number | null;
  public description?: string | null;
  public typeId: number;
  public typeName?: string | null;
  public isEnabled?: boolean | null;
  public averageRate?: number | null;
  public serviceId?: number | null;
  public status: CounterStatus;
  public nextCheckDate?: number | null;
  public unitName?: string | null;
  public unitPrice?: number;
  public resourceId: number;
  public resourceName: string;

  public constructor(value: CounterInterface) {
    this.id = value.id;
    this.previousValue = value.previousValue;
    this.previousValueDate = value.previousValueDate;
    this.number = value.number;
    this.integer = value.integer;
    this.decimal = value.decimal;
    this.description = value.description;
    this.typeId = value.typeId;
    this.typeName = value.typeName;
    this.isEnabled = value.isEnabled;
    this.averageRate = value.averageRate;
    this.serviceId = value.serviceId;
    this.status = value.status;
    this.nextCheckDate = value.nextCheckDate;
    this.unitName = value.unitName;
    this.unitPrice = value.unitPrice;
    this.resourceId = value.resourceId;
    this.resourceName = value.resourceName;
  }
}

export enum CounterKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  SERVICE_ID = 'serviceId',
  COUNTER_ID = 'counterPlugId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export interface CounterFilterProps {
  [CounterKeys.PROVIDER_ID]?: FilterOperators<number>;
  [CounterKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [CounterKeys.SERVICE_ID]?: FilterOperators<number>;
}

export type LoadCounter = Load<
  { filter?: CounterFilterProps; limit?: number; offset?: number },
  Counter[]
>;
// int $providerId,
//   string $accountNumber,
//   int $counterId,
//   float $value,
//   int $date,
//   int $typeId,

export type CounterValue = {
  accountNumber: string;
  providerId: number;
  counterId: number;
  value: number;
  date: number;
  typeId: number;
};
