import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';
import { mergeDeepRight } from 'ramda';

import { JrpcResponse } from '../httpClient/jrpc';
import Services from '../services';
import {
  Address,
  AddressInterface,
  AddressKeys,
  AddressFilterProps,
  AddAddressFilter,
  ClearAddressFilter,
  GetAddress,
} from '@core/entities/Opencity/Address';
import Store from './Store';
import FlatStore from './FlatStore';
import { Loading, endLoading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Filter } from './interfaces/Filter';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';

type AddressSearchResponse = JrpcResponse<AddressInterface[]>;

interface Relations {
  flatStore: FlatStore;
}

class AddressStore extends Store<Relations>
  implements
    Loading,
    Entity<Address, {}, string>,
    Filter<AddressFilterProps, AddressKeys>,
    Pagination {
  @observable private _loading: boolean;
  @observable private _addresses: Address[];
  @observable private _defaultAddresses: Address[];
  @observable private _filter: AddressFilterProps;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services, relations: Relations) {
    super(services, relations);

    this._addresses = [];
    this._defaultAddresses = [];
    this._filter = {};
    this._loading = false;
    this._limit = 40;
    this._offset = 0;
    this._total = 0;
  }

  @action public search = async (address?: string): Promise<Address[]> => {
    this._loading = true;

    let addresses: Address[] = [];

    await this._services.opencity.requests
      .addressSearch({
        params: {
          address: address || '',
          filter: this.filter,
          limit: this._limit,
          offset: this._offset,
        },
      })
      .then(({ data: { result } }: AxiosResponse<AddressSearchResponse>): void => {
        if (Array.isArray(result)) {
          addresses = result.map((value: AddressInterface): Address => new Address(value));

          this._addresses = addresses;
        }
      })
      .finally(this._endLoading);

    return addresses;
  };

  @action public setDefault = (addresses: Address[]): void => {
    this._defaultAddresses = addresses;
  };

  @action public addFilter: AddAddressFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public clearFilter: ClearAddressFilter = () => {
    this._filter = {};
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public get: GetAddress = async filter =>
    [...this._addresses, ...this._defaultAddresses].find(
      (value: Address): boolean => value.id === filter[AddressKeys.ID],
    );

  @action public cleanUp = (): void => {
    this._addresses = [];
    this._defaultAddresses = [];
    this._filter = {};
    this._loading = false;
    this._limit = 40;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Address[] {
    return toJS(this._addresses);
  }

  @computed public get defaultList(): Address[] {
    return toJS(this._defaultAddresses);
  }

  @computed public get filter(): AddressFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default AddressStore;
