import { FC, ReactNode } from 'react';

import { useOperationStore } from './hooks';

interface IsAllowedProps {
  operation: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props: any) => any;
  variant?: 'exclude' | 'include';
  defaultElement?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

const IsAllowed: FC<IsAllowedProps> = ({
  operation,
  children,
  defaultElement = null,
  variant = 'include',
  ...rest
}) => {
  const { isAllowed } = useOperationStore();

  if (variant === 'include' && isAllowed(operation)) {
    return children(rest);
  }

  if (variant === 'exclude' && !isAllowed(operation)) {
    return children(rest);
  }

  return defaultElement;
};

export default IsAllowed;
