import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      padding: '24px 24px 0 32px',
    },

    contentRoot: {
      display: 'flex',
      alignItems: 'center',
      zIndex: 3,
      padding: 0,
      paddingLeft: '5%',
      paddingRight: '5%',
      overflowY: 'visible',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },

    icon: {
      alignSelf: 'flex-start',
      marginTop: -8,
      marginRight: -8,
    },

    title: {
      fontSize: 24,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
    },

    text: {
      fontSize: 24,
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: 2,
        textAlign: 'center',
      },
    },

    textBlock: {
      [theme.breakpoints.up('sm')]: {
        width: '50%',
        paddingRight: 10,
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },

    imageBlock: {
      overflowX: 'hidden',
      [theme.breakpoints.between(420, 'xs')]: {
        padding: '0 15px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('md')]: {
        width: '40%',
      },
    },

    image: {
      objectFit: 'cover',
      maxWidth: 600,
      maxHeight: 600,
      width: '100%',
      height: '100%',

      [theme.breakpoints.down(420)]: {
        width: '130%',
        marginLeft: '-15%',
      },
    },

    payIcon: {
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        maxWidth: 110,
      },

      '& + &': {
        marginLeft: 24,
        [theme.breakpoints.up('md')]: {
          marginLeft: 32,
        },
      },
    },

    iconWrapper: {
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
      },
    },

    titleWrapper: {
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
      },
    },

    textWrapper: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 15px',
      },
    },

    ellipse: {
      position: 'absolute',
      bottom: 0,
      zIndex: 2,
      height: '30vh',
    },

    lowerBackground: {
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      maxWidth: '100vw',
      height: '30vh',
      overflow: 'hidden',
      [theme.breakpoints.up('lg')]: {
        height: '40vh',
      },
    },

    upperBackgroundDesktop: {
      position: 'absolute',
      top: '15%',
      left: '5%',
      zIndex: 2,
      width: '90vw',
    },

    backgroundMobile: {
      position: 'absolute',
      height: '80vh',
      maxWidth: '90vw',
      top: '15vh',
      left: '10vw',
      zIndex: 2,
    },
  }),
);
