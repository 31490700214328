import React, { FC, ReactElement } from 'react';
import { TimelineMax, Power3 } from 'gsap';

import { primaryMain } from 'oc-theme/lib';
import HowSystemWorksStepImage, {
  AnimationType,
} from '../HowSystemWorksStepImage/HowSystemWorksStepImage';

const CONTENT_CLASSNAME = 'solving-problem__content';
const GEAR_CLASSNAME = 'solving-problem__gear';
const WINDOW_CIRCLES_CLASSNAME = 'solving-problem__circles';
const MAP_MARKER_CLASSNAME = 'solving-problem__map-marker';

const windowContentAnimation: AnimationType = (timeline: TimelineMax) => {
  const content = `.${CONTENT_CLASSNAME} *`;
  const circles = `.${WINDOW_CIRCLES_CLASSNAME} *`;
  const gear = `.${GEAR_CLASSNAME} *`;

  timeline
    .fromTo(
      [content, circles, gear],
      1,
      { drawSVG: '100%', ease: Power3.easeIn },
      {
        drawSVG: '0',
        ease: Power3.easeIn,
      },
      0,
    )
    .to([content, circles, gear], 1, { drawSVG: '100%', ease: Power3.easeIn })
    .to(gear, 4, { rotation: '+=1440', transformOrigin: '50% 50%' }, 0);
};

const manAnimation = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.restart();

  const mapMarker = `.${MAP_MARKER_CLASSNAME}`;

  timeline.fromTo(mapMarker, 1, { y: -3 }, { y: 0 });
};

const onPause = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.kill();
};

const tlMapMarker: TimelineMax = new TimelineMax({
  repeat: -1,
});

const StepSolvingProblem: FC<{ isActive: boolean }> = ({ isActive }) => {
  const windowContentSvgElement: ReactElement = (
    <g>
      <g>
        <line x1="145" y1="59" x2="189" y2="59" />
        <path
          d=" M 145 50 L 189 50 Q 194 49.688 194 54 Q 194 58.313 194 95 Q 193.688 100.25 189 100.25 Q 184.313 100.25 145 100.25 Q 141 98.688 141 95 Q 141 91.313 141 54 Q 141.125 50 145 50 Z "
          fill="none"
        />
        <path
          d=" M 186 47 Q 185.781 42.094 180 42 Q 174.219 41.906 138 42 Q 132.969 41.406 133 47 Q 133.031 52.594 133 87.023 Q 133.031 92.25 138 92.25"
          fill="none"
        />
      </g>
      <g className={CONTENT_CLASSNAME}>
        <path d=" M 183 94 L 185.375 94 L 189 94" fill="none" />
        <line x1="145" y1="64" x2="153" y2="64" />
        <line x1="145" y1="69" x2="151" y2="69" />
      </g>
      <g className={GEAR_CLASSNAME}>
        <circle cx="167.5" cy="78.0" r="6.9" fill="none" />
        <path
          d=" M 177.657 72 L 179 75.079 L 182.577 75.079 L 182.421 80.943 L 178.422 81.077 L 177.657 83.968 L 180.266 87.023 L 176.266 90.918 L 173.5 87.918 L 170.266 88.918 L 170.266 92.918 L 164.266 92.918 L 164.266 88.918 L 161 87.918 L 158.266 90.918 L 154.032 86.889 L 157.109 83.968 L 156.188 80.839 L 152.345 80.839 L 152.345 74.841 L 156.343 74.841 L 157.421 71.972 L 154.5 69 L 158.5 65 L 161.5 68 L 164.5 67 L 164.5 63 L 170.5 63 L 170.5 67 L 173.5 68 L 176.5 65 L 180.5 69 L 177.657 72 Z "
          fill="none"
        />
      </g>
      <g>
        <g
          className={WINDOW_CIRCLES_CLASSNAME}
          strokeWidth={0}
          fill={primaryMain}
          style={{ transformOrigin: '50% 50%' }}
        >
          <circle cx="145.5" cy="54.5" r="1.5" />
          <circle cx="149.5" cy="54.5" r="1.5" />
          <circle cx="153.5" cy="54.5" r="1.5" />
          <circle cx="137.5" cy="46.5" r="1.5" />
          <circle cx="141.5" cy="46.5" r="1.5" />
          <circle cx="145.5" cy="46.5" r="1.5" />
        </g>
      </g>
    </g>
  );

  const manSvgElement: ReactElement = (
    <g style={{ isolation: 'isolate' }}>
      <g className={MAP_MARKER_CLASSNAME}>
        <path
          d=" M 113.309 42.075 C 109.141 42.075 105.75 45.492 105.75 49.693 C 105.75 54.906 112.514 62.559 112.802 62.882 C 113.073 63.185 113.545 63.185 113.815 62.882 C 114.103 62.559 120.868 54.906 120.868 49.693 C 120.867 45.492 117.477 42.075 113.309 42.075 Z  M 113.309 53.526 C 111.212 53.526 109.506 51.806 109.506 49.693 C 109.506 47.579 111.212 45.86 113.309 45.86 C 115.406 45.86 117.112 47.579 117.112 49.693 C 117.112 51.806 115.406 53.526 113.309 53.526 Z "
          fill="rgb(29,147,185)"
        />
      </g>
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx="104.76562500000001"
        cy="141.04687500000006"
        rx="37.546874999999986"
        ry="3.09375"
        fill="rgb(235,235,235)"
      />
      <path
        d=" M 80.367 134.93 Q 79.063 139.125 79.781 139.406 Q 84.031 140 85.922 140.273 C 87.643 140.522 91.969 141.82 92.391 141.258 C 92.813 140.695 92.32 139.711 91.758 139.219 C 91.195 138.727 85.57 136.266 84.656 135.281 L 80.367 134.93 Z "
        fill="rgb(26,26,26)"
      />
      <path
        d=" M 108.957 136.612 Q 109.617 141.188 110.426 141.117 Q 113.625 139.043 116.379 138.692 C 118.104 138.471 122.438 137.193 122.543 136.498 C 122.649 135.803 121.749 135.169 121.021 135.001 C 120.293 134.834 114.173 135.327 112.902 134.892 L 108.957 136.612 Z "
        fill="rgb(26,26,26)"
      />
      <path
        d=" M 89.5 95.25 Q 89.188 107.688 88.625 113.063 Q 86 116.875 83.75 123.625 Q 81.5 130.375 80.25 135 Q 81.375 136.188 82.625 136.125 Q 83.875 136.063 84.813 135 Q 85.125 132.688 85.938 130.375 Q 86.75 128.063 91.813 116.063 Q 96.188 108.313 97.125 105.063 Q 100.063 94.5 99.375 91.938 Q 98.688 89.375 98.875 89.625 L 89.688 89.063 L 89.5 95.25 Z "
        fill="rgb(54,67,85)"
      />
      <path
        d=" M 99 90 Q 104.316 99.984 106 108 Q 108.215 113.984 107.897 115 Q 107.578 116.016 112.043 131.59 L 114 135 Q 112.531 137.84 109 137 Q 107.794 133.016 107.001 130 C 106.207 126.984 103.168 122.098 103 117 Q 97.504 106.227 95 103 Q 90.377 97.64 89.309 95.724 C 89.137 95.416 89.034 95.174 89 95 Q 88.641 93.141 89 88 L 91 89 L 99 90 Z "
        fill="rgb(68,84,107)"
      />
      <path
        d="M 112.429 67.117 L 115.198 69.272 C 115.434 69.456 115.469 69.791 115.276 70.02 L 110.975 75.125 C 110.782 75.354 110.433 75.391 110.197 75.207 L 107.429 73.052 C 107.192 72.868 107.157 72.533 107.35 72.304 L 111.652 67.199 C 111.844 66.97 112.193 66.933 112.429 67.117 Z"
        style={{ stroke: 'none', fill: '#12AAD4', strokeMiterlimit: 10 }}
      />
      <path
        d="M 111.998 67.828 L 114.417 69.711 C 114.607 69.859 114.635 70.128 114.48 70.312 L 111.284 74.106 C 111.129 74.29 110.85 74.319 110.66 74.171 L 108.24 72.288 C 108.051 72.141 108.023 71.871 108.177 71.688 L 111.374 67.894 C 111.529 67.71 111.808 67.681 111.998 67.828 Z"
        style={{ stroke: 'none', fill: '#18CCA7', strokeMiterlimit: 10 }}
      />
      <path
        d=" M 104 84 Q 110.41 77.117 112 76 Q 113.59 74.883 114 73 Q 115.147 69.984 114.761 70 Q 114.375 70.016 113 72.719 L 111.004 74.439 Q 104.746 78.547 104 79 L 100.765 77 L 100 82 Q 103.305 84.223 104 84 Z "
        fill="rgb(251,190,133)"
      />
      <path
        d=" M 89 74 L 93 76 L 90 83 L 89 89 Q 90.016 89.578 95 90 Q 97.641 90.141 99 90 Q 100.359 89.859 101 89 Q 100.8 85.16 100.765 82.617 C 100.732 80.2 100.81 78.661 101 78 C 101.484 76.313 102.094 74.063 102 72 C 101.906 69.938 98 68.156 97 66 L 96 64 L 92 63 Q 89.078 66.109 89 74 Z "
        fill="rgb(24,204,167)"
      />
      <path
        d=" M 93 61 L 91 60 L 90 56 Q 89.047 52.57 91 51 Q 92.953 49.43 97 50 Q 98.754 49.805 99 48 Q 100.609 48.672 101 50 C 101.391 51.328 101.738 53.047 100 55 Q 98.262 56.953 93 61 Z "
        fill="rgb(88,47,29)"
      />
      <path
        d=" M 94 56 Q 94.93 53.652 96.719 53.563 Q 98.508 53.473 100 55 Q 99.839 56.651 100 57 Q 100.161 57.349 101 58 L 100 59 Q 100.191 60.477 100 61 C 99.809 61.523 99.387 61.914 99 62 Q 98.613 62.086 96 61 L 96 64 L 92 63 L 92 61 L 93 60 Q 94.66 58.832 94 59 Q 93.34 59.168 93 57 Q 92.656 55.469 94 56 Z "
        fill="rgb(251,190,133)"
      />
      <path
        d="M 78.082 105.563 L 94.731 105.563 C 95.224 105.563 95.625 105.963 95.625 106.457 L 95.625 114.606 C 95.625 115.099 95.224 115.5 94.731 115.5 L 78.082 115.5 C 77.588 115.5 77.187 115.099 77.187 114.606 L 77.187 106.457 C 77.187 105.963 77.588 105.563 78.082 105.563 Z"
        style={{ stroke: 'none', fill: '#18809F', strokeMiterlimit: 10 }}
      />
      <path
        d=" M 77.752 105.625 Q 76.943 105.771 76.353 107.002 Q 75.762 108.232 78.125 107.558 L 77.752 105.625 Z "
        fill="rgb(24,128,159)"
      />
      <path
        d="M 78.094 101.766 L 78.985 101.766 C 79.075 101.766 79.148 101.839 79.148 101.93 L 79.148 105.992 C 79.148 106.083 79.075 106.156 78.985 106.156 L 78.094 106.156 C 78.003 106.156 77.93 106.083 77.93 105.992 L 77.93 101.93 C 77.93 101.839 78.003 101.766 78.094 101.766 Z"
        style={{ stroke: 'none', fill: '#1D93B9', strokeMiterlimit: 10 }}
      />
      <path
        d="M 92.123 101.766 L 93.018 101.766 C 93.107 101.766 93.18 101.838 93.18 101.927 L 93.18 105.87 C 93.18 105.959 93.107 106.031 93.018 106.031 L 92.123 106.031 C 92.033 106.031 91.961 105.959 91.961 105.87 L 91.961 101.927 C 91.961 101.838 92.033 101.766 92.123 101.766 Z"
        style={{ stroke: 'none', fill: '#1D93B9', strokeMiterlimit: 10 }}
      />
      <path
        d="M 78.254 101.531 L 92.832 101.531 C 93.011 101.531 93.156 101.677 93.156 101.855 L 93.156 102.582 C 93.156 102.761 93.011 102.906 92.832 102.906 L 78.254 102.906 C 78.075 102.906 77.93 102.761 77.93 102.582 L 77.93 101.855 C 77.93 101.677 78.075 101.531 78.254 101.531 Z"
        style={{ stroke: 'none', fill: '#1D93B9', strokeMiterlimit: 10 }}
      />
      <path
        d=" M 86 97 Q 86.625 87.141 87 84 Q 87.375 80.859 89 74 L 93 76 Q 90.516 83.844 90 85 Q 90.141 86.016 90 87 Q 89.859 87.984 88 97 Q 88.156 98.031 88 101 L 87 101 Q 87.422 101.109 87 102 C 86.578 102.891 85.625 104.078 85 104 Q 84.375 103.922 84 103 L 84 100 L 86 97 Z "
        fill="rgb(251,190,133)"
      />
      <path
        d=" M 95.563 114.563 Q 95.531 115.781 94.156 116.438 L 92.781 115.219 L 95.563 114.563 Z "
        fill="rgb(24,128,159)"
      />
      <path
        d="M 77.082 106.625 L 93.731 106.625 C 94.224 106.625 94.625 107.026 94.625 107.519 L 94.625 115.668 C 94.625 116.162 94.224 116.563 93.731 116.563 L 77.082 116.563 C 76.588 116.563 76.188 116.162 76.188 115.668 L 76.188 107.519 C 76.188 107.026 76.588 106.625 77.082 106.625 Z"
        style={{ stroke: 'none', fill: '#1D93B9', strokeMiterlimit: 10 }}
      />
      <rect
        x="76.188"
        y="111.125"
        width="18.453"
        height="1.281"
        transform="matrix(1,0,0,1,0,0)"
        fill="rgb(183,227,242)"
      />
      <path
        d="M 84.032 106.406 L 86.656 106.406 C 86.759 106.406 86.844 106.491 86.844 106.594 L 86.844 107.562 C 86.844 107.666 86.759 107.75 86.656 107.75 L 84.032 107.75 C 83.928 107.75 83.844 107.666 83.844 107.562 L 83.844 106.594 C 83.844 106.491 83.928 106.406 84.032 106.406 Z"
        style={{ stroke: 'none', fill: '#18809F', strokeMiterlimit: 10 }}
      />
    </g>
  );

  return (
    <HowSystemWorksStepImage
      windowContentSvg={windowContentSvgElement}
      manSvg={manSvgElement}
      windowContentAnimation={windowContentAnimation}
      manAnimation={manAnimation(tlMapMarker)}
      onPause={onPause(tlMapMarker)}
      isActive={isActive}
    />
  );
};

export default StepSolvingProblem;
