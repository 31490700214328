import React, { FC, useState, ReactElement, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import StepSendOrder from './StepSendOrder';
import StepSolvingProblem from './StepSolvingProblem';
import StepJobEvaluation from './StepJobEvaluation/StepJobEvaluation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(50),
      padding: theme.spacing(0, 30),

      [theme.breakpoints.down(1240)]: {
        padding: theme.spacing(0, 10),
      },

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(20),
        padding: theme.spacing(0),
      },
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        maxWidth: 500,
        width: '100%',
      },
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      width: theme.spacing(62),

      [theme.breakpoints.up('md')]: {
        padding: '0 !important',
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        paddingRight: '0 !important',
        textAlign: 'right',
      },
    },
    descriptionTitle: {
      fontWeight: 700,
      marginBottom: theme.spacing(4),
    },
    descriptionsContainer: {
      '& > div:nth-child(n + 2)': {
        marginLeft: theme.spacing(14),
      },

      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      padding: theme.spacing(0, 8),

      [theme.breakpoints.down('sm')]: {
        '& > div:nth-child(n + 2)': {
          marginLeft: 0,
          marginTop: theme.spacing(14),
        },

        alignItems: 'flex-end',
        boxSizing: 'border-box',
        flexDirection: 'column',
        padding: `${theme.spacing(0)} !important`,
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      height: 1,
      marginLeft: -7,
      marginRight: -7,
      width: 'calc(100% + 14px)',

      [theme.breakpoints.down('sm')]: {
        height: '100%',
        margin: 0,
        width: 1,
      },
    },
    dividerWrapper: {
      flexGrow: 1,
    },
    img: {
      width: theme.spacing(62),
    },
    point: {
      background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
      borderRadius: 30,
      color: '#ffffff',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: 40,
      fontWeight: 700,
      height: theme.spacing(15),
      lineHeight: '60px',
      position: 'relative',
      textAlign: 'center',
      transition: 'all 0.4s cubic-bezier(0.18, 0.89, 0.21, 1.95)',
      width: theme.spacing(15),
      zIndex: 2,
    },
    pointActive: {
      [theme.breakpoints.up('md')]: {
        marginLeft: -15,
        marginRight: -15,
        width: '90px',
      },
    },
    pointsContainer: {
      minWidth: theme.spacing(15),
      paddingLeft: `${theme.spacing(34)}px !important`,
      paddingRight: '125px !important',

      [theme.breakpoints.down('sm')]: {
        boxSizing: 'border-box',
        flex: 0,
        flexDirection: 'column',
        minWidth: theme.spacing(23),
        padding: `${theme.spacing(4, 8, 4, 0)} !important`,
      },
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(20),
      textAlign: 'center',

      [theme.breakpoints.down('md')]: {
        fontSize: '1.85rem !important',
        marginBottom: theme.spacing(10),
      },
    },
  }),
);

const ANIMATION_DURATION = 5;

interface HowSystemWorksProps {
  id: string;
}

const HowSystemWorks: FC<HowSystemWorksProps> = ({ id }) => {
  const classes = useStyles();

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const intervalRef = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    intervalRef.current = setTimeout(() => {
      if (activeStepIndex === 2) {
        setActiveStepIndex(0);
      } else {
        setActiveStepIndex(activeStepIndex + 1);
      }
    }, ANIMATION_DURATION * 1000);

    return (): void => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [activeStepIndex]);

  const renderDescription = (title: string, text: string): ReactElement => (
    <div className={classes.description}>
      <Typography className={classes.descriptionTitle} color="textPrimary" variant="body1">
        {title}
      </Typography>
      <Typography color="textPrimary" variant="body2">
        {text}
      </Typography>
    </div>
  );

  const renderPoint = (label: string, isActive: boolean): ReactElement => (
    <div className={classNames(classes.point, { [classes.pointActive]: isActive })}>{label}</div>
  );

  const pointsElement: ReactElement = (
    <Grid
      className={classes.pointsContainer}
      container={true}
      item={true}
      justify="space-between"
      alignItems="center"
      md={12}
      sm={1}
    >
      <Grid item={true}>{renderPoint('1', activeStepIndex === 0)}</Grid>
      <Grid className={classes.dividerWrapper} item={true}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item={true}>{renderPoint('2', activeStepIndex === 1)}</Grid>
      <Grid className={classes.dividerWrapper} item={true}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item={true}>{renderPoint('3', activeStepIndex === 2)}</Grid>
    </Grid>
  );

  const imagesElement: ReactElement = (
    <Hidden smDown={true}>
      <Grid container={true} item={true} justify="space-between">
        <Grid item={true}>
          <StepSendOrder isActive={activeStepIndex === 0} />
        </Grid>
        <Grid item={true}>
          <StepSolvingProblem isActive={activeStepIndex === 1} />
        </Grid>
        <Grid item={true}>
          <StepJobEvaluation isActive={activeStepIndex === 2} />
        </Grid>
      </Grid>
    </Hidden>
  );

  const descriptionsElement: ReactElement = (
    <Grid className={classes.descriptionsContainer} item={true}>
      {renderDescription('Вы оставляете заявку', 'Максимально подробно опишите суть проблемы')}
      {renderDescription(
        'Службы ЖКХ приступают к устранению проблемы',
        'Ход выполнения работы вы можете отслеживать по статусу заявки',
      )}
      {renderDescription(
        'Оценка качества работы',
        'Вы оцениваете качество – при ненадлежащем исполнении заявка будет принята в работу повторно',
      )}
    </Grid>
  );

  return (
    <Grid
      className={classes.container}
      id={id}
      container={true}
      component="section"
      direction="column"
      alignItems="center"
      spacing={4}
      data-testid="how-system-works"
    >
      <Grid item={true}>
        <Typography className={classes.title} color="textPrimary" variant="h2">
          Как работает система?
        </Typography>
      </Grid>
      <Grid className={classes.content} container={true} item={true} justify="center" spacing={10}>
        {pointsElement}
        {imagesElement}
        {descriptionsElement}
      </Grid>
    </Grid>
  );
};

export default HowSystemWorks;
