import { Load, Count } from '@stores/interfaces/Entity';
import { FilterOperators, AddFilter, ClearFilter } from '@stores/interfaces/Filter';

export interface StreetInterface {
  id: number;
  name?: string | null;
  fiasId?: string | null;
  settlementId?: number | null;
  cityId?: number | null;
  typeId?: number | null;
}

export class Street {
  public id: number;
  public name?: string | null;
  public fiasId?: string | null;
  public settlementId?: number | null;
  public cityId?: number | null;
  public typeId?: number | null;

  public constructor(value: StreetInterface) {
    this.id = value.id;
    this.name = value.name;
    this.fiasId = value.fiasId;
    this.settlementId = value.settlementId;
    this.cityId = value.cityId;
    this.typeId = value.typeId;
  }
}

export enum StreetKeys {
  ID = 'id',
  INTERRUPT_ID = 'interruptId',
  NAME = 'name',
}

export interface StreetFilterProps {
  [StreetKeys.ID]?: FilterOperators<number>;
  [StreetKeys.INTERRUPT_ID]?: FilterOperators<number>;
  [StreetKeys.NAME]?: FilterOperators<string>;
}

export type AddStreetFilter = AddFilter<StreetFilterProps>;

export type LoadStreet = Load<
  { limit?: number; offset?: number; filter?: StreetFilterProps; ownStreets?: boolean },
  Street[]
>;

export type CountStreet = Count<{ filter?: StreetFilterProps }, number>;

export type ClearStreetFilter = ClearFilter;
