import { action } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import Store from './Store';
import { UserCategoryLoad, UserCategoryInterface } from '@entities/Opencity/UserCategory';

type ReferenceUserCategoryIndex = JrpcResponse<{
  items: UserCategoryInterface[];
  total: number;
}>;

class UserCategoryStore extends Store {
  public constructor(services: Services) {
    super(services);
  }

  @action public load: UserCategoryLoad = async params => {
    return await this._services.opencity.requests
      .userCategoryIndex({ params })
      .then(({ data: { result } }: AxiosResponse<ReferenceUserCategoryIndex>) => {
        if (result && result.items && result.total) {
          return result.items.map(item => item.categoryName);
        }
        return [];
      });
  };
}

export default UserCategoryStore;
