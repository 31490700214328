import { observable } from 'mobx';

import { AddFilter, FilterOperators, ClearFilter } from '@stores/interfaces/Filter';
import { Load, Count } from '@stores/interfaces/Entity';
import { FlatAccount } from './FlatAccount';

export interface FlatInterface {
  id: number;
  buildingNumber?: string | null;
  houseId?: number | null;
  houseNumber?: string | null;
  porch?: string | null;
  stage?: number | null;
  streetId?: number | null;
  streetName?: string | null;
  typeId?: number | null;
  number?: string | null;
  citizenId?: number | null;
  porchPosition?: number;
  flatPosition?: number;
  controlId?: number;
  providerId?: number;
}

export class Flat {
  @observable public id: number;
  @observable public buildingNumber?: string | null;
  @observable public houseId?: number | null;
  @observable public houseNumber?: string | null;
  @observable public porch?: string | null;
  @observable public streetId?: number | null;
  @observable public streetName?: string | null;
  @observable public typeId?: number | null;
  @observable public number?: string | null;
  @observable public stage?: number | null;
  @observable public flatAccount?: FlatAccount;
  @observable public citizenId?: number | null;
  public porchPosition?: number;
  public flatPosition?: number;
  public readonly addressLabel: string;
  public controlId?: number;
  public providerId?: number;

  public constructor(value: FlatInterface) {
    this.buildingNumber = value.buildingNumber;
    this.houseId = value.houseId;
    this.houseNumber = value.houseNumber;
    this.id = value.id;
    this.porch = value.porch;
    this.streetId = value.streetId;
    this.streetName = value.streetName;
    this.typeId = value.typeId;
    this.number = value.number;
    this.stage = value.stage;
    this.citizenId = value.citizenId;
    this.porchPosition = value.porchPosition;
    this.flatPosition = value.flatPosition;
    this.controlId = value.controlId;
    this.providerId = value.providerId;
    this.addressLabel = `ул. ${value.streetName}, д. ${value.houseNumber}, ${
      value.buildingNumber !== '-' ? `корп. ${value.buildingNumber},` : ''
    } кв. ${value.number}`;
  }
}

export type LoadFlat = Load<
  { filter?: FlatFilterProps; limit?: number; offset?: number; ownFlats?: boolean },
  Flat[]
>;

export type CountFlat = Count<{ filter?: FlatFilterProps }, number>;

export enum FlatKeys {
  ID = 'id',
  CITIZEN_ID = 'citizenId',
  HOUSE_ID = 'houseId',
  INTERRUPT_ID = 'interruptId',
  NUMBER = 'number',
}

export interface FlatFilterProps {
  [FlatKeys.ID]?: FilterOperators<number>;
  [FlatKeys.CITIZEN_ID]?: FilterOperators<number>;
  [FlatKeys.HOUSE_ID]?: FilterOperators<number>;
  [FlatKeys.INTERRUPT_ID]?: FilterOperators<number>;
  [FlatKeys.NUMBER]?: FilterOperators<string>;
}

export type AddFlatFilter = AddFilter<FlatFilterProps>;

export type ClearFlatFilter = ClearFilter;
