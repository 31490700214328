import { APP_ROOT } from '@constants/routes';

export const ROOT = APP_ROOT;

export const FAST_PAYMENT = `${ROOT}fast_payment`;

export const ORDER = `${ROOT}order`;

export const SHUTDOWNS = `${ROOT}shutdowns`;

export const RATINGS = `${ROOT}ratings`;

export const HELP_CENTER = `${ROOT}help`;

export const SIGN_IN = `${ROOT}sign_in`;

export const SIGN_UP = `${ROOT}sign_up`;

export const USER_AGREEMENT = `${ROOT}user_agreement`;

export const PRIVACY_POLICY = `${ROOT}privacy_policy`;

export const PASSWORD_RECOVERY = `${ROOT}password_recovery`;

export const PASSWORD_CHANGE = `${ROOT}password_change`;

export const AUTH_SOCIAL_PAGE = `${ROOT}auth/social/:provider`;

export const ACQUIRING = `${ROOT}acquiring`;

export const UN_SUBSCRIBE = `${ROOT}unsubscribe`;

export const OTHER = `*`;
