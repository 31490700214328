import React, { FC, useCallback, useState } from 'react';
import { Theme, makeStyles, ClickAwayListener, Tooltip, Typography } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(3, 4),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  tooltipText: {
    fontSize: '13px !important',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: 20,
  },
  icon: {
    color: '#44546B',
  },
}));

interface ConfirmedEmailAdormentProps {
  isConfirmed: boolean;
}

export const ConfirmedEmailAdorment: FC<ConfirmedEmailAdormentProps> = ({ isConfirmed }) => {
  const classes = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = useCallback((): void => {
    setTooltipOpen(false);
  }, []);

  const handleTooltipOpen = useCallback((): void => {
    setTooltipOpen(true);
  }, []);

  return (
    <div hidden={!isConfirmed}>
      <div onMouseLeave={handleTooltipClose}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            placement="top"
            interactive
            arrow
            open={tooltipOpen}
            title={
              <Typography variant="body2" className={classes.tooltipText}>
                Ваш email подтверждён
              </Typography>
            }
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <VerifiedUserIcon
              onMouseEnter={handleTooltipOpen}
              onClick={handleTooltipOpen}
              className={classes.icon}
            />
          </Tooltip>
        </ClickAwayListener>
      </div>
    </div>
  );
};
