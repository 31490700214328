import { Load, Get } from '@stores/interfaces/Entity';
import { FilterOperators } from '@stores/interfaces/Filter';

export interface OrganizationInterface {
  id: number;
  name?: string | null;
  inn?: number | null;
  region?: string | null;
  custom?: string | null;
}

export class Organization {
  public id: number;
  public name?: string | null;
  public inn?: number | null;
  public region?: string | null;
  public custom?: string | null;

  public constructor(value: OrganizationInterface) {
    this.id = value.id;
    this.name = value.name;
    this.inn = value.inn;
    this.region = value.region;
    this.custom = value.custom;
  }
}

export enum OrganizationKeys {
  ID = 'id',
  NAME = 'name',
}

export interface OrganizationFilterProps {
  [OrganizationKeys.ID]?: number;
  [OrganizationKeys.NAME]?: FilterOperators<string>;
}

export type LoadOrganization = Load<
  {
    filter?: OrganizationFilterProps;
    limit?: number;
    offset?: number;
  },
  Organization[]
>;

export type GetOrganization = Get<Organization, OrganizationFilterProps>;
