import { v4 as uuidv4 } from 'uuid';

import { AddFilter, ClearFilter, FilterOperators } from '@stores/interfaces/Filter';
import { Get } from '@stores/interfaces/Entity';

export interface AddressInterface {
  id?: string;
  cityId?: number | null;
  cityName?: string | null;
  regionName?: string | null;
  regionId?: number | null;
  streetName?: string | null;
  streetId?: number | null;
  houseNumber?: string | null;
  houseId?: number | null;
  buildingNumber?: string | null;
  flatNumber?: string | null;
  flatPorch?: string | null;
  flatId?: number | null;
}

export class Address {
  public id: string;
  public cityId?: number | null;
  public cityName?: string | null;
  public regionId?: number | null;
  public regionName?: string | null;
  public streetId?: number | null;
  public streetName?: string | null;
  public houseNumber?: string | null;
  public houseId?: number | null;
  public buildingNumber?: string | null;
  public flatNumber?: string | null;
  public flatPorch?: string | null;
  public flatId?: number | null;

  public constructor(value: AddressInterface) {
    this.id = value.id || uuidv4();
    this.cityId = value.cityId;
    this.cityName = value.cityName;
    this.regionId = value.regionId;
    this.regionName = value.regionName;
    this.streetId = value.streetId;
    this.streetName = value.streetName;
    this.houseNumber = value.houseNumber;
    this.houseId = value.houseId;
    this.buildingNumber = value.buildingNumber;
    this.flatNumber = value.flatNumber;
    this.flatPorch = value.flatPorch;
    this.flatId = value.flatId;
  }

  public get label(): string {
    let label = `ул. ${this.streetName}`;

    if (this.houseNumber) {
      label = `${label}, д. ${this.houseNumber}`;
    }

    if (this.buildingNumber && this.buildingNumber !== '-') {
      label = `${label}, корп. ${this.buildingNumber}`;
    }

    if (this.flatNumber) {
      label = `${label}, кв. ${this.flatNumber}`;
    }

    return label;
  }
}

export enum AddressKeys {
  ID = 'id',
  STREET_ID = 'streetId',
  HOUSE_ID = 'houseId',
  FLAT_ID = 'flatId',
  SEARCH_KEY = 'searchKey',
}

export interface AddressFilterProps {
  [AddressKeys.ID]?: string;
  [AddressKeys.STREET_ID]?: FilterOperators<number>;
  [AddressKeys.HOUSE_ID]?: FilterOperators<number>;
  [AddressKeys.FLAT_ID]?: number;
}

export type AddAddressFilter = AddFilter<AddressFilterProps>;

export type ClearAddressFilter = ClearFilter;

export type GetAddress = Get<Address, AddressFilterProps>;
