import React, { FC } from 'react';
import { makeStyles, createStyles, Grid, Theme } from '@material-ui/core';

import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 50,
    },

    smallIcon: {
      maxHeight: 25,
    },

    imgContainer: {
      flexBasis: 0,
    },
    cardWrapper: {
      width: 'auto',
      marginRight: theme.spacing(2),
    },
  }),
);

interface Props {
  adornment?: boolean;
}

export const PaymentMethods: FC<Props> = ({ adornment = false }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      spacing={4}
      justify="center"
      wrap="nowrap"
      className={cn(adornment && classes.cardWrapper)}
    >
      <Grid className={classes.imgContainer} item={true} container={true} alignItems="center">
        <img
          className={cn(classes.img, adornment && classes.smallIcon)}
          alt={'Visa'}
          src="/images/visa.svg"
        />
      </Grid>

      <Grid className={classes.imgContainer} item={true} container={true} alignItems="center">
        <img
          className={cn(classes.img, adornment && classes.smallIcon)}
          alt={'Master Card'}
          src="/images/master-card.svg"
        />
      </Grid>

      <Grid className={classes.imgContainer} item={true} container={true} alignItems="center">
        <img
          className={cn(classes.img, adornment && classes.smallIcon)}
          alt={'Мир'}
          src="/images/mir.svg"
        />
      </Grid>
    </Grid>
  );
};
