/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSnackbarStore = () => {
  const { snackbarStore } = useStores();

  return useObserver(() => ({
    cleanUp: snackbarStore.cleanUp,
    message: snackbarStore.message,
    type: snackbarStore.type,
  }));
};

export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    cleanUpOperationStore: operationStore.cleanUp,
    operations: operationStore.list,
    loadOperation: operationStore.load,
    isAllowed: operationStore.isAllowed,
  }));
};

export const useSignStore = () => {
  const { signStore } = useStores();

  return useObserver(() => ({
    codeConfirmed: signStore.codeConfirmed,
    cleanConfirmationStatus: signStore.cleanConfirmationStatus,
  }));
};
