import { primaryMain, secondaryMain, white } from 'oc-theme/lib';

export const scale = (value: string, scaleValue: number): string =>
  parseFloat(value) * scaleValue + value.toString().replace(/[^a-zA-Z]+/g, '') || 'px';

export enum ParticleSizes {
  LG = '1.56vw',
  MD = '1vw',
  SM = '0.52vw',
  XS = '0.36vw',
  XXS = '0.2vw',
}

export enum ParticleDirections {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface ParticlePosition {
  [key: string]: string;
}

export interface Particle {
  size: ParticleSizes | string;
  direction: ParticleDirections;
  position: ParticlePosition;
  color: string;
}

export const PARTICLES_MAIN_RIGHT_SIDE: Particle[] = [
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '0',
      top: '7.292vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: white,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '1.823vw',
      top: '13.177vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '7.813vw',
      top: '11.458vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: white,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '10.677vw',
      top: '6.771vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '0.521vw',
      top: '1.302vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: white,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '6.771vw',
      top: '1.302vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '16.146vw',
      top: '7.292vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '22.656vw',
      top: '11.458vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: white,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '26.563vw',
      top: '7.292vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: white,
    direction: ParticleDirections.LEFT,
    position: {
      right: '31.771vw',
      top: '7.292vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '0',
      top: '40.104vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '2.083vw',
      top: '44.271vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '7.292vw',
      top: '44.792vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '8.854vw',
      top: '38.542vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '15.625vw',
      top: '39.063vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '23.958vw',
      top: '44.792vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '25vw',
      top: '39.583vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '30.208vw',
      top: '39.063vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '6.250vw',
      top: '26.563vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '9.896vw',
      top: '26.563vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '120px',
      top: '430px',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '9.896vw',
      top: '22.396vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '6.250vw',
      top: '18.229vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      right: '9.896vw',
      top: '18.229vw',
    },
    size: ParticleSizes.XS,
  },
];

export const PARTICLES_MAIN_LEFT_SIDE: Particle[] = [
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '0',
      top: '6.771vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '2.083vw',
      top: '9.375vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '6.250vw',
      top: '9.375vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '0',
      top: '12.500vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '2.083vw',
      top: '15.104vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '6.250vw',
      top: '15.104vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '0',
      top: '17.708vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '2.083vw',
      top: '20.313vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '6.250vw',
      top: '15.104vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '0',
      top: '22.917vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '0',
      top: '29.167vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '2.083vw',
      top: '32.813vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '8.854vw',
      top: '33.333vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '10.417vw',
      top: '29.167vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '14.583vw',
      top: '29.427vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '22.396vw',
      top: '33.333vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '18.229vw',
      top: '22.396vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '28.646vw',
      top: '29.427vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '44.271vw',
      top: '7.292vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '44.271vw',
      top: '11.458vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '49.479vw',
      top: '11.458vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '49vw',
      top: '36vw',
    },
    size: ParticleSizes.MD,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '50vw',
      top: '40vw',
    },
    size: ParticleSizes.LG,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '40vw',
      top: '42vw',
    },
    size: ParticleSizes.SM,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.LEFT,
    position: {
      left: '25vw',
      top: '42vw',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '30vw',
      top: '42vw',
    },
    size: ParticleSizes.XS,
  },
];

export const PARTICLES_MAIN_SM_DOWN: Particle[] = PARTICLES_MAIN_RIGHT_SIDE.map(
  (particle: Particle) => {
    const scaleValue: number = 100 / 53;

    const newPosition: ParticlePosition = Object.keys(particle.position).reduce(
      (acc: ParticlePosition, key: string): ParticlePosition => {
        acc[key] = scale(particle.position[key], scaleValue);

        return acc;
      },
      {} as ParticlePosition,
    );

    return {
      ...particle,
      position: newPosition,
      size: scale(particle.size, scaleValue),
    };
  },
);

export const PARTICLES_HOW_SYSTEM_WORKS_STEP: Particle[] = [
  {
    color: primaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '5%',
      top: '10%',
    },
    size: ParticleSizes.XS,
  },
  {
    color: primaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      left: '10%',
      top: '70%',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '10%',
      top: '10%',
    },
    size: ParticleSizes.XXS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '0%',
      top: '0%',
    },
    size: ParticleSizes.XS,
  },
  {
    color: secondaryMain,
    direction: ParticleDirections.RIGHT,
    position: {
      right: '5%',
      top: '40%',
    },
    size: ParticleSizes.XS,
  },
];
