import { Get } from '@stores/interfaces/Entity';

export interface ClassifierInterface {
  id: number;
  key?: number | null;
  type?: string | null;
  name?: string | null;
  categoryId?: number | null;
  categoryName?: string | null;
  trackerId?: number | null;
  trackerName?: string | null;
}

export class Classifier {
  public id: number;
  public key?: number | null;
  public type?: string | null;
  public name?: string | null;
  public categoryId?: number | null;
  public categoryName?: string | null;
  public trackerId?: number | null;
  public trackerName?: string | null;

  public constructor(value: ClassifierInterface) {
    this.id = value.id;
    this.key = value.key;
    this.type = value.type;
    this.name = value.name;
    this.categoryId = value.categoryId;
    this.categoryName = value.categoryName;
    this.trackerId = value.trackerId;
    this.trackerName = value.trackerName;
  }
}

export enum ClassifierKeys {
  ID = 'id',
}

export interface ClassifierFilterProps {
  [ClassifierKeys.ID]?: number;
}

export type SearchClassifiers = (value: string) => void;

export type ClearClassifiers = () => void;

export type GetClassifier = Get<Classifier, ClassifierFilterProps>;
