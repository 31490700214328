import Currency from '@utils/currency';
import moment from 'moment';
import { AddressParts } from '@entities/Billing/Account';

export const bigNumberFormat = (sum: number): string =>
  new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(sum);

export const moneyFormat = (sum: number): string =>
  new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(sum) + Currency.ruble;

export const integerMoneyFormat = (sum: number): string =>
  new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 0 }).format(sum) + Currency.ruble;

export const timeFormat = (seconds: string | number): string =>
  moment.utc(Number(seconds) * 1000).format(Number(seconds) > 360 ? 'HH:mm:ss' : 'mm:ss');

export const counterFormat = (sum: number, unit: string): string =>
  new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(sum) + ' ' + unit;

export const firstCharUpperFormat = (str: string): string =>
  `${str[0].toUpperCase()}${str.slice(1)}`;

export const addressByAddressParts = (addressParts: AddressParts | undefined | null): string => {
  if (!addressParts) {
    return '';
  }

  const streetPrefix = addressParts.streetPrefix || '';
  const street = addressParts.street || '';
  const house = addressParts.house || '';
  const building = addressParts.building || '';
  const houseLiter = addressParts.houseLiter || '';
  const roomPrefix = addressParts.roomPrefix || '';
  const room = addressParts.room || '';
  const roomLiter = addressParts.roomLiter || '';
  const roomLiterOnly = addressParts.roomLiterOnly || '';

  return `${streetPrefix}. ${street}, д. ${house}${
    building ? `/${building}` : ''
  }${houseLiter}, ${roomPrefix}. ${
    roomLiterOnly ? `${roomLiter}` : `${room}${roomLiter ? `-${roomLiter}` : ''}`
  }`;
};

export const footerActualDate = (text: string): string =>
  text && text.includes('CURRENT_YEAR')
    ? text.replace(
        'CURRENT_YEAR',
        moment()
          .year()
          .toString(),
      )
    : text;

export const floatValueFormatter = (
  value: string | number,
  decimal?: null | number,
  integer?: null | number,
): string => {
  let prefix = '';

  if (String(value)[0] === '-') {
    prefix = '-';
  }

  const _value = String(value).replace(/[^\d\.]/g, '');
  let [before, after] = _value.split('.');

  if (before && typeof integer === 'number') {
    before = before.substr(0, integer);
  }
  if (after && typeof decimal === 'number') {
    after = after.substr(0, decimal);
  }

  const dot = _value.includes('.') ? '.' : '';
  before = before || '';
  after = after || '';
  return prefix + before + dot + after;
};

export const playerTimeFormat = (currentTime: any): string => {
  if (currentTime && typeof currentTime === 'number') {
    return moment.unix(Number(currentTime)).format('mm:ss');
  }

  return '00:00';
};

export const titleNormalize = (title: string, len: number): string => {
  if (title.length > len) {
    return title.slice(0, len - 3) + '...';
  }

  return title;
};
