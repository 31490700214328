import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import classNames from 'classnames';

import Layout from '@public/components/Layout';
import Particles from 'oc-animation/lib/Particles';
import City from 'oc-animation/lib/City';
import Page from '@components/Page';
import {
  PARTICLES_MAIN_LEFT_SIDE,
  PARTICLES_MAIN_RIGHT_SIDE,
  PARTICLES_MAIN_SM_DOWN,
} from '@public/constants/particles';
import Slider from './Slider';
import About from './About';
import HowSystemWorks from './HowSystemWorks';
import ProjectStatistics from './ProjectStatistics';
import Map from './Map';
import MobileApp from './MobileApp';
import GetStarted from './GetStarted';
import { useSnackbarStore } from './hooks';
import { MINIO_END_POINT } from '@constants/minio';
import { isUsinsk } from '@constants/project';
import { isBilling, isSpecialBilling, isKazan } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(20, 30),
      position: 'relative',
      zIndex: 3,

      [theme.breakpoints.down(1400)]: {
        padding: theme.spacing(16, 16),
      },

      [theme.breakpoints.down(1240)]: {
        padding: theme.spacing(88, 8, 16),
      },

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(88, 8, 12),
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(88, 4, 4),
      },

      [theme.breakpoints.down(400)]: {
        padding: theme.spacing(72, 4, 4),
      },
    },
    img: {
      height: '53vw',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '53vw',
      zIndex: 1,

      [theme.breakpoints.down('sm')]: {
        height: '65vw',
        width: '65vw',
      },

      [theme.breakpoints.down('xs')]: {
        '& g[mask="url(#mask)"]': {
          transform: 'scale(0.8) translateX(250px)',
        },

        height: '100vw',
        width: '100vw',
      },
    },
    particles: {
      zIndex: 2,
    },
  }),
);

const ABOUT_ID = '#main__about';
const HOW_SYSTEM_WORKS_ID = '#main__how-system-works-block';
const PROJECT_STATISTICS_ID = '#main__project-statistics';
const MAP_ID = '#main__map';
const MOBILE_APP_ID = '#main__mobile-app';
const GET_STARTED_ID = '#main__get-started';

const Main: FC<{ pathname: string }> = ({ pathname }) => {
  const classes = useStyles();

  const { cleanUpSnackbarStore, setMessage } = useSnackbarStore();

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    cleanUpSnackbarStore();

    const startAnimation = setTimeout(() => {
      setStartAnimation(true);

      if (!isBilling && !isSpecialBilling && !isKazan) {
        setMessage('Если у вас включен VPN, пожалуйста, выключите его', 'warning');
      }
    }, 2000);

    return () => clearTimeout(startAnimation);
  }, []);

  const CityElementImages = {
    cityBuildings: `images/service/city/city_buildings.png`,
    car1: `images/service/city/car1.png`,
    car2: `images/service/city/car2.png`,
    car3: `images/service/city/car3.png`,
    car4: `images/service/city/car4.png`,
    bicycle1: `images/service/city/bicycle1.png`,
    bicycle2: `images/service/city/bicycle2.png`,
    oldCouple: `images/service/city/old_couple.png`,
  };

  return (
    <>
      <Layout pathname={pathname}>
        <Page className={classes.container}>
          <>
            <Hidden xsDown={true}>
              <Particles
                className={classes.particles}
                particles={[...PARTICLES_MAIN_RIGHT_SIDE, ...PARTICLES_MAIN_LEFT_SIDE]}
              />
            </Hidden>
            <Hidden smUp={true}>
              <Particles className={classes.particles} particles={PARTICLES_MAIN_SM_DOWN} />
            </Hidden>
            <Slider />
            {!isUsinsk && <About id={ABOUT_ID} />}
            {!isUsinsk && <HowSystemWorks id={HOW_SYSTEM_WORKS_ID} />}
            {!isUsinsk && <ProjectStatistics id={PROJECT_STATISTICS_ID} />}
            {!isUsinsk && (
              <Hidden smDown={true}>
                <Map id={MAP_ID} />
              </Hidden>
            )}
            {!isUsinsk && <MobileApp id={MOBILE_APP_ID} />}
            {!isUsinsk && <GetStarted id={GET_STARTED_ID} />}
          </>
        </Page>
      </Layout>
      {startAnimation && (
        <City
          className={classNames(classes.img, 'ym-hide-content')}
          images={CityElementImages}
          minioEndPoint={MINIO_END_POINT}
        />
      )}
    </>
  );
};

export default Main;
