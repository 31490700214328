import { AddFilter, ClearFilter, RemoveFilter, FilterOperators } from '@stores/interfaces/Filter';
import { ClearErrors, RemoveError } from '@stores/interfaces/Errors';
import { Load, Count, Get, Create, Update, GetWithParams } from '@stores/interfaces/Entity';
import SortDirections from '@constants/sort';
import { Address } from './Address';
import { Resource } from './Resource';
import { Classifier } from './Classifier';
import { OrderIconTypes } from '@private/components/OrderIcons/OrderIcon/OrderIcon';
import project from '@constants/project';

const SHORT_TEXT_BREAKPOINT = 120;
const SECONDS_PER_DAY = 86400;

export enum iconForAttribute {
  NK_SYSTEM = 'nk',
  EXPIRES = 'time',
  EXPIRED_BY_THE_CONTROL = 'triangular',
  EXPIRED_BY_THE_PROVIDER = 'circle',
  SPECIAL = 'special',
  ERA = 'era',
  LOBACHEVSKY = 'lobachevsky',
  LOCOLO = 'locolo',
}

export enum EraRecordType {
  STREET = 'streetRecord',
  HOME = 'homeRecord',
  FLAT = 'flatRecord',
  DESCRIPTION = 'descriptionRecord',
}
export interface EraRecord {
  id: number;
  type: EraRecordType;
}

export interface EraRecordResponseItem extends EraRecord {
  url: string;
}

export interface EraRecordResponseList {
  items: EraRecordResponseItem[];
}

export interface OrderInterface {
  id: number;
  activeComment?: string | null;
  activeReason?: number | null;
  activeStatus?: number | null;
  activeStatusTime?: number | null;
  buildingNumber?: string | null;
  controlId?: string | null;
  controlName?: string | null;
  flatNumber?: string | null;
  flatId?: number | null;
  houseNumber?: string | null;
  isInTime?: boolean | null;
  isInTimeControl?: boolean | null;
  number?: string | null;
  serviceName?: string | null;
  serviceId?: number | null;
  parentServiceName?: string | null;
  standardDate?: number | null;
  standardDateLeft: number;
  state?: string | null;
  streetName?: string | null;
  text?: string | null;
  timestamp?: number | null;
  title?: string | null;
  trackerId?: number | null;
  type?: OrderType | null;
  year?: number | null;
  userSpecialCategories?: Array<string | null>;
  personalAccountNumber?: string | null;
  nameApplicant?: string | null;
  phoneApplicant?: string | null;
  delayedDate?: number | null;
  providerId?: number | null;
  providerName?: string | null;
  toProviderCount?: number | null;
  address: string | null;
  getAddress: string;
  getAttributes: OrderIconTypes[];
  streetId: number | null;
  houseId: number | null;
  attribute: OrderIconTypes[];
  foreignSystems?: string[];
  latitude?: string | null;
  longitude?: string | null;
  viewed: boolean;
  stateHistory?: string;
  foreignSystemOrderId?: string;
  records?: EraRecord[];
}

export class Order {
  public id: number;
  public activeComment?: string | null;
  public activeReason?: number | null;
  public activeStatus?: number | null;
  public activeStatusTime?: number | null;
  public buildingNumber?: string | null;
  public controlId?: string | null;
  public controlName?: string | null;
  public flatNumber?: string | null;
  public flatId?: number | null;
  public houseNumber?: string | null;
  public isInTime?: boolean | null;
  public isInTimeControl?: boolean | null;
  public number?: string | null;
  public serviceName?: string | null;
  public serviceId?: number | null;
  public standardDate?: number | null;
  public state?: string | null;
  public streetName?: string | null;
  public text?: string | null;
  public shortText?: string | null;
  public timestamp?: number | null;
  public title?: string | null;
  public trackerId?: number | null;
  public type?: OrderType | null;
  public year?: number | null;
  public userSpecialCategories?: Array<string | null>;
  public personalAccountNumber?: string | null;
  public nameApplicant?: string | null;
  public phoneApplicant?: string | null;
  public delayedDate?: number | null;
  public providerId?: number | null;
  public providerName?: string | null;
  public toProviderCount?: number | null;
  public parentServiceName?: string | null;
  public address: string | null;
  public streetId: number | null;
  public houseId: number | null;
  public latitude?: string | null;
  public longitude?: string | null;
  public foreignSystems?: string[];
  public foreignSystem?: string;
  public attribute: OrderIconTypes[];
  public standardDateLeft: number;
  public viewed: boolean;
  public stateHistory?: string;
  public foreignSystemOrderId?: string;
  public records?: EraRecord[];

  public constructor(value: OrderInterface) {
    this.id = value.id;
    this.activeComment = value.activeComment;
    this.activeReason = value.activeReason;
    this.activeStatus = value.activeStatus;
    this.activeStatusTime = value.activeStatusTime;
    this.buildingNumber = value.buildingNumber;
    this.controlId = value.controlId;
    this.controlName = value.controlName;
    this.flatNumber = value.flatNumber;
    this.flatId = value.flatId;
    this.houseNumber = value.houseNumber;
    this.isInTime = value.isInTime;
    this.isInTimeControl = value.isInTimeControl;
    this.number = value.number;
    this.serviceName = value.serviceName;
    this.serviceId = value.serviceId;
    this.standardDate = value.standardDate;
    this.state = value.state;
    this.streetName = value.streetName;
    this.text = value.text;
    this.timestamp = value.timestamp;
    this.title = value.title;
    this.trackerId = value.trackerId;
    this.type = value.type;
    this.year = value.year;
    this.userSpecialCategories = value.userSpecialCategories;
    this.personalAccountNumber = value.personalAccountNumber;
    this.nameApplicant = value.nameApplicant;
    this.phoneApplicant = value.phoneApplicant?.replace(/^8/g, '+7');
    this.delayedDate = value.delayedDate;
    this.providerId = value.providerId;
    this.providerName = value.providerName;
    this.address = this.getAddress;
    this.streetId = value.streetId;
    this.houseId = value.houseId;
    this.toProviderCount = value.toProviderCount;
    this.parentServiceName = value.parentServiceName;
    this.latitude = value.latitude;
    this.longitude = value.longitude;
    this.standardDateLeft = value.standardDateLeft;
    this.foreignSystems = value.foreignSystems;
    this.attribute = this.getAttributes;
    this.viewed = value.viewed;
    this.stateHistory = value.stateHistory;
    this.foreignSystemOrderId = value.foreignSystemOrderId;
    this.records = value.records;

    if (value.text && value.text.length < SHORT_TEXT_BREAKPOINT) {
      this.shortText = value.text;
    } else {
      this.shortText = `${value.text && value.text.substring(0, SHORT_TEXT_BREAKPOINT)}...`;
    }
  }

  public get getAttributes(): OrderIconTypes[] {
    const attributes: OrderIconTypes[] = [];

    if (this.foreignSystems?.length) {
      this.foreignSystems.forEach(system => {
        switch (system) {
          case 'opengov':
            if (
              ['nizhnekamsk', 'kazan', 'elabuga', 'almet', 'agryz', 'innopolis', 'zainsk'].includes(
                project,
              )
            ) {
              attributes.push(iconForAttribute.NK_SYSTEM);
            }
            break;

          case 'era':
            if (['kazan'].includes(project)) {
              attributes.push(iconForAttribute.ERA);
            }
            break;

          case 'locolo':
            if (['kazan'].includes(project)) {
              attributes.push(iconForAttribute.LOCOLO);
            }
            break;

          case 'lobachevsky':
            if (['nnov'].includes(project)) {
              attributes.push(iconForAttribute.LOBACHEVSKY);
            }
            break;
          //в дальнейшем могут быть добавлены другие системы
          default:
            break;
        }
      });
    }

    if (
      this.standardDateLeft &&
      this.standardDateLeft < SECONDS_PER_DAY &&
      this.state &&
      [
        'new',
        'restored',
        'to provider',
        'not exist',
        'unconfirmed',
        'not accepted',
        'motivated refusal',
        'to general provider',
      ].includes(this.state)
    ) {
      attributes.push(iconForAttribute.EXPIRES);
    }

    if (!this.isInTimeControl) {
      attributes.push(iconForAttribute.EXPIRED_BY_THE_CONTROL);
    }

    if (!this.isInTime) {
      attributes.push(iconForAttribute.EXPIRED_BY_THE_PROVIDER);
    }

    if (this.userSpecialCategories?.length) {
      attributes.push(iconForAttribute.SPECIAL);
    }

    return attributes;
  }

  public get getAddress(): string {
    return `ул. ${this.streetName}, д. ${this.houseNumber}, ${
      this.buildingNumber !== '-' ? `корп. ${this.buildingNumber},` : ''
    }кв. ${this.flatNumber}`;
  }
}

export interface OrderCreationProps {
  nameApplicant?: string;
  personalAccountNumber: string;
  service: number;
  address: Address;
  phone?: string | null;
  text?: string | null;
  email?: string | null;
  fileIds?: number[] | null;
  latitude?: string | null;
  longitude?: string | null;
}

export interface OrderFormProps {
  address?: Address;
  nameApplicant: string;
  resources?: Resource[];
  flatAccountNumber: string;
  phone: string;
  service?: Classifier;
  text: string;
  email?: string;
}

export enum OrderKeys {
  ID = 'id',
  ATTRIBUTE = 'attribute',
  STATE = 'state',
  NUMBER = 'number',
  TYPE = 'type',
  STREET_NAME = 'streetName',
  HOUSE_NUMBER = 'houseNumber',
  BUILDING_NUMBER = 'buildingNumber',
  FLAT_NUMBER = 'flatNumber',
  TEXT = 'text',
  STANDARD_DATE = 'standardDate',
  CREATED_AT = 'timestamp',
  CONTROL_NAME = 'controlName',
  PROVIDER_NAME = 'providerName',
  ADDRESS = 'address',
  FLAT_ID = 'flatId',
  STREET_ID = 'streetId',
  HOUSE_ID = 'houseId',
  IS_IN_TIME = 'isInTime',
  IS_IN_TIME_CONTROL = 'isInTimeControl',
  PARENT_SERVICE_NAME = 'parentServiceName',
  STANDARD_DATE_LEFT = 'standardDateLeft',
  FOREIGN_SYSTEMS = 'foreignSystems',
  FOREIGN_SYSTEM = 'foreignSystem',
  TRACKER = 'trackerId',
  STATE_HISTORY = 'stateHistory',
  PROVIDER_ID = 'providerId',
  CONTROL_ID = 'controlId',
  FOREIGN_SYSTEM_ORDER_ID = 'foreignSystemOrderId',
  PHONE_APPLICANT = 'phoneApplicant',
}

export interface OrderFilterProps {
  [OrderKeys.ID]?: FilterOperators<number>;
  [OrderKeys.NUMBER]?: FilterOperators<string>;
  [OrderKeys.STATE]?: FilterOperators<string>;
  [OrderKeys.TYPE]?: FilterOperators<string>;
  [OrderKeys.STREET_NAME]?: FilterOperators<string>;
  [OrderKeys.HOUSE_NUMBER]?: FilterOperators<string>;
  [OrderKeys.BUILDING_NUMBER]?: FilterOperators<string>;
  [OrderKeys.FLAT_NUMBER]?: FilterOperators<string>;
  [OrderKeys.STANDARD_DATE]?: FilterOperators<number>;
  [OrderKeys.CREATED_AT]?: FilterOperators<number>;
  [OrderKeys.TEXT]?: FilterOperators<string>;
  [OrderKeys.CONTROL_NAME]?: FilterOperators<string>;
  [OrderKeys.PROVIDER_NAME]?: FilterOperators<string>;
  [OrderKeys.FLAT_ID]?: FilterOperators<number>;
  [OrderKeys.STREET_ID]?: FilterOperators<number>;
  [OrderKeys.HOUSE_ID]?: FilterOperators<number>;
  [OrderKeys.IS_IN_TIME]?: boolean;
  [OrderKeys.IS_IN_TIME_CONTROL]?: boolean;
  [OrderKeys.STANDARD_DATE_LEFT]?: FilterOperators<number>;
  [OrderKeys.PARENT_SERVICE_NAME]?: FilterOperators<string>;
  [OrderKeys.FOREIGN_SYSTEM]?: FilterOperators<string>;
  [OrderKeys.TRACKER]?: FilterOperators<number>;
  [OrderKeys.PROVIDER_ID]?: FilterOperators<number>;
  [OrderKeys.CONTROL_ID]?: FilterOperators<number>;
  [OrderKeys.FOREIGN_SYSTEM_ORDER_ID]?: FilterOperators<string>;
  [OrderKeys.STATE_HISTORY]?: string;
  [OrderKeys.PHONE_APPLICANT]?: number;
  unconfirmedCount?: FilterOperators<number>;
  status?: FilterOperators<FilterOperators<number>>;
  $or?: { isInTime?: boolean; isInTimeControl?: boolean }[];
}

export interface LastApplicantParamsProps {
  flatId: number;
  trackerId: number;
}

export type AddOrderFilter = AddFilter<OrderFilterProps>;

export type RemoveOrderFilter = RemoveFilter<OrderKeys>;

export type ClearOrderFilter = ClearFilter;

export interface OrderSortProps {
  field: OrderKeys;
  desc: SortDirections;
}

export enum OrderType {
  EMERGENCY = 'emergency',
  CURRENT = 'current',
}

export const OrderTypeLabels: { [key in OrderType]: string } = {
  current: 'Текущая',
  emergency: 'Аварийная',
};

export enum OrderState {
  ALL = 'all',
  HOUSE = 'house',
  NEW = 'new',
  TO_PROVIDER = 'toProvider',
  IN_WORK = 'inWork',
  DONE = 'done',
  OK = 'ok',
  CLOSED = 'closed',
  IS_IN_TIME = 'isInTime',
  DELAYED = 'delayed',
  DELETED = 'deleted',
  UNCONFIRMED = 'unconfirmed',
  RESTORED = 'restored',
  TO_EXECUTE = 'toExecute',
  CLOSED_BY_CITIZEN = 'closedByCitizen',
  CLOSED_BY_COMMUNAL = 'closedByCommunal',
  CLOSED_BY_ANALYST = 'closedByAnalyst',
  CLOSED_AUTO = 'closedAuto',
  CLOSED_BY_STD = 'closedByStd',
  NOT_ACCEPTED = 'notAccepted',
  RETURNED = 'returned',
  TO_GENERAL_PROVIDER = 'toGeneralProvider',
  REJECTED = 'rejected',
  CONTROL = 'control',
  PROBLEMATIC = 'problematic',
}

export const OrderStateLabels: {
  [key in OrderState]: { singular: string; plural: string; key: string };
} = {
  all: { singular: 'Все', plural: 'Все', key: 'all' },
  house: { singular: 'По дому', plural: 'По дому', key: 'house' },
  closed: { singular: 'Закрыта', plural: 'Закрытые', key: 'closed' },
  isInTime: { singular: 'Просрочена', plural: 'Просроченные', key: 'isInTime' },
  closedAuto: {
    key: 'closed auto',
    plural: 'Закрыта автоматически',
    singular: 'Закрыта автоматически',
  },
  closedByAnalyst: {
    key: 'closed by analyst',
    plural: 'Закрыта аналитиком',
    singular: 'Закрыта аналитиком',
  },
  closedByCitizen: {
    key: 'closed by citizen',
    plural: 'Закрыта жителем',
    singular: 'Закрыта жителем',
  },
  closedByCommunal: {
    key: 'closed by communal',
    plural: 'Передано коммунальным службам',
    singular: 'Передано коммунальным службам',
  },
  closedByStd: {
    key: 'closed by std',
    plural: 'Закрыта по нормативу',
    singular: 'Закрыта по нормативу',
  },
  delayed: { singular: 'Отложена', plural: 'Отложенные', key: 'delayed' },
  deleted: { singular: 'Удалена', plural: 'Удаленные', key: 'deleted' },
  done: { singular: 'Выполнена', plural: 'Выполненные', key: 'done' },
  inWork: { singular: 'В работе', plural: 'В работе', key: 'in work' },
  new: { singular: 'Новая', plural: 'Новые', key: 'new' },
  notAccepted: { singular: 'Отказано', plural: 'Отказано', key: 'not accepted' },
  ok: { singular: 'Подтверждена', plural: 'Подтвержденные', key: 'ok' },
  rejected: { singular: 'Отклонена', plural: 'Отклоненные', key: 'rejected' },
  restored: { singular: 'Восстановлена', plural: 'Восстановленные', key: 'restored' },
  returned: { singular: 'Возвращена', plural: 'Возвращена', key: 'returned' },
  toExecute: { singular: 'К выполнению', plural: 'К выполнению', key: 'to execute' },
  toGeneralProvider: { singular: 'Назначена', plural: 'Назначена', key: 'to general provider' },
  toProvider: { singular: 'Назначена', plural: 'Назначенные', key: 'to provider' },
  unconfirmed: { singular: 'Не подтверждена', plural: 'Неподтвержденные', key: 'unconfirmed' },
  control: { singular: 'Контроль', plural: 'Контроль', key: 'Контроль' },
  problematic: { singular: 'Проблемные', plural: 'Проблемные', key: 'Проблемные' },
};

export const OrderKeyLabel: { [key: string]: string } = {
  [OrderStateLabels.all.key]: OrderStateLabels.all.singular,
  [OrderStateLabels.closed.key]: OrderStateLabels.closed.singular,
  [OrderStateLabels.closedAuto.key]: OrderStateLabels.closedAuto.singular,
  [OrderStateLabels.closedByAnalyst.key]: OrderStateLabels.closedByAnalyst.singular,
  [OrderStateLabels.closedByCitizen.key]: OrderStateLabels.closedByCitizen.singular,
  [OrderStateLabels.closedByCommunal.key]: OrderStateLabels.closedByCommunal.singular,
  [OrderStateLabels.closedByStd.key]: OrderStateLabels.closedByStd.singular,
  [OrderStateLabels.isInTime.key]: OrderStateLabels.isInTime.singular,
  [OrderStateLabels.delayed.key]: OrderStateLabels.delayed.singular,
  [OrderStateLabels.deleted.key]: OrderStateLabels.deleted.singular,
  [OrderStateLabels.done.key]: OrderStateLabels.done.singular,
  [OrderStateLabels.inWork.key]: OrderStateLabels.inWork.singular,
  [OrderStateLabels.new.key]: OrderStateLabels.new.singular,
  [OrderStateLabels.notAccepted.key]: OrderStateLabels.notAccepted.singular,
  [OrderStateLabels.ok.key]: OrderStateLabels.ok.singular,
  [OrderStateLabels.rejected.key]: OrderStateLabels.rejected.singular,
  [OrderStateLabels.restored.key]: OrderStateLabels.restored.singular,
  [OrderStateLabels.returned.key]: OrderStateLabels.returned.singular,
  [OrderStateLabels.toExecute.key]: OrderStateLabels.toExecute.singular,
  [OrderStateLabels.toGeneralProvider.key]: OrderStateLabels.toGeneralProvider.singular,
  [OrderStateLabels.toProvider.key]: OrderStateLabels.toProvider.singular,
  [OrderStateLabels.unconfirmed.key]: OrderStateLabels.unconfirmed.singular,
  [OrderStateLabels.control.key]: OrderStateLabels.control.singular,
  [OrderStateLabels.problematic.key]: OrderStateLabels.problematic.singular,
};

export enum OrderTabs {
  ALL = 'all',
  HOUSE = 'house',
  CONTROL = 'control',
  PROBLEMATIC = 'problematic',
  NEW = 'new',
  TO_PROVIDER = 'toProvider',
  IN_WORK = 'inWork',
  DONE = 'done',
  OK = 'ok',
  CLOSED = 'closed',
  IS_IN_TIME = 'isInTime',
  DELAYED = 'delayed',
  UNCONFIRMED = 'unconfirmed',
  NK = 'nk',
  ERA = 'era',
  LOBACHEVSKY = 'lobachevsky',
  LOCOLO = 'locolo',
}

export const orderTabsLabels: { [key in OrderTabs]: string } = {
  [OrderTabs.ALL]: 'Все',
  [OrderTabs.HOUSE]: 'По дому',
  [OrderTabs.CONTROL]: 'Контроль',
  [OrderTabs.PROBLEMATIC]: 'Проблемные',
  [OrderTabs.NEW]: 'Новые',
  [OrderTabs.TO_PROVIDER]: 'Назначенные',
  [OrderTabs.IN_WORK]: 'В работе',
  [OrderTabs.DONE]: 'Выполненные',
  [OrderTabs.OK]: 'Подтвержденные',
  [OrderTabs.CLOSED]: 'Закрытые',
  [OrderTabs.IS_IN_TIME]: 'Просроченные',
  [OrderTabs.DELAYED]: 'Отложенные',
  [OrderTabs.UNCONFIRMED]: 'Неподтвержденные',
  [OrderTabs.NK]: 'Народный контроль',
  [OrderTabs.ERA]: 'Создано роботом',
  [OrderTabs.LOBACHEVSKY]: 'Система "Лобачевский"',
  [OrderTabs.LOCOLO]: 'Система "Локоло"',
};

export const orderTabFilter: { [key in OrderTabs]: OrderFilterProps } = {
  [OrderTabs.ALL]: {},
  [OrderTabs.HOUSE]: {},
  [OrderTabs.CONTROL]: {
    [OrderKeys.IS_IN_TIME]: false,
    state: {
      $in: [
        OrderStateLabels.delayed.key,
        OrderStateLabels.inWork.key,
        OrderStateLabels.new.key,
        OrderStateLabels.toProvider.key,
      ],
    },
  },
  [OrderTabs.IS_IN_TIME]: {
    $or: [
      {
        [OrderKeys.IS_IN_TIME]: false,
        [OrderKeys.IS_IN_TIME_CONTROL]: false,
      },
      {
        [OrderKeys.IS_IN_TIME]: false,
      },
      {
        [OrderKeys.IS_IN_TIME_CONTROL]: false,
      },
    ],
    state: {
      $in: [
        OrderStateLabels.new.key,
        OrderStateLabels.toProvider.key,
        OrderStateLabels.restored.key,
        OrderStateLabels.done.key,
      ],
    },
  },
  [OrderTabs.PROBLEMATIC]: {
    state: undefined,
    unconfirmedCount: {
      $gt: 0,
    },
    status: {
      $not: {
        $in: [6, 100, 700],
      },
    },
  },
  [OrderTabs.NEW]: { state: { $in: [OrderStateLabels.new.key, OrderStateLabels.restored.key] } },
  [OrderTabs.TO_PROVIDER]: { state: { $eq: OrderStateLabels[OrderState.TO_PROVIDER].key } },
  [OrderTabs.IN_WORK]: { state: { $eq: OrderStateLabels[OrderState.IN_WORK].key } },
  [OrderTabs.DONE]: { state: { $eq: OrderStateLabels[OrderState.DONE].key } },
  [OrderTabs.OK]: { state: { $eq: OrderStateLabels[OrderState.OK].key } },
  [OrderTabs.CLOSED]: {
    state: {
      $in: [
        OrderStateLabels.closed.key,
        OrderStateLabels.closedAuto.key,
        OrderStateLabels.closedByAnalyst.key,
        OrderStateLabels.closedByCitizen.key,
        OrderStateLabels.closedByCommunal.key,
        OrderStateLabels.closedByStd.key,
      ],
    },
  },
  [OrderTabs.DELAYED]: { state: { $eq: OrderStateLabels[OrderState.DELAYED].key } },
  [OrderTabs.UNCONFIRMED]: { state: { $eq: OrderStateLabels[OrderState.UNCONFIRMED].key } },
  [OrderTabs.NK]: { foreignSystem: { $eq: 'opengov' } },
  [OrderTabs.ERA]: { foreignSystem: { $eq: 'era' } },
  [OrderTabs.LOBACHEVSKY]: { foreignSystem: { $eq: 'lobachevsky' } },
  [OrderTabs.LOCOLO]: { foreignSystem: { $eq: 'locolo' } },
};

export enum OrderErrorKeys {
  FLAT_ACCOUNT = 'flatAccount',
}
export interface OrderErrorProps {
  [OrderErrorKeys.FLAT_ACCOUNT]?: string;
}

export type RemoveOrderError = RemoveError<OrderErrorKeys>;

export type ClearOrderErrors = ClearErrors;

export type CreateOrder = Create<
  OrderCreationProps,
  Promise<boolean | Order | null>,
  { notification?: boolean; _returnNewOrder?: boolean }
>;

export type UpdateOrder = Update<
  {
    payload?: {
      fileIds?: number[];
      orderParams?: {
        comment?: string;
        delayedDate?: number;
        standardDate?: number;
        providerId?: number;
      };
      transition: string;
      workOrderFileIds?: number[];
    };
    entity?: Order;
  },
  Promise<Order | null>
>;

export type LoadOrder = Load<
  {
    filter?: OrderFilterProps;
    sort?: OrderSortProps[];
    limit?: number;
    offset?: number;
    page?: string;
  },
  Order[]
>;

export type LastApplicant = {
  nameApplicant: string;
  phoneApplicant: string;
  emailApplicant: string;
};

export type GetOrder = Get<Order, OrderFilterProps>;

export type GetLastApplicant = GetWithParams<LastApplicant, LastApplicantParamsProps>;

export type CountOrder = Count<
  { filter?: OrderFilterProps; limit?: number; offset?: number },
  number
>;

export type ExportOrder = Load<
  {
    filter?: OrderFilterProps;
    sort?: OrderSortProps[];
    limit?: number;
    offset?: number;
    select?: string[];
  },
  string
>;
