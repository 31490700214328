import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { ORDER, SIGN_UP } from '@public/constants/routes';
import { ORGANIZATION_SIGN_UP_LINK } from '@public/constants/organization';
import { isUsinsk } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(50),

      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(75),
      },
    },
    buttons: {
      alignItems: 'flex-end',
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        '& > a:nth-child(2)': {
          marginTop: theme.spacing(4),
        },

        alignItems: 'center',
        flexDirection: 'column',
        flexGrow: 0,
        paddingTop: theme.spacing(4),
      },
    },
    container: {
      marginTop: theme.spacing(40),

      [theme.breakpoints.down(1240)]: {
        flexDirection: 'column',
      },

      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        flexWrap: 'nowrap',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(20),
      },
    },
    description: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'justify',
      },
    },
    link: {
      textDecoration: 'none',
    },
    paper: {
      // borderRadius: 20,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      height: theme.spacing(60),
      maxWidth: theme.spacing(140),
      padding: `${theme.spacing(6)}px !important`,

      [theme.breakpoints.down(1240)]: {
        '&:nth-child(2)': {
          marginTop: theme.spacing(8),
        },
      },

      [theme.breakpoints.down('sm')]: {
        maxWidth: theme.spacing(165),
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none !important',
        height: '100%',
        padding: `${theme.spacing(3)}px !important`,
      },
    },
    paperContent: {
      flexGrow: 1,
      width: '100%',
    },
    title: {
      // fontWeight: 700,
      marginBottom: theme.spacing(5),
      fontSize: '25px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.85rem !important',
        textAlign: 'center',
      },
    },
  }),
);

interface GetStartedProps {
  id: string;
}

const GetStarted: FC<GetStartedProps> = ({ id }) => {
  const classes = useStyles();

  const sendOrderElement: ReactElement = (
    <Grid className={classes.paper} item={true} component={Paper}>
      <Typography className={classes.title} color="textPrimary" variant="h1">
        Оставить заявку
      </Typography>
      <Typography className={classes.description} color="textPrimary" variant="body1">
        Вы можете оставить заявку в адрес вашей обслуживающей организации.
      </Typography>
      <div className={classes.buttons}>
        <Link className={classes.link} id={`${id}__send-order`} to={ORDER}>
          <Button className={classes.button} color="primary" variant="contained">
            Подать заявку
          </Button>
        </Link>
        <Link className={classes.link} id={`${id}__sign-up`} to={SIGN_UP}>
          <Button className={classes.button} color="primary" variant="contained">
            Регистрация
          </Button>
        </Link>
      </div>
    </Grid>
  );

  const organizationSignUpElement: ReactElement = (
    <Grid className={classes.paper} item={true} component={Paper}>
      <Typography className={classes.title} color="textPrimary" variant="h1">
        Подключить организацию
      </Typography>
      <Typography className={classes.description} color="textPrimary" variant="body1">
        Вы выполняете работы в сфере жилищно-коммунального хозяйства?
      </Typography>
      <div className={classes.buttons}>
        <a
          className={classes.link}
          id={`${id}__ogranization-sign-up`}
          tabIndex={-1}
          target="_blank"
          rel="noopener noreferrer"
          href={ORGANIZATION_SIGN_UP_LINK}
        >
          <Button className={classes.button} color="primary" variant="contained">
            Оставить заявку
          </Button>
        </a>
      </div>
    </Grid>
  );

  return (
    <Grid
      className={classes.container}
      id={id}
      container={true}
      justify="space-between"
      spacing={6}
      component="section"
      data-testid="get-started"
    >
      {!isUsinsk && sendOrderElement}
      {organizationSignUpElement}
    </Grid>
  );
};

export default GetStarted;
