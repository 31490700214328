import React, { FC } from 'react';
import Hidden from '@material-ui/core/Hidden';

import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

interface HeaderMenuProps {
  pathname: string;
  id: string;
}

const HeaderMenu: FC<HeaderMenuProps> = ({ id, pathname }) => {
  return (
    <>
      <Hidden smDown={true}>
        <DesktopMenu id={id} pathname={pathname} />
      </Hidden>
      <Hidden mdUp={true}>
        <MobileMenu id={id} />
      </Hidden>
    </>
  );
};

export default HeaderMenu;
