import React, { FC, useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import config from '@constants/config';
import { isBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    oldVersionAlert: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 100,

      [theme.breakpoints.down('xs')]: {
        maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
        left: theme.spacing(4),
      },
    },
    oldVersionAlertMessage: {
      width: '100%',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      marginLeft: theme.spacing(2),
    },
    nonPaddingButton: {
      padding: 0,
    },
    messageContainer: {
      alignSelf: 'center',
    },
  }),
);

interface OldVersionAlert {
  onClick?: () => void;
}

const OldVersionAlert: FC<OldVersionAlert> = ({ onClick }) => {
  const classes = useStyles();

  const [showOldVersionAlert, setShowOldVersionAlert] = useState(Boolean(config.oldVersion));

  const handleCloseOldVersionAlert = useCallback(() => {
    setShowOldVersionAlert(false);
    onClick?.();
  }, []);

  return showOldVersionAlert ? (
    <Alert
      classes={{ root: classes.oldVersionAlert, message: classes.oldVersionAlertMessage }}
      icon={false}
    >
      <div className={classes.messageContainer}>
        {isBilling ? (
          <Link href={config.oldVersion}>Вернуться на старую версию</Link>
        ) : (
          <Link href={config.oldVersion}>Открыть старую версию системы</Link>
        )}
      </div>
      <IconButton
        className={classNames(classes.closeButton, { [classes.nonPaddingButton]: isBilling })}
        color="primary"
        onClick={handleCloseOldVersionAlert}
      >
        <CloseIcon />
      </IconButton>
    </Alert>
  ) : null;
};

export default OldVersionAlert;
