import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  Banner,
  BannerFilterProps,
  BannerInterface,
  BannerKeys,
  LoadBanner,
} from '@core/entities/Billing/Banner';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';

type BannerIndexResponse = JrpcResponse<{ items: BannerInterface[] }>;

class BannerStore extends Store
  implements
    Loading,
    Filter<BannerFilterProps, BannerKeys>,
    Entity<Banner, { filter?: BannerFilterProps }> {
  @observable private _banners: Banner[];
  @observable private _filter: BannerFilterProps;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(500).bind(this);

  public constructor(services: Services) {
    super(services);

    this._banners = [];
    this._filter = {};
    this._loading = false;
  }

  @action public load: LoadBanner = async params => {
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);

    this._filter = filter;

    const {
      data: { result },
    }: AxiosResponse<BannerIndexResponse> = await this._services.billing.requests.organizationBannerIndex(
      { params: { filter, limit: 0 } },
    );

    if (result && Array.isArray(result.items)) {
      const banners = result.items.map(value => new Banner(value));

      this._banners = banners;
      this._loading = false;

      return banners;
    }

    this._endLoading();

    return [];
  };

  @action public cleanUp = (): void => {
    this._banners = [];
    this._filter = {};
    this._loading = false;
  };

  @computed public get list(): Banner[] {
    return toJS(this._banners);
  }

  @computed public get filter(): BannerFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default BannerStore;
