import Store from './Store';
import { action, computed, observable } from 'mobx';

import Services from '@core/services';
import SnackbarStore from './SnackbarStore';
import { SmevAccountList, SmevErrorInterface, SmevParams } from '@core/entities/Billing/Smev';

class SmevStore extends Store {
  private readonly _snackBarStore: SnackbarStore;

  @observable private _loadingSmevAccounts: boolean;
  @observable private _loadingSmevAccountsError: boolean;
  @observable private _capchaError: boolean;
  @observable private _notFirstFromSmev: boolean;
  @observable private _smevAccountsList?: SmevAccountList;
  @observable private _smevAccountsError?: SmevErrorInterface;

  public constructor(services: Services, snackBarStore: SnackbarStore) {
    super(services);

    this._snackBarStore = snackBarStore;

    this._loadingSmevAccounts = false;
    this._loadingSmevAccountsError = false;
    this._capchaError = false;
    this._notFirstFromSmev = false;
  }

  @action public loadSmevAccounts = async (params: SmevParams): Promise<SmevAccountList> => {
    this._loadingSmevAccountsError = false;
    this._capchaError = false;
    this._loadingSmevAccounts = true;
    this._smevAccountsList = undefined;
    this._smevAccountsError = undefined;
    this._notFirstFromSmev = true;

    let smevAccountsList: SmevAccountList = {};

    const { filter, limit, offset } = params;

    await this._services.billing.requests
      .clerkAccountIndex({
        params: {
          filter,
          offset,
          limit,
        },
      })
      .then(
        ({
          data: { result, error },
        }: {
          data: { result: SmevAccountList; error: SmevErrorInterface };
        }) => {
          if (error && error?.code === 100006) {
            this._capchaError = true;
            this._snackBarStore.setMessage(
              'В целях безопасности пользователей мы вынуждены отклонить ваш запрос. Попробуйте повторить запрос позднее',
              'error',
            );
          } else if (
            (error && error?.code === 191000) ||
            (Array.isArray(result) && result.length === 0)
          ) {
            this._smevAccountsList = new SmevAccountList({});
          } else if ((error && error?.code !== 100006 && error?.code !== 191000) || !result) {
            this._smevAccountsError = {
              code: 999,
              data: JSON.stringify(error),
              message: '',
            };
          } else if (Array.isArray(result)) {
            smevAccountsList = new SmevAccountList({ result });
            this._smevAccountsList = smevAccountsList;
          }
        },
      )
      .catch(error => {
        this._smevAccountsError = {
          code: 999,
          data: JSON.stringify(error),
          message: '',
        };
      })
      .finally(() => (this._loadingSmevAccounts = false));

    this._smevAccountsList = smevAccountsList;

    return smevAccountsList;
  };

  @computed public get smevAccountList(): SmevAccountList | undefined {
    return this._smevAccountsList;
  }

  @computed public get loadingSmevAccounts(): boolean {
    return this._loadingSmevAccounts;
  }

  @computed public get notFirstFromSmev(): boolean {
    return this._notFirstFromSmev;
  }

  @action public setNotFirstFromSmev: (bool: boolean) => void = (bool: boolean): void => {
    this._notFirstFromSmev = bool;
  };

  @computed public get loadingSmevAccountsError(): boolean {
    return this._loadingSmevAccountsError;
  }

  @computed public get smevAccountsError(): SmevErrorInterface | undefined {
    return this._smevAccountsError;
  }

  @action public smevAccountListCleanUp = () => {
    this._smevAccountsList = undefined;
  };

  @computed public get capchaError(): boolean {
    return this._capchaError;
  }
}

export default SmevStore;
