export enum ResourceTypesEnum {
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_TIFF = 'image/tiff',
  IMAGE_GIF = 'image/gif',
  APPLICATION_PDF = 'application/pdf',
}

export interface ResourceInterface {
  id: number;
  fileName?: string;
  originalFileName?: string;
  description?: string;
  size?: number;
  mime?: ResourceTypesEnum;
  isSecure?: boolean;
  fileUrl?: string;
  uploadTime?: number;
}

export class Resource {
  public id: number;
  public fileName?: string;
  public originalFileName?: string;
  public description?: string;
  public size?: number;
  public mime?: ResourceTypesEnum | string;
  public isSecure?: boolean;
  public fileUrl?: string;
  public uploadTime?: number;

  public constructor(value: ResourceInterface) {
    this.id = value.id;
    this.fileName = value.fileName;
    this.originalFileName = value.originalFileName;
    this.description = value.description;
    this.size = value.size;
    this.mime = value.mime;
    this.isSecure = value.isSecure;
    this.fileUrl = value.fileUrl;
    this.uploadTime = value.uploadTime;
  }
}

export enum ResourcePrefix {
  SECURED = 'secured',
  NOT_SECURED = 'notsecured',
}

export type SourceType =
  | 'order'
  | 'photoArchive'
  | 'report'
  | 'status'
  | 'technicalPassport'
  | 'workOrder';

export type UploadResource = (
  values: File[],
  resources?: Resource[],
  isSecure?: boolean,
) => Promise<Resource[]>;

export type LoadResource = (
  sourceType: SourceType,
  sourceId: string[],
  limit?: number,
) => Promise<Resource[]>;

export type RemoveResource = (id: number, resources?: Resource[]) => Resource[];
