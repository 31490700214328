/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useCounterStore = () => {
  const { counterStore } = useStores();

  return useObserver(() => ({
    loadCounters: counterStore.load,
    loadValue: counterStore.loadValue,
    list: counterStore.list,
  }));
};

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    paymentGetLink: paymentStore.getLink,
  }));
};

export const useSnackbarStore = () => {
  const { snackbarStore } = useStores();

  return useObserver(() => ({
    setMessage: snackbarStore.setMessage,
  }));
};

export const useAuthenticationStore = () => {
  const { authenticationStore } = useStores();

  return useObserver(() => ({
    authenticated: authenticationStore.authenticated,
  }));
};

export const useAccountStore = () => {
  const { accountStore } = useStores();

  return useObserver(() => ({
    accountList: accountStore.list,
  }));
};
