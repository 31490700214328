import { Load } from '@core/stores/interfaces/Entity';
export interface PaymentReceiptInterface {
  paymentId: number;
  paymentDate?: string | null;
  amount?: number | null;
  commission?: number | null;
  receiverName?: string | null;
  receiverINN?: string | null;
  receiverBankName?: string | null;
  receiverBankBIK?: string | null;
  receiverBankAccount?: string | null;
  receiverBankCorrespondent?: string | null;
  acquirerName?: string | null;
  acquirerINN?: string | null;
  acquirerBIK?: string | null;
  accountNumber?: string | null;
  payerName?: string | null;
  acquirerCorrespondent?: string | null;
  benefit?: number | null;
  commissionIsUpper: boolean;
}

export class PaymentReceipt {
  public paymentId: number;
  public paymentDate?: string | null;
  public amount?: number | null;
  public commission?: number | null;
  public receiverName?: string | null;
  public receiverINN?: string | null;
  public receiverBankName?: string | null;
  public receiverBankBIK?: string | null;
  public receiverBankAccount?: string | null;
  public receiverBankCorrespondent?: string | null;
  public acquirerName?: string | null;
  public acquirerINN?: string | null;
  public acquirerBIK?: string | null;
  public accountNumber?: string | null;
  public payerName?: string | null;
  public acquirerCorrespondent?: string | null;
  public benefit?: number | null;
  public commissionIsUpper: boolean;

  public constructor(value: PaymentReceiptInterface) {
    this.paymentId = value.paymentId;
    this.paymentDate = value.paymentDate;
    this.amount = value.amount;
    this.commission = value.commission;
    this.receiverName = value.receiverName;
    this.receiverINN = value.receiverINN;
    this.receiverBankName = value.receiverBankName;
    this.receiverBankAccount = value.receiverBankAccount;
    this.receiverBankCorrespondent = value.receiverBankCorrespondent;
    this.receiverBankBIK = value.receiverBankBIK;
    this.acquirerName = value.acquirerName;
    this.acquirerINN = value.acquirerINN;
    this.acquirerBIK = value.acquirerBIK;
    this.accountNumber = value.accountNumber;
    this.payerName = value.payerName;
    this.acquirerCorrespondent = value.acquirerCorrespondent;
    this.benefit = value.benefit;
    this.commissionIsUpper = value.commissionIsUpper;
  }
}

export enum PaymentReceiptKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  PAYMENT_ID = 'paymentId',
}

export interface PaymentReceiptFilterProps {
  [PaymentReceiptKeys.PROVIDER_ID]: number;
  [PaymentReceiptKeys.ACCOUNT_NUMBER]: string;
  [PaymentReceiptKeys.PAYMENT_ID]: string;
}

export type LoadPaymentReceipt = Load<
  { filter: PaymentReceiptFilterProps; limit?: number; offset?: number },
  PaymentReceipt[]
>;
