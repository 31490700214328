import { action, observable, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@core/services';
import { OverhaulInterface } from '@core/entities/Opencity/Overhaul';
import Store from './Store';

interface loadParams {
  year: string;
  houseId?: number;
}
interface IreqParams {
  params: {
    filter: {
      year: {
        $eq: string;
      };
      houseId?: {
        $eq: number;
      };
    };
    offset: number;
    limit: number;
  };
}

class OverhaulStore extends Store {
  @observable private _loading: boolean;
  @observable private _loadingError: boolean;
  @observable private _list: OverhaulInterface[];

  constructor(services: Services) {
    super(services);

    this._loading = false;
    this._loadingError = false;
    this._list = [];
  }

  @action public load = async (params: loadParams): Promise<OverhaulInterface[]> => {
    this._loadingError = false;
    this._loading = true;

    const { year, houseId } = params;

    const createReqParams = () => {
      let reqParams: IreqParams = {
        params: {
          filter: {
            year: {
              $eq: year,
            },
          },
          offset: 0,
          limit: 0,
        },
      };

      if (houseId) {
        reqParams = {
          params: {
            filter: {
              year: {
                $eq: year,
              },
              houseId: {
                $eq: houseId,
              },
            },
            offset: 0,
            limit: 0,
          },
        };
      }

      return reqParams;
    };

    await this._services.opencity.requests
      .overhaulIndex(createReqParams())
      .then(({ data: { result } }: any) => {
        if (!result) {
          this._loadingError = true;
          this._list = [];
        } else if (result?.total !== 0) {
          this._list = result?.items;
        } else {
          this._list = [];
        }
      })
      .finally(() => (this._loading = false));

    return this._list;
  };

  @computed public get list(): OverhaulInterface[] {
    return this._list;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get loadingError(): boolean {
    return this._loadingError;
  }
}

export default OverhaulStore;
