import { Load, Count } from '@stores/interfaces/Entity';
import { AddFilter, ClearFilter, FilterOperators, RemoveFilter } from '@stores/interfaces/Filter';
import SortDirections from '@constants/sort';

export interface HouseInterface {
  id: number;
  districtId?: number | null;
  streetId?: number | null;
  streetName?: string | null;
  number?: string | null;
  buildingNumber?: string | null;
  lat?: number | null;
  lng?: number | null;
  fiasId?: string | null;
  typeId?: number | null;
}

export class House {
  public id: number;
  public districtId?: number | null;
  public streetId?: number | null;
  public streetName?: string | null;
  public number?: string | null;
  public buildingNumber?: string | null;
  public lat?: number | null;
  public lng?: number | null;
  public fiasId?: string | null;
  public typeId?: number | null;

  public constructor(value: HouseInterface) {
    this.id = value.id;
    this.districtId = value.districtId;
    this.streetId = value.streetId;
    this.streetName = value.streetName;
    this.number = value.number;
    this.buildingNumber = value.buildingNumber;
    this.lat = value.lat;
    this.lng = value.lng;
    this.fiasId = value.fiasId;
    this.typeId = value.typeId;
  }
}

export type CountHouse = Count<{ filter?: HouseFilterProps }, number>;

export enum HouseKeys {
  ID = 'id',
  STREET = 'street',
  STREET_ID = 'streetId',
  INTERRUPT_ID = 'interruptId',
  NUMBER = 'houseNumber',
}

export interface HouseFilterProps {
  [HouseKeys.ID]?: FilterOperators<number>;
  [HouseKeys.STREET]?: FilterOperators<string>;
  [HouseKeys.STREET_ID]?: FilterOperators<number>;
  [HouseKeys.INTERRUPT_ID]?: FilterOperators<number>;
  [HouseKeys.NUMBER]?: FilterOperators<string>;
}

export interface HouseSortProps {
  field: HouseKeys;
  desc: SortDirections;
}

export type AddHouseFilter = AddFilter<HouseFilterProps>;

export type RemoveHouseFilter = RemoveFilter<HouseKeys>;

export type ClearHouseFitler = ClearFilter;

export type LoadHouse = Load<
  {
    filter?: HouseFilterProps;
    limit?: number;
    offset?: number;
    ownHouses?: boolean;
    sort?: HouseSortProps[];
  },
  House[]
>;
