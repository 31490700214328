import { HttpClient, jrpcRequest } from '@httpClient/index';
import WorkflowService from './service';
import { OPENCITY_BASE_URL, OPENCITY_JSONRPC_ENTRY_POINT } from './config';
import jrpcConfig from './jrpcConfig';
import ActionNames from './actionNames';

export default (): HttpClient<ActionNames> =>
  new WorkflowService(jrpcRequest(OPENCITY_JSONRPC_ENTRY_POINT, jrpcConfig), {
    baseURL: OPENCITY_BASE_URL,
  });
