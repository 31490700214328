import axios, { CancelTokenSource } from 'axios';
import OpencityMethods from '@services/opencity/methods';

const CancelToken = axios.CancelToken;

type ICancelTokenHandler = {
  [key in OpencityMethods]?: {
    handleRequestCancellation: () => CancelTokenSource;
  };
};

interface CancelTokenRequestHandler {
  cancelToken: undefined | CancelTokenSource;
}

export function createCancelTokenHandler(propertyName: OpencityMethods): ICancelTokenHandler {
  const cancelTokenHandler: ICancelTokenHandler = {};

  const cancelTokenRequestHandler: CancelTokenRequestHandler = {
    cancelToken: undefined,
  };

  cancelTokenHandler[propertyName] = {
    handleRequestCancellation: (): CancelTokenSource => {
      cancelTokenRequestHandler.cancelToken &&
        cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`);

      cancelTokenRequestHandler.cancelToken = CancelToken.source();
      return cancelTokenRequestHandler.cancelToken;
    },
  };
  return cancelTokenHandler;
}
