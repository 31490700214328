export interface MapOrderInterface {
  buildingNumber?: string | null;
  houseNumber?: string | null;
  lat?: string | null;
  lng?: string | null;
  type?: string | null;
  category?: string | null;
  streetName?: string | null;
}

export class MapOrder {
  public buildingNumber?: string | null;
  public houseNumber?: string | null;
  public lat?: string | null;
  public lng?: string | null;
  public type?: string | null;
  public category?: string | null;
  public streetName?: string | null;

  public constructor(value: MapOrderInterface) {
    this.buildingNumber = value.buildingNumber;
    this.houseNumber = value.houseNumber;
    this.lat = value.lat;
    this.lng = value.lng;
    this.type = value.type;
    this.category = value.category;
    this.streetName = value.streetName;
  }
}
