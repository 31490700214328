import { FilterOperators } from '@core/stores/interfaces/Filter';

export enum SmevFilterKeys {
  TICKET_UID = 'ticketUid',
  HOUSE_FIAS_ID = 'houseFiasId',
  ACCOUNT_NUMBER = 'accountNumber',
  FLAT_NUMBER = 'flatNumber',
  ALL_ACCOUNTS = 'allAccounts',
  CAPCHA_RESPONSE = 'captchaResponse',
}

export interface SmevFilterProps {
  [SmevFilterKeys.TICKET_UID]?: FilterOperators<string>;
  [SmevFilterKeys.HOUSE_FIAS_ID]?: FilterOperators<string>;
  [SmevFilterKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [SmevFilterKeys.FLAT_NUMBER]?: FilterOperators<string>;
  [SmevFilterKeys.ALL_ACCOUNTS]?: boolean;
  [SmevFilterKeys.CAPCHA_RESPONSE]?: string | null;
}

export interface SmevParams {
  filter?: SmevFilterProps;
  limit?: number;
  offset?: number;
}

export interface SmevAccountInterface {
  accountNumber?: string;
  accountAddress?: string;
  balanceActualDate?: string;
  merchantBalance?: number;
  merchantGroupId?: string;
  recipient?: {
    name?: string;
    kpp?: string;
    paymentAccount?: string;
    inn?: string;
  };
  partner?: {
    name?: string;
    externalId?: number;
  };
  bank?: {
    name?: string;
    correspondentAccount?: string;
    bik?: string;
  };
  payment?: {
    offer?: string;
    commissionType?: string;
    percent?: string;
    minimalCommission?: string;
    minimalPaymentSum?: string;
    maximumPaymentSum?: string;
  };
}

export interface SmevAccountListInterface {
  result?: SmevAccountInterface[];
}

export interface SmevErrorInterface {
  code: number;
  data: string;
  message: string;
}

export class SmevAccountList implements SmevAccountListInterface {
  public constructor(value: SmevAccountListInterface) {
    this.result = value.result;
  }
  result?: SmevAccountInterface[];
}
