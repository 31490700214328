import { MerchantGroup } from '@entities/Billing/MerchantGroup';
import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';
import { Document } from '@core/entities/Billing/MerchantGroup';

export interface AccrualInterface {
  balanceDate?: number | null;
  totalOfMonthBalance: number;
  totalOfMonthPenalty: number;
  merchantGroups: MerchantGroup[];
}

export class Accrual {
  public balanceDate?: number | null;
  public totalOfMonthBalance: number;
  public totalOfMonthPenalty: number;
  public merchantGroups: MerchantGroup[];

  constructor(value: AccrualInterface) {
    this.balanceDate = value.balanceDate;
    this.totalOfMonthBalance = value.totalOfMonthBalance;
    this.totalOfMonthPenalty = value.totalOfMonthPenalty;
    this.merchantGroups = value.merchantGroups;
  }
}

export enum AccrualKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export interface AccrualFilterProps {
  [AccrualKeys.PROVIDER_ID]?: FilterOperators<number>;
  [AccrualKeys.ACCOUNT_NUMBER]?: FilterOperators<string>;
  [AccrualKeys.START_DATE]?: number;
  [AccrualKeys.END_DATE]?: number;
}

export type LoadAccrual = Load<
  { filter?: AccrualFilterProps; limit?: number; offset?: number },
  Accrual[]
>;

export type ParseredAccruals = {
  balanceDate: Accrual['balanceDate'];
  docName: Document['docName'];
  docUrl: Document['docUrl'];
};
