import HttpClient from '@httpClient/httpClient';
import opencityCreator from './opencity';
import cctvCreator from './cctv';
import authenticationCreator from './authentication';
import workflowCreator from './workflow';
import billingCreator from './billing';
import AuthenticationActionNames from './authentication/actionNames';
import OpencityActionNames from './opencity/actionNames';
import CctvActionNames from './cctv/actionNames';
import WorkflowActionNames from './workflow/actionNames';
import BillingActionNames from './billing/actionNames';

class Services {
  public static getServices = (): Services => {
    if (Services._instance) {
      return Services._instance;
    }

    Services._instance = new Services();

    return Services._instance;
  };
  private static _instance?: Services;

  public readonly opencity: HttpClient<OpencityActionNames>;
  public readonly authentication: HttpClient<AuthenticationActionNames>;
  public readonly cctv: HttpClient<CctvActionNames>;
  public readonly workflow: HttpClient<WorkflowActionNames>;
  public readonly billing: HttpClient<BillingActionNames>;

  private constructor() {
    this.opencity = opencityCreator();
    this.authentication = authenticationCreator();
    this.cctv = cctvCreator();
    this.workflow = workflowCreator();
    this.billing = billingCreator();
  }
}

export default Services;
