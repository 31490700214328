import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';
import { OutlinedInputProps } from '@material-ui/core';

interface NameApplicantFieldProps {
  className?: string;
  id?: string;
  autoFocus?: boolean;
  value?: string;
  touched?: boolean;
  error?: string;
  dataTestAttribute?: string;
  helperText?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: InputProps['onChange'];
  onBlur: InputProps['onBlur'];
}

const NameApplicantField: FC<NameApplicantFieldProps> = ({
  className,
  autoFocus,
  value,
  touched,
  error,
  helperText = 'Введите ФИО',
  label = 'ФИО',
  placeholder,
  required,
  dataTestAttribute,
  onChange,
  onBlur,
}) => {
  const inputProps: OutlinedInputProps = {
    inputProps: {
      'data-test': dataTestAttribute,
    },
    notched: false,
  };

  const helperTextElement: string = error || helperText;

  return (
    <TextField
      className={className}
      autoFocus={autoFocus}
      required={required}
      name="nameApplicant"
      value={value}
      error={Boolean(touched && error)}
      helperText={helperTextElement}
      label={label}
      placeholder={placeholder}
      fullWidth={true}
      InputProps={inputProps}
      InputLabelProps={{ shrink: true }}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default NameApplicantField;
