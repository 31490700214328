import { AxiosResponse } from 'axios';
import { mergeDeepRight } from 'ramda';
import { observable, computed, action, toJS } from 'mobx';

import Services from '@services/index';
import OpencityActionNames from '@services/opencity/actionNames';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  House,
  HouseInterface,
  LoadHouse,
  CountHouse,
  HouseKeys,
  HouseFilterProps,
  AddHouseFilter,
  HouseSortProps,
} from '@core/entities/Opencity/House';
import Store from './Store';
import { Loading, endLoading, SetLoading } from './interfaces/Loading';
import { Pagination } from './interfaces/Pagination';
import { Entity } from './interfaces/Entity';
import { Filter } from './interfaces/Filter';
import SortDirections from '@constants/sort';

type GisHouseIndexResponse = JrpcResponse<{ items: HouseInterface[]; total: number }>;
type GisHouseCountResponse = JrpcResponse<number>;

const DEFAULT_SORT: HouseSortProps[] = [
  {
    field: HouseKeys.NUMBER,
    desc: SortDirections.ASC,
  },
];

class HouseStore extends Store
  implements
    Loading,
    Pagination,
    Entity<House, { filter?: HouseFilterProps; limit?: number; offset?: number }>,
    Filter<HouseFilterProps, HouseKeys> {
  @observable private _houses: House[];
  @observable private _filter: HouseFilterProps;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _loading: boolean;
  @observable private _sort: HouseSortProps[];

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._houses = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
    this._sort = DEFAULT_SORT;
  }

  @action public load: LoadHouse = async params => {
    this._loading = true;

    let houses: House[] = [];
    const { _filter, _limit, _offset } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;
    const sort = (params && params.sort) || this._sort;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;
    this._sort = sort;

    await this._services.opencity.requests[
      params?.ownHouses ? OpencityActionNames.USER_HOUSE_INDEX : OpencityActionNames.GIS_HOUSE_INDEX
    ]({
      params: { filter, limit, offset, sort },
    })
      .then(({ data: { result } }: AxiosResponse<GisHouseIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            houses = items.map(value => new House(value));

            this._houses = houses;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return houses;
  };

  @action public count: CountHouse = async params => {
    let count: number = this._total;

    const filter = (params && params.filter) || toJS(this._filter);

    await this._services.opencity.requests
      .gisHouseCount({
        params: { filter },
      })
      .then(({ data: { result } }: AxiosResponse<GisHouseCountResponse>) => {
        if (typeof result === 'number') {
          count = result;
        }
      });

    return count;
  };

  public getByStreetId = (streetId: number): House[] => {
    return this.list.filter((value: House): boolean => value.streetId === streetId);
  };

  @action public addFilter: AddHouseFilter = filter => {
    this._filter = mergeDeepRight(this._filter, filter);
  };

  @action public setLoading: SetLoading = loading => {
    this._loading = loading;
  };

  @action public cleanUp = (): void => {
    this._houses = [];
    this._filter = {};
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  };

  @computed public get list(): House[] {
    return toJS(this._houses);
  }

  @computed public get filter(): HouseFilterProps {
    return toJS(this._filter);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default HouseStore;
