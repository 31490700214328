import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStatisticsStore = () => {
  const { statisticsStore } = useStores();

  return useObserver(() => ({
    load: statisticsStore.load,
    statistics: statisticsStore.statistics,
  }));
};
