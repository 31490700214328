export interface PaymentStatusInterface {
  paymentId: string;
  externalPaymentId?: string | null;
  status: string;
  message?: string | null;
}

export class PaymentStatus {
  public paymentId: string;
  public externalPaymentId?: string | null;
  public status: string;
  public message?: string | null;

  constructor(value: PaymentStatusInterface) {
    this.paymentId = value.paymentId;
    this.externalPaymentId = value.externalPaymentId;
    this.status = value.status;
    this.message = value.message;
  }
}
