import React, { ReactElement, useEffect } from 'react';

import config from '@core/constants/config';

const SocialShare = (): ReactElement => {
  const {
    Ya: { share2 },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = window as any;

  useEffect(() => {
    share2('ya-share2', {
      contentByService: {
        vkontakte: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
        facebook: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
        odnoklassniki: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
        telegram: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
        viber: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
        whatsapp: {
          url: window.location.origin,
          title: config.projectName,
          description: config.projectDescription,
          image: `${window.location.origin}/images/share.jpg`,
        },
      },
      theme: {
        limit: 3,
        services: 'vkontakte,odnoklassniki,telegram,viber,whatsapp',
        curtain: true,
        popupDirection: 'top',
      },
    });
  }, []);

  return <div id="ya-share2" />;
};

export default SocialShare;
