import { AxiosResponse } from 'axios';
import { observable, computed, action } from 'mobx';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import SnackbarStore from './SnackbarStore';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';

type AuthPasswordRecoveryResponse = JrpcResponse<boolean, { message: string }>;
type AuthPasswordResetResponse = JrpcResponse<boolean, { message: string }>;
type AuthPasswordUpdateResponse = JrpcResponse<
  boolean,
  { oldPassword?: string; newPassword?: string; password?: string }
>;

interface PasswordStoreRelations {
  snackbarStore: SnackbarStore;
}

class PasswordStore extends Store<PasswordStoreRelations> implements Loading {
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(100).bind(this);

  public constructor(services: Services, relations: PasswordStoreRelations) {
    super(services, relations);

    this._loading = false;
  }

  public recovery = async (email: string): Promise<boolean> => {
    let state = false;

    this._loading = true;

    await this._services.opencity.requests
      .authPasswordRecovery({
        params: { email, client: 'v3' },
      })
      .then(({ data: { result, error } }: AxiosResponse<AuthPasswordRecoveryResponse>) => {
        if (typeof result === 'boolean') {
          state = result;

          this._relations.snackbarStore.setMessage(
            `Ссылка для восстановления пароля отправлена на почту ${email}`,
            'success',
          );
        }

        if (error && error.data) {
          this._relations.snackbarStore.setMessage(error.data.message, 'error');
        }
      })
      .finally(this._endLoading);

    return state;
  };

  public reset = async (
    type: 'phone' | 'email',
    value: string,
    code: string,
    password: string,
  ): Promise<boolean> => {
    let state = false;

    this._loading = true;

    await this._services.opencity.requests
      .authPasswordReset({ params: { [type]: value, code, password } })
      .then(({ data: { result, error } }: AxiosResponse<AuthPasswordResetResponse>) => {
        if (typeof result === 'boolean') {
          state = result;

          this._relations.snackbarStore.setMessage('Пароль успешно обновлен', 'success');
        }

        if (error && error.data) {
          this._relations.snackbarStore.setMessage(error.data.message, 'error');
        }
      })
      .finally(this._endLoading);

    return state;
  };

  public update = async (
    userId: number,
    oldPassword: string,
    newPassword: string,
  ): Promise<{ oldPassword?: string; newPassword?: string; password?: string }> => {
    let resultMessage: { oldPassword?: string; newPassword?: string; password?: string } = {};

    this._loading = true;

    await this._services.opencity.requests
      .authPasswordUpdate({ params: { userId, oldPassword, newPassword } })
      .then(({ data: { result, error } }: AxiosResponse<AuthPasswordUpdateResponse>) => {
        if (typeof result === 'boolean') {
          this._relations.snackbarStore.setMessage('Пароль успешно изменён', 'success');
        }

        if (error && error.data) {
          resultMessage = error.data;
        }
      })
      .finally(this._endLoading);

    return resultMessage;
  };

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default PasswordStore;
