import { action, computed, observable, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  ClassifierType,
  ClassifierTypeFilterProps,
  ClassifierTypeInterface,
  ClassifierTypeKeys,
  ClassifierTypeLoad,
  ClassifierTypeSortProps,
} from '@core/entities/Opencity/ClassifierType';
import Store from './Store';
import { endLoading, Loading } from './interfaces/Loading';
import { Entity } from './interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import SortDirections from '@constants/sort';

type ReferenceClassifierTypeIndex = JrpcResponse<{
  items: ClassifierTypeInterface[];
  total: number;
}>;

const DEFAULT_SORT: ClassifierTypeSortProps[] = [
  { field: ClassifierTypeKeys.TYPE, desc: SortDirections.DESC },
];
class ClassifierTrackerStore extends Store
  implements
    Loading,
    Pagination,
    Entity<ClassifierType, { limit?: number; offset?: number }>,
    Filter<ClassifierTypeFilterProps, ClassifierTypeKeys> {
  @observable private _loading: boolean;
  @observable private _classifierTypes: ClassifierType[];
  @observable private _filter: ClassifierTypeFilterProps;
  @observable private _sort: ClassifierTypeSortProps[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._classifierTypes = [];
    this._loading = false;
    this._filter = {};
    this._sort = DEFAULT_SORT;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action load: ClassifierTypeLoad = async params => {
    this._loading = true;

    let classifierTypes: ClassifierType[] = [];

    const filter = params?.filter || this._filter;
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;
    const sort = (params && params.sort) || this._sort;

    this._limit = limit;
    this._offset = offset;
    this._filter = filter;
    this._sort = sort;

    await this._services.opencity.requests
      .referenceClassifierTypeIndex({ params: { filter, limit, offset, sort } })
      .then(({ data: { result } }: AxiosResponse<ReferenceClassifierTypeIndex>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            this._classifierTypes = items.map<ClassifierType>(value => new ClassifierType(value));
            classifierTypes = toJS(this._classifierTypes);
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return classifierTypes;
  };

  @action setLimit: SetLimit = value => {
    this._limit = value;
  };

  @action setOffset: SetOffset = value => {
    this._offset = value;
  };

  @computed public get list(): ClassifierType[] {
    return toJS(this._classifierTypes);
  }

  @computed public get filter(): ClassifierTypeFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default ClassifierTrackerStore;
