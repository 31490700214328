import { AxiosResponse } from 'axios';
import { observable, computed, action, IObservableValue } from 'mobx';

import Services from '@services/index';
import Store from './Store';
import { JrpcResponse } from '@httpClient/jrpc';
import { StatisticsProps } from '@core/entities/Opencity/Statistics';

type OrganizationCountResponse = JrpcResponse<number>;
type UserCountResponse = JrpcResponse<number>;
type OrderCountResponse = JrpcResponse<number>;

class StatisticsStore extends Store {
  @observable private _userCount: IObservableValue<number>;
  @observable private _organizationCount: IObservableValue<number>;
  @observable private _orderCount: IObservableValue<number>;
  @observable private _completedOrdersCount: IObservableValue<number>;

  public constructor(services: Services) {
    super(services);

    this._userCount = observable.box(0);
    this._organizationCount = observable.box(0);
    this._orderCount = observable.box(0);
    this._completedOrdersCount = observable.box(0);
  }

  @action public load = async (): Promise<void> => {
    await this._services.opencity.requests
      .userCount()
      .then(({ data: { result } }: AxiosResponse<UserCountResponse>) => {
        if (typeof result === 'number') {
          this._userCount.set(result);
        }
      });

    await this._services.opencity.requests
      .organizationCount()
      .then(({ data: { result } }: AxiosResponse<OrganizationCountResponse>) => {
        if (typeof result === 'number') {
          this._organizationCount.set(result);
        }
      });

    await this._services.opencity.requests
      .cacheIssueCount()
      .then(({ data: { result } }: AxiosResponse<OrderCountResponse>) => {
        if (result) {
          this._orderCount.set(result);
        }
      });

    this._services.opencity.requests
      .cacheIssueCount({
        params: {
          filter: {
            $or: [
              { trackerId: { $in: [1, 4, 5] }, status: 100, reason: 5 },
              { status: 100, reason: 10 },
              { trackerId: 1, status: 100, reason: { $in: [4, 6, 9] } },
              { trackerId: 1, status: 700 },
              { trackerId: 1, status: 5, reason: 3 },
              { trackerId: 5, status: 5, reason: 2 },
              { trackerId: 5, status: { $in: [1, 10, 665] } },
            ],
          },
        },
      })
      .then(({ data: { result } }: AxiosResponse<OrderCountResponse>) => {
        if (result) {
          this._completedOrdersCount.set(result);
        }
      });
  };

  @computed public get statistics(): StatisticsProps {
    return {
      completedOrderCount: this._completedOrdersCount.get(),
      orderCount: this._orderCount.get(),
      organizationCount: this._organizationCount.get(),
      userCount: this._userCount.get(),
    };
  }
}

export default StatisticsStore;
