import React, { FC, ReactChild } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Preloader from 'oc-preloader/lib';
import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles(
  createStyles({
    container: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
    },
    preloader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface ContentProps {
  children: ReactChild;
  loading?: boolean;
  className?: string;
}

const Content: FC<ContentProps> = ({ children, loading, className }) => {
  const classes = useStyles();

  return (
    <main className={classNames(classes.container, className)}>
      {loading ? (
        <Preloader
          color={isSpecialBilling ? '#3167F3' : ''}
          className={classes.preloader}
          height="100%"
        />
      ) : (
        children
      )}
    </main>
  );
};

export default Content;
