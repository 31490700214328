import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useFastPaymentStore } from '../hooks';

import config from '@core/constants/config';
import { ROOT } from '@public/constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      lineHeight: '100%',
      minWidth: theme.spacing(12),
      marginBottom: theme.spacing(2),
    },
    img: {
      width: 200,
      maxHeight: 64,

      [theme.breakpoints.down('sm')]: {
        width: 100,
      },
    },
    link: {
      lineHeight: '100%',
    },
  }),
);

interface LogoProps {
  className?: string;
  id: string;
}

const Logo: FC<LogoProps> = ({ className, id }) => {
  const classes = useStyles();
  const { cleanUp } = useFastPaymentStore();

  const handleClick = (): void => {
    cleanUp();
  };

  return (
    <div className={classNames(classes.container, className)} onClick={handleClick}>
      <Link className={classes.link} id={`${id}__logo`} to={ROOT}>
        <img className={classes.img} alt={config.projectName} src={config.logo} />
      </Link>
    </div>
  );
};

export default Logo;
