import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

import PageTitle from '@core/components/PageTitle';
import { useMediaQuery } from '@material-ui/core';
import theme from '@core/themes/billing';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(6),
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      '@media print': {
        padding: '0 !important',
        boxShadow: 'none !important',
        transition: 'none !important',
      },
    },
    header: {
      paddingBottom: theme.spacing(6),
      '@media print': {
        paddingBottom: '5px !important',
      },

      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
      },
    },
  }),
);

interface PageProps {
  children: ReactNode | ReactNode[];
  banner?: ReactNode;
  header?: ReactNode;
  className?: string;
  id?: string;
  innerClasses?: {
    header?: string;
  };
  loading?: boolean;
  title?: string;
}

const Page: FC<PageProps> = ({
  children,
  banner,
  header,
  className,
  id,
  innerClasses,
  loading,
  title,
}) => {
  const classes = useStyles();

  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Fade in={!loading} timeout={1000}>
      <section className={classNames(classes.container, className)} id={id}>
        {banner && banner}
        <PageTitle title={title} />
        {header && !isXs && (
          <header className={classNames(classes.header, innerClasses && innerClasses.header)}>
            {header}
          </header>
        )}
        {children}
      </section>
    </Fade>
  );
};

export default Page;
