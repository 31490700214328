import React, { FC, ReactChild, ReactElement, useState, useEffect } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { useRouteMatch } from 'react-router-dom';

import Header from '@components/Header';
import Footer from '@components/Footer';
import Content from '@components/Content';
import config from '@core/constants/config';
import * as routes from '@public/constants/routes';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@public/constants/ui';
import { PAGE_Y_OFFSET } from '@public/constants/ui';
import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from '@public/constants/mobileAppLinks';
import FeedbackDialog from '@core/components/FeedbackDialog';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import FooterCaption from './FooterCaption';
import Logo from './Logo';
import { isSpecialBilling, isUsinsk } from '@core/constants/project';

const LAYOUT_ID = 'layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    oldVersionAlert: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 100,

      [theme.breakpoints.down('xs')]: {
        maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
        left: theme.spacing(4),
      },
    },
    oldVersionAlertMessage: {
      flexDirection: 'row',
    },
    container: {
      minHeight: '100%',
    },
    content: {
      marginTop: HEADER_HEIGHT,

      [theme.breakpoints.down('sm')]: {
        marginTop: MOBILE_HEADER_HEIGHT,
        minHeight: 0,
      },
    },
    footer: {
      zIndex: 3,
      '& > div:nth-child(1)': {
        marginBottom: theme.spacing(4),
      },

      padding: `${theme.spacing(0, 30)}`,

      [theme.breakpoints.down(1400)]: {
        padding: `${theme.spacing(6, 16)} !important`,
      },

      [theme.breakpoints.down(1240)]: {
        padding: `${theme.spacing(6, 8)} !important`,
      },

      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(0, 4, 6)} !important`,
      },
    },
    footerDivider: {
      backgroundColor: '#18cda6',
      flexGrow: 1,
      height: 2,
    },
    footerLogo: {
      marginBottom: theme.spacing(4),
      marginRight: theme.spacing(12),
    },
    header: {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      boxSizing: 'border-box',
      height: HEADER_HEIGHT,
      left: 0,
      padding: `${theme.spacing(6, 30)} !important`,
      transition: 'all .25s ease !important',
      width: '100%',

      [theme.breakpoints.down(1400)]: {
        padding: `${theme.spacing(6, 16)} !important`,
      },

      [theme.breakpoints.down(1240)]: {
        padding: `${theme.spacing(6, 8)} !important`,
      },

      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none !important',
        height: MOBILE_HEADER_HEIGHT,
      },

      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(3, 2)} !important`,
      },
    },
    headerBackgroundAfterScroll: {
      backgroundColor: 'rgba(255, 255, 255, 1)',

      [theme.breakpoints.down('sm')]: {
        boxShadow: `4px 6px 35px rgba(139, 157, 177, 0.33) !important`,
      },
    },
    img: {
      height: theme.spacing(9),
    },
    link: {
      '&:first-child': {
        marginRight: theme.spacing(8),
      },

      [theme.breakpoints.down('sm')]: {
        marginRight: '0 !important',
      },

      color: 'inherit',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    overrideContent: {
      marginTop: 0,
      height: '100%',
      '@media (max-height:910px)': {
        height: 'auto',
      },
    },

    footerCaption: {
      zIndex: 3,
    },
  }),
);

interface LayoutProps {
  children: ReactChild;
  pathname: string;
  loading?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, loading, pathname }) => {
  const classes = useStyles();

  const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);
  const [feedbackDialogIsOpen, setFeedbackDialogOpen] = useState(false);

  const signPage =
    useRouteMatch(routes.SIGN_IN) ||
    useRouteMatch(routes.SIGN_UP) ||
    useRouteMatch(routes.PASSWORD_CHANGE) ||
    useRouteMatch(routes.PASSWORD_RECOVERY);

  const authSocialPage = useRouteMatch(routes.AUTH_SOCIAL_PAGE);

  useEffect(() => {
    window.onscroll = (): void => setPageYOffset(window.pageYOffset);
  }, []);

  const handleSetFeedbackDialogOpen = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setFeedbackDialogOpen(!feedbackDialogIsOpen);
  };

  const handleCloseFeedbackDialog = (): void => setFeedbackDialogOpen(false);

  const headerElement: ReactElement = (
    <Header
      className={classNames(classes.header, {
        [classes.headerBackgroundAfterScroll]:
          pathname !== routes.ROOT || pageYOffset >= PAGE_Y_OFFSET,
      })}
    >
      <HeaderLeft id={LAYOUT_ID} pathname={pathname} />
      <Hidden smDown={true}>
        <HeaderRight id={LAYOUT_ID} pathname={pathname} pageYOffset={pageYOffset} />
      </Hidden>
    </Header>
  );

  const contentElement: ReactElement = (
    <Content
      className={classNames(classes.content, signPage && classes.overrideContent)}
      loading={loading}
    >
      {children}
    </Content>
  );

  const contentSpecialElement: ReactElement = <Content loading={loading}>{children}</Content>;

  const footerElement: ReactElement = (
    <Footer className={classes.footer}>
      <Divider className={classes.footerDivider} />
      <Grid container={true} item={true} alignItems="center" justify="space-between" wrap="nowrap">
        <Hidden smDown={true}>
          <Grid item={true}>
            <Logo id={LAYOUT_ID} className={classes.footerLogo} />
          </Grid>
        </Hidden>
        {config.supportEmail && (
          <Grid container={true} item={true} direction="column">
            <Grid item={true}>
              <Typography color="textPrimary">Служба поддержки</Typography>
            </Grid>
            <Grid item={true}>
              <Typography color="secondary">
                <Link onClick={handleSetFeedbackDialogOpen} className={classes.link}>
                  Написать в техподдержку
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}
        {config.showMobileLinks && (
          <Hidden xsDown={true}>
            <Grid container={true} item={true} justify="flex-end">
              <a
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
                href={APP_STORE_LINK}
              >
                <img className={classes.img} alt="" src="/images/app_store_image.svg" />
              </a>
              <a
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
                href={GOOGLE_PLAY_LINK}
              >
                <img className={classes.img} alt="" src="/images/google_play_image.svg" />
              </a>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Footer>
  );

  const footerCaptionElement: ReactElement = (
    <Grid item={true} className={classes.footerCaption}>
      <FooterCaption text={config.footerCaption} />
    </Grid>
  );

  if (authSocialPage || signPage) return contentElement;

  if (isSpecialBilling) {
    return (
      <>
        <Grid className={classes.container} container direction="column" justify="space-between">
          {contentSpecialElement}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        className={classes.container}
        container={true}
        direction="column"
        justify="space-between"
      >
        {headerElement}
        {contentElement}
        {!isUsinsk && footerElement}
        {footerCaptionElement}
      </Grid>
      <FeedbackDialog open={feedbackDialogIsOpen} onClose={handleCloseFeedbackDialog} fromPublic />
    </>
  );
};

export default Layout;
