export const HEADER_HEIGHT = 80;

export const XS_HEADER_HEIGHT = 64;

export const DRAWER_WIDTH = 250;

export const CLOSED_DRAWER_WIDTH = 60;

export const LIGHT_GREEN_COLOR = '#f0fcfc';

export const FOOTER_HEIGHT = 64;

export const XS_FOOTER_HEIGHT = 100;
