import { Load } from '@stores/interfaces/Entity';

export interface ShutdownTypeInterface {
  id: number;
  name?: string | null;
  isPublic?: boolean | null;
}

export class ShutdownType {
  public id: number;
  public name?: string | null;
  public isPublic?: boolean | null;

  public constructor(value: ShutdownTypeInterface) {
    this.id = value.id;
    this.name = value.name;
    this.isPublic = value.isPublic;
  }
}

export type LoadShutdownType = Load<{ limit?: number }, ShutdownType[]>;
