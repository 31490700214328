import { observable, action, computed, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  ShutdownSenderInterface,
  ShutdownSender,
  LoadShutdownSender,
} from '@core/entities/Opencity/ShutdownSender';
import Store from './Store';
import { Entity } from './interfaces/Entity';
import { Loading, endLoading } from './interfaces/Loading';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';

type InterruptSenderIndexResponse = JrpcResponse<{
  items: ShutdownSenderInterface[];
  total: number;
}>;

class ShutdownSenderStore extends Store
  implements Loading, Entity<ShutdownSender, { limit?: number }>, Pagination {
  @observable private _shutdownSenders: ShutdownSender[];
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _loading: boolean;

  @action private _endLoading = endLoading(200).bind(this);

  public constructor(services: Services) {
    super(services);

    this._shutdownSenders = [];
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  }

  @action public load: LoadShutdownSender = async params => {
    let shtudownSenders: ShutdownSender[] = [];

    this._loading = true;

    const limit = params && typeof params.limit ? params.limit : this._limit;
    const offset = this._offset;

    await this._services.opencity.requests
      .referenceInterruptSenderIndex({ params: { limit, offset } })
      .then(({ data: { result } }: AxiosResponse<InterruptSenderIndexResponse>) => {
        if (result) {
          const { items, total } = result;

          if (Array.isArray(items)) {
            shtudownSenders = items.map<ShutdownSender>(value => new ShutdownSender(value));

            this._shutdownSenders = shtudownSenders;
          }

          if (typeof total === 'number') {
            this._total = total;
          }
        }
      })
      .finally(this._endLoading);

    return shtudownSenders;
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._shutdownSenders = [];
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._loading = false;
  };

  @computed public get list(): ShutdownSender[] {
    return toJS<ShutdownSender[]>(this._shutdownSenders);
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loading(): boolean {
    return this._loading;
  }
}

export default ShutdownSenderStore;
