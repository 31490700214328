/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';

import { Headers } from './headers';

type Requests<Keys extends string> = {
  [key in Keys]: (params?: any, headers?: Headers) => Promise<AxiosResponse>;
};

class HttpClient<Keys extends string> {
  public requests: Requests<Keys>;
  public client: AxiosInstance;
  public request: any;

  public constructor(request: any, options?: AxiosRequestConfig) {
    this.client = axios.create(options);
    this.requests = {} as Requests<Keys>;
    this.request = request;
  }

  public setHeaders = (headers: Headers): void => {
    this.client.defaults.headers = {
      ...this.client.defaults.headers,
      ...headers,
    };
  };

  public getHeader = (headerName: string): string => {
    return this.client.defaults.headers[headerName];
  };

  public removeHeader = (headerName: string): void => {
    delete this.client.defaults.headers[headerName];
  };
}

export default HttpClient;
