import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  MeteringDeviceValueInterface,
  MeteringDeviceValue,
  MeteringDeviceValueFilterProps,
  MeteringDeviceValueFilterKeys,
  LoadMeteringDeviceValue,
  CreateMeteringDeviceValue,
  MeteringDeviceValueSortProps,
} from '@core/entities/Opencity/MeteringDeviceValue';
import SortDirections from '@constants/sort';

import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';
import { Pagination } from './interfaces/Pagination';
import { Errors } from './interfaces/Errors';
import SnackbarStore from '@stores/SnackbarStore';

const DEFAULT_SORT: MeteringDeviceValueSortProps[] = [
  { field: MeteringDeviceValueFilterKeys.READ_OUT_AT, desc: SortDirections.DESC },
];
interface MeteringDeviceValueRelations {
  snackbarStore: SnackbarStore;
}

type DeviceMeteringValueIndexResponse = JrpcResponse<{
  items: MeteringDeviceValue[];
  total: number;
}>;

type DeviceMeteringValueCreateResponse = JrpcResponse<MeteringDeviceValueInterface, string>;

enum MeteringDeviceValueStoreErrorKeys {
  CREATE = 'create',
}

interface MeteringDeviceValueStoreErrorProps {
  [MeteringDeviceValueStoreErrorKeys.CREATE]?: string;
}

class MeteringDeviceValueStore extends Store<MeteringDeviceValueRelations>
  implements
    Loading,
    Pagination,
    Entity<MeteringDeviceValue, {}>,
    Filter<MeteringDeviceValueFilterProps, MeteringDeviceValueFilterKeys>,
    Errors<MeteringDeviceValueStoreErrorProps, MeteringDeviceValueStoreErrorKeys> {
  @observable private _meteringDeviceValues: MeteringDeviceValue[];
  @observable private _filter: MeteringDeviceValueFilterProps;
  @observable private _errors: MeteringDeviceValueStoreErrorProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _sort: MeteringDeviceValueSortProps[];

  @action private _endLoading = endLoading(500).bind(this);

  public constructor(services: Services, relations: MeteringDeviceValueRelations) {
    super(services, relations);

    this._meteringDeviceValues = [];
    this._filter = {};
    this._errors = {};
    this._loading = false;
    this._limit = 0;
    this._offset = 0;
    this._total = 0;
    this._sort = DEFAULT_SORT;
  }

  @action public load: LoadMeteringDeviceValue = async params => {
    this._loading = true;

    let meteringDeviceValues: MeteringDeviceValue[] = [];

    const { _limit, _offset, _filter, _sort } = this;

    const filter = (params && params.filter) || toJS(_filter);
    const limit = (params && params.limit) || _limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : _offset;
    const sort = (params && params.sort) || _sort;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;
    this._sort = sort;

    await this._services.opencity.requests
      .deviceMeteringValueIndex({
        params: { filter, limit, offset, sort },
      })
      .then(({ data: { result } }: AxiosResponse<DeviceMeteringValueIndexResponse>) => {
        if (result && result.items) {
          meteringDeviceValues = result.items;
        }
      })
      .finally(this._endLoading);

    this._meteringDeviceValues = meteringDeviceValues;

    return meteringDeviceValues;
  };
  @action public create: CreateMeteringDeviceValue = async ({
    meteringDeviceId,
    dailyValue,
    nightValue,
  }) => {
    this._loading = true;
    let creationResult = false;

    await this._services.opencity.requests
      .deviceMeteringValueCreate({
        params: {
          data: {
            meteringDeviceId,
            dailyValue,
            nightValue,
          },
        },
      })
      .then(({ data: { result, error } }: AxiosResponse<DeviceMeteringValueCreateResponse>) => {
        if (result) {
          creationResult = Boolean(result);

          if (creationResult) {
            this._relations.snackbarStore.setMessage('Показания переданы', 'success');
          }
        }

        if (error) {
          this._relations.snackbarStore.setMessage('Ошибка при передачи показаний', 'error');
        }
      })
      .finally(this._endLoading);

    return creationResult;
  };

  @computed public get list(): MeteringDeviceValue[] {
    return toJS(this._meteringDeviceValues);
  }

  @computed get filter(): MeteringDeviceValueFilterProps {
    return toJS<MeteringDeviceValueFilterProps>(this._filter);
  }

  @computed get errors(): MeteringDeviceValueStoreErrorProps {
    return toJS<MeteringDeviceValueStoreErrorProps>(this._errors);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }
}

export default MeteringDeviceValueStore;
