import React, { FC, ReactElement } from 'react';
import { TimelineMax, Power3 } from 'gsap';

import HowSystemWorksStepImage, {
  AnimationType,
} from '../HowSystemWorksStepImage/HowSystemWorksStepImage';

const FRONT_SHEET_CLASSNAME = 'send-order-image__sheet_front';
const BACK_SHEET_CLASSNAME = 'send-order-image__sheet_back';
const SHEET_LINES_CLASSNAME = 'send-order-image__lines';
const LEFT_HAND_CLASSNAME = 'send-order-image__man_hand_left';
const RIGHT_HAND_CLASSNAME = 'send-order-image__man_hand_right';

const windowContentAnimation: AnimationType = (timeline: TimelineMax) => {
  const sheetLines = `.${SHEET_LINES_CLASSNAME} *`;

  timeline
    .staggerFromTo(
      sheetLines,
      0.1,
      {
        drawSVG: '100%',
        ease: Power3.easeIn,
      },
      { drawSVG: '0', ease: Power3.easeIn },
      0.2,
      0,
    )
    .staggerTo(
      sheetLines,
      0.1,
      {
        drawSVG: '100%',
        ease: Power3.easeIn,
      },
      0.2,
    );
};

const manAnimation = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.restart();

  const duration = 0.15;

  const leftHand = `.${LEFT_HAND_CLASSNAME}`;
  const rightHand = `.${RIGHT_HAND_CLASSNAME}`;

  const angle = 2;

  timeline
    .fromTo(leftHand, duration, { transform: `rotate(${angle}deg)` }, { transform: 'rotate(0deg)' })
    .fromTo(
      rightHand,
      duration,
      { transform: `rotate(${-angle}deg)` },
      { transform: 'rotate(0deg)' },
    );
};

const onPause = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.kill();
};

const timelineHands: TimelineMax = new TimelineMax({
  repeat: -1,
});

const StepSendOrder: FC<{ isActive: boolean }> = ({ isActive }) => {
  const windowContentSvgElement: ReactElement = (
    <g>
      <g className={SHEET_LINES_CLASSNAME}>
        <line x1="143" x2="150" y1="56" y2="56" />
        <path d="m143 62h23" />
        <path d="m143 68h15" />
        <line x1="161" x2="166" y1="68" y2="68" />
        <path d="m143 74h23" />
        <line x1="143" x2="149" y1="80" y2="80" />
        <line x1="152" x2="166" y1="80" y2="80" />
        <line x1="143" x2="166" y1="86" y2="86" />
        <line x1="143" x2="146" y1="93" y2="93" />
        <line x1="149" x2="166" y1="93" y2="93" />
      </g>
      <g className={FRONT_SHEET_CLASSNAME}>
        <path d="m135 48v53h39v-43l-10-10h-29z" />
        <path d="m164 49v9h9" />
      </g>
      <g className={BACK_SHEET_CLASSNAME}>
        <path d="m141 44v-2h39v53h-2" />
      </g>
    </g>
  );

  const manSvgElement: ReactElement = (
    <g>
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx="107.75322540329583"
        cy="138"
        rx="41.24677459670414"
        ry="2"
        fill="rgb(235,235,235)"
      />
      <path
        d=" M 72.186 108.113 C 70.882 102.373 77.374 106.553 79.14 105.009 Q 80.906 103.465 79.14 111.217 L 75 129 Q 70.737 128.695 71 128 C 71.263 127.305 73.49 113.852 72.186 108.113 Z "
        fill="rgb(46,57,72)"
      />
      <line
        x1="103"
        y1="89"
        x2="113"
        y2="72"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1.5"
        stroke="rgb(118,118,128)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="3"
      />
      <path
        d=" M 50 96.744 Q 50.953 101.969 56 102 Q 61.047 102.031 79 102 Q 78.992 104.063 78 106 Q 79.977 123.227 78 132 Q 80.242 133.773 82 132 Q 85.922 109.352 86 102 C 86.078 94.648 82.133 95.703 75 95 Q 67.867 94.297 63 94 L 50 96.744 Z "
        fill="rgb(68,84,107)"
      />
      <path
        d=" M 71 128 Q 68.949 130.086 69.001 131 C 69.054 131.914 75.433 134.922 77.938 135 C 80.443 135.078 75.724 132.625 75 129 L 71 128 Z "
        fill="rgb(24,24,24)"
      />
      <path d=" M 73 107 L 70 108 L 73 138 L 74 138 L 73 107 Z " fill="rgb(42,33,29)" />
      <path d=" M 52 106 L 55 108 L 52 138 L 51 138 L 52 106 Z " fill="rgb(42,33,29)" />
      <path
        d=" M 78 132 Q 81.016 132.719 82 132 Q 83.383 134.813 88 136 Q 89.32 136.18 89 137 Q 85.25 138.078 78 137 Q 77.797 133.773 78 132 Z "
        fill="rgb(24,24,24)"
      />
      <path
        d=" M 55 62 Q 59.469 64.078 63 64 C 66.531 63.922 64.641 90.297 66 93 C 66.292 93.582 66.434 94.066 66.506 94.217 C 66.578 94.367 57.113 94.596 50 97 Q 48.375 73.453 55 62 Z "
        fill="rgb(254,111,97)"
      />
      <path
        d=" M 44.848 96.744 C 44.22 90.756 44.792 79.473 47 71 C 48.297 65.547 50.125 63.594 53 66 C 52.284 70.939 49.617 79.755 49.848 87.744 Q 50.258 101.951 55.848 101.744 L 79.988 101.744 Q 80.227 104.576 78.848 106.744 Q 77.469 108.912 55.848 108.744 Q 45.871 106.514 44.848 96.744"
        fill="rgb(185,134,82)"
      />
      <path
        d=" M 48 92 Q 49.203 90.242 54 89 C 58.797 87.758 68.973 88.344 69 89 Q 70.348 90.762 68 91 C 65.652 91.238 59.531 91.598 56 93 Q 52.469 94.402 49 97 L 48 92 Z "
        fill="rgb(185,134,82)"
      />
      <line
        x1="81"
        y1="90"
        x2="81"
        y2="137.75"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1.5"
        stroke="rgb(194,195,198)"
        strokeLinejoin="miter"
        strokeLinecap="square"
        strokeMiterlimit="3"
      />
      <g className={RIGHT_HAND_CLASSNAME} style={{ transformOrigin: '10% 60%' }}>
        <path
          d=" M 88.306 82.617 Q 91.915 82.711 93.306 82.617 Q 94.696 82.523 95.306 86.617 Q 94.806 87.383 94.306 86.617 Q 93.806 85.852 93.306 85.617 L 88.306 85.617 L 88.306 82.617 Z "
          fill="rgb(244,189,135)"
        />
        <path
          d=" M 62 87.744 L 86 86 L 88 86 Q 90.141 84.781 89 83 Q 87.859 81.219 63 83 L 59 83 L 59 87 L 62 87.744 Z "
          fill="rgb(254,111,97)"
        />
      </g>
      <g className={LEFT_HAND_CLASSNAME} style={{ transformOrigin: '10% 60%' }}>
        <path
          d=" M 87 77.002 Q 90.609 77.096 92 77.002 Q 93.391 76.908 94 81.002 Q 93.5 81.768 93 81.002 Q 92.5 80.236 92 80.002 L 87 80.002 L 87 77.002 Z "
          fill="rgb(244,189,135)"
        />
        <path
          d=" M 61 80 Q 74.738 78.125 86.957 77 Q 88.958 77.234 87.99 80 L 60.099 87 L 57 80 L 61 80 Z "
          fill="rgb(254,111,97)"
        />
      </g>
      <path
        d=" M 58 52 Q 57.805 53.047 57 53 C 56.195 52.953 54.453 53.656 55 50 C 55.547 46.344 54.136 46.297 55 45 C 56.125 43.313 55.758 43.5 55.848 42 C 55.938 40.5 57.75 42.063 58 40 Q 58.25 37.938 61 38 Q 63.156 37.492 64 39 Q 66.484 38.297 67 40 Q 70.305 39.422 70 40 C 69.695 40.578 70.141 43.758 67 46 Q 63.859 48.242 67 54 L 68 55 L 66.378 59.325 L 65 63 L 62 62 L 58 60 L 58 52 Z "
        fill="rgb(111,70,53)"
      />
      <path
        d=" M 60.099 49 Q 57.555 49.078 58 52 L 57 53 Q 56.5 60.438 57 61 Q 57.5 61.563 60.099 62 L 62 62 Q 60.51 61.125 60.099 60 Q 59.688 58.875 60.099 49 Z "
        fill="rgb(244,189,135)"
      />
      <line
        x1="79.14"
        y1="90"
        x2="118"
        y2="90"
        vectorEffect="non-scaling-stroke"
        strokeWidth="2"
        stroke="rgb(204,204,204)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="3"
      />
      <path
        d=" M 67 44 Q 64.461 44.5 63 45 Q 61.539 45.5 61 47 L 61 48 L 62 53 Q 62.563 53.57 63 54 Q 63.438 54.43 63 56 Q 63.156 56.445 64 56 Q 64.844 55.555 65 55 Q 67.625 54.445 68 55 L 69 53 L 67 50 L 68 45 Q 68.031 44.188 67 44 Z "
        fill="rgb(244,189,135)"
      />
      <line
        x1="116"
        y1="90"
        x2="116"
        y2="137"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1.5"
        stroke="rgb(204,204,204)"
        strokeLinejoin="miter"
        strokeLinecap="square"
        strokeMiterlimit="3"
      />
      <line
        x1="88"
        y1="88"
        x2="103"
        y2="88"
        vectorEffect="non-scaling-stroke"
        strokeWidth="2"
        stroke="rgb(118,118,128)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeMiterlimit="3"
      />
    </g>
  );

  return (
    <HowSystemWorksStepImage
      windowContentSvg={windowContentSvgElement}
      manSvg={manSvgElement}
      windowContentAnimation={windowContentAnimation}
      manAnimation={manAnimation(timelineHands)}
      onPause={onPause(timelineHands)}
      isActive={isActive}
    />
  );
};

export default StepSendOrder;
