import createMuiTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createMuiTheme';

import {
  textSecondary,
  white,
  textPrimary,
  boxShadowColor,
  boxShadowPosition,
  secondaryDark,
  secondaryMain,
  secondaryLight,
  errorColor,
  infoColor,
} from 'oc-theme/lib';

const specialOptions: ThemeOptions = {
  props: {
    MuiTextField: {
      variant: 'outlined',
      InputProps: {
        notched: false,
      },
      InputLabelProps: {
        shrink: true,
        variant: 'standard',
      },
      FormHelperTextProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      variant: 'outlined',
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiInputLabel: {
      variant: 'standard',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: white,
        opacity: 1,
      },
      root: {
        borderRadius: 0,
        boxShadow: '4px 6px 35px rgba(0, 0, 0, 0.08)',
      },
    },
    MuiTextField: {
      root: {
        marginTop: '4px',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: white,
      },
      containedSizeLarge: {
        fontSize: '18px',
      },
      containedSizeSmall: {
        fontSize: '15px',
      },
      outlinedSizeLarge: {
        fontSize: '18px',
      },
      outlinedSizeSmall: {
        fontSize: '15px',
      },
      root: {
        borderRadius: 4,
        boxShadow: 'none !important',
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: 500,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
      },
    },
    MuiInput: {
      input: {
        fontSize: '15px',
        padding: '16px',
        '&:-moz-placeholder': {
          color: textSecondary,
          opacity: 0.8,
        },
        '&:-ms-input-placeholder': {
          color: textSecondary,
          opacity: 0.8,
        },
        '&::-moz-placeholder': {
          color: textSecondary,
          opacity: 0.8,
        },
        '&::-webkit-input-placeholder': {
          color: textSecondary,
          opacity: 0.8,
        },
      },
    },
    MuiOutlinedInput: {
      root: { marginTop: '4px' },
      input: { padding: '16px', height: 'auto', fontSize: '15px' },
      multiline: { padding: '16px' },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0,0) scale(1)',
        fontSize: '15px',
      },
      root: {
        position: 'static',
        color: textPrimary,
      },
      formControl: {
        position: 'static',
      },
    },
    MuiInputBase: {
      root: { fontSize: '15px' },
    },
    MuiPaper: {
      root: {
        borderRadius: 8,
        boxShadow: `${boxShadowPosition} ${boxShadowColor} !important`,
      },
    },
    MuiStepConnector: {
      active: {
        borderColor: '#3167F3',
      },
      line: {
        borderColor: '#3167F3',
        flexGrow: 1,
        height: '100%',
        marginLeft: 12,
      },
      root: {
        display: 'flex',
        flexGrow: 1,
      },
      vertical: {
        padding: '0px !important',
      },
    },
    MuiStepContent: {
      root: {
        borderLeft: 'none !imortant',
      },
    },
    MuiStepLabel: {
      root: {},
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: `#3167F3 !important`,
        },
        fontWeight: 500,
        backgroundColor: 'transparent',
        color: `${textPrimary} !important`,
        textTransform: 'none',
      },
    },
  },
  palette: {
    primary: {
      main: '#3167F3',
      light: '#5080FF',
      dark: '#285EEB',
    },
    secondary: {
      dark: secondaryDark,
      light: secondaryLight,
      main: secondaryMain,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    error: {
      dark: errorColor,
      light: errorColor,
      main: errorColor,
    },
    info: {
      dark: infoColor,
      light: infoColor,
      main: infoColor,
    },
  },
  typography: {
    fontFamily: 'Roboto, Raleway, sans-serif',
    body1: {
      fontSize: '18px',
      fontWeight: 400,
      letterSpacing: 'inherit',
    },
    body2: {
      fontSize: '15px !important',
      fontWeight: 400,
      letterSpacing: 'inherit',
    },
    button: {
      fontSize: '1.125em !important',
      fontWeight: 700,
      letterSpacing: 'inherit',
    },
    h1: {
      fontFamily: 'Raleway',
      fontSize: '60px',
      fontWeight: 800,
    },
    h2: {
      fontFamily: 'Raleway',
      fontSize: '40px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '28px',
      fontWeight: 500,
      letterSpacing: 'inherit',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: 'inherit',
    },
  },
  spacing: 4,
};

export default (options: ThemeOptions): Theme =>
  createMuiTheme({
    ...specialOptions,
    ...options,
  });
