import config from '@constants/config';

export const HELP_CENTER = config.helpCenterPublic;

export const PENALTY = `${HELP_CENTER}ka_peny`;

export const METER = `${HELP_CENTER}ka_meter`;

export const VERIFICATION = `${HELP_CENTER}ka_meter`;

export const STOCK = `${HELP_CENTER}ka_akciya`;
