export const parseFlatNumber = (address: string) => {
  let subStrIndex = address.indexOf(', кв ');

  if (subStrIndex > -1) {
    return parseInt(address.substring(subStrIndex + 5));
  }

  subStrIndex = address.indexOf(' кв.');

  if (subStrIndex > -1) {
    return parseInt(address.substring(subStrIndex + 4));
  }

  return undefined;
};
