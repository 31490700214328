import React, { FC, useMemo } from 'react';
import { makeStyles, createStyles, Grid, Typography } from '@material-ui/core';
import { Banner } from '@core/components';
import { PROVIDER_NIZHNY, PROVIDER_TATARSTAN } from '@core/constants/openCityProviderId';
import Icon from '@core/components/Icon';

const useStyles = makeStyles(
  createStyles({
    root: {
      padding: '12px !important',
    },

    iconWrapper: {
      alignSelf: 'flex-start',
    },

    text: {
      fontSize: '13px !important',
    },
  }),
);

interface Props {
  providerId: number;
}

export const BalanceInfoBanner: FC<Props> = ({ providerId }) => {
  const classes = useStyles();

  const text = useMemo(() => {
    if (providerId === PROVIDER_TATARSTAN) {
      return 'Обновление информации о балансе вашего лицевого счета осуществляется раз в месяц — с 5 по 10 число. При совершении платежа вы можете указать ваш email для получения информации о платеже';
    }

    if (providerId === PROVIDER_NIZHNY) {
      return 'Обновление информации о балансе вашего лицевого счета осуществляется в течение трех рабочих дней. При совершении платежа вы можете указать ваш email для получения информации о платеже';
    }

    return null;
  }, [providerId]);

  if (!text) {
    return null;
  }

  return (
    <Banner className={classes.root}>
      <Grid container wrap="nowrap" spacing={3}>
        <Grid item className={classes.iconWrapper}>
          <Icon icon="info" />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" className={classes.text}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Banner>
  );
};
