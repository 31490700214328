import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px 32px 0 32px',
    },

    content: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 32px 16px 32px',
    },

    contentTextWrapper: {
      marginTop: '5%',
    },

    buttonsContainer: {
      width: 'auto',
      justifyContent: 'center',
      marginTop: '12%',
    },

    secondaryTextContainer: {
      marginTop: '4%',
    },

    linkWrapper: {
      '&:last-child': {
        margin: 0,
      },
      [theme.breakpoints.up('sm')]: {
        '& + &': {
          marginLeft: 8,
        },
      },
      '@media (max-width: 475px)': {
        marginBottom: 8,
      },
    },

    link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,

      '&:focus': {
        outline: 'none',
      },
    },

    text: {
      color: '#07003D',
    },

    secondaryText: {
      opacity: 0.5,
      cursor: 'pointer',
    },

    boldText: {
      fontFamily: 'Raleway',
      fontWeight: 700,
    },

    imageContainer: {
      marginTop: '8%',
    },

    image: {
      width: '100%',
      objectFit: 'contain',
    },
  }),
);
