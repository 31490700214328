import React, { FC, ReactElement } from 'react';
import { TimelineMax, Power3 } from 'gsap';

import HowSystemWorksStepImage, {
  AnimationType,
} from '../HowSystemWorksStepImage/HowSystemWorksStepImage';

const CONTENT_CLASSNAME = 'job-evaluation__content';
const LIKE_CLASSNAME = 'job-evaluation__like';
const DISLIKE_CLASSNAME = 'job-evaluation__dislike';
const HEART_CLASSNAME = 'job-evaluation__heart';

const windowContentAnimation: AnimationType = (timeline: TimelineMax) => {
  const like = `.${LIKE_CLASSNAME} *`;
  const dislike = `.${DISLIKE_CLASSNAME} *`;

  timeline
    .staggerFromTo(
      [like, dislike],
      0.5,
      { drawSVG: '100%', ease: Power3.easeIn },
      {
        drawSVG: '0',
        ease: Power3.easeIn,
      },
      0.3,
      0,
    )
    .staggerTo([dislike, like], 0.5, { drawSVG: '100%', ease: Power3.easeIn }, 0.3);
};

const manAnimation = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.restart();

  const heart = `.${HEART_CLASSNAME}`;

  timeline.fromTo(heart, 0.7, { x: -5, y: 0 }, { y: -25 }).to(heart, 0.3, { opacity: 0 });
};

const onPause = (timeline: TimelineMax): AnimationType => (): void => {
  timeline.kill();
};

const timelineHeart: TimelineMax = new TimelineMax({
  repeat: -1,
});

const StepJobEvaluation: FC<{ isActive: boolean }> = ({ isActive }) => {
  const windowContentSvgElement: ReactElement = (
    <g className={CONTENT_CLASSNAME}>
      <g>
        <path d=" M 156 88 Q 151 88.531 151 93 Q 151 97.469 151 97" />
        <path d=" M 166 88 Q 171 88.531 171 93 Q 171 97.469 171 97" />
        <line x1="161" y1="91" x2="161" y2="97" />
        <path
          d=" M 159 86 L 157 88 Q 158.219 90.734 161 91 Q 163.781 91.266 165.164 88 L 163 86"
          strokeLinecap={'butt'}
        />
        <path
          d=" M 167.991 75.502 C 168.634 76.529 169 77.725 169 79 C 169 82.863 165.639 86 161.5 86 C 157.361 86 154 82.863 154 79 C 154 77.601 154.44 76.298 155.216 75.216"
          strokeLinecap={'butt'}
        />
        <path d=" M 152 69 L 163 69 Q 166.938 69.063 168 72 Q 169.063 74.938 169 76 Q 168.688 77.125 167 77 Q 165.313 76.875 157 77 Q 154.813 76.875 153 74 Q 151.188 71.125 152 69 Z " />
      </g>
      <g>
        <path d=" M 153 65 L 158.203 65.109 Q 164.875 65.625 165 61 Q 165.125 56.375 165 47 Q 165 43 161 43 Q 157 43 140 43 Q 135 42.5 135 48 Q 135 53.5 135 65 Q 134.25 74 147 74 Q 142.25 73.25 142 69 Q 141.75 64.75 153 65 Z " />
        <g className={LIKE_CLASSNAME}>
          <path
            d=" M 146 53 Q 148 52.781 148 51 Q 148 49.219 148 47 Q 148.266 46 150 46 Q 151.734 46 152 47 L 152 52 Q 157 52.609 157 55 Q 157 57.391 157 59 Q 156.766 61 152 61 Q 147.234 61 146 61"
            fill="none"
          />
          <rect x="142" y="52" width="4" height="10" transform="matrix(1,0,0,1,0,0)" />
          <path
            d=" M 156 47 L 159 47 M 157.5 48.5 L 157.5 45.5"
            fillRule={'evenodd'}
            strokeLinecap={'square'}
          />
        </g>
      </g>
      <g>
        <path
          d=" M 165.055 49 C 165.069 49 165.116 49 165.164 49 Q 170.651 49 183.021 49 Q 188.021 48.5 188.021 54 Q 188.021 59.5 188.021 71 Q 188.771 80 176.021 80 Q 180.771 79.25 181.021 75 Q 181.271 70.75 170.021 71 Q 169.072 71.002 167.991 71"
          strokeLinecap={'square'}
        />
        <g className={DISLIKE_CLASSNAME}>
          <path
            d=" M 159 69 C 158.422 68.461 158.021 65.788 158 65"
            fill="none"
            strokeLinecap={'butt'}
          />
          <line x1="167" y1="67" x2="170" y2="67" strokeLinecap={'square'} />
          <path
            d=" M 180 61 Q 178 61.219 178 63 Q 178 64.781 178 67 Q 177.734 68 176 68 Q 174.266 68 174 67 L 174 62 Q 169 61.391 169 59 Q 169 56.609 169 55 Q 169.234 53 174 53 Q 178.766 53 180 53"
            strokeLinecap={'square'}
          />
          <rect x="180" y="52" width="4" height="10" fill="none" />
        </g>
      </g>
    </g>
  );

  const manSvgElement: ReactElement = (
    <g>
      <path
        d=" M 92.536 79.844 Q 91.995 80.761 91.254 80.442 C 90.514 80.122 88.637 80.187 90.401 76.938 C 92.165 73.69 90.856 73.163 92.111 72.24 C 93.745 71.04 93.336 71.09 93.934 69.711 C 94.532 68.332 95.7 70.421 96.64 68.568 Q 97.58 66.715 100.143 67.714 Q 102.343 67.974 102.62 69.68 Q 105.195 69.869 105.098 71.646 Q 108.401 72.233 107.917 72.672 C 107.432 73.111 106.764 76.251 103.045 77.284 Q 99.327 78.316 100.309 84.801 L 100.907 86.083 L 97.904 89.593 L 95.352 92.575 L 92.875 90.609 L 89.8 87.361 L 92.536 79.844 Z "
        fill="rgb(111,70,53)"
      />
      <path
        d=" M 95.535 77.743 Q 93.117 76.946 92.536 79.844 L 91.254 80.442 Q 88.241 87.26 88.518 87.959 Q 88.795 88.659 91.088 89.959 L 92.875 90.609 Q 91.774 89.277 91.772 88.079 Q 91.771 86.882 95.535 77.743 Z "
        fill="rgb(244,189,135)"
      />
      <path
        d=" M 103.729 75.405 Q 101.173 75.006 99.629 74.976 Q 98.085 74.946 97.065 76.172 L 96.723 77.111 L 95.953 82.152 Q 96.287 82.88 96.55 83.433 Q 96.815 83.987 95.866 85.313 Q 95.861 85.784 96.806 85.655 Q 97.751 85.525 98.088 85.057 Q 100.744 85.433 100.907 86.083 L 102.531 84.546 L 101.677 81.043 L 104.327 76.686 Q 104.634 75.934 103.729 75.405 Z "
        fill="rgb(244,189,135)"
      />
      <ellipse
        vectorEffect="non-scaling-stroke"
        cx="115.45312499999994"
        cy="135.14648437499997"
        rx="41.20312500000001"
        ry="4.259765624999972"
        fill="rgb(239,239,239)"
      />
      <path
        d="M 115.569 99.606 L 118.285 101.985 C 118.522 102.192 118.546 102.552 118.338 102.789 L 113.86 107.903 C 113.653 108.139 113.292 108.163 113.056 107.956 L 110.34 105.578 C 110.103 105.371 110.079 105.01 110.287 104.773 L 114.765 99.66 C 114.972 99.423 115.333 99.399 115.569 99.606 Z"
        style={{ stroke: 'none', fill: '#1E1E1E', strokeMiterlimit: 10 }}
      />
      <path
        d="M 115.204 100.333 L 117.324 102.19 C 117.509 102.352 117.528 102.633 117.366 102.818 L 113.869 106.811 C 113.708 106.996 113.426 107.014 113.241 106.852 L 111.121 104.995 C 110.936 104.834 110.917 104.552 111.079 104.367 L 114.576 100.375 C 114.738 100.19 115.019 100.171 115.204 100.333 Z"
        style={{ stroke: 'none', fill: '#EAEAEA', strokeMiterlimit: 10 }}
      />
      <path
        d=" M 86.25 119.531 Q 103.031 109.406 105.656 109.594 C 108.281 109.781 109.031 109.406 111.094 110.906 Q 113.156 112.406 122.906 129.656 L 115.219 129.281 Q 105.281 118.313 105.094 116.531 Q 96.469 126.656 94.313 127.125 Q 86.156 124.5 86.25 119.531 Z "
        fill="rgb(50,60,75)"
      />
      <path
        d=" M 108.688 107.688 L 110.719 105.906 Q 112.781 107.844 113.531 107.594 Q 114.281 107.344 116.25 104.625 Q 117.438 103.781 117.188 104.406 C 116.938 105.031 116.188 107.313 115.438 107.813 C 114.688 108.313 113.75 109.5 113.188 109.688 Q 112.625 109.875 110.125 109.125 L 106.5 110.625 L 105.563 107.563 L 108.688 107.688 Z "
        fill="rgb(245,191,138)"
      />
      <path
        d=" M 112.125 109 Q 109.313 112.563 108.5 112.875 Q 107.688 113.188 105.938 113.375 L 106.313 109.563 L 109.375 108.875 L 112.125 109 Z "
        fill="rgb(245,191,138)"
      />
      <path
        d=" M 138.047 126.656 Q 138.047 126.281 138.938 126.234 Q 139.828 126.188 141.844 125.672 Q 144.328 120.609 145.453 120.141 Q 146.578 119.672 146.813 120.938 Q 146.109 128.484 145.594 129.469 Q 145.078 130.453 144.891 132.609 Q 144.516 133.453 143.438 133.453 Q 142.359 133.453 140.859 133.078 L 140.719 129.422 L 140.531 128.391 Q 137.766 127.125 138.047 126.656 Z "
        fill="rgb(30,30,30)"
      />
      <path
        d=" M 136.125 130.172 Q 139.734 128.953 140.016 128.391 C 140.297 127.828 141.094 128.625 141.141 129.703 C 141.188 130.781 141.82 132.715 141.258 132.996 Q 140.695 133.277 136.734 133.266 L 136.125 130.172 Z "
        fill="rgb(244,190,137)"
      />
      <path
        d=" M 86.344 114.563 Q 86.063 111.094 87.656 106.406 L 87.75 106.5 Q 89.719 111.563 90.938 112.219 C 92.156 112.875 105.094 111.844 106.219 111.281 Q 107.344 110.719 106.313 107.156 L 96.375 106.969 Q 93.844 99.469 93 97.5 Q 92.156 95.531 89.648 91.547 L 86.168 89.965 Q 80.25 89.625 74.625 97.031 Q 69 104.438 68.906 123.281 Q 69.563 128.438 70.594 130.125 Q 71.625 131.813 72.75 132.469 Q 78.188 132.656 81.563 130.688 C 84.938 128.719 89.156 123.375 88.031 122.063 C 86.906 120.75 86.625 118.031 86.344 114.563 Z "
        fill="rgb(213,93,81)"
      />
      <path
        d=" M 91.406 109.688 Q 92.438 97.219 90.188 94.594 C 87.938 91.969 83.625 91.313 82.125 93.75 C 80.625 96.188 80.719 100.219 81.656 103.969 C 82.594 107.719 85.688 118.781 86.531 118.781 Q 87.375 118.781 106.688 113.813 Q 106.594 111.563 106.5 110.719 C 106.406 109.875 91.406 112.125 91.406 109.688 Z "
        fill="rgb(254,114,100)"
      />
      <path
        d=" M 79.219 93 C 75.938 95.438 75.469 90.938 77.906 87.938 Q 80.344 84.938 84 85.031 Q 91.875 86.063 92.344 90.75 Q 92.813 95.438 92.719 97.406 Q 89.625 91.031 87.094 90.75 C 84.563 90.469 82.5 90.563 79.219 93 Z "
        fill="rgb(253,112,99)"
      />
      <path
        d=" M 71.402 130.992 Q 73.406 133.418 76.887 134.262 C 79.529 134.902 88.207 134.895 90.176 134.895 C 92.145 134.895 105 133.125 106.5 133.219 C 108 133.313 132.844 133.688 134.906 133.875 Q 137.156 133.875 137.063 132.375 Q 136.969 130.875 136.031 129.656 Q 120.375 129 117.188 128.625 C 114 128.25 101.063 127.594 98.25 126.938 Q 95.438 126.281 88.313 123.375 Q 83.965 129.773 71.402 130.992 Z "
        fill="rgb(71,87,109)"
      />
      <g>
        <path
          d=" M 126 93.531 C 125.939 93.531 125.877 93.513 125.824 93.477 C 125.78 93.447 124.727 92.728 123.657 91.738 C 122.177 90.369 121.426 89.189 121.426 88.232 C 121.426 86.57 122.673 85.219 124.207 85.219 C 124.864 85.219 125.501 85.474 126 85.929 C 126.499 85.474 127.136 85.219 127.793 85.219 C 129.327 85.219 130.574 86.57 130.574 88.232 C 130.574 89.189 129.823 90.369 128.343 91.738 C 127.273 92.728 126.22 93.447 126.176 93.477 C 126.123 93.513 126.061 93.531 126 93.531 Z  M 125.969 90.594"
          fill="rgb(24,205,166)"
          className={HEART_CLASSNAME}
        />
      </g>
    </g>
  );

  return (
    <HowSystemWorksStepImage
      windowContentSvg={windowContentSvgElement}
      manSvg={manSvgElement}
      windowContentAnimation={windowContentAnimation}
      manAnimation={manAnimation(timelineHeart)}
      onPause={onPause(timelineHeart)}
      isActive={isActive}
    />
  );
};

export default StepJobEvaluation;
