/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

export const useSignStore = () => {
  const { signStore } = useStores();

  return useObserver(() => ({
    userGenerateCode: signStore.userGenerateCode,
  }));
};

export const useSnackbarStore = () => {
  const { snackbarStore } = useStores();

  return useObserver(() => ({
    setMessage: snackbarStore.setMessage,
  }));
};

export const useAccountStore = () => {
  const { accountStore } = useStores();

  return useObserver(() => ({
    load: accountStore.load,
    loading: accountStore.loading,
    list: accountStore.list,
    needToSaveTemplate: accountStore.needToSaveTemplate,
    toggleSaveTemplate: accountStore.toggleSaveTemplate,
    cleanUp: accountStore.cleanUp,
    partnerNotActive: accountStore.partnerNotActive,
  }));
};

// export const useAccountStore = () => {
//   const { accountStore } = useStores();

//   return useObserver(() => ({
//     loadAccount: accountStore.load,
//     partnerNotActive: accountStore.partnerNotActive,
//     checkPartners: accountStore.checkPartners,
//   }));
// };

export const useOwnProfileStore = () => {
  const { ownProfileStore } = useStores();

  return useObserver(() => ({
    billingUsers: ownProfileStore.billingUsers,
    cleanUp: ownProfileStore.cleanUp,
    selectedBillingUser: ownProfileStore.selectedBillingUser,
    user: ownProfileStore.user,
    profile: ownProfileStore.profile,
    update: ownProfileStore.update,
    updateBillingUser: ownProfileStore.updateBillingUser,
    orgId: ownProfileStore.selectedBillingUser?.organizationId,
    externalSupplier: ownProfileStore.externalSupplier,
    selectBillingUser: ownProfileStore.selectBillingUser,
  }));
};

export const useMerchantGroupStore = () => {
  const { merchantGroupStore } = useStores();

  return useObserver(() => ({
    listMerchantGroup: merchantGroupStore.list,
    loadingMerchantGroup: merchantGroupStore.loading,
    loadMerchantGroup: merchantGroupStore.load,
    cleanUpMerchantGroup: merchantGroupStore.cleanUp,
    merchantGroups: merchantGroupStore.list,
  }));
};

export const useMerchantStore = () => {
  const { merchantStore } = useStores();

  return useObserver(() => ({
    listMerchant: merchantStore.list,
    loadingMerchant: merchantStore.loading,
    loadMerchant: merchantStore.load,
    cleanUpMerchant: merchantStore.cleanUp,
    merchants: merchantStore.list,
  }));
};

export const useServiceStore = () => {
  const { serviceStore } = useStores();

  return useObserver(() => ({
    listService: serviceStore.list,
    loadingService: serviceStore.loading,
    loadService: serviceStore.load,
    cleanUpService: serviceStore.cleanUp,
    services: serviceStore.list,
  }));
};

export const usePaymentStore = () => {
  const { paymentStore } = useStores();

  return useObserver(() => ({
    paymentGetLink: paymentStore.getLink,
    loadCards: paymentStore.loadCards,
    payByCard: paymentStore.payByCard,
    detachCard: paymentStore.detachCard,
  }));
};

export const useChangeEmailStore = () => {
  const { changeEmailStore } = useStores();

  return useObserver(() => ({
    setModalState: changeEmailStore.setModalState,
    setSuccessModal: changeEmailStore.setSuccessModal,
  }));
};

export const useFastPaymentStore = () => {
  const { fastPaymentStore } = useStores();

  return useObserver(() => ({
    getOrgName: fastPaymentStore.orgName,
  }));
};

export const useOperationStore = () => {
  const { operationStore } = useStores();

  return useObserver(() => ({
    isAllowed: operationStore.isAllowed,
  }));
};

export const useBannerStore = () => {
  const { bannerStore } = useStores();

  return useObserver(() => ({
    list: bannerStore.list,
    bannerLoad: bannerStore.load,
    loading: bannerStore.loading,
  }));
};
