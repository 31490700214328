enum OpencityActionNames {
  AUTH_PASSWORD_RESET = 'authPasswordReset',
  AUTH_PASSWORD_RECOVERY = 'authPasswordRecovery',
  AUTH_PASSWORD_UPDATE = 'authPasswordUpdate',
  ADDRESS_SEARCH = 'addressSearch',
  CLASSIFIER_SEARCH = 'classifierSearch',
  ISSUE_CREATE = 'issueCreate',
  ISSUE_UPDATE = 'issueUpdate',
  ISSUE_EXPORT = 'issueExport',
  ISSUE_UNCONFIRMED_REJECTED_UPDATE = 'opencity.issue.unconfirmed.rejected.update',
  ISSUE_UNCONFIRMED_IN_WORK_UPDATE = 'opencity.issue.unconfirmed.in_work.update',
  ISSUE_UNCONFIRMED_DONE_UPDATE = 'opencity.issue.unconfirmed.done.update',
  ISSUE_REJECTED_NEW_UPDATE = 'opencity.issue.rejected.new.update',
  ISSUE_REJECTED_CLOSED_UPDATE = 'opencity.issue.rejected.closed.update',
  ISSUE_DONE_UNCONFIRMED_UPDATE = 'opencity.issue.done.unconfirmed.update',
  ISSUE_NEW_IN_WORK_UPDATE = 'opencity.issue.new.in_work.update',
  ISSUE_NEW_DONE_UPDATE = 'opencity.issue.new.done.update',
  ISSUE_TO_PROVIDER_CLOSED_BY_COMMUNAL_UPDATE = 'opencity.issue.to_provider.closed_by_communal.update',
  ISSUE_DONE_CLOSED_BY_COMMUNAL_UPDATE = 'opencity.issue.done.closed_by_communal.update',
  ISSUE_NEW_CLOSED_BY_COMMUNAL_UPDATE = 'opencity.issue.new.closed_by_communal.update',
  ISSUE_NEW_TO_EXECUTE_UPDATE = 'opencity.issue.new.to_execute.update',
  ISSUE_NEW_DELETED_UPDATE = 'opencity.issue.new.deleted.update',
  ISSUE_NEW_DELAYED_UPDATE = 'opencity.issue.new.delayed.update',
  ISSUE_NEW_TO_PROVIDER_UPDATE = 'opencity.issue.new.to_provider.update',
  ISSUE_DELAYED_RESTORED_UPDATE = 'opencity.issue.delayed.restored.update',
  ISSUE_RESTORED_DELAYED_UPDATE = 'opencity.issue.restored.delayed.update',
  ISSUE_DONE_CLOSED_UPDATE = 'opencity.issue.done.closed.update',
  ISSUE_RESTORED_TO_PROVIDER_UPDATE = 'opencity.issue.restored.to_provider.update',
  ISSUE_RESTORED_DELETED_UPDATE = 'opencity.issue.restored.deleted.update',
  ISSUE_TO_EXECUTE_DELETED_UPDATE = 'opencity.issue.to_execute.deleted.update',
  ISSUE_TO_EXECUTE_TO_PROVIDER_UPDATE = 'opencity.issue.to_execute.to_provider.update',
  ISSUE_TO_EXECUTE_DELAYED_UPDATE = 'opencity.issue.to_execute.delayed.update',
  ISSUE_TO_PROVIDER_IN_WORK_UPDATE = 'opencity.issue.to_provider.in_work.update',
  ISSUE_TO_PROVIDER_DONE_UPDATE = 'opencity.issue.to_provider.done.update',
  ISSUE_IN_WORK_DONE_UPDATE = 'opencity.issue.in_work.done.update',
  ISSUE_DONE_DELETED_UPDATE = 'opencity.issue.done.deleted.update',
  ISSUE_DONE_TO_PROVIDER_UPDATE = 'opencity.issue.done.to_provider.update',
  ISSUE_DONE_OK_UPDATE = 'opencity.issue.done.ok.update',
  ISSUE_RESTORED_DONE_UPDATE = 'opencity.issue.restored.done.update',
  ISSUE_OK_UNCONFIRMED_UPDATE = 'opencity.issue.ok.unconfirmed.update',
  ISSUE_OK_CLOSED_BY_CITIZEN_UPDATE = 'opencity.issue.ok.closed_by_citizen.update',
  ISSUE_OK_CLOSED_UPDATE = 'opencity.issue.ok.closed.update',
  ISSUE_OK_CLOSED_BY_STD_UPDATE = 'opencity.issue.ok.closed_by_std.update',
  ISSUE_OK_CLOSED_AUTO_UPDATE = 'opencity.issue.ok.closed_auto.update',
  ISSUE_UNCONFIRMED_TO_EXECUTE_UPDATE = 'opencity.issue.unconfirmed.to_execute.update',
  ISSUE_UNCONFIRMED_TO_PROVIDER_UPDATE = 'opencity.issue.unconfirmed.to_provider.update',
  ISSUE_UNCONFIRMED_CLOSED_BY_ANALYST_UPDATE = 'opencity.issue.unconfirmed.closed_by_analyst.update',
  ISSUE_TO_PROVIDER_DELETED_UPDATE = 'opencity.issue.to_provider.deleted.update',
  ISSUE_TO_PROVIDER_DELAYED_UPDATE = 'opencity.issue.to_provider.delayed.update',
  ISSUE_DELETED_RESTORED_UPDATE = 'opencity.issue.deleted.restored.update',
  ISSUE_NEW_REJECTED_UPDATE = 'opencity.issue.new.rejected.update',
  ISSUE_COUNT = 'issueCount',
  ISSUE_INDEX = 'issueIndex',
  ISSUE_COMMENT_INDEX = 'issueCommentIndex',
  ISSUE_DOCUMENT_CREATE = 'issueDocumentCreate',
  ISSUE_DOCUMENT_INDEX = 'issueDocumentIndex',
  ISSUE_STATISTICS_INDEX = 'statisticsIndex',
  ISSUE_STATISTICS_DYNAMICS = 'statisticsDynamicsIndex',
  ISSUE_STATISTICS_TOP_BY_HOUSE = 'statisticsTopByHouse',
  ISSUE_STATISTICS_TOP_BY_CONTROL = 'statisticsTopByControl',
  ISSUE_RECORD_GET_URL = 'issueRecordGetUrl',
  MAP_ISSUE_INDEX = 'mapIssueIndex',
  FLAT_ACCOUNT_INDEX = 'flatAccountIndex',
  FLAT_ACCOUNT_COUNT = 'flatAccountCount',
  FLAT_ACCOUNT_OWNER_CREATE = 'flatAccountOwnerCreate',
  FLAT_ACCOUNT_OWNER_UPDATE = 'flatAccountOwnerUpdate',
  FLAT_ACCOUNT_GET_LAST_APPLICANT = 'flatAccountGetLastApplicant',
  USER_COUNT = 'userCount',
  USER_CREATE = 'userCreate',
  USER_INDEX = 'userIndex',
  USER_DELETE = 'userDelete',
  USER_CATEGORY_INDEX = 'userCategoryIndex',
  USER_RULE_UPDATE = 'userRuleUpdate',
  USER_NOTIFICATION_UNSUBSCRIBE = 'userNotificationUnsubscribe',
  ORGANIZATION_COUNT = 'organizationCount',
  ORGANIZATION_INDEX = 'organizationIndex',
  ORGANIZATION_DISTRICT_INDEX = 'organizationDistrictIndex',
  ORGANIZATION_RATING_INDEX = 'organizationRatingIndex',
  ORGANIZATION_PREFERABLE_INDEX = 'preferableOrganizationIndex',
  INTERRUPT_INDEX = 'interruptIndex',
  INTERRUPT_COUNT = 'interruptCount',
  INTERRUPT_CREATE = 'interruptCreate',
  INTERRUPT_UPDATE = 'interruptUpdate',
  PROFILE_INDEX = 'profileIndex',
  PROFILE_CREATE = 'profileCreate',
  PROFILE_UPDATE = 'profileUpdate',
  PROFILE_DELETE = 'profileDelete',
  PROFILE_ACCOUNT_CREATE = 'profileAccountCreate',
  PROFILE_ACCOUNT_DELETE = 'profileAccountDelete',
  AVATAR_CREATE = 'avatarCreate',
  AVATAR_DELETE = 'avatarDelete',
  AVATAR_INDEX = 'avatarIndex',
  AVATAR_UPDATE = 'avatarUpdate',
  GIS_STREET_INDEX = 'gisStreetIndex',
  GIS_STREET_COUNT = 'gisStreetCount',
  GIS_HOUSE_INDEX = 'gisHouseIndex',
  GIS_HOUSE_COUNT = 'gisHouseCount',
  GIS_FLAT_INDEX = 'gisFlatIndex',
  GIS_FLAT_COUNT = 'gisFlatCount',
  GIS_DISTRICT_INDEX = 'gisDistrictIndex',
  GIS_DISTRICT_COUNT = 'gisDistrictCount',
  USER_STREET_INDEX = 'userStreetIndex',
  USER_HOUSE_INDEX = 'userHouseIndex',
  USER_FLAT_INDEX = 'userFlatIndex',
  DEVICE_METERING_INDEX = 'deviceMeteringIndex',
  DEVICE_METERING_REFRESH = 'deviceMeteringRefresh',
  DEVICE_METERING_VALUE_INDEX = 'deviceMeteringValueIndex',
  DEVICE_METERING_VALUE_CREATE = 'deviceMeteringValueCreate',
  FILE_CREATE = 'fileCreate',
  FILE_INDEX = 'fileIndex',
  REFERENCE_INTERRUPT_REASON_INDEX = 'referenceInterruptReasonIndex',
  REFERENCE_INTERRUPT_SENDER_INDEX = 'referenceInterruptSenderIndex',
  REFERENCE_INTERRUPT_TYPE_INDEX = 'referenceInterruptTypeIndex',
  REFERENCE_CLASSIFIER_CATEGORY_INDEX = 'referenceClassifierCategoryIndex',
  REFERENCE_CLASSIFIER_TRACKER_INDEX = 'referenceClassifierTrackerIndex',
  REFERENCE_CLASSIFIER_TYPE_INDEX = 'referenceClassifierTypeIndex',
  REFERENCE_CLASSIFIER_TAG_INDEX = 'referenceClassifierTagIndex',
  FEEDBACK_CREATE = 'feedbackCreate',

  TRANSITION_BY_STATE_FROM_INDEX = 'transitionByStateFromIndex',

  AUTH_OPERATION_ALLOWED = 'authOperationAllowed',
  REPORT_INTERRUPT_CREATE = 'reportInterruptCreate',
  REPORT_INTERRUPT_EXTENDED_CREATE = 'reportInterruptExtendedCreate',
  REPORT_INTERRUPT_SENDER_CREATE = 'reportInterruptSenderCreate',
  REPORT_INTERRUPT_CONTROL_CREATE = 'reportInterruptControlCreate',
  REPORT_ISSUE_OPERATOR_CREATE = 'reportIssueOperatorCreate',
  REPORT_COUNTER_CREATE = 'reportCounterCreate',
  REPORT_ACCOUNT_CREATE = 'reportAccountCreate',
  REPORT_ISSUE_CHECKERBOARD = 'reportIssueCheckerboard',
  REPORT_ISSUE_FOOTCLOTH = 'reportIssueFootcloth',
  REPORT_ISSUE_CREATE = 'reportIssueCreate',
  REPORT_INDEX = 'reportIndex',

  AUTH_PROVIDER_GET_URL = 'authProviderGetUrl',
  AUTH_PROVIDER_LOGIN = 'authProviderLogin',
  ISSUE_CHANGE_PROVIDER = 'issueChangeProvider',
  ISSUE_CHANGE_CLASSIFIER = 'issueChangeClassifier',
  CACHE_ISSUE_COUNT = 'cacheIssueCount',
  USER_CODE_GENERATE = 'userCodeGenerate',
  USER_CODE_GET_NEXT_TIME = 'userCodeGetNextTime',
  USER_CODE_CONFIRM = 'userCodeConfirm',
  USER_CODE_VALIDATE = 'userCodeValudate',
  AUTH_LOGIN_EXIST = 'authLoginExist',

  USER_NOTIFICATION_DELETE = 'userNotificationDelete',

  ISSUE_COMMENT_CREATE = 'issueCommentCreate',

  ISSUE_VIEW = 'issueView',

  ISSUE_NOTICE = 'issueNotice',

  USER_PREFERENCE_UPDATE = 'userPreferenceUpdate',
  USER_PREFERENCE_INDEX = 'userPreferenceIndex',
  CAPTCHA_CHECK = 'captchaCheck',

  OVERHAUL_INDEX = 'overhaulIndex',
}

export default OpencityActionNames;
