import { useObserver } from 'mobx-react';

import useStores from '@stores/useStores';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAuthenticationStore = () => {
  const { authenticationStore } = useStores();

  return useObserver(() => ({
    authenticated: authenticationStore.authenticated,
    authenticatedAsGuest: authenticationStore.authenticatedAsGuest,
    refreshByToken: authenticationStore.refreshByToken,
  }));
};

export const useSnackbarStore = () => {
  const { snackbarStore } = useStores();

  return useObserver(() => ({
    setMessage: snackbarStore.setMessage,
  }));
};
