const YMActionNames = {
  sendOpenForm: 'send_open_form',
  sendConfirmEmail: 'send_confirm_email',
  closeConfirmEmail: 'close_confirm_email',
  addPersonalAccount: 'add_personal_account',
  sendAddPersonalAccount: 'send_add_personal_account',
  sendAddPersonalAccountValidationError: 'send_add_personal_account_validation_error',
  sendAddPersonalAccountError: 'send_add_personal_account_error',
  buttonPay: 'button_pay',
  buttonPayFast: 'button_pay(fastpay)',
  sendFormPayment: 'send_form_payment',
  sendFormPaymentFast: 'send_form_payment(fastpay)',
  downloadInvoice: 'download_invoice',
  sendCounters: 'send_counters',
  sendCountersUserError: 'send_counters_user_error',
  sendCountersServerError: 'send_counters_server_error',
  accountNotFound: 'pa_not_found (fastpay)',
  receiptsRejection: 'otkaz_epd_info_payment',
  receiptsRejectionFromPublic: 'otkaz_epd_info_mainpage',
  buttonQr: 'button_qr',
  buttonMypay: 'button_mypay',
  sendQrToEmail: 'send_qr_to_email',
  sendQrToEmailError: 'send_qr_to_email_error',
  smartcardMore: 'smartcard_more',
  smartcardAdd: 'smartcard_add',
  sliderPrepayment: 'slider_prepayment',
};

export type YMActionNameType = keyof typeof YMActionNames;

export { YMActionNames };
