import { AxiosRequestConfig } from 'axios';

import { HttpClient } from '@httpClient/index';
import Methods from './methods';
import ActionNames from './actionNames';

class AuthenticationService extends HttpClient<ActionNames> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(request: any, options?: AxiosRequestConfig) {
    super(request, options);

    this.init();
  }

  private init = (): void => {
    this.requests[ActionNames.TOKEN_CREATE] = this.request({ method: Methods.TOKEN_CREATE });

    this.requests[ActionNames.TOKEN_REFRESH] = this.request({ method: Methods.TOKEN_REFRESH });
  };
}

export default AuthenticationService;
