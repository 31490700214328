import Store from './Store';
import { action, computed, observable } from 'mobx';

import Services from '@core/services';
import SnackbarStore from './SnackbarStore';
import { DadataParams, DadataSuggestionsList } from '@core/entities/Billing/Dadata';

class DadataStore extends Store {
  private readonly _snackBarStore: SnackbarStore;

  @observable private _loadingDadataAdresses: boolean;
  @observable private _loadingDadataAdressesError: boolean;
  @observable private _capchaError: boolean;
  @observable private _dadataAdressesList?: DadataSuggestionsList;
  @observable private _isShowDadataCaptcha: boolean | undefined;

  public constructor(services: Services, snackBarStore: SnackbarStore) {
    super(services);

    this._snackBarStore = snackBarStore;

    this._loadingDadataAdresses = false;
    this._loadingDadataAdressesError = false;
    this._capchaError = false;
    this._isShowDadataCaptcha = undefined;
  }

  @action public checkDadataIsShowCaptcha = async (): Promise<boolean | undefined> => {
    this._isShowDadataCaptcha = undefined;

    await this._services.billing.requests.dadataIsShowCaptcha().then(({ data: { result } }) => {
      if (typeof result === 'boolean') {
        this._isShowDadataCaptcha = result;
      }
    });

    return this._isShowDadataCaptcha;
  };

  @action public loadDadataAdresses = async (
    params: DadataParams,
  ): Promise<DadataSuggestionsList> => {
    this._loadingDadataAdressesError = false;
    this._capchaError = false;
    this._loadingDadataAdresses = true;
    this._dadataAdressesList = undefined;

    let dadataAdressesList: DadataSuggestionsList = {};

    const { address, limit, captchaResponse } = params;

    await this._services.billing.requests
      .dadataSuggsGet({
        params: {
          address,
          limit: limit || 10,
          captchaResponse,
        },
      })
      .then(({ data: { result, error } }) => {
        if (error && error?.code === 100006) {
          this._capchaError = true;
          this._snackBarStore.setMessage(
            'В целях безопасности пользователей мы вынуждены отклонить ваш запрос. Попробуйте повторить запрос позднее',
            'error',
          );
        } else if ((error && error?.code !== 100006) || !result) {
          this._loadingDadataAdressesError = true;
        } else if (Array.isArray(result?.suggestions)) {
          dadataAdressesList = new DadataSuggestionsList({ suggestions: result?.suggestions });
          this._dadataAdressesList = dadataAdressesList;
        }
      })
      .finally(() => (this._loadingDadataAdresses = false));

    return dadataAdressesList;
  };

  @computed public get dadataAdressesList(): DadataSuggestionsList | undefined {
    return this._dadataAdressesList;
  }

  @computed public get loadingDadataAdresses(): boolean {
    return this._loadingDadataAdresses;
  }

  @computed public get loadingDadataAdressesError(): boolean {
    return this._loadingDadataAdressesError;
  }

  @computed public get capchaError(): boolean {
    return this._capchaError;
  }

  @action public dadataAdressesListCleanUp = () => {
    this._dadataAdressesList = undefined;
  };
}

export default DadataStore;
