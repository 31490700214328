import { AxiosResponse } from 'axios';
import { observable, computed, action, toJS } from 'mobx';
import { uniq } from 'ramda';

import Services from '@services/index';
import { JrpcResponse } from '@httpClient/jrpc';
import {
  OrganizationInterface,
  Organization,
  OrganizationFilterProps,
  OrganizationKeys,
  LoadOrganization,
  GetOrganization,
} from '@core/entities/Opencity/Organization';
import {
  PreferableOrganization,
  PreferableOrganizationInterface,
  LoadPreferableOrganization,
} from '@core/entities/Opencity/PreferableOrganization';
import Store from './Store';
import { Loading, endLoading } from './interfaces/Loading';
import { Filter } from './interfaces/Filter';
import { Entity } from './interfaces/Entity';
import { Pagination } from './interfaces/Pagination';

type OrganizationIndexResponse = JrpcResponse<{ items: OrganizationInterface[] }>;
type PreferableOrganizationIndexResponse = JrpcResponse<{
  items: PreferableOrganizationInterface[];
}>;

class OrganizationStore extends Store
  implements
    Loading,
    Filter<OrganizationFilterProps, OrganizationKeys>,
    Entity<Organization, { filter?: OrganizationFilterProps; limit?: number }>,
    Pagination {
  @observable private _organizations: Organization[];
  @observable private _preferableOrganization: PreferableOrganization[];
  @observable private _filter: OrganizationFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(500).bind(this);

  public constructor(services: Services) {
    super(services);

    this._organizations = [];
    this._preferableOrganization = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public load: LoadOrganization = async params => {
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = typeof params?.limit === 'number' ? params.limit : this._limit;

    this._filter = filter;
    this._limit = limit;

    const {
      data: { result },
    }: AxiosResponse<OrganizationIndexResponse> = await this._services.opencity.requests.organizationIndex(
      { params: { filter, limit } },
    );

    if (result && Array.isArray(result.items)) {
      const organizations = result.items.map(value => new Organization(value));

      this._organizations = organizations;
      this._loading = false;

      return organizations;
    }

    this._endLoading();

    return [];
  };

  @action public get: GetOrganization = async filter => {
    let organization: Organization | undefined = await this._organizations.find(
      value => value.id === filter[OrganizationKeys.ID]?.$eq,
    );

    if (!organization) {
      this._loading = true;

      await this._services.opencity.requests
        .organizationIndex({ params: { filter } })
        .then(({ data: { result } }: AxiosResponse<OrganizationIndexResponse>) => {
          if (result && Array.isArray(result.items)) {
            organization = result.items[0];
            if (organization) {
              this._organizations = uniq([...this._organizations, organization]);
            }
          }
        })
        .finally(this._endLoading);
    }

    return organization;
  };

  @action public loadPreferable: LoadPreferableOrganization = async params => {
    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = typeof params?.limit === 'number' ? params.limit : this._limit;

    this._filter = filter;
    this._limit = limit;

    const {
      data: { result },
    }: AxiosResponse<PreferableOrganizationIndexResponse> = await this._services.opencity.requests.preferableOrganizationIndex(
      { params: { filter, limit } },
    );

    if (result && Array.isArray(result.items)) {
      const preferableOrganizations = result.items.map(value => new PreferableOrganization(value));

      this._preferableOrganization = preferableOrganizations;
      this._loading = false;

      return preferableOrganizations;
    }

    this._endLoading();

    return [];
  };

  @action public cleanUp = (): void => {
    this._organizations = [];
    this._preferableOrganization = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Organization[] {
    return toJS(this._organizations);
  }

  @computed public get filter(): OrganizationFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get preferableList(): PreferableOrganization[] {
    return this._preferableOrganization;
  }
}

export default OrganizationStore;
