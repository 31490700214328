import * as yup from 'yup';
import { email } from '@core/validation';

export const FormSchema = yup.object({
  amount: yup
    .number()
    .typeError('Сумма должна быть числом')
    .positive('Сумма должна быть больше нуля')
    .required('Необходимо ввести значение'),

  wantToTransfer: yup.boolean(),
  counters: yup.array(),
  emailForFiscalReceipt: email,
});

export type FormProps = yup.InferType<typeof FormSchema>;
