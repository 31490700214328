import { Create, Load } from '@stores/interfaces/Entity';
import { FilterOperators } from '@stores/interfaces/Filter';

export interface OrderDocumentInterface {
  id: number;
  orderId?: number | null;
  providerAvrId?: number | null;
  workOrderId?: number | null;
  providerId?: number | null;
  createdDate?: number | null;
  number?: number | null;
  scanFileName?: string | null;
}

export class OrderDocument {
  public id: number;
  public orderId?: number | null;
  public providerAvrId?: number | null;
  public workOrderId?: number | null;
  public providerId?: number | null;
  public createdDate?: number | null;
  public number?: number | null;
  public scanFileName?: string | null;

  public constructor(value: OrderDocumentInterface) {
    this.id = value.id;
    this.orderId = value.orderId;
    this.providerAvrId = value.providerAvrId;
    this.workOrderId = value.workOrderId;
    this.providerId = value.providerId;
    this.createdDate = value.createdDate;
    this.number = value.number;
    this.scanFileName = value.scanFileName;
  }
}

export enum OrderKeys {
  ORDER_ID = 'orderId',
}

export interface OrderDocumentFilterProps {
  [OrderKeys.ORDER_ID]: FilterOperators<number>;
}

export type CreateOrderDocument = Create<
  { orderId: number; fileId: number },
  Promise<OrderDocument | null>
>;

export type LoadOrderDocument = Load<{ filter?: OrderDocumentFilterProps }, OrderDocument[]>;
