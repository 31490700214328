import React, { ReactElement, useEffect } from 'react';

import './yaShare.css';

const SocialShareHeader = (): ReactElement => {
  const {
    Ya: { share2 },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = window as any;

  useEffect(() => {
    share2('ya-share-header-social', {
      contentByService: {
        vkontakte: {
          url: 'https://cabinet.rc-online.ru',
          title:
            'Попробуйте Кабинет Абонента - оплата ЖКУ, показания счетчиков, электронная квитанция и многое другое ',
          description: '',
        },
        odnoklassniki: {
          url: 'https://cabinet.rc-online.ru',
          title:
            'Попробуйте Кабинет Абонента - оплата ЖКУ, показания счетчиков, электронная квитанция и многое другое ',
          description: '',
        },
        whatsapp: {
          url: 'https://cabinet.rc-online.ru',
          title:
            'Попробуйте Кабинет Абонента - оплата ЖКУ, показания счетчиков, электронная квитанция и многое другое ',
          description: '',
        },
        telegram: {
          url: 'https://cabinet.rc-online.ru',
          title:
            'Попробуйте Кабинет Абонента - оплата ЖКУ, показания счетчиков, электронная квитанция и многое другое ',
          description: '',
        },
      },
      theme: {
        services: 'vkontakte,odnoklassniki,whatsapp,telegram',
        curtain: true,
        size: 'l',
        shape: 'round',
      },
    });
  }, []);

  return <div id="ya-share-header-social"></div>;
};

export default SocialShareHeader;
