export const oS = ((): string => {
  const _appVersion = window.navigator.appVersion;

  if (_appVersion.indexOf('Android') !== -1) {
    return 'android';
  }
  if (_appVersion.indexOf('iPhone') !== -1) {
    return 'ios';
  }
  if (_appVersion.indexOf('Win') !== -1) {
    return 'windows';
  }
  if (_appVersion.indexOf('Mac') !== -1) {
    return 'macos';
  }
  if (_appVersion.indexOf('X11') !== -1) {
    return 'linux';
  }
  if (_appVersion.indexOf('Linux') !== -1) {
    return 'linux';
  }
  return '';
})();

export const openTransitionModal = ((): boolean => {
  const _userAgent = window.navigator.userAgent.toLowerCase();

  if (/firefox/.test(_userAgent)) {
    return true;
  }

  if (/safari/.test(_userAgent) && !/chrome/.test(_userAgent)) {
    return true;
  }

  return false;
})();
