enum projects {
  KAZAN = 'kazan',
  NIZHNY_NOVGOROD = 'nnov',
  BILLING = 'billing',
  BILLING_SPECIAL = 'special',
  NIZHNEKAMSK = 'nizhnekamsk',
  AGRYZ = 'agryz',
  USINSK = 'usinsk',
}

export default projects;
