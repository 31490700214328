import React, { FC } from 'react';
import { Fade, Theme, makeStyles, createStyles } from '@material-ui/core';
import cn from 'classnames';

import { isSpecialBilling } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      borderRadius: 6,
      backgroundColor: isSpecialBilling ? 'rgba(80, 128, 255, 0.1)' : '#E6F9F7',
      padding: 24,

      [theme.breakpoints.down('xs')]: {
        padding: 12,
      },
    },

    close: {
      top: -9,
      right: -9,
      position: 'absolute',
      cursor: 'pointer',
    },
  }),
);

interface Props {
  isVisible?: boolean;
  timeout?: number;
  onClick?: () => void;
  className?: string;
}

export const Banner: FC<Props> = ({
  isVisible = true,
  timeout = 0,
  onClick,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Fade in={isVisible} timeout={timeout} unmountOnExit={true}>
      <div className={cn(classes.root, className)}>
        {onClick && (
          <img
            src={
              isSpecialBilling ? '/images/special/buttons/close.svg' : '/images/button/close.svg'
            }
            onClick={onClick}
            className={classes.close}
          />
        )}

        {children}
      </div>
    </Fade>
  );
};
