import * as yup from 'yup';

import config from '@core/constants/config';
import { EMAIL_REGEXP, PASSWORD_REGEXP } from '@constants/regexp';
import { Address } from '@core/entities/Opencity/Address';
import { Classifier } from '@core/entities/Opencity/Classifier';
import { Resource } from '@core/entities/Opencity/Resource';

export const DESCRIPTION_MAX_LENGTH = 4000;

export const street = yup
  .number()
  .nullable()
  .when('isNizhnekamsk', {
    is: true,
    then: yup.number().nullable(),
    otherwise: yup
      .number()
      .nullable()
      .required('Необходимо выбрать улицу'),
  });

export const house = yup
  .number()
  .nullable()
  .when('isNizhnekamsk', {
    is: true,
    then: yup.number().nullable(),
    otherwise: yup
      .number()
      .nullable()
      .required('Необходимо выбрать дом'),
  });

export const flat = yup
  .number()
  .nullable()
  .when('isNizhnekamsk', {
    is: true,
    then: yup.number().nullable(),
    otherwise: yup
      .number()
      .nullable()
      .required('Необходимо выбрать квартиру'),
  });

export const address = yup
  .object()
  .shape<Address>({
    id: yup.string(),
    label: yup.string(),
  })
  .nullable()
  .when('isNizhnekamsk', {
    is: true,
    then: yup
      .object()
      .shape<Address>({
        id: yup.string(),
        label: yup.string(),
      })
      .nullable(),
    otherwise: yup
      .object()
      .shape<Address>({
        id: yup.string(),
        label: yup.string(),
      })
      .required('Необходимо выбрать адрес')
      .nullable(),
  });

export const flatAccountNumber = yup.string().when('isNizhnekamsk', {
  is: true,
  then: yup.string().trim(),
  otherwise: yup
    .string()
    .trim()
    .required('Необходимо ввести лицевой счёт'),
});

export const nameApplicant = yup.string().trim();

export const phone = yup
  .string()
  .trim()
  .nullable()
  .email(({ value }) => {
    const phoneValue: string = value.replace(/\D/g, '');

    if (phoneValue.length < 5) {
      return 'Некорректный номер телефона';
    }
  });

export const resources = yup
  .array()
  .of(
    yup.object().shape<Resource>({
      description: yup.string(),
      fileName: yup.string(),
      fileUrl: yup.string(),
      id: yup.number().required(),
      isSecure: yup.boolean(),
      mime: yup.string(),
      originalFileName: yup.string(),
      size: yup
        .number()
        .max(
          config.constraints.weightOfUploadedFile * 1000000,
          `Размер файла не может превышать ${config.constraints.weightOfUploadedFile} мб`,
        ),
    }),
  )
  .max(5, 'Максимальное количество загружемых файлов - 5');

export const classifier = yup
  .object()
  .shape<Classifier>({ id: yup.number().required(), name: yup.string().required() })
  .required('Необходимо выбрать тип проблемы')
  .nullable();

export const description = yup
  .string()
  .trim()
  .max(DESCRIPTION_MAX_LENGTH, `Допустимое количество символов - ${DESCRIPTION_MAX_LENGTH}`);

export const email = yup
  .string()
  .matches(EMAIL_REGEXP, 'Укажите корректный адрес электронной почты')
  .trim();

export const lastName = yup
  .string()
  .trim()
  .nullable();

export const name = yup
  .string()
  .trim()
  .nullable();

export const patronymic = yup
  .string()
  .trim()
  .nullable();

export const date = yup.number().nullable();

export const provider = yup.number().nullable();

export const shutdownSender = yup
  .number()
  .nullable()
  .required('Необходимо указать информатора');

export const shutdownReason = yup
  .number()
  .nullable()
  .required('Необходимо указать тип отключения');

export const shutdownType = yup
  .number()
  .nullable()
  .required('Необходимо указать класс отключения');

export const shutdownAddress = yup
  .object({
    flatIds: yup.array(yup.number()),
    houseIds: yup.array(yup.number()),
    streetIds: yup.array(yup.number()),
  })
  .nullable()
  .required('Необходимо выбрать адреса');

export const deleteProfile = yup
  .string()
  .trim()
  .required('Введите текущий пароль');

export const updatePassword = yup.object({
  oldPassword: yup
    .string()
    .trim()
    .required('Введите текущий пароль'),
  newPassword: yup
    .string()
    .trim()
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .max(128, 'Пароль должен содержать не более 128 символов')
    .notOneOf([yup.ref('oldPassword'), null], 'Новый пароль совпадает с текущим')
    .matches(
      PASSWORD_REGEXP,
      'Пароль должен содержать прописные и строчные буквы, цифры и специальные символы (!@#$%^&*()?~=-_/|+)',
    )
    .required('Введите новый пароль'),
  repeatNewPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
    .required('Повторите пароль'),
});
