import { OrderFilterProps, OrderKeys, OrderState, OrderTabs } from '@entities/Opencity/Order';
import { Address } from '@entities/Opencity/Address';
import { OptionType } from '@components/Autocomplete';

export interface PageSettingsInterface {
  filter: OrderFilterProps;
  tabState: OrderTabs;
  selectedColumns: Array<OrderKeys>;
  limit: number;
  offset: number;
  addressSearchData: Address | null;
  controlSearchData: OptionType[];
  providerSearchData: OptionType[];
  pageTab: number;
}
export type PageSettingsValue = PageSettingsInterface[keyof PageSettingsInterface];

export const settings: PageSettingsInterface = {
  filter: {
    // state: { $eq: OrderState.ALL },
  },
  tabState: OrderTabs.ALL,
  selectedColumns: [
    OrderKeys.ADDRESS,
    OrderKeys.TEXT,
    OrderKeys.NUMBER,
    OrderKeys.STANDARD_DATE,
    OrderKeys.STATE,
  ],
  limit: 20,
  offset: 0,
  addressSearchData: null,
  controlSearchData: [],
  providerSearchData: [],
  pageTab: 0,
};
