import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  LoadCounter,
  CounterFilterProps,
  CounterKeys,
  Counter,
  CounterInterface,
} from '@entities/Billing/Counter';
import {
  CounterHistory,
  LoadCounterHistory,
  CounterHistoryInterface,
} from '@entities/Billing/CounterHistory';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';
import { CounterValue } from '@entities/Billing/CounterValue';
import SnackbarStore from '@stores/SnackbarStore';

type CounterIndexResponse = JrpcResponse<{ items: CounterInterface[]; total: number }>;
type CounterHistoryIndexResponse = JrpcResponse<{
  items: CounterHistoryInterface[];
  total: number;
}>;
type LoadCounterResponse = JrpcResponse<boolean>;

interface CounterStoreRelations {
  snackbarStore: SnackbarStore;
}

class CounterStore extends Store<CounterStoreRelations>
  implements
    Loading,
    Pagination,
    Filter<CounterFilterProps, CounterKeys>,
    Entity<Counter, { filter?: CounterFilterProps; limit?: number; offset?: number }> {
  @observable private _counters: Counter[];
  @observable private _loaded: boolean;
  @observable private _filter: CounterFilterProps;
  @observable private _loading: boolean;
  @observable private _loadingHistory: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services, relations: CounterStoreRelations) {
    super(services, relations);

    this._counters = [];
    this._loaded = false;
    this._filter = {};
    this._loading = false;
    this._loadingHistory = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  }

  @action public load: LoadCounter = async params => {
    let counters: Counter[] = [];

    this._loading = true;
    this._loaded = false;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .counterIndex({ params: { filter, limit, offset } })
      .then(({ data: { result } }: AxiosResponse<CounterIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          counters = result.items.map(value => new Counter(value));

          this._counters = counters;
          this._loaded = true;
        }

        if (result?.total && typeof result.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return counters;
  };

  @action public loadValue = async (value: CounterValue): Promise<boolean> => {
    this._loading = true;
    let result = false;

    await this._services.billing.requests
      .counterValueCreate({ params: { ...value } })
      .then((res: AxiosResponse<LoadCounterResponse>) => {
        if (res) {
          result = true;
          this._relations.snackbarStore.setMessage('Показания переданы', 'success');
        }
      })
      .finally(this._endLoading);

    return result;
  };

  @action public loadHistory: LoadCounterHistory = async params => {
    this._loadingHistory = true;
    let countersHistory: CounterHistory[] = [];

    const filter = params?.filter || toJS(this._filter);
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    await this._services.billing.requests
      .counterValueIndex({ params: { filter, offset } })
      .then(({ data: { result } }: AxiosResponse<CounterHistoryIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          countersHistory = result.items.map(value => new CounterHistory(value));
        }
      })
      .finally(() => {
        this._loadingHistory = false;
      });

    return countersHistory;
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._counters = [];
    this._loaded = false;
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): Counter[] {
    return toJS(this._counters);
  }

  @computed public get filter(): CounterFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get loaded(): boolean {
    return this._loaded;
  }

  @computed public get loadingHistory(): boolean {
    return this._loadingHistory;
  }
}

export default CounterStore;
