import React, { FC, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';
import { InputLabelProps } from '@material-ui/core/InputLabel';
import { Link, makeStyles, OutlinedInputProps } from '@material-ui/core';

import cn from 'classnames';
import { ConfirmedEmailAdorment } from '@private/components/ConfirmedEmailAdorment';
import { isBilling } from '@core/constants/project';

interface NameApplicantFieldProps {
  className?: string;
  inputClass?: string;
  id?: string;
  autoFocus?: boolean;
  value?: string | null;
  touched?: boolean;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  dataTestAttribute?: string;
  onChange?: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  isCurrentEmail?: boolean;
  onClick?: () => void;
  profileModal?: boolean;
  isConfirmed?: boolean;
  name?: string;
  fullWidth?: boolean;
  customInputProps?: OutlinedInputProps;
  customInputLabelProps?: InputLabelProps;
  withPlaceholder?: boolean;
  InputProps?: InputProps;
  InputLabelProps?: InputLabelProps;
  withoutLabel?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  link: {
    fontSize: 15,
    top: 38,
    right: -82,
    position: 'absolute',
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  linkProfileModal: {
    '@media (min-width: 960px) and (max-width: 1150px)': {
      top: 76,
      left: 2,
      right: 0,
    },
    '@media (max-width: 850px)': {
      top: 76,
      left: 2,
      right: 0,
    },
  },
  test: {
    marginTop: 123,
  },
}));

const EmailField: FC<NameApplicantFieldProps> = ({
  className,
  inputClass,
  autoFocus,
  value,
  touched,
  error,
  required,
  label = 'Адрес электронной почты',
  helperText = 'Введите адрес электронной почты',
  placeholder = '',
  disabled,
  dataTestAttribute,
  onChange,
  onBlur,
  isCurrentEmail,
  onClick,
  name,
  profileModal = false,
  isConfirmed = false,
  fullWidth = true,
  customInputProps,
  customInputLabelProps,
  withPlaceholder = false,
  InputProps,
  InputLabelProps,
  withoutLabel,
}) => {
  const classes = useStyles();

  const buttonText = useMemo(() => (isCurrentEmail ? 'Изменить' : 'Добавить'), [isCurrentEmail]);

  const presetedInputProps: OutlinedInputProps = {
    inputProps: {
      'data-test': dataTestAttribute,
      className: inputClass,
    },
    notched: false,
    endAdornment: <ConfirmedEmailAdorment isConfirmed={isConfirmed} />,
  };

  const presetedLabelProps: InputLabelProps = {
    required,
    shrink: true,
  };

  const helperTextElement = useMemo(() => {
    if (withPlaceholder) {
      return error || '';
    }
    if (touched) {
      return error;
    }
    return helperText;
  }, [error, helperText, touched, withPlaceholder]);

  return (
    <div className={classes.root}>
      <TextField
        className={className}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        name={name || 'email'}
        value={value}
        error={Boolean(error)}
        helperText={helperTextElement}
        disabled={disabled}
        placeholder={withPlaceholder ? 'Введите ваш email' : placeholder}
        label={withoutLabel ? undefined : label}
        InputLabelProps={{ ...presetedLabelProps, ...InputLabelProps, ...customInputLabelProps }}
        InputProps={{ ...presetedInputProps, ...InputProps, ...customInputProps }}
        onChange={onChange}
        onBlur={onBlur}
      />
      {onClick && isBilling && (
        <Link
          onClick={onClick}
          className={cn(classes.link, { [classes.linkProfileModal]: profileModal })}
        >
          {buttonText}
        </Link>
      )}
    </div>
  );
};

export default EmailField;
