import { FilterOperators } from '@core/stores/interfaces/Filter';

export enum InformationMessagesKeys {
  PROVIDER_ID = 'providerId',
  ACCOUNT_NUMBER = 'accountNumber',
}

export interface InformationMessagesFilterProps {
  [InformationMessagesKeys.PROVIDER_ID]?: FilterOperators<number>;
  [InformationMessagesKeys.ACCOUNT_NUMBER]?: FilterOperators<number>;
}

export interface InformationMessagesInterface {
  items: { providerId: number; announcements: string[] }[];
  count: number;
}

export class InformationMessages implements InformationMessagesInterface {
  items: { providerId: number; announcements: string[] }[];
  count: number;

  public constructor(value: InformationMessagesInterface) {
    this.items = [
      { providerId: value.items[0].providerId, announcements: value.items[0].announcements },
    ];
    this.count = value.count;
  }
}
