import { observable, computed, action, toJS } from 'mobx';
import { AxiosResponse } from 'axios';

import { JrpcResponse } from '@httpClient/jrpc';
import Services from '@services/index';
import {
  LoadMerchantGroup,
  MerchantGroupFilterProps,
  MerchantGroup,
  MerchantGroupKeys,
  MerchantGroupInterface,
} from '@entities/Billing/MerchantGroup';
import Store from './Store';
import { Loading, endLoading } from '@stores/interfaces/Loading';
import { Entity } from '@stores/interfaces/Entity';
import { Pagination, SetLimit, SetOffset } from './interfaces/Pagination';
import { Filter } from './interfaces/Filter';

type MerchantGroupIndexResponse = JrpcResponse<{ items: MerchantGroupInterface[]; total: number }>;

class MerchantGroupStore extends Store
  implements
    Loading,
    Pagination,
    Filter<MerchantGroupFilterProps, MerchantGroupKeys>,
    Entity<MerchantGroup, { filter?: MerchantGroupFilterProps; limit?: number; offset?: number }> {
  @observable private _merchantGroups: MerchantGroup[];
  @observable private _filter: MerchantGroupFilterProps;
  @observable private _loading: boolean;
  @observable private _limit: number;
  @observable private _offset: number;
  @observable private _total: number;
  @observable private _existTokenizationEnabledMerchantGroup: boolean;
  @observable private _isSbpEnabled: boolean;

  @action private _endLoading = endLoading(50).bind(this);

  public constructor(services: Services) {
    super(services);

    this._merchantGroups = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
    this._existTokenizationEnabledMerchantGroup = false;
    this._isSbpEnabled = false;
  }

  @action public load: LoadMerchantGroup = async params => {
    let merchantGroups: MerchantGroup[] = [];

    this._loading = true;

    const filter = params?.filter || toJS(this._filter);
    const limit = params && typeof params.limit === 'number' ? params.limit : this._limit;
    const offset = params && typeof params.offset === 'number' ? params.offset : this._offset;

    this._filter = filter;
    this._limit = limit;
    this._offset = offset;

    await this._services.billing.requests
      .merchantGroupIndex({ params: { filter, offset, limit } })
      .then(({ data: { result } }: AxiosResponse<MerchantGroupIndexResponse>) => {
        if (result?.items && Array.isArray(result.items)) {
          merchantGroups = result.items.map(value => new MerchantGroup(value));

          this._merchantGroups = merchantGroups;

          this._existTokenizationEnabledMerchantGroup = Boolean(
            merchantGroups.filter(item => item.tokenizationEnabled).length,
          );
        }

        if (result?.total && typeof result.total === 'number') {
          this._total = result.total;
        }
      })
      .finally(this._endLoading);

    return merchantGroups;
  };

  @action public setLimit: SetLimit = limit => {
    this._limit = limit;
  };

  @action public setOffset: SetOffset = offset => {
    this._offset = offset;
  };

  @action public cleanUp = (): void => {
    this._merchantGroups = [];
    this._filter = {};
    this._loading = false;
    this._limit = 20;
    this._offset = 0;
    this._total = 0;
  };

  @computed public get list(): MerchantGroup[] {
    return toJS(this._merchantGroups);
  }

  @computed public get filter(): MerchantGroupFilterProps {
    return toJS(this._filter);
  }

  @computed public get loading(): boolean {
    return this._loading;
  }

  @computed public get limit(): number {
    return this._limit;
  }

  @computed public get offset(): number {
    return this._offset;
  }

  @computed public get total(): number {
    return this._total;
  }

  @computed public get existTokenizationEnabledMerchantGroup(): boolean {
    return this._existTokenizationEnabledMerchantGroup;
  }

  @computed public get sbpEnabled(): boolean {
    let sbpEnabled = false;

    if (this._merchantGroups.length > 0) {
      for (let i = 0; i < this._merchantGroups.length; i++) {
        const commissionList = this._merchantGroups[i].bankCommissionList;

        if (commissionList && commissionList.length > 0) {
          for (let j = 0; j < commissionList.length; j++) {
            if (Boolean(commissionList[j].sbpEnabled)) {
              this._isSbpEnabled = true;
              sbpEnabled = true;
              return sbpEnabled;
            }
          }
        }
      }
    }

    return sbpEnabled;
  }
}

export default MerchantGroupStore;
