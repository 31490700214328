import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useFastPaymentStore } from '../hooks';

import OpencityOperations from '@services/opencity/methods';
import BillingMethods from '@core/services/billing/methods';
import AuthenticationOperations from '@services/authentication/methods';
import IsAllowed from '@components/IsAllowed';
import * as routes from '@public/constants/routes';
import { MOBILE_DRAWER_WIDTH, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@public/constants/ui';
import Logo from '../Logo';
import config from '@constants/config';
import { isUsinsk } from '@core/constants/project';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minWidth: MOBILE_DRAWER_WIDTH,
    },
    drawerPaper: {
      borderRadius: 0,
    },
    icon: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    link: {
      textDecoration: 'none',
    },
    listItem: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(4),
      },

      paddingLeft: theme.spacing(8),
    },
    list: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0px',
      },
    },
    listSubheader: {
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      borderBottom: `0.5px solid ${theme.palette.primary.main}`,
      display: 'flex',
      height: HEADER_HEIGHT,
      justifyContent: 'space-between',
      padding: theme.spacing(6, 8),

      [theme.breakpoints.down('sm')]: {
        height: MOBILE_HEADER_HEIGHT,
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4),
      },
    },
    menuButton: {
      color: theme.palette.primary.main,
    },
    signControlsContainer: {
      margin: 0,
    },
  }),
);

interface MobileMenuProps {
  id: string;
}

const MobileMenu: FC<MobileMenuProps> = ({ id }) => {
  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);

  const handleSetDrawerIsOpen = (): void => setDrawerIsOpen(!drawerIsOpen);

  const { cleanUp } = useFastPaymentStore();

  const handleClick = (): void => {
    cleanUp();
  };

  const renderListItem = (
    text: string,
    route: string,
    itemId: string,
    operation?: OpencityOperations | AuthenticationOperations | BillingMethods,
    icon?: ReactElement,
  ): ReactElement =>
    operation ? (
      <IsAllowed operation={operation}>
        {(): ReactNode => (
          <div onClick={handleClick}>
            <Link to={route} className={classes.link}>
              <ListItem className={classes.listItem} id={itemId} button={true}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText
                  primaryTypographyProps={{
                    color: 'textPrimary',
                  }}
                >
                  {text}
                </ListItemText>
              </ListItem>
            </Link>
          </div>
        )}
      </IsAllowed>
    ) : (
      <Link to={route} className={classes.link}>
        <ListItem className={classes.listItem} id={itemId} button={true}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText
            primaryTypographyProps={{
              color: 'textPrimary',
            }}
          >
            {text}
          </ListItemText>
        </ListItem>
      </Link>
    );

  const signControlsList: ReactElement = (
    <List>
      {renderListItem('Войти', routes.SIGN_IN, `${id}__sign-in`)}
      {!isUsinsk && renderListItem('Регистрация', routes.SIGN_UP, `${id}__sign-up`)}
    </List>
  );

  const menuList: ReactElement = (
    <List
      className={classes.list}
      subheader={
        <ListSubheader className={classes.listSubheader}>
          <Logo id={id} />
          <IconButton
            className={classes.menuButton}
            id={`${id}__close-button`}
            onClick={handleSetDrawerIsOpen}
            color="primary"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </ListSubheader>
      }
    >
      {renderListItem('Главная', routes.ROOT, `${id}__home`, OpencityOperations.ISSUE_CREATE)}
      {!isUsinsk &&
        renderListItem('Заявка', routes.ORDER, `${id}__order`, OpencityOperations.ISSUE_CREATE)}
      {renderListItem(
        'Отключения',
        routes.SHUTDOWNS,
        `${id}__shutdowns`,
        OpencityOperations.INTERRUPT_INDEX,
      )}
      {renderListItem(
        'Рейтинги',
        routes.RATINGS,
        `${id}__ratings`,
        OpencityOperations.ORGANIZATION_RATING_INDEX,
      )}
      {config.helpCenterPublic && (
        <a
          href={config.helpCenterPublic}
          target={'_blank'}
          rel="noopener noreferrer"
          className={classes.link}
        >
          <ListItem className={classes.listItem} button={true}>
            <ListItemText
              primaryTypographyProps={{
                color: 'textPrimary',
              }}
            >
              Справка
            </ListItemText>
          </ListItem>
        </a>
      )}
    </List>
  );

  return (
    <>
      <IconButton
        className={classes.menuButton}
        id={`${id}__open-button`}
        onClick={handleSetDrawerIsOpen}
      >
        <MenuIcon className={classes.icon} />
      </IconButton>
      <SwipeableDrawer
        anchor="top"
        id={`${id}__drawer`}
        open={drawerIsOpen}
        classes={{ paper: classes.drawerPaper }}
        onOpen={handleSetDrawerIsOpen}
        onClose={handleSetDrawerIsOpen}
      >
        <div
          className={classes.drawerContent}
          tabIndex={0}
          role="button"
          onClick={handleSetDrawerIsOpen}
          onKeyDown={handleSetDrawerIsOpen}
        >
          {menuList}
          <Divider />
          {signControlsList}
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
