import React, { useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import ThemeProvider from '@public/components/Theme';
import { useAuthProvidersStore, useAuthenticationStore } from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      marginTop: `${theme.spacing(12)}px`,
      width: `${theme.spacing(20)}px !important`,
      height: `${theme.spacing(20)}px !important`,
      marginBottom: `${theme.spacing(12)}px`,
    },
    text: {
      color: theme.palette.text.primary,
    },
  }),
);

const AuthSocial: FC<{ setMountApprove: (value: boolean) => void }> = ({ setMountApprove }) => {
  const classes = useStyles();
  const { login } = useAuthProvidersStore();
  const { singByTokenData } = useAuthenticationStore();
  const { provider } = useParams();

  useEffect(() => {
    const url = new URL(window.location.href);
    const activationCode = url.searchParams.get('code');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');

    if (activationCode && provider) {
      login(provider, activationCode)
        .then(result => {
          singByTokenData(result);
        })
        .finally(() =>
          setTimeout(() => {
            window.location.href = '/';
            setMountApprove(true);
          }, 500),
        );
    }
  }, []);

  return (
    <ThemeProvider>
      <Grid container={true} direction={'column'} alignItems={'center'}>
        <Grid item={true}>
          <CircularProgress className={classes.loader} />
        </Grid>
        <Grid item={true}>
          <Typography className={classes.text}>Загрузка...</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default AuthSocial;
