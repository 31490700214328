import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import config from '@core/constants/config';

interface PageTitleProps {
  title?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} - ${config.projectName}` : config.projectName}</title>
    </Helmet>
  );
};

export default PageTitle;
